
/***
**Module Name: aboutus
 **File Name :  About.js
 **Project :    Zeus
 **Copyright(c) : X Platform Consulting.
 **Organization : Peafowl Inc
 **author :  Hemababu
 **author :  Hari
 **author :  Narasimha
 **author :  Rajesh
 **license :
 **version :  1.0.0
 **Created on :
 **Created on: Oct 20 2017
 **Last modified on: June 21 2018
 **Description : Contains details about the zeus organization.
 ***/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Header from '../authentication/Header';
import Footer from '../authentication/Footer';
//import NavLink from './NavLink';
//import Slider from 'react-slick';
import moment from 'moment';
import axios from "axios";
import { browserHistory } from 'react-router';
import { msToTime, priceFormat } from '../../redux/reducers/reducer';
import windowSize from 'react-window-size';
//import MusicPromoLink from "./MusicPromoLink";
import SignIn from '../authentication/SignIn';
import SignUp from '../authentication/SignUp';
// import VideoDonation from './VideoDonation';
import AlertPopup from '../AlertPopup';
//import EventCountdown from './EventCountdown';
import { removeSpecialCharecters, location } from '../../utils/utils';
var urlParams = new URLSearchParams(window.location.search);
let assetid = new URLSearchParams(window.location.search);
assetid = location("assetid");
let isPaused = false;
let userinfo = {};
let previewStartTime = 0;
var previewTimeleft = 20;
let previewProgressInt;
let isPreviewEnable = false;
let prevTimeleft;
let siteSettings = window.site;
let appSettings = window.app
let siteConfig = (siteSettings && siteSettings.config) || (appSettings && appSettings.defaultSiteSettings && appSettings.defaultSiteSettings.config);
let appStorage = appSettings.storage;
let token = appStorage.getItem('token');
let imagePrefix = siteConfig.commonResourcesUrl + siteConfig.appResourcesPath;
let imageSuffix = siteConfig.imageSuffix || "?auto=compress,format";
let defaultLandscape = siteConfig.defaultLandscape;
let bitmovinSettings = (siteConfig.player || {}).bitmovin || {};
let bitAnalyticsKey = bitmovinSettings.analyticsKey;
let playerKey = bitmovinSettings.playerKey;
let player;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    }
};
class AssetInfoPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assetid: this.props.assetid,
            creatorcode: this.props.creatorcode,
            assetsCount: 12,
            showmessage: false,
            notPublished: false,
            isMusic: false,
            userinfo: {},
            errors: {},
            playMovieCss: { display: "none" },
            isOpen: false,
            containerCSS: {},
            signInClick: false,
            signUpClick: false,
            videodonation_class: 'bhoechie-tab-content ',
            videodonation_class_active: 'list-group-item text-center',
            playStart: false,
            sideNav: 'none',
            subscriptions: [],
            isPreview: false,
            alertpopup: false,
            remainingCountdown: '',
            reminderPopup: false,
            startPlayer: false,
        }
        this.additionalAsset = this.additionalAsset.bind(this);
        this.buyChange = this.buyChange.bind(this);
        this.playAsset = this.playAsset.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.sideNavClose = this.sideNavClose.bind(this);
        this.logout = this.logout.bind(this);
    }
    componentDidMount() {
        let self = this;
        //console.log("this.props.assetid",this.props.params.assetid)
        // localStorage.removeItem("assetId");
        localStorage.removeItem("price");
        localStorage.removeItem("title");
        localStorage.removeItem("channelcode");
        localStorage.setItem("assetId", assetid);
        window.localStorage.setItem("assetId", assetid);
        let envoiScreeningApiBaseUrl = siteSettings && siteSettings.screeningApiBaseUrl;
        axios.post(envoiScreeningApiBaseUrl + '/assetDetails?appname=' + window.app.appName + '&assetid=' + assetid + '&env=' + window.app.appEnvName + locationUrl + '&appType=' + "screening")
            .then(function (response) {
                if (response && response.data && response.data.result && response.data.result.message) {
                    self.setState({ notPublished: true });
                } else if (response && response.data && response.data.result) {
                    self.assetDetails(response.data.result)
                }
            })
            .catch(function (error) {
            });
        // this.props.releatedAssets(self.state.assetid, self.state.assetsCount)

    }
    componentWillReceiveProps(nextProps) {
        var self = this;
        if (nextProps.relatedAssetsProps && nextProps.relatedAssetsProps !== this.props.relatedAssetsProps) {
            self.setState({
                releatedAssets: nextProps.relatedAssetsProps,
            });
        }
        if (nextProps.categoryAssetsProps && nextProps.categoryAssetsProps !== this.props.categoryAssetsProps) {
            self.setState({
                categoryAssetsData: nextProps.categoryAssetsProps.Items
            });
        }
        if (nextProps.reminderAsset && nextProps.reminderAsset != self.props.reminderAsset) {
            let reminderResult = nextProps.reminderAsset;
            //console.log('reminderResult', reminderResult)
            if (reminderResult && reminderResult.reminderStatus || reminderResult == "Created Successfully") {
                //console.log('nextProps.reminderAsset.statusCode', nextProps.reminderAsset.reminderStatus)
                self.setState({
                    reminderPopup: true,
                    reminderSucess: reminderResult.reminderStatus,
                    reminderResult
                });

            } else {
                self.setState({
                    reminderPopup: true,
                    reminderError: nextProps.reminderAsset.Message || nextProps.reminderAsset.message
                });
            }
        }
    }
    logout() {
        this.setState({ notPublished: false })
        if (token && landingPage == "learning") {
            window.location = "/modules";
        } else if (token) {
            window.location = "/justadded";
        } else {
            window.location = '/';
        }

    }
    assetDetails(resultData) {
        let self = this;
        console.log('resultData', resultData)

        if (resultData.preview && resultData.preview.enable && resultData.availability && !resultData.availability.free) {
            self.stateUpdate("isPreview", true);
            isPreviewEnable = true;
            previewStartTime = resultData.preview.startAt ? parseInt(resultData.preview.startAt) * 60 : 10
            previewTimeleft = resultData.preview.maxMin ? (parseInt(resultData.preview.maxMin) * 60) : 60;
            if (resultData.userPreview) {
                previewTimeleft = resultData.userPreview && "remainingTime" in resultData.userPreview && !resultData.userPreview.isPreviewed ? resultData.userPreview.remainingTime : previewTimeleft;
                previewTimeleft = resultData.userPreview && !resultData.userPreview.isPreviewed ? previewTimeleft : 0;
            }

        }

        self.setState({ trailerAssetinfo: resultData });
        let crew = resultData && resultData.crew;
        let grouped = _.mapValues(_.groupBy(crew, 'crewrole'),
            clist => clist.map(car => _.omit(car, 'crewrole')));
        self.setState({ crewList: grouped });
        if (resultData.mediaType == "audio") {
            self.setState({ isMusic: true })
        }
        if (resultData.availability && resultData.availability.free) {
            localStorage.setItem("avaiableAsset", "Free");
        }

        let availabilityBuy = resultData && resultData.availability || {};
        if (availabilityBuy && availabilityBuy.buyplans && Object.keys(availabilityBuy.buyplans).length > 0) {
            let buyPlans = Object.keys(availabilityBuy.buyplans)

            let subscriptions = buyPlans.filter(obj => obj != "allow" && obj != "buy" && obj != "gift" && obj != "rent" && obj != "free" && obj != "plan" && obj != "download")
            self.setState({
                subscriptions
            });

        }
        self.setState({
            availabilityBuy: availabilityBuy
        });
    }
    stateUpdate(field, value) {
        this.setState({ [field]: value });
    }
    // bitmovinplayer(playSource,assetid, playerDivID) {
    //     let container = document.getElementById(playerDivID);
    //     let config = {
    //         "key": playerKey,
    //     };
    //     let configWithAnalytics = Object.assign({}, config, {
    //         analytics: {
    //             key: bitAnalyticsKey,
    //             videoId: assetid
    //         },
    //         playback: {
    //             autoplay: true,
    //             muted: true,
    //         }
    //     });
    //     player = new bitmovin.player.Player(container, configWithAnalytics);
    //     // let playableSource = {};
    //     // // playableSource['poster'] = playSource.poster;
    //     // if (playSource.hls && playSource.hls != "") {
    //     //     let playerStream = playSource.hls.includes('.m3u8') ? 'hls' : 'progressive';
    //     //     playableSource[playerStream] = playSource.hls
    //     // }
    //    // console.log('playableSource',playableSource)
    //     if (player) {
    //         player.load(playSource).then(
    //             function (value) {

    //                 // $('#' + playerDivID).append($('#hoverplayerid'));

    //                 // $('#hoverplayerid').appendTo('#' + playerDivID);
    //                 $(".bmpui-ui-controlbar .bmpui-controlbar-bottom").css("display", "none");

    //                 player.on(bitmovin.player.PlayerEvent.Play, function () {
    //                     isPaused = false;
    //                 });
    //                 // //console.log('player', player)
    //                 player.on(bitmovin.player.PlayerEvent.Paused, function () {
    //                     isPaused = true;
    //                 });
    //                 console.log('Successfully created Bitmovin Player instance')
    //             },
    //             function (reason) {
    //                 // console.log('reason',reason)
    //                 console.log('Error while creating Bitmovin Player instance')
    //             }
    //         );
    //     }

    // }

    bitmovinplayer(playSource, assetid, playerDivID) {
        // console.log("playSource", playSource,asset)
        //  let markersArr = await this.markerPoints(this.props.markersData)
        let self = this;
        var config = {
            "key": playerKey
        };
        var configWithAnalytics = Object.assign({}, config, {
            analytics: {
                key: bitAnalyticsKey,
            },
            playback: {
                autoplay: true
            }
        });
        var container = document.getElementById(playerDivID);
        player = new bitmovin.player.Player(container, configWithAnalytics);
        player.load(playSource).then(
            function (value) {
                myUiManager = new bitmovin.playerui.UIFactory.buildDefaultUI(player);
                player.on(bitmovin.player.PlayerEvent.Play, function () {
                    isPaused = false;
                    // setInterval(function () {
                    //     if (!isPaused) {
                    //         self.props.markersTime();
                    //     }
                    // }, sendMarkersCurrenttime);

                });

                player.on(bitmovin.player.PlayerEvent.Paused, function () {
                    isPaused = true;
                });
                player.on(bitmovin.player.PlayerEvent.Error, function () {
                    self.setState({ notPublished: true })
                });
                // player.on(bitmovin.player.PlayerEvent.SourceLoaded, function () {
                // markersArr && markersArr.map(function (marker) {
                //     myUiManager.addTimelineMarker(marker);
                // });
                // })
                if (self.state.startPlayer) {
                    player.play();
                }
            },
            function (reason) {
            }
        );

    }

    // bitmovinplayer(playSource, assetid, playerDivID) {
    //     let self = this;
    //     let config = {
    //         "key": playerKey
    //     };
    //     let configWithAnalytics = Object.assign({}, config, {
    //         analytics: {
    //             key: bitAnalyticsKey,
    //             videoId: assetid
    //         },
    //         playback: {
    //             autoplay: true
    //         }
    //     });
    //     let startPlay, isPlaystarted, livePlayerStartTime;
    //     var container = document.getElementById(playerDivID);
    //     player = new bitmovin.player.Player(container, configWithAnalytics);

    //     player.load(playSource).then(
    //         function (value) {

    //         },
    //         function (reason) {
    //         }
    //     );

    //     player.on(bitmovin.player.PlayerEvent.Play, function () {
    //         isPaused = false;
    //     });
    //     player.on(bitmovin.player.PlayerEvent.Paused, function () {
    //         isPaused = true;
    //     });


    //     player.on(bitmovin.player.PlayerEvent.SourceLoaded, function () {
    //         self.stateUpdate("playStart", true);
    //         startPlay = true;
    //         if (self.state.isPreview) {
    //             $(".bmpui-controlbar-top").css("display", "none");
    //         } else {
    //             $(".bmpui-controlbar-top").css("display", "");
    //         }
    //     });

    //     player.on(bitmovin.player.PlayerEvent.Error, function () {
    //         self.setState({ notPublished: true })
    //     });
    // }
    progress(timeleft, timetotal, $element) {
        let self = this;
        if (prevTimeleft != timeleft) {
            var progressBarWidth = timeleft * $element.width() / timetotal;
            $element.find('div').animate({ width: progressBarWidth }, timeleft == timetotal ? 0 : 1000, "linear")
            prevTimeleft = timeleft;
        }
    }


    playFreeAsset(e, trailerAssetinfo, playerDivID) {
        let self = this;
        let siteSettings = window.site;
        let appSettings = window.app
        let siteConfig = (siteSettings && siteSettings.config) || (appSettings && appSettings.defaultSiteSettings && appSettings.defaultSiteSettings.config);

        //const { trailerAssetinfo } = this.state;
        let filename = trailerAssetinfo && trailerAssetinfo.assetname;
        let trailers = trailerAssetinfo.video || {}
        let m3u8Url = trailers && trailers.m3u8 && trailers.m3u8.proxyUrl
        let sourceCloudFront = siteConfig.proxiesCloudFront + m3u8Url
        let playSource = {
            "title": filename,
            "poster": trailerAssetinfo && trailerAssetinfo.landscape_logo_image,
            "hls": sourceCloudFront
        }
        console.log('playSource', playSource)
        // if (trailers && trailers.m3u8_url) {
        //     playSource["hls"] = sourceCloudFront + trailers.m3u8_url && trailers.m3u8_url.proxyUrl
        // }
        // else if (trailers && trailers.vdms_url) {
        //     playSource["hls"] = trailers.vdms_url
        // } else if (trailers && trailers.iconik_proxy_url) {
        //     playSource["progressive"] = trailers.iconik_proxy_url
        // } else if (trailers && trailers.eluvio_url) {
        //     playSource["hls"] = trailers.eluvio_url
        // } else if (trailers && trailers.Mp4_proxy_URL) {
        //     playSource["progressive"] = trailers.Mp4_proxy_URL
        // } else if (trailers && trailers.safestreamUrl) {
        //     playSource["hls"] = trailers.safestreamUrl
        // }

        // let height = "670px";
        // console.log("self.props.windowWidth", self.props.windowWidth)
        // if(self.props.windowWidth < 700){
        //     height = "520px"
        // } else if(self.props.windowWidth > 700 && self.props.windowWidth > 801){
        //     height = "590px"
        // }

        if (playSource.hls || playSource.progressive) {
            // self.setState({ playMovieCss: { position: "absolute", height: height, zIndex: "999", width: "100%" } })

            self.setState({ playMovieCss: { height: "100vh", marginTop: "-56px" } })
            $("#contentPlaceholder").addClass("hidediv");
            $("#headerPlayer").addClass("hidediv");
            self.bitmovinplayer(playSource, trailerAssetinfo.assetid, playerDivID)
        }
        // let userAssetPromoIdentify = {
        //     "title": "Asset Landing",
        //     "page": "AssetLandingPage",
        //     "created": new Date(),
        //     "assetid": trailerAssetinfo && trailerAssetinfo.assetid,
        //     "assetname": filename,

        // }
        // hotJarUserIdentify(userAssetPromoIdentify)

    }

    playAsset(e, item) {
        let self = this;
        // let assetInfo = { ...self.state.assetInfo }
        let routeRedirect = "asset";
        // if (item.assetType === "Episodic") {
        //     routeRedirect = "series";
        // }
        // if (item && item.assetType === "Episodic" && (item.availability && item.availability.buyplans && assetInfo.buy == false)) {
        //     self.setState({ buyPopup: true });
        // } 
        // else if (item && item.assetType === "Episodic" && self.state.selectedSName) {
        //     routeRedirect = "series";
        //     localStorage.setItem("assetinfopage", item.assetid);
        //     browserHistory.push("/player/" + routeRedirect + "/" + item.assetid + '/' + self.state.selectedSName.replace(/ /g, "_"))
        // } 
        console.log('item', item)
        if (item && item.availability && item.availability.free) {
            console.log('item12345', item)
            window.location = "/player?asset=" + item.assetid;
        }
        else if (item && item.preview && item.preview.enable && item.mediaType != "audio" && item.mediaType != "image" && item.mediaType != "document") {
            localStorage.setItem("assetinfopage", item.assetid);
            // browserHistory.push("/player/" + routeRedirect + "?=" + item.assetid)
            window.location = "/player?asset=" + item.assetid;
        } else if (item && !item.availability) {
            self.assetTypeBasedNavigation(item, routeRedirect);
            // localStorage.setItem("assetinfopage", item.assetid);
            // browserHistory.push("/player/" + routeRedirect + "/" + item.assetid)
        } else if (item && item.availability && item.availability.availableFrom && item.availability.availableTo) {
            let availabilityStart = moment(item.availability.availableFrom);
            let availabilityEnd = moment(item.availability.availableTo);
            if (moment().isAfter(availabilityStart) && moment().isBefore(availabilityEnd)) {
                self.assetTypeBasedNavigation(item, routeRedirect);
            } else {
                self.setState({ showmessage: true })
            }
        } else {
            self.assetTypeBasedNavigation(item, routeRedirect);
        }
    }
    assetTypeBasedNavigation(item, routeRedirect) {
        if (item && (item.assetType && item.assetType.toLowerCase() == "image" || item.mediaType == "image")) {
            browserHistory.push("/gallery/Image/" + item.assetid)
        } else if (item && item.mediaType == "audio" && item.audio) {
            browserHistory.push("/musicplayer/asset/" + (item.assetid || ''))
        } else if (item && item.mediaType == "document" && item.document) {
            browserHistory.push("/file/" + (item.assetid || ''));
        } else {
            localStorage.setItem("assetinfopage", item.assetid);
            console.log('item.assetid', item.assetid)
            // browserHistory.push("/player/" + routeRedirect + "?=" + item.assetid)
            window.location = "/player?asset=" + item.assetid;
        }
    }
    additionalAsset(e, item, playerDivID) {
        let self = this;
        let filename = item && item.assetname;
        let playSource = {
            "title": filename,
            "poster": item.landscape_logo_image,
            "hls": item && item.trailers && item.trailers.trailers1 && item.trailers.trailers1.m3u8_url
        }

        if (item && item.trailers && item.trailers.trailers1 && item.trailers.trailers1.m3u8_url)
            $("#" + playerDivID).removeClass("hidediv");
        self.bitmovinplayer(playSource, item.assetid, playerDivID)
    }
    handleAlertPopup() {

        this.setState({ alertpopup: false })

    }
    handleCancelClick() {
        // if (player) {
        //     player.pause();
        //     player.destroy();
        // }
        $("#headerPlayer").removeClass("hidediv");
        $("#contentPlaceholder").removeClass("hidediv");
        this.setState({ playMovieCss: { display: "none" } })

        if (player) {
            let playerDestroy = function () {
                return player.unload().then(function () {
                    //return player.destroy();
                    return 'success';
                });
            };
            playerDestroy();
            // divinsert = 0;
            player = null;
        }
    }
    otherAsset(pathlink) {
        window.location = "/?asset=" + pathlink;
    }

    buyClick(e, assetInfo) {
        let self = this;
        let mediaType = (assetInfo || {}).mediaType || '';
        let asset = (assetInfo || {}).assetid;
        localStorage.setItem("assetId", asset);
        self.mediaTypeStorage(mediaType);
        if (token) {
            window.location = "/buyAsset?assetid=" + assetInfo.assetid;
        } else {
            setTimeout(() => {
                self.setState({ signUpClick: true })
            }, 300)
            self.setState({ signUpClick: false })
        }
    }

    mediaTypeStorage(mediaType) {
        switch (mediaType) {
            case 'image':
                localStorage.setItem("a", "image");
                break;
            case 'audio':
                localStorage.setItem("a", "music");
                break;
            case 'document':
                localStorage.setItem("a", "document");
                break;
            default:
                localStorage.setItem("a", "asset");
        }
    }

    rentClick(e, assetInfo) {
        let self = this;
        let mediaType = (assetInfo || {}).mediaType;
        let asset = (assetInfo || {}).assetid;
        localStorage.setItem("assetId", asset);
        self.mediaTypeStorage(mediaType);
        if (token) {
            window.location = "/buyAsset?assetid=" + assetInfo.assetid;
        } else {
            setTimeout(() => {
                self.setState({ signUpClick: true })
            }, 300)
            self.setState({ signUpClick: false })
        }
    }
    subscriptionClick(e, trailerAssetinfo) {
        let self = this;
        localStorage.removeItem("action");
        if (token) {
            window.location = "/subscriptionpayment";
        } else {
            localStorage.setItem("a", "subscrption");
            setTimeout(() => {
                self.setState({ signUpClick: true })
            }, 300)
            self.setState({ signUpClick: false })
        }
    }

    mediaTypeStorageForGift(mediaType) {
        switch (mediaType) {
            case 'image':
                localStorage.setItem("a", "imagegift");
                break;
            case 'audio':
                localStorage.setItem("a", "musicgift");
                break;
            case 'document':
                localStorage.setItem("a", "documentgift");
                break;
            default:
                localStorage.setItem("a", "gift");
        }
    }

    giftClick(e, assetInfo) {
        let self = this;
        let asset = assetInfo && assetInfo.assetid;
        localStorage.setItem("assetId", asset);
        let mediaType = (assetInfo || {}).mediaType || '';
        self.mediaTypeStorageForGift(mediaType);
        if (token) {
            // window.location = `/asset?assetid=${asset && asset.assetid}`
            window.location = "/giftedPayment?assetid=" + assetInfo.assetid;
        } else {
            setTimeout(() => {
                self.setState({ signUpClick: true })
            }, 300)
            self.setState({ signUpClick: false })
        }
    }
    buyChange(e, value, assetInfo) {
        let self = this;
        self.handleAlertPopup()
        localStorage.setItem("contentType", value);
        localStorage.setItem("action", "buy");
        self.buyClick(e, assetInfo)

        // userinfo[name] = value;
        // this.setState({ userinfo })

    }
    rentChange(e, value, assetInfo) {
        let self = this;
        self.handleAlertPopup()
        localStorage.setItem("contentType", value);
        localStorage.setItem("action", "rent");
        self.rentClick(e, assetInfo)
        // userinfo[name] = value;
        // this.setState({ userinfo })

    }
    giftChange(e, value, assetInfo) {
        let self = this;
        self.handleAlertPopup()
        localStorage.setItem("contentType", value);
        localStorage.setItem("action", "gift");
        self.giftClick(e, assetInfo)

    }
    subscriptionChange(e, value, trailerAssetinfo) {
        let self = this;
        self.handleAlertPopup()
        localStorage.setItem("contentType", value);
        self.subscriptionClick(e, trailerAssetinfo)

    }
    downloadChange(e, value, trailerAssetinfo) {
        let self = this;
        localStorage.setItem("contentType", value);
        localStorage.setItem("action", "download");
        self.buyClick(e, trailerAssetinfo)
    }
    donation(e, trailerAssetinfo) {
        this.setState({ isOpen: true })
    }
    closeModal() {
        this.setState({ isOpen: false })
    }
    onChangePrice(price, priceMethod) {
        let self = this;
        self.setState({ price, priceMethod })
    }
    onChange(e) {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value, price: value })

    }
    continue() {
        let self = this;
        localStorage.setItem("a", "asset");
        localStorage.setItem("assetPrice", self.state.price);
        browserHistory.push("/paymentdonation?planId=true");
    }
    sideNavClose(nav, toggleBtns) {
        this.setState({
            sideNav: nav,
        });
        $('.bhoechie-tab-content').removeClass('scrollauto active');

        {
            toggleBtns == 'videodonation_class' ? this.setState({ videodonation_class: 'bhoechie-tab-content scrollauto active', videodonation_class_active: 'button1 list-group-item text-center active' }) :
                this.setState({ videodonation_class: 'bhoechie-tab-content', videodonation_class_active: 'button1 list-group-item text-center' })
        }

    }
    okClick() {
        let self = this;
        self.setState({ reminderPopup: false, });
        if (self.state.assetid && token) {
            axios.get(lambdaUrl + '/trailerAsset?appname=' + appname + '&token=' + token + '&assetid=' + self.state.assetid + '&env=' + environmentVariable + locationUrl)
                .then(function (response) {
                    if (response && response.data && response.data.result && response.data.result.message) {
                        self.setState({ notPublished: true });
                    } else if (response && response.data && response.data.result && response.data.result[0]) {
                        self.assetDetails(response.data.result[0])
                    }
                })
                .catch(function (error) {
                });
        }
    }
    reminderClick(e, item) {
        let self = this;
        // let reminderStatus;
        // if (item && item.reminderStatus == "Pending") {
        //     reminderStatus = "Unset"
        // } else {
        //     reminderStatus = "Pending"
        // }
        let assetid = item && item.assetid;
        let userassetType = "asset"
        self.props.reminder(assetid, userassetType);
        // self.setState({ reminderPopup: true, });
    }
    goBack(e) {
        let self = this;
        browserHistory.goBack();
    }
    handleClose() {
        window.location = `/onDemand`
    }
    render() {
        let self = this;
        let siteSettings = window.site;
        let appSettings = window.app
        let siteConfig = (siteSettings && siteSettings.config) || (appSettings && appSettings.defaultSiteSettings && appSettings.defaultSiteSettings.config);
        let imagePrefix = siteConfig.commonResourcesUrl + siteConfig.appResourcesPath;
        let imageSuffix = siteConfig.imageSuffix || "?auto=compress,format";
        let defaultLandscape = siteConfig.defaultLandscape;
        const { priceFormat, msToTime } = self.props;
        const { trailerAssetinfo, notPublished, playMovieCss, subscriptions, alertpopup, isPreview, remainingCountdown, signInClick, signUpClick, availabilityBuy, crewList } = self.state;
        let sourceCloudFront = siteConfig.sourceCloudFront
        let style, landscapeImage;
        let assetData = trailerAssetinfo || {};
        let includePortrait = trailerAssetinfo && trailerAssetinfo.portrait_logo_image && trailerAssetinfo.portrait_logo_image.includes('portrait_logo.jpg');
        if (!includePortrait) {
            landscapeImage = trailerAssetinfo && trailerAssetinfo.portrait_logo_image;
        } else if (trailerAssetinfo && trailerAssetinfo.mediaType == "image") {
            landscapeImage = trailerAssetinfo && trailerAssetinfo.image && trailerAssetinfo.image.sourcePath ? sourceCloudFront + "/" + trailerAssetinfo.image.sourcePath + imageSuffix : defaultLandscape + imageSuffix;
        } else {
            landscapeImage = trailerAssetinfo && trailerAssetinfo.thumbnail ? trailerAssetinfo.thumbnail : trailerAssetinfo.landscape_logo_image;
        }
        if (landscapeImage) {
            style = {
                backgroundImage: `url(${landscapeImage.replace(" ", "%20") + imageSuffix})`
            }
        }
        let bgClass = !includePortrait ? "hero_section mob_player portrait_bg" : "hero_section force-ht";
        let assetid = trailerAssetinfo && trailerAssetinfo;
        //let price = trailerAssetinfo && trailerAssetinfo.price;
        let playerCss = { position: "absolute", left: "0", right: "0", top: "0", bottom: "0" }
        let playMovieButton = { width: "24px", position: "absolute", zIndex: "9", top: "10px", right: "10px" }

        let trailerModal = { backgroundColor: "rgba(0, 0, 0, 0.5)" }
        let modelContent = { width: "100%" }
        let language = ["Australian English, ", "Brazilian Portuguese, ", "British English, ", "Canadian French,", "French, ", "German, ", "Indian English, ", "Indian Hindi, ", "Indian Telugu, ", "Italian, ", "Korean, ", "Modern Standard Arabic, ", "Spanish, ", "US English, ", "US Spanish"]

        let trailerPath;
        if (trailerAssetinfo && trailerAssetinfo.trailers && trailerAssetinfo.trailers.trailer1 && trailerAssetinfo.trailers.trailer1.m3u8_url) {
            trailerPath = trailerAssetinfo.trailers.trailer1.m3u8_url
        } else if (trailerAssetinfo && trailerAssetinfo.trailers && trailerAssetinfo.trailers.trailer1 && trailerAssetinfo.trailers.trailer1.vdms_url) {
            trailerPath = trailerAssetinfo.trailers.trailer1.vdms_url
        } else if (trailerAssetinfo && trailerAssetinfo.trailers && trailerAssetinfo.trailers.trailer1 && trailerAssetinfo.trailers.trailer1.iconik_proxy_url) {
            trailerPath = trailerAssetinfo.trailers.trailer1.iconik_proxy_url
        } else if (trailerAssetinfo && trailerAssetinfo.trailers && trailerAssetinfo.trailers.trailer1 && trailerAssetinfo.trailers.trailer1.eluvio_url) {
            trailerPath = trailerAssetinfo.trailers.trailer1.eluvio_url
        } else if (trailerAssetinfo && trailerAssetinfo.trailers && trailerAssetinfo.trailers.trailer1 && trailerAssetinfo.trailers.trailer1.Mp4_proxy_URL) {
            trailerPath = trailerAssetinfo.trailers.trailer1.Mp4_proxy_URL
        } else if (trailerAssetinfo && trailerAssetinfo.trailers && trailerAssetinfo.trailers.trailer1 && trailerAssetinfo.trailers.trailer1.safestreamUrl) {
            trailerPath = trailerAssetinfo.trailers.trailer1.safestreamUrl
        }


        let publicViewingAsset;
        if (trailerAssetinfo && trailerAssetinfo.m3u8_url) {
            publicViewingAsset = trailerAssetinfo.m3u8_url
        } else if (trailerAssetinfo && trailerAssetinfo.vdms_url) {
            publicViewingAsset = trailerAssetinfo.vdms_url
        } else if (trailerAssetinfo && trailerAssetinfo.iconik_proxy_url) {
            publicViewingAsset = trailerAssetinfo.iconik_proxy_url
        } else if (trailerAssetinfo && trailerAssetinfo.eluvio_url) {
            publicViewingAsset = trailerAssetinfo.eluvio_url
        } else if (trailerAssetinfo && trailerAssetinfo.Mp4_proxy_URL) {
            publicViewingAsset = trailerAssetinfo.Mp4_proxy_URL
        } else if (trailerAssetinfo && trailerAssetinfo.safestreamUrl) {
            publicViewingAsset = trailerAssetinfo.safestreamUrl
        }
        let buyAsset = false, rentAsset = false, giftAsset = false, downloadAsset = false;
        if (trailerAssetinfo && trailerAssetinfo.availability && trailerAssetinfo.availability.buyplans && trailerAssetinfo.availability.buyplans.buy && trailerAssetinfo.availability.buyplans.buy.contentPrice && Object.keys(trailerAssetinfo.availability.buyplans.buy.contentPrice).length > 0) {
            Object.keys(trailerAssetinfo.availability.buyplans.buy.contentPrice).forEach(element => {
                if (trailerAssetinfo.availability.buyplans.buy.contentPrice[element] != "" && trailerAssetinfo.availability.buyplans.buy.contentPrice[element] != "0")
                    buyAsset = true;
            });
        }
        if (trailerAssetinfo && trailerAssetinfo.availability && trailerAssetinfo.availability.buyplans && trailerAssetinfo.availability.buyplans.rent && trailerAssetinfo.availability.buyplans.rent.contentPrice && Object.keys(trailerAssetinfo.availability.buyplans.rent.contentPrice).length > 0) {
            Object.keys(trailerAssetinfo.availability.buyplans.rent.contentPrice).forEach(element => {
                if (trailerAssetinfo.availability.buyplans.rent.contentPrice[element] != "" && trailerAssetinfo.availability.buyplans.rent.contentPrice[element] != "0")
                    rentAsset = true;
            });
        }
        if (trailerAssetinfo && trailerAssetinfo.availability && trailerAssetinfo.availability.buyplans && trailerAssetinfo.availability.buyplans.gift && trailerAssetinfo.availability.buyplans.gift.contentPrice && Object.keys(trailerAssetinfo.availability.buyplans.gift.contentPrice).length > 0) {
            Object.keys(trailerAssetinfo.availability.buyplans.gift.contentPrice).forEach(element => {
                if (trailerAssetinfo.availability.buyplans.gift.contentPrice[element] != "" && trailerAssetinfo.availability.buyplans.gift.contentPrice[element] != "0")
                    giftAsset = true;
            });
        }
        if (trailerAssetinfo && trailerAssetinfo.availability && trailerAssetinfo.availability.buyplans && trailerAssetinfo.availability.buyplans.download && trailerAssetinfo.availability.buyplans.download.contentPrice && Object.keys(trailerAssetinfo.availability.buyplans.download.contentPrice).length > 0) {
            Object.keys(trailerAssetinfo.availability.buyplans.download.contentPrice).forEach(element => {
                if (trailerAssetinfo.availability.buyplans.download.contentPrice[element] != "" && trailerAssetinfo.availability.buyplans.download.contentPrice[element] != "0")
                    downloadAsset = true;
            });
        }
        let websiteLink = trailerAssetinfo && trailerAssetinfo.donation && trailerAssetinfo.donation.website;
        let description = trailerAssetinfo && trailerAssetinfo.description || "";
        if (description && description.length > 500) {
            description = description.substring(0, 500) + "..."
        }

        let instagram = trailerAssetinfo && trailerAssetinfo.instagram;
        let facebook = trailerAssetinfo && trailerAssetinfo.facebook;
        let twitter = trailerAssetinfo && trailerAssetinfo.twitter;
        let youtube = trailerAssetinfo && trailerAssetinfo.youtube;
        let spotify = trailerAssetinfo && trailerAssetinfo.spotify;
        let soundcloud = trailerAssetinfo && trailerAssetinfo.soundcloud;
        let filmfreeway = trailerAssetinfo && trailerAssetinfo.filmfreeway;
        let website = trailerAssetinfo && trailerAssetinfo.website;
        let imdb = trailerAssetinfo && trailerAssetinfo.imdb;
        let stage32 = trailerAssetinfo && trailerAssetinfo.stage32;
        let appleitunes = trailerAssetinfo && trailerAssetinfo.appleitunes;
        let invalidAsset = true;
        let availability = (trailerAssetinfo || {}).availability || {};
        let assetAvailable = true;
        let availableText = "";
        if (availability.availableFrom || availability.availableTo) {
            let availabilityStart = availability.availableFrom ? moment(availability.availableFrom) : false;
            let availabilityEnd = availability.availableTo ? moment(availability.availableTo) : false;
            let aStart = availability.availableFrom ? moment(availability.availableFrom).format('MMM Do YYYY, h:mm a') : '';
            let aEnd = availability.availableTo ? moment(availability.availableTo).format('MMM Do YYYY, h:mm a') : '';
            if (availabilityStart && availabilityEnd) {
                if (moment(new Date()).isAfter(availabilityStart) && moment(new Date()).isBefore(availabilityEnd)) {
                    availableText = "<i class='material-icons mr-2'>videocam</i>" + aStart + " to " + aEnd
                } else if (moment(new Date()).isAfter(availabilityEnd)) {
                    assetAvailable = false;
                    availableText = "<i class='material-icons mr-2'>videocam_off</i>Video not available at this moment";
                } else {
                    availableText = "<i class='material-icons mr-2'>sensors</i>" + aStart + " to " + aEnd
                }
            } else if (availabilityStart) {
                if (moment(new Date()).isAfter(availabilityStart)) {
                    availableText = "<i class='material-icons mr-2'>videocam</i>" + aStart;
                } else {
                    availableText = "<i class='material-icons mr-2'>sensors</i>" + aStart;
                }
            } else if (availabilityEnd) {
                if (moment(new Date()).isBefore(availabilityEnd)) {
                    availableText = "<i class='material-icons mr-2'>videocam</i>" + aEnd;
                } else {
                    assetAvailable = false;
                    availableText = "<i class='material-icons mr-2'>videocam_off</i>Video not available at this moment";
                }
            }
        }
        let partner = trailerAssetinfo && trailerAssetinfo.partner && trailerAssetinfo.partner[0] || {};
        let assetname = trailerAssetinfo && trailerAssetinfo.assetname ? trailerAssetinfo.assetname : '';
        if (assetname.length > 25) assetname = assetname.substring(0, 25);

        let buyplans = trailerAssetinfo && trailerAssetinfo.availability && trailerAssetinfo.availability.buyplans && Object.keys(trailerAssetinfo.availability.buyplans).length > 0 ? trailerAssetinfo.availability.buyplans : {};

        let subscriptionAsset;
        if (trailerAssetinfo && trailerAssetinfo.availability && trailerAssetinfo.availability.buyplans && subscriptions) {
            subscriptionAsset = true;
        }
        let donationPrice;
        if (trailerAssetinfo) {
            donationPrice = trailerAssetinfo.donation && trailerAssetinfo.donation.sessions && trailerAssetinfo.donation.sessions.length > 0 ? trailerAssetinfo.donation.sessions[0].price : trailerAssetinfo.donation && trailerAssetinfo.donation.price;
        }
        var todayDate = new Date();
        let remainderIcon = trailerAssetinfo && trailerAssetinfo.availability && (new Date(trailerAssetinfo.availability.availableFrom) > todayDate);
        let reminderActiveIcon = trailerAssetinfo && trailerAssetinfo.reminderStatus == "Set" ? "notifications_off" : "notification_important";
        // let eventStart = trailerAssetinfo && trailerAssetinfo.availability && trailerAssetinfo.availability.availableFrom;
        var settings = {
            dots: false,
            infinite: false,
            speed: 300,
            slidesToShow: 6,
            slidesToScroll: 6,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 6,
                        slidesToScroll: 6,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 6,
                        slidesToScroll: 6
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        //let cardWidth = $("#assetDetailsThumbPanel").height();
        let mobileWidth = $("#assetDetailsMobileId").height();
        let morelikethisWidth = $("#morelikethis").width();
        let morelikethisWidthPortrait = $("#morelikethisportrait").width();
        let morelikethisWidthImage = $("#morelikethisimage").width();
        return (
            <div>
                {/* <Header /> */}
                {alertpopup &&
                    <div className="novideo-alert watching_popup">
                        <button type="button" className="close" data-dismiss="modal" onClick={e => self.handleAlertPopup(e)} style={playMovieButton}>
                            <svg viewBox="0 0 25.5 25.5" className="modal-header-close-icon">
                                <title></title>
                                <path fill="#fff"
                                    d="M25.5 1.4L24 0 12.7 11.3 1.4 0 0 1.4l11.3 11.3L0 24l1.4 1.5 11.3-11.4L24 25.5l1.5-1.5-11.4-11.3z">
                                </path>
                            </svg>
                        </button>

                        <div className="alert-u">
                            <p className="u-pop-icon"><img src={imagesAssetResourcesPrefix + themePrefix + "watching-tv.png"} /></p>
                            <p>Please own it to continue watching.</p>
                            <div className="wtg_btns">
                                <div className="buy-options">
                                    {buyAsset == true && paymentEnable ?
                                        <div className="dropdown wtg_buy">
                                            <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                                                <i className="material-icons mr-2">shopping_cart</i>Buy
                                            </button>
                                            <div className="dropdown-menu" value={this.state.buyPrice} name="buyPrice"  >
                                                {
                                                    Object.keys(buyplans.buy.contentPrice).map((item, j) => {
                                                        return (buyplans.buy.contentPrice[item] != 0 &&
                                                            <span className="dropdown-item" key={j} value={item} onClick={e => self.buyChange(e, item, trailerAssetinfo)}>{item.toUpperCase()} {priceFormat(buyplans.buy.contentPrice[item], 'USD', 'en-US')} </span>)
                                                    })
                                                }
                                            </div>
                                        </div> : null}
                                    {rentAsset == true && paymentEnable ?
                                        <div className="dropdown wtg_rent">
                                            <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                                                <i className="material-icons mr-2">vpn_key</i>Rent
                                            </button>
                                            <div className="dropdown-menu" value={this.state.rentPrice} name="rentPrice"  >
                                                {
                                                    Object.keys(buyplans.rent.contentPrice).map((item, j) => {
                                                        return (buyplans.rent.contentPrice[item] != 0 &&
                                                            <span className="dropdown-item" key={j} value={item} onClick={e => self.rentChange(e, item, trailerAssetinfo)}>{item.toUpperCase()} {priceFormat(buyplans.rent.contentPrice[item], 'USD', 'en-US')} </span>)
                                                    })
                                                }
                                            </div>
                                        </div> : null}

                                    {giftAsset == true && paymentEnable ?
                                        <div className="dropdown wtg_gift">
                                            <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                                                <i className="material-icons mr-2">card_giftcard</i>Gift
                                            </button>
                                            <div className="dropdown-menu" value={this.state.giftPrice} name="giftPrice"  >
                                                {
                                                    Object.keys(buyplans.gift.contentPrice).map((item, j) => {
                                                        return (buyplans.gift.contentPrice[item] != 0 &&
                                                            <span className="dropdown-item" key={j} value={item} onClick={e => self.giftChange(e, item, trailerAssetinfo)}>{item.toUpperCase()} {priceFormat(buyplans.gift.contentPrice[item], 'USD', 'en-US')} </span>)
                                                    })
                                                }
                                            </div>
                                        </div> : null}
                                    {trailerAssetinfo && downloadAsset == true && paymentEnable ?
                                        <div className="dropdown">
                                            <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                                                <i className="material-icons mr-2">download</i>Download
                                            </button>
                                            <div className="dropdown-menu" value={this.state.downloadPrice} name="downloadPrice"  >
                                                {
                                                    Object.keys(buyplans.download.contentPrice).map((item, j) => {
                                                        return (buyplans.download.contentPrice[item] != 0 &&
                                                            <a className="dropdown-item" href="#!" key={j} value={item} onClick={e => self.downloadChange(e, item, trailerAssetinfo)}>{item.toUpperCase()} {priceFormat(buyplans.download.contentPrice[item], 'USD', 'en-US')} </a>)
                                                    })
                                                }
                                            </div>
                                        </div> : null}
                                    {subscriptionAsset && subscriptions.length > 0 && paymentEnable &&
                                        <div className="dropdown subscriptions wtg_gift">
                                            <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                                                <i className="material-icons mr-2">subscriptions</i>Subscribe
                                            </button>
                                            <div className="dropdown-menu dropdownsubscriptions" value={this.state.subscriptionPrice} name="subscriptionPrice">
                                                {
                                                    subscriptions && subscriptions.map((item, j) => {
                                                        return (<span key={j} className="dropdown-item" onClick={e => self.subscriptionChange(e, item, playerdata)}>{item.toUpperCase()} {priceFormat(buyplans && buyplans[item] && buyplans[item].contentPrice && buyplans[item].contentPrice.planPrice, 'USD', 'en-US')} </span>)
                                                    })
                                                }

                                            </div>
                                        </div>

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {isPreview && remainingCountdown && $("#headerPlayer").hasClass('hidediv') &&
                    <div id="countdown">
                        <p>You are watching a preview of {trailerAssetinfo && trailerAssetinfo.assetname}</p>
                        <div id="previewProgressBar">
                            <div ><span className="video_drsn">Enjoy your preview pass for the next {remainingCountdown}</span></div>
                        </div>
                    </div>
                }

                {/* {isMusic == true ?
                     <MusicPromoLink trailerAssetinfo={trailerAssetinfo} /> : */}
                <div className="page_bg">
                    <div className="partner_body">
                        {/* <div className="container-fluid top-fix blackbg nopad-y" id="headerPlayer">
                            <div className="container">
                                <div className="landing_wrapper">
                                    <div className="flixlanding_hed">
                                        <span className="land_logo" onClick={e => self.goBack(e)} style={{ "cursor": "pointer" }}> <img src={imagePrefix + "screening/images/headerlogo.png"} className="logo" />

                                        </span>
                                        <span onClick={e => self.handleClose()} className="cls"></span>

                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <Header />
                        {!token &&
                            <div className="landing_buttons" style={{ display: "none" }}>
                                <SignUp labelText="SIGN UP" cssClass="ln_signup" hiddenDiv="page_bg" removeHidden="authsignupdiv" clickAccess={signUpClick} buttonHide={false} />
                                <SignIn labelText="LOGIN" cssClass="ln_signin" hiddenDiv="page_bg" removeHidden="authlogindiv" clickAccess={signInClick} buttonHide={false} />
                            </div>}
                        <div className="partner-container pt-66">
                            {notPublished ?
                                <AlertPopup message="Sorry, this asset is not available for viewing." closePopup={this.logout} title='NOT AVAILABLE' imagePath="images/states_of_applications/warning_gray.png" showClose={true} />
                                :
                                <div className="ast_landing">
                                    <section className={bgClass} style={style}>
                                        <div className="dummy"></div>
                                        <div id="contentPlayer" className="play-movie" style={playMovieCss} >
                                            <div className="right-panel">
                                                <div className="content">
                                                    <div className="bitmovinplayer-container" id="player">
                                                        <button type="button" className="close" data-dismiss="modal" onClick={e => self.handleCancelClick(e)} style={playMovieButton}>
                                                            <svg viewBox="0 0 25.5 25.5" className="modal-header-close-icon">
                                                                <title></title>
                                                                <path fill="#fff"
                                                                    d="M25.5 1.4L24 0 12.7 11.3 1.4 0 0 1.4l11.3 11.3L0 24l1.4 1.5 11.3-11.4L24 25.5l1.5-1.5-11.4-11.3z">
                                                                </path>
                                                            </svg>
                                                        </button>

                                                        <div id="playMovie"></div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="contentPlaceholder">
                                            <div className="port-dummy mobile-logo"></div>
                                            <div className="grey-left"></div>
                                            {!includePortrait &&
                                                <div className="port_posterbg" style={style}></div>}
                                            <div className="container pos-center desktop-logo">
                                                {!includePortrait &&
                                                    <img src={self.state.trailerAssetinfo && self.state.trailerAssetinfo.portrait_logo_image + imageSuffix} className="hero-portrait" />}

                                                <div className="info-container ">
                                                    {trailerAssetinfo && trailerAssetinfo.logo_image && <img src={trailerAssetinfo.logo_image + imageSuffix} />}

                                                    <div className="hero_description">
                                                        <h1>{trailerAssetinfo && trailerAssetinfo.artistname}
                                                            <span>{assetname}</span></h1>

                                                    </div>
                                                    <div className="video-dscptn-text">
                                                        <p className="u-video-tit">

                                                            <span> {trailerAssetinfo && trailerAssetinfo.released ? trailerAssetinfo.released && moment(trailerAssetinfo.released).format('YYYY') + " |" : " "}</span>

                                                            {trailerAssetinfo && trailerAssetinfo.maturityrating && <span>{trailerAssetinfo.maturityrating && trailerAssetinfo.maturityrating != " " && trailerAssetinfo.maturityrating || ''} </span>}
                                                            {trailerAssetinfo && trailerAssetinfo.maturityrating && <span>|</span>}

                                                            <span> {trailerAssetinfo && trailerAssetinfo.genre && trailerAssetinfo.genre.length > 0 ? trailerAssetinfo.genre + " |" : " "}</span>

                                                            <span>
                                                                {trailerAssetinfo && trailerAssetinfo.duration && msToTime(trailerAssetinfo.duration) || " "}
                                                            </span>

                                                            {/* {trailerAssetinfo && trailerAssetinfo.released && <span>{moment(trailerAssetinfo.released).format('YYYY') != 0 && moment(trailerAssetinfo.released).format('YYYY') || 'Not Available'}</span>}
                                                                 {trailerAssetinfo && moment(trailerAssetinfo.released).format('YYYY') && <span>|</span>} */}


                                                        </p>
                                                        {trailerAssetinfo && trailerAssetinfo.description && <p className="">{description}</p>}
                                                        {trailerAssetinfo && trailerAssetinfo.cast && <p className="u-cast-text">
                                                            <span className="u-bright-text">Starring: </span>
                                                            <span>
                                                                {trailerAssetinfo && trailerAssetinfo.cast && trailerAssetinfo.cast.length > 0 && trailerAssetinfo.cast.map(function (item, i) {
                                                                    let cast = item && item.cast || item.castrole || '';
                                                                    cast = cast.split(',').splice(0, 3).join(", ");
                                                                    if (i < 3) {
                                                                        return (
                                                                            <span key={i} className="u-light-text">
                                                                                {cast}{!(i == trailerAssetinfo.cast.length - 1) && ","} &nbsp;
                                                                            </span>)
                                                                    }
                                                                })}
                                                            </span>
                                                        </p>}
                                                        {trailerAssetinfo && (trailerAssetinfo.crew) && <p className="u-cast-text crew">
                                                            {/* <h5 className="u-bright-text">Crew: </h5> */}
                                                            {crewList && Object.keys(crewList).length > 0 && Object.keys(crewList).map(function (item, c) {
                                                                if (c < 3) {
                                                                    return (
                                                                        <p key={c} className="u-light-text">

                                                                            {item}:  {crewList[item] && crewList[item].length > 0 && crewList[item].map(function (item, c) {
                                                                                return (
                                                                                    <span key={c} className="u-light-text">

                                                                                        {item.crew}

                                                                                    </span>)

                                                                            })}

                                                                        </p>)
                                                                }
                                                            })}{crewList && Object.keys(crewList).length > 3 && <p className="u-light-text"><span href="#moreHere">More Details ...</span></p>}
                                                        </p>}
                                                        {/* {trailerAssetinfo && (trailerAssetinfo.crew || trailerAssetinfo.writer) && <p className="u-cast-text">
                                                                 <span className="u-bright-text">Crew: </span>
                                                                 <span>
                                                                     {trailerAssetinfo && trailerAssetinfo.crew && trailerAssetinfo.crew.length > 0 && trailerAssetinfo.crew.map(function (item, c) {
                                                                         return (
                                                                             <span key={c} className="u-light-text">
                                                                                 {item && item.crew || item.crewrole}{!(c == trailerAssetinfo.crew.length - 1) && ","} &nbsp;
                                                                                 </span>)
                                                                     })}
                                                                     {trailerAssetinfo && trailerAssetinfo.writer && trailerAssetinfo.writer.length > 0 && trailerAssetinfo.writer.map(function (item, c) {
                                                                         return (
                                                                             <span key={c} className="u-light-text">
                                                                                 {c == 0 ? "," : ""} {item}{!(c == trailerAssetinfo.writer.length - 1) && ","} &nbsp;
                                                                                 </span>)
                                                                     })}
                                                                 </span>
                                                             </p>} */}
                                                    </div>
                                                    {/* {assetAvailable && */}
                                                    <div className="buy-options">
                                                        {/* {buyAsset == true ? */}
                                                        {/* {trailerAssetinfo && trailerAssetinfo.availability && trailerAssetinfo.availability.type == "Buy Plans" && trailerAssetinfo.availability.buy && trailerAssetinfo.availability.buy.contentPrice && trailerAssetinfo.availability.buy.contentPrice && Object.keys(trailerAssetinfo.availability.buy.contentPrice).length > 0 ? */}
                                                        {trailerAssetinfo && !trailerAssetinfo.buy && buyAsset == true ?
                                                            <div className="dropdown">
                                                                <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                                                                    <i className="material-icons mr-2">shopping_cart</i>Buy
                                                                </button>
                                                                <div className="dropdown-menu" value={this.state.buyPrice} name="buyPrice"  >
                                                                    {trailerAssetinfo && trailerAssetinfo.availability && trailerAssetinfo.availability.buyplans && trailerAssetinfo.availability.buyplans.buy && trailerAssetinfo.availability.buyplans.buy.contentPrice &&
                                                                        Object.keys(trailerAssetinfo.availability.buyplans.buy.contentPrice).map((item, j) => {
                                                                            return (trailerAssetinfo.availability.buyplans.buy.contentPrice[item] != 0 &&
                                                                                <span className="dropdown-item" key={j} value={item} onClick={e => self.buyChange(e, item, trailerAssetinfo)}>{item.toUpperCase()} {priceFormat(trailerAssetinfo.availability.buyplans.buy.contentPrice[item], 'USD', 'en-US')} </span>)
                                                                        })
                                                                    }
                                                                </div>
                                                            </div> : null}
                                                        {/* {trailerAssetinfo && trailerAssetinfo.availability && trailerAssetinfo.availability.type == "Buy Plans" && trailerAssetinfo.availability.rent && trailerAssetinfo.availability.rent.contentPrice && trailerAssetinfo.availability.rent.contentPrice && Object.keys(trailerAssetinfo.availability.rent.contentPrice).length > 0 ? */}
                                                        {trailerAssetinfo && (!trailerAssetinfo.rent && !trailerAssetinfo.buy) && rentAsset == true ?
                                                            <div className="dropdown">
                                                                <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                                                                    <i className="material-icons mr-2">vpn_key</i>Rent
                                                                </button>
                                                                <div className="dropdown-menu" value={this.state.rentPrice} name="rentPrice" >
                                                                    {
                                                                        trailerAssetinfo && trailerAssetinfo.availability && trailerAssetinfo.availability.buyplans && trailerAssetinfo.availability.buyplans.rent && trailerAssetinfo.availability.buyplans.rent.contentPrice &&
                                                                        Object.keys(trailerAssetinfo.availability.buyplans.rent.contentPrice).map((item, j) => {
                                                                            return (trailerAssetinfo.availability.buyplans.rent.contentPrice[item] != 0 &&
                                                                                <span className="dropdown-item" key={j} value={item} onClick={e => self.rentChange(e, item, trailerAssetinfo)}>{item.toUpperCase()} {priceFormat(trailerAssetinfo.availability.buyplans.rent.contentPrice[item], 'USD', 'en-US')} </span>)
                                                                        })
                                                                    }
                                                                </div>
                                                            </div> : null}
                                                        {/* {trailerAssetinfo && trailerAssetinfo.gifting && trailerAssetinfo.gifting.allow && trailerAssetinfo.gifting.contentPrice && Object.keys(trailerAssetinfo.gifting.contentPrice).length > 0 ? */}
                                                        {trailerAssetinfo && !trailerAssetinfo.gift && giftAsset == true ?
                                                            <div className="dropdown">
                                                                <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                                                                    <i className="material-icons mr-2">card_giftcard</i>Gift
                                                                </button>
                                                                <div className="dropdown-menu" value={this.state.giftPrice} name="giftPrice"  >
                                                                    {
                                                                        trailerAssetinfo && trailerAssetinfo.availability && trailerAssetinfo.availability.buyplans && trailerAssetinfo.availability.buyplans.gift && trailerAssetinfo.availability.buyplans.gift.allow == true && trailerAssetinfo.availability.buyplans.gift.contentPrice &&
                                                                        Object.keys(trailerAssetinfo.availability.buyplans.gift.contentPrice).map((item, j) => {
                                                                            return (trailerAssetinfo.availability.buyplans.gift.contentPrice[item] != 0 &&
                                                                                <span className="dropdown-item" key={j} value={item} onClick={e => self.giftChange(e, item, trailerAssetinfo)}>{item.toUpperCase()} {priceFormat(trailerAssetinfo.availability.buyplans.gift.contentPrice[item], 'USD', 'en-US')} </span>)
                                                                        })
                                                                    }
                                                                </div>
                                                            </div> : null}
                                                        {trailerAssetinfo && downloadAsset == true && paymentEnable ?
                                                            <div className="dropdown">
                                                                <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                                                                    <i className="material-icons mr-2">download</i>Download
                                                                </button>
                                                                <div className="dropdown-menu" value={this.state.downloadPrice} name="downloadPrice"  >
                                                                    {
                                                                        Object.keys(buyplans.download.contentPrice).map((item, j) => {
                                                                            return (buyplans.download.contentPrice[item] != 0 &&
                                                                                <a className="dropdown-item" href="#!" key={j} value={item} onClick={e => self.downloadChange(e, item, trailerAssetinfo)}>{item.toUpperCase()} {priceFormat(buyplans.download.contentPrice[item], 'USD', 'en-US')} </a>)
                                                                        })
                                                                    }
                                                                </div>
                                                            </div> : null}
                                                        {trailerAssetinfo && !trailerAssetinfo.buy && subscriptions && subscriptions.length > 0 &&
                                                            <div className="dropdown subscriptions">
                                                                <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                                                                    <i className="material-icons mr-2">subscriptions</i>Subscribe
                                                                </button>
                                                                <div className="dropdown-menu dropdownsubscriptions" value={this.state.subscriptionPrice} name="subscriptionPrice">
                                                                    {
                                                                        subscriptions && subscriptions.map((item, j) => {
                                                                            return (<span className="dropdown-item" key={j} onClick={e => self.subscriptionChange(e, item, trailerAssetinfo)}>{item.toUpperCase()} {priceFormat(availabilityBuy && availabilityBuy.buyplans && availabilityBuy.buyplans[item] && availabilityBuy.buyplans[item].contentPrice && availabilityBuy.buyplans[item].contentPrice.planPrice, 'USD', 'en-US')} </span>)
                                                                        })
                                                                    }

                                                                </div>
                                                            </div>

                                                        }

                                                        <div className="dropdown">

                                                            {/* {trailerAssetinfo && trailerPath &&
                                                                     <button type="button" className="btn" onClick={e => self.playerCheck(e, "playMovie")} ><i className="material-icons mr-2" >movie</i>Trailer</button>
                                                                 } */}
                                                            {trailerAssetinfo && trailerAssetinfo.availability && (trailerAssetinfo.availability.buyplans && (trailerAssetinfo.buy == true || trailerAssetinfo.gift == true || trailerAssetinfo.rent == true)) &&

                                                                <button type="button" className="btn" onClick={e => self.playAsset(e, trailerAssetinfo, "playMovie")}><i className="material-icons mr-2">play_arrow</i>Play</button>
                                                            }
                                                            {trailerAssetinfo && !trailerAssetinfo.availability && 
                                                                <button type="button" className="btn" onClick={e => self.playAsset(e, trailerAssetinfo, "playMovie")}><i className="material-icons mr-2">play_arrow</i>Play</button>
                                                            }
                                                            {trailerAssetinfo && trailerAssetinfo.preview && trailerAssetinfo.preview.enable || trailerAssetinfo && trailerAssetinfo.availability && (trailerAssetinfo.availability.free || trailerAssetinfo.availability.publicViewing == ("true" || true)) &&
                                                                <button type="button" className="btn" onClick={e => self.playAsset(e, trailerAssetinfo, "playMovie")}><i className="material-icons mr-2">play_arrow</i>Play</button>}
                                                            {/* <h1>thataji</h1> */}
                                                        </div>
                                                        {/* {trailerAssetinfo && trailerAssetinfo.donation && trailerAssetinfo.donation.allow == true ?
                                                                 <div className="dropdown">
                                                                     <button type="button" className="btn dt_btn" onClick={e => self.donation(e, trailerAssetinfo)} > <i className="material-icons mr-2">monetization_on</i> {trailerAssetinfo && trailerAssetinfo.donation && trailerAssetinfo.donation.label}</button>
                                                                 </div>
                                                                 : null} */}
                                                    </div>
                                                    {/* } */}
                                                    {/* {(availableText != "" || (reminder && remainderIcon && token)) && <div className="rem-text">
                                                         {availableText != "" &&
                                                             <p className="alert_text">{ReactHtmlParser(availableText)}</p>}
                                                         {reminder && remainderIcon && token && <div className="rem-bigbtn" onClick={(e) => self.reminderClick(e, trailerAssetinfo)} style={{ "cursor": "pointer" }}><i className="material-icons">{reminderActiveIcon}</i></div>}
                                                     </div>} */}

                                                    {/* {eventStart && moment().isBefore(eventStart) &&
                                                     <div className="event_timer">
                                                         <EventCountdown eventStart={eventStart} />
                                                     </div>} */}
                                                    {partner && partner.name && <div className="sponsor">
                                                        <p className="u-cast-text">Sponsered By: </p>
                                                        <p className="name">
                                                            <img src={partner.landscape_logo_image || landScapeImg} className="img" />
                                                            <span class="u-light-text">{partner.name}</span>
                                                        </p>
                                                    </div>}
                                                    {/* <div className="promo_social">
                                                         {facebook ?
                                                             <NavLink to={facebook} target="_blank"><img src={imagesAssetResourcesPrefix + "images/dark/social-icons/facebook.png" + imageSuffix} /></NavLink> : null}
                                                         {twitter ?
                                                             <NavLink to={twitter} target="_blank"><img src={imagesAssetResourcesPrefix + "images/dark/social-icons/twitter.png" + imageSuffix} /></NavLink> : null}
                                                         {instagram ?
                                                             <NavLink to={instagram} target="_blank"><img src={imagesAssetResourcesPrefix + "images/dark/social-icons/instagram.png" + imageSuffix} /></NavLink> : null}
                                                         {youtube ?
                                                             <NavLink to={youtube}><img src={imagesAssetResourcesPrefix + "images/dark/social-icons/youtube.png" + imageSuffix} /></NavLink> : null}
                                                         {spotify ?
                                                             <NavLink to={spotify}><img src={imagesAssetResourcesPrefix + "images/dark/social-icons/spotify.png" + imageSuffix} /></NavLink> : null}
                                                         {soundcloud ?
                                                             <NavLink to={soundcloud}><img src={imagesAssetResourcesPrefix + "images/dark/social-icons/soundcloud.png" + imageSuffix} /></NavLink> : null}
                                                         {stage32 ?
                                                             <NavLink to={stage32}><img src={imagesAssetResourcesPrefix + "images/dark/social-icons/stage32.png" + imageSuffix} /></NavLink> : null}
                                                         {filmfreeway ?
                                                             <NavLink to={filmfreeway}><img src={imagesAssetResourcesPrefix + "images/dark/social-icons/filmfreeway.png" + imageSuffix} /></NavLink> : null}
                                                         {website ?
                                                             <NavLink to={website}><img src={imagesAssetResourcesPrefix + "images/dark/social-icons/web.png" + imageSuffix} /></NavLink> : null}
                                                         {imdb ?
                                                             <NavLink to={imdb}><img src={imagesAssetResourcesPrefix + "images/dark/social-icons/imdb.png" + imageSuffix} /></NavLink> : null}
                                                         {appleitunes ?
                                                             <NavLink to={appleitunes}><img src={imagesAssetResourcesPrefix + "images/dark/social-icons/itunes.png" + imageSuffix} /></NavLink> : null}
                                                     </div> */}
                                                </div>
                                            </div>

                                        </div>
                                    </section>

                                    <section className="grey-section-bg mt-3 com_pad container" id='moreHere'>
                                        {assetData && assetData.length > 0 &&
                                            <h1>More Details</h1>}
                                        <div className="details-block">
                                            {assetData && assetData.genre && assetData.genre.length > 0 &&
                                                <div className="discription-block">
                                                    <div className="video-dscptn-text more-details-block">
                                                        <p className="details-label">Genres</p>
                                                        <p className="details-tags">{assetData && assetData.genre}</p>
                                                    </div>
                                                </div>
                                            }
                                            {assetData.award && assetData.award.length > 0 && <div className="discription-block">
                                                <div className="video-dscptn-text more-details-block">
                                                    <p className="details-label">Award</p>
                                                    <p className="details-tags">{assetData && assetData.writer && assetData.writer.toString()}</p>
                                                </div></div>}
                                            {assetData.distributer && assetData.distributer.length > 0 && <div className="discription-block">
                                                <div className="video-dscptn-text more-details-block">
                                                    <p className="details-label">Distributer</p>
                                                    <p className="details-tags">{assetData && assetData.distributer && assetData.distributer.toString()}</p>
                                                </div></div>}
                                            {assetData.screening && assetData.screening.length > 0 && <div className="discription-block">
                                                <div className="video-dscptn-text more-details-block">
                                                    <p className="details-label">Screening</p>
                                                    <p className="details-tags">{assetData && assetData.screening && assetData.screening.toString()}</p>
                                                </div></div>}
                                            {assetData.studio && assetData.studio.length > 0 && <div className="discription-block">
                                                <div className="video-dscptn-text more-details-block">
                                                    <p className="details-label">Studio</p>
                                                    <p className="details-tags">{assetData && assetData.studio && assetData.studio.toString()}</p>
                                                </div></div>}
                                            {assetData.writer && assetData.writer.length > 0 && <div className="discription-block">
                                                <div className="video-dscptn-text more-details-block">
                                                    <p className="details-label">Writer</p>
                                                    <p className="details-tags">{assetData && assetData.writer && assetData.writer.toString()}</p>
                                                </div></div>}
                                            {assetData.originalLanguage && assetData.originalLanguage.length > 0 && <div className="discription-block">
                                                <div className="video-dscptn-text more-details-block">
                                                    <p className="details-label">Language</p>
                                                    <p className="details-tags">{assetData && assetData.originalLanguage && assetData.originalLanguage.toString()}</p>
                                                </div>
                                            </div>}
                                        </div>
                                    </section>
                                    {/* <footer className="footer "><div className="container-fluid">
                                         <ul className='footer_nav'>
                                             <li><a href="#" className='active'>Live Channels</a></li>
                                             <li><a href="#">On Demand</a></li>
                                         </ul>
                                         <p className="footer-para">  © 2022 Envoi Live. All rights reserved. </p></div></footer> */}
                                    <Footer />
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapState = ({ }) => ({});
const mapDispatch = { msToTime, priceFormat };
export default windowSize(connect(mapState, mapDispatch)(AssetInfoPage));
 //export default (AssetInfoPage);