import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import CookieConsent, { Cookies } from "react-cookie-consent";

let menus = [
    {
        menu: 'Live Channels',
        menuId: 'Channels',
        route: '/'
    },
    {
        menu: 'On Demand',
        menuId: 'On Demand',
        route: '/onDemand'
    },
    {
        menu: 'Movies',
        menuId: 'Movies',
        route: '/movies'
    },
    {
        menu: 'Shows',
        menuId: 'Shows',
        route: '/shows'
    },
    {
        menu: 'About',
        menuId: 'About',
        route: '/about'
    },
    {
        menu: 'Contact Us',
        menuId: 'Contact Us',
        route: '/contactUs'
    },
    {
        menu: 'Terms',
        menuId: 'Terms',
        route: '/terms'
    },
]
class Footer extends Component {
    constructor() {
        super();
        this.state = {
            channelsData: [],
            activeMenu: window.location.pathname,
        }
        this.handleMenu = this.handleMenu.bind(this);
    }
    handleMenu = (item) => {
        this.setState({ activeMenu: item.route })
        browserHistory.push(item.route);
    }
    render() {
        let self = this;
        return (
            <React.Fragment>
                         <CookieConsent
                    location="bottom"
                    buttonText="ACCEPT"
                    declineButtonText="REJECT"
                    cookieName="acceptCookies"
                    expires={150}
                // enableDeclineButton
                // style={{ position: "absolute", "marginBottom":"-120px" }}
                >
                    <p>We are using cookies to give you the best experience on our website.</p>
                    <p> You can select if you want to accept or reject our cookies to the right.</p>
                    <p>By choosing accept you are consenting to our use of cookies in accordance with our  <a href="/privacy" className="landingAbout">Privacy Policy</a>.</p>
                </CookieConsent>
               <footer className="footer "><div className="container-fluid">
                    <ul className='footer_nav' style={{display : "block"}}>
                    {menus && menus.length > 0 && menus.map((menu, i) => {
                                        let menuClass = (self.state.activeMenu == menu.route) ? " active" : ""
                                        return (
                                            <li key={i}>
                                                <a href="" className={menuClass} aria-current="page" onClick={() => this.handleMenu(menu)}>{menu.menu}</a>
                                            </li>
                                        )
                                    })}
                        {/* <li><a href="#" className='active' onClick={() => this.handleMenu()}>Live Channels</a></li>
                        <li><a href="#" onClick={() => this.handleMenu(menu)}>On Demand</a></li> */}
                    </ul>
                    <p className="footer-para">  © 2023 Envoi Live. All rights reserved. </p></div>
           
                    </footer>

            </React.Fragment>
        )

    }
}
const mapState = ({ loading }) => ({ loading });
const mapDispatch = {};
export default connect(mapState, mapDispatch)(Footer);