// import React from 'react';
import React, { Component } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
class SelectionsExample extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: this.props.options,
        };
    }
    componentDidMount() {
        let self = this;
        const { labelKey } = this.props
        let options = this.props.options && this.props.options;
        if (labelKey == "name" || labelKey == "emailid") {
            options && options.map(function (item, i) {
                if (item[labelKey] && item[labelKey] != "") {
                    item[labelKey] = item[labelKey];
                    options[i][labelKey] = item[labelKey];
                } else {
                    item[labelKey] = " ";
                    options[i][labelKey] = " ";
                }
            })
        } else if (Array.isArray(options)) {
            options = options;
        } else {
            options = []
        }
        self.setState({ options })
    }

    render() {
        var self = this;
        const { options, selected, onChange, placeholder, labelKey, allowNew, multiple, isView, onKeyDown, handleInputChange, } = this.props
        return (
            <div className="w-100">
                <Typeahead
                    allowNew={allowNew}
                    newSelectionPrefix=''
                    id="my-typeahead-id"
                    clearButton
                    labelKey={labelKey && labelKey}
                    //item.lastName ? item.name + ' ' + item.lastName : item.name
                    //labelKey={option => `${option.name}  ${option.lastName}`}
                    selected={selected && selected}
                    multiple={multiple}
                    onChange={e => onChange(e, placeholder)}
                    options={options && options}
                    placeholder={'Select ' + placeholder + (multiple ? '(S)' : '')}
                    disabled={isView}
                    onKeyDown={onKeyDown}
                    onInputChange={handleInputChange}
                    ref={typeahead => this.typeahead = typeahead}
                // onInputChange={(text) => this.setState({text})}
                // onBlur={(e) => this.setState({selected: text ? [text] : []})}
                // defaultInputValue={self.state.formUsers}
                // defaultSelected={self.props.formUsers}
                />
            </div>
        )
    }
}
SelectionsExample.defaultProps = {
    allowNew: false,
    multiple: true,
    isView: false,
};
export default SelectionsExample;