import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
let menus = [
    {
    menu: 'Channels',
    menuId: 'Channels',
    route: '/'
},
// {
//     menu: 'On Demand',
//     menuId: 'On Demand',
//     route: '/onDemand'
// },
{
    menu: 'Movies',
    menuId: 'Movies',
    route: '/movies'
},
{
    menu: 'Shows',
    menuId: 'Shows',
    route: '/shows'
},
// {
//     menu: 'Podcasts',
//     menuId: 'Podcasts',
//     route: '/podcasts'
// },
]
class Header extends Component {
    constructor() {
        super();
        this.state = {
            channelsData: [],
            activeMenu: window.location.pathname,
        }
        this.handleMenu = this.handleMenu.bind(this);
    }

    componentDidMount() {
        
        let firstName = localStorage.getItem("firstname");
        let lastName = localStorage.getItem("lastname");
        let userName = firstName + " " + lastName;
        let userId = localStorage.getItem("user_id");
        this.setState({ userName, userId })

    }

    componentDidUpdate(prevProps) {

        if (prevProps.channels !== this.props.channels) {
            let channelsData = (this.props.channels || []);
            this.setState({ channelsData })
        }
    }




    logOut(e) {
        if (window.site && window.site.config.authentication.key === "auth0") {
            if (window.signinLock) {
                window.signinLock.logout()
                console.log('logged out');
            }
        }
        e.stopPropagation();
        e.preventDefault();
        let appStorage = window.app.storage;
        let appName = appStorage.getItem('appName');
        let appEnvName = appStorage.getItem('appEnvName');
        let windowLocation = appStorage.getItem('windowLocation');
        appStorage.clear();
        if (appName) appStorage.setItem('appName', appName);
        if (appEnvName) appStorage.setItem('appEnvName', appEnvName);
        if (windowLocation) appStorage.setItem('windowLocation', windowLocation);
        window.location = '/?appname=' + appName;
    };


    handleMenu = (item) => {
        let appStorage = window.app.storage;
        let appName = appStorage.getItem('appName');
        this.setState({ activeMenu: item.route })
        browserHistory.push(item.route+"?appname="+appName);
    }
    handleclick=()=>{
        let appStorage = window.app.storage;
        let appName = appStorage.getItem('appName');
        window.location = "/?appname="+appName
    }


    profileClick = () => {
        // browserHistory.push('/profile');
        window.location = '/profile'
    }
    
    ManageAccount = () => {
        // browserHistory.push('/profile');
        window.location = '/manageaccount'
    }
    render() {
        let self = this;
        const { userName } = self.state;
        let siteSettings = window.site;
        let siteConfig = siteSettings.config;
        let imagePrefix = siteConfig.commonResourcesUrl + siteConfig.appResourcesPath;
        return (
            <React.Fragment>
                <header className="fixed-top live-header">
                    <div className="container-fluid">
                        <nav className="navbar navbar-expand-md live-nav ">
                            <div className="nav-brand live-brand">
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="material-symbols-outlined"> menu </span>
                                </button>
                                <a className="navbar-brand" href="#" onClick={() => this.handleclick()}>
                                    <img src={imagePrefix + "screening/images/headerlogo.png"} />
                                </a>
                            </div>

                            <div className="collapse navbar-collapse live-menu" id="navbarNav">
                                {!this.props.isTerms &&<ul className="navbar-nav live-navlist">
                                    {menus && menus.length > 0 && menus.map((menu, i) => {
                                        let menuClass = (self.state.activeMenu == menu.route) ? "nav-link active" : "nav-link"
                                        return (
                                            <li className="nav-item" key={i}>
                                                <a href="" className={menuClass} aria-current="page" onClick={() => this.handleMenu(menu)}>{menu.menu}</a>
                                            </li>
                                        )
                                    })}
                                </ul>}
                            </div>
  <div className="hed_right">   </div>
                            {/* <div className="block-group profile-block">
                                
                                <div className="dropdown profile-dd">
                                    <button className="profile-btn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><div className="dropdown-item" onClick={(e) => self.profileClick(e)}><span className="material-symbols-outlined"> person_filled </span>{userName}</div></li>
                                        <li><div className="dropdown-item" onClick={(e) => self.logOut(e)}><span className="material-symbols-outlined"> trending_up </span>Log Out</div></li>
                                    </ul>
                                </div>
                            </div> */}

                        </nav>
                    </div>
                </header>

            </React.Fragment>
        )

    }
}

const mapState = ({ loading }) => ({ loading });
const mapDispatch = {};
export default connect(mapState, mapDispatch)(Header);