import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
let siteSettings = window.site;
let appSettings = window.app
let siteConfig = (siteSettings && siteSettings.config) || (appSettings && appSettings.defaultSiteSettings && appSettings.defaultSiteSettings.config);
let appStorage = appSettings.storage;
let token = appStorage.getItem('token');
let imagePrefix = siteConfig.commonResourcesUrl + siteConfig.appResourcesPath;
let imageSuffix = siteConfig.imageSuffix || "?auto=compress,format";
class Success extends Component {
    constructor(props) {
        super(props);
        this.state = {
            time: {},
            seconds: 2,
        };
        this.loadSiteConfig = this.loadSiteConfig.bind(this);
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
    }
    componentDidMount() {
        var self = this;
//self.loadSiteConfig();
        self.startTimer();
    }
    componentWillReceiveProps(nextProps) {
        let self = this;
    }

    loadSiteConfig() {
        let self = this;
        let configinterval = setInterval(function () {
            if (siteConfig && siteConfig.appResourcesUrl) {
                logoImage = imagePrefix + "screening/images/headerlogo.png" + imageSuffix;
                preLoader = imagePrefix + 'screening/images/preloader.png';
                self.setState({ logoImage: logoImage, preLoader: preLoader });
                clearInterval(configinterval);
            }

        }, 1000);
    }
    click(e) {
        let assetId = localStorage.getItem("assetId");
        window.location = "/player?asset=" + assetId;
    //    let assettype = localStorage.getItem("a");
    //     let imagetype = localStorage.getItem("i");
    //     let musictype = localStorage.getItem("m");
    //     console.log('musictype',musictype)
    //    localStorage.removeItem("a");
    //    let code = localStorage.getItem("channelcode");
    //     let assetId = localStorage.getItem("assetId");
    //     let actionType = localStorage.getItem("action");
    //     let actualRoute = localStorage.getItem("actualRoute");
    //     console.log('assetId',assetId)
    //     // let playlistType = localStorage.getItem("playlistType") ? localStorage.getItem("playlistType") : 'playlistbytag';
    //     // let seasonName = localStorage.getItem("seasonName");
    //     // playlistType = playlistType != 'undefined' ? playlistType : 'playlistbytag';
    //     // if (localStorage.getItem("isFromBuyPopupSeries")) {
    //     //     localStorage.removeItem("seasonName");
    //     //     browserHistory.push("/player/series/" + assetId + "/" + seasonName);
    //     // }
    //     // else if (actionType == "rent" && assettype == "channelasset" && (playlistType != 'multiCam' && playlistType != 'Multiplex')) {
    //     //     browserHistory.push("/player/channel/" + code);
    //     // }
    //      if ((assettype == "asset" || assettype == "gift") && assetId != null) {
    //         window.location = "/player?asset=" + item.assetid;
    //     } else if ((assettype == "image" || assettype == 'imagegift') && assetId != null) {
    //         browserHistory.push("/gallery/Image/" + assetId);
    //     } else if ((assettype == "music" || assettype == 'musicgift') && assetId != null) {
    //         browserHistory.push("/musicplayer/asset/" + assetId);
    //     } else if ((assettype == "document" || assettype == 'documentgift') && assetId != null) {
    //         browserHistory.push("/file/" + assetId);
    //     }
    //     // else if ((assettype == "channelasset" || code && code != "undefined") && (playlistType != 'multiCam' && playlistType != 'Multiplex')) {
    //     //     browserHistory.push("/player/channel/" + code);
    //     // } else if ((assettype == "channelasset" || code && code != "undefined") && (playlistType == 'multiCam' || playlistType == 'Multiplex')) {
    //     //     browserHistory.push('/players/' + code + '/' + playlistType);
    //     // } else if (assetId && assetId != "undefined" && assetId != null) {
    //     //     browserHistory.push("/player/asset/" + assetId);
    //     // } else {
    //     //     if (actualRoute && actualRoute != null)
    //     //         browserHistory.push("/" + actualRoute);
    //     //     else
    //     //         browserHistory.push("/" + defaultRoute);
    //     // }
    //    // window.location = "/buyAsset?assetid=" + assetId;
    }
    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    }
    startTimer() {
        if (this.timer == 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
        //self.click()
    }

    countDown() {
        let self = this;
        // Remove one second, set state so a re-render happens.
        let seconds = self.state.seconds - 1;
        self.setState({
            time: self.secondsToTime(seconds),
            seconds: seconds,
        });

        // Check if we're at zero.
        if (seconds == 0) {
            clearInterval(self.timer);
            self.click()
        }
    }
    render() {
        // let assetID = localStorage.getItem("assetid");
        // let appname = localStorage.getItem("anw");
        // let type = localStorage.getItem("t");
        // let limitPlayback = localStorage.getItem("lp");
        // let URL = "/" + appname + "/" + type + "/" + assetID + (limitPlayback != "" && "?limitPlayback="+ limitPlayback)
        return (
            <div>
                <div className="subscrib_wrp">
                    <div className="subscrib_logo"><img src={imagePrefix + "screening/images/headerlogo.png"} /></div>
                    <div className="subscrib_block">
                        <h4>Thank you for purchasing</h4>
                        {/* <div className="form-group">
                            <button onClick={e => this.click(e)} className="submit font-white-u">GO TO PLAY</button>
                        </div> */}
                        {/* <div className="timer">{this.state.time.s}</div> */}
                    </div>
                </div>

            </div>
        )
    }
}


const mapState = ({  }) => ({  });
const mapDispatch = {};
export default connect(mapState, mapDispatch)(Success);