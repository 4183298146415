import React, { Component } from 'react';
import {
  Redirect,
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { browserHistory } from 'react-router';
import * as config from '../config';
import { connect } from 'react-redux';
import { getWebsiteDefault, getLocation } from '../redux/reducers/reducer';
import LandingPage from './authentication/LandingPage';
import { UserProvider } from './userContext';
import AddDevice from "./authentication/AddDevice";
import CreateAnAccount from './authentication/CreateAnAccount';
import ActivationCode from './authentication/ActivationCode';
import AlertPopup from './AlertPopup';
import SuccessBought from './scheduler/SuccessBought';
import Loader from './Loader';
import SubscriptionPayment from './authentication/SubscriptionPayment';
import Terms from './authentication/Terms';
import Privacy from './authentication/Privacy';
import About from './authentication/About';
import Contactus from './authentication/Contactus';
import Plans from './authentication/Plans';
import Profile from './authentication/Profile';
import Scheduler from './scheduler/index';
import LiveChannels from './scheduler/LiveChannels';
import OnDemand from './scheduler/OnDemand';
import AssetInfoPage from './scheduler/AssetInfoPage';
import buyAsset from './scheduler/buyAsset';
import GiftPayment from './scheduler/GiftPayment';
import subscriptionPayment from './scheduler/subscriptionPayment';
import Movies from './scheduler/Movies';
import Shows from './scheduler/Shows';
import Player from './scheduler/Player';
import contactSuccess from './authentication/contactSuccess';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      status: localStorage.getItem('status'),
    }
  }
  componentDidMount() {
    this.props.getWebsiteDefault();
  }
  async componentDidUpdate(prevProps) {
    let nextProps = this.props
    if (nextProps.websiteDefaultData !== prevProps.websiteDefaultData) {
      let siteConfig = window.site.config || {};
      let imageSuffix = siteConfig.imageSuffix;
      let imagePrefix = siteConfig.imagePrefix
      this.setState({ imagePrefix, imageSuffix, siteConfig, isLoaded: true })
      this.props.getLocation()
    }
  }
  render() {
    const { imagePrefix, siteConfig, isLoaded, status } = this.state;
    const baseHref = config.BASE_HREF;
    return (isLoaded ?
      <div className="App full-width full-height envoi-scheduler channel-guide">
        <UserProvider value={{ imagePrefix, siteConfig }}>
          <Router history={browserHistory}>
            <Switch>
              <Route path="/create" component={CreateAnAccount} />
              <Route path='/adddevice' component={AddDevice} />
              <Route path="/activation" component={ActivationCode} />
              <Route path="/boughtsuccess" component={SuccessBought} />
              {/* <Route path="/subpayment/:planId" component={SubscriptionPayment} /> */}
              <Route path="/subscriptionpayment" component={subscriptionPayment} />
              <Route path="/buyAsset" component={buyAsset} />
              <Route path="/giftedPayment" component={GiftPayment} />
              <Route path="/terms" component={Terms} />
              <Route path="/about" component={About} />
              <Route path="/privacy" component={Privacy} />
              <Route path="/contactUs" component={Contactus} />
              <Route path="/plans" component={Plans} />
              <Route path="/profile" component={Profile} />
              <Route path="/scheduler" component={Scheduler} />
              <Route path="/onDemand" component={OnDemand} />
              <Route path="/asset" component={AssetInfoPage} />
              <Route path="/movies" component={Movies} />
              <Route path="/shows" component={Shows} />
              <Route path="/player" component={Player} />
              <Route path="/success" component={contactSuccess} />
              {/* <Route path={`${baseHref}`}>
                <OnDemand
                /> */}
                 <Route path={`${baseHref}`}>
                <LiveChannels
                /> 
              </Route>
            </Switch>
          </Router>
        </UserProvider>
        <AlertPopup />
      </div> : <Loader />
    );
  }
}

const mapState = ({ websiteDefaultData }) => ({ websiteDefaultData });
const mapDispatch = { getWebsiteDefault, getLocation };
export default connect(mapState, mapDispatch)(App);

