import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';

class ContactSuccess extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    componentDidMount() {
        let self = this
    }
    
    render() {
        let siteSettings = window.site;
        let siteConfig = siteSettings.config;
        let imagePrefix = siteConfig.commonResourcesUrl;
        var successimage = imagePrefix + "/screening/images/dark/success-n.png";
        return (
            <div className="content_page">
                <Header status isTerms={true} />

                <div className="page_content">

                    <div className="content_wrp">



                        <div className="succes_blk">
                            <div className="suc_logo"><i className="material-icons"><img src={successimage} /></i></div>
                            <h3>Success</h3>
                            <p>Thank you for contacting us,<br /> We will get back to you soon.</p>
                        </div>

                    </div>
                </div>



                <Footer />
            </div>
        )
    }
}

export default ContactSuccess;