/***
**Module Name:  Player
**File Name :  Player.js
**Project :       Zeus
**Copyright(c) : X Platform Consulting.
**Organization : Peafowl Inc
**author :  Hemababu
**author :  Ramki
**license :
**version :  1.0.0
**Created on :
**Last modified on: June 22 2018
**Description : Contains details Player the zeus organization.
*/
import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
//import AssetInfoShare from './AssetInfoShare';
import { getplayerinfo, priceFormat, channelAssets } from '../../redux/reducers/reducer';
import axios from "axios";
import Modal from 'react-modal';
import ReactGA from 'react-ga';
import { removeSpecialCharecters, location } from '../../utils/utils';
// import AssetCardAlertPopup from './AssetCardAlertPopup';
// import BuyOptionsPopupMaster from './BuyOptionsPopupMaster';
import AlertPopup from '../scheduler/AlertPopup';

//import './embed.css';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    }
};
Modal.setAppElement('#root')
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
} from 'react-share';
import NavLink from '../NavLink';
import asyncLoop from "node-async-loop";
import windowSize from 'react-window-size';
import Loader from '../Loader';
var isdebugEnable = false;
var assetInfo;
var durtime = 10;
let nextassetid;
let divplayer = "player";
let divinsert = 0;
let playlistShouldAutoAdvance = true;
let playlistShouldAutoPlay = true;
let playlist = [];
let playlistCursor;
let playlistShouldRepeat = true;
let playlistShouldResetOnFinish = true;
let playerinterval;
let isPaused = false;
let nextvideoData = false;
let getnextplay = false;
let testurl;
let stime = 5;
let playerStream = 'hls';
let manageUserActivityInterval = 10000;
let sendMarkersCurrenttime = 100;
let markersNextasset = [];
let manageUserActivityTimeinterval;
let previewStartTime = 0;
var previewTimeleft = 20;
let prevTimeleft;
let previewProgressInt;
let bitmovininterval;
let isPreviewEnable = false;
let assetStatusMessage = "";
let playerSourceMetadata = {};
let envoiActivityBaseUrl = 'https://activity.envoi.cloud/v1';
let presentTime = moment();
let startWatching = moment(presentTime).format("hh:mm:ss a");
let totaltimeWatched = 0;
let siteSettings = window.site;
let appSettings = window.app
let siteConfig = (siteSettings && siteSettings.config) || (appSettings && appSettings.defaultSiteSettings && appSettings.defaultSiteSettings.config);
let appStorage = appSettings.storage;
let token = appStorage.getItem('token');
let imagePrefix = siteConfig.commonResourcesUrl + siteConfig.appResourcesPath;
let imageSuffix = siteConfig.imageSuffix || "?auto=compress,format";
let defaultLandscape = siteConfig.defaultLandscape;
let player;
//let bitmovin;
let assetid = new URLSearchParams(window.location.search);
assetid = location("asset");
let channelCode = new URLSearchParams(window.location.search);
channelCode = location("channelCode");
function new_script(src) {
    return new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        script.src = src;
        script.addEventListener('load', function () {
            resolve();
        });
        script.addEventListener('error', function (e) {
            reject(e);
        });
        document.body.appendChild(script);
    })
};
// Promise Interface can ensure load the script only once.
// localStorage.removeItem("assetId")
// window.localStorage.removeItem("assetId");
let bitmovinSettings = (siteConfig.player || {}).bitmovin || {};
let playerKey = bitmovinSettings.playerKey;
let config = {
    "key": playerKey,
    playback: {
        autoplay: true,
        muted: siteConfig.player.playerMute
    },
    adaptation: {
        desktop: {
            preload: true
        },
        mobile: {
            preload: true
        }
    }
};

class Player extends Component {
    constructor(props) {
        super(props);
        let isLoggedIn = false;
        // if (!token) {
        //     window.location = '/';
        // } else {
        //     isLoggedIn = true;
        // }
        this.state = {
            remainingCountdown: '',
            showBuypopup: false,
            isPreview: false,
            alertpopup: false,
            defaultpopup: false,
            data: [],
            loader: true,
            login: isLoggedIn,
            // assetid: this.props.params.id,
            nextassetid: "",
            nextassetinfo: {},
            globalnextvideo: false,
            playerclass: "",
            thumbnailMessage: '',
            thumbnailLoader: false,
            item_id: '',
            poster_msg: '',
            poster_loader: false,
            thumbnailValue: '',
            poster_Val: '',
            currentSeekTime: '',
            durationErrorThumb: false,
            durationErrorPoster: false,
            inputShow: true,
            sideNav: 'none',
            bitmovinReady: false,
            showmessage: false

        };
        // if (siteConfig && siteConfig.segment && siteConfig.segment.enable === true) {
        //     let options = {
        //         pageName: this.constructor.displayName,
        //         "appName": appname
        //     }
        //     analytics.page(this.constructor.displayName, options);
        // }
        if (siteConfig && siteConfig.google && siteConfig.google.enable) {
            ReactGA.initialize(siteConfig.google.key);
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.playNext = this.playNext.bind(this);
        this.seektime = this.seektime.bind(this);
        this.createposter = this.createposter.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getSeekTime = this.getSeekTime.bind(this);
        this.sideNavClose = this.sideNavClose.bind(this);
        this.loadBitmovin = this.loadBitmovin.bind(this);
        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.stateUpdate = this.stateUpdate.bind(this);
        // this.getNextAsset = this.getNextAsset.bind(this);
        this.onShareClick = this.onShareClick.bind(this);
        this.closeplayershareModal = this.closeplayershareModal.bind(this);
        this.changeAssetsOrder = this.changeAssetsOrder.bind(this);
        this.okClick = this.okClick.bind(this);
        this.nextClick = this.nextClick.bind(this);
        this.previousClick = this.previousClick.bind(this);
        this.mounted = false;
    }
    componentDidMount() {
        let self = this;
        let channelDataUrl = localStorage.getItem("channelData");
        let channelname = localStorage.getItem("channelname");
        console.log('channelData', channelDataUrl)
        if (channelCode) {
            self.getPlayer(channelDataUrl,channelname)
        } else {
            let player;
            let bitmovin;
            let videotype;
            player = null
            self.mounted = true;
            let playbackUrls = true;
            // let paramType = this.props.params.type;
            // let extraParam = this.props.params.param;
            // let seasonName = this.props.params.param;
            let paramId = assetid;
            // if(channelCode){
            //     paramId = channelCode;
            // }else{
            //     paramId = assetid; 
            // }

            console.log('paramId', paramId)
            //self.props.getLocation();
            self.setState({ paramId })
            if (localStorage.getItem("loc")) {
                let locationObj = JSON.parse(localStorage.getItem("loc"));
                let locationData = locationObj && locationObj.headers || {};
                self.handleLocation(locationData);
            }



            playlist = [];
            playlistCursor = 0;
            divinsert = 0;
            markersNextasset = [];

            $(document.body).delegate('#bmpui-id-1041', 'click', function () {
                self.setState({
                    playershareClickPopup: true
                });
                player.pause();
            });
            $(document.body).delegate('#playerinfo', 'click', function () {
                self.setState({
                    playerinfo: !self.state.playerinfo

                }, () => {
                    if (self.state.playerinfo) {

                        $(".player_info2").addClass("active");
                    } else {
                        $(".player_info2").removeClass("active");
                    }
                });
            });

            // if (paramType == "channel") {
            //     if (siteConfig && siteConfig.settings) {
            //         settings = (siteConfig.settings || {}).channel || {};
            //     }

            //     // if (self.state.selectedItem && self.state.selectedItem.preview && self.state.selectedItem.preview.enable && !self.state.selectedItem.buy && !self.state.selectedItem.rent && !self.state.selectedItem.gift && self.state.selectedItem.availability && self.state.selectedItem.availability.type != 'Free') {
            //     //     self.stateUpdate("isPreview", true);
            //     //     previewStartTime = self.state.selectedItem.preview.startAt ? parseInt(self.state.selectedItem.preview.startAt) * 60 : 0
            //     //     previewTimeleft = self.state.selectedItem.preview.maxMin ? (parseInt(self.state.selectedItem.preview.maxMin) * 60) : 70;
            //     // }
            //     // if (extraParam != "playlistbyurl") {
            //     self.setState({ channelCode: paramId })
            //     //self.props.channelAssets(paramId, playbackUrls)
            //     // } else {
            //     //     const liveUrl = localStorage.getItem("live");
            //     //     //self.setState({ liveUrl });
            //     //     let liveUrlObj = [
            //     //         {
            //     //             'assetname': 'Live',
            //     //             'playerurls': {
            //     //                 'm3u8_Url': liveUrl
            //     //             }
            //     //         }
            //     //     ]
            //     //     self.fetchPlaylist(liveUrlObj);
            //     //     self.loadBitmovinInterval();
            //     // }
            // } else if (paramType == "event") {
            //     const liveUrl = localStorage.getItem("eventUrl");
            //     //self.setState({ liveUrl });
            //     let liveUrlObj = [
            //         {
            //             'assetname': '',
            //             'playerurls': {
            //                 'm3u8_url': liveUrl
            //             }
            //         }
            //     ]
            //     self.fetchPlaylist(liveUrlObj, "event");
            //     self.loadBitmovinInterval();
            // } else if (paramType == "playlist") {
            //     let playlistbody = {
            //         activity: "fetch",
            //         playBackUrls: true
            //     }
            //     self.props.getManagePlayList(playlistbody, paramId)
            // } else if (paramType == "aws") {
            //     const awsUrl = localStorage.getItem("aws");
            //     //self.setState({ liveUrl });
            //     let awsUrlObj = [
            //         {
            //             'assetname': 'Live',
            //             'playerurls': {
            //                 'm3u8_url': awsUrl
            //             }
            //         }
            //     ]
            //     self.fetchPlaylist(awsUrlObj, "aws");
            //     self.loadBitmovinInterval();
            // } else if (paramType == "series" && seasonName) {
            //     self.props.getepisodes(paramId, playbackUrls, seasonName.replace(/_/g, " "))
            // }

            // if (token && (paramType == "asset")) {
            //     // if (settings && settings.markers && settings.markers.enable) {
            //     //     let formData = {
            //     //         "mode": "fetch"
            //     //     }
            //     //     self.props.fetchMarkers(paramId, formData);
            //     // }
            //     //self.props.getassetinfo(paramId, token);
            //     self.props.getplayerinfo(paramId, token);
            // }
            // // else if (paramType != "channel" && paramType != "playlist" && paramType != "series") {
            // //     self.props.getplayerinfo(paramId)
            // // }
            // if(channelCode){
            //     self.props.channelAssets(channelCode, playbackUrls)
            // }else{
            //     self.props.getplayerinfo(paramId, null,token);
            // }
            self.props.getplayerinfo(paramId, null, token);
            if (videotype == "safestream" || videotype == "vdms" || videotype == "m3u8" || videotype == "eluvio")
                playerStream = "hls";
            else
                playerStream = "progressive";
            if (siteConfig && siteConfig.segment && siteConfig.segment.enable) {
                let options = {
                    "pageName": this.constructor.name,
                    "appName": window.app.appName,
                    "path": window.location.pathname,
                    "search": window.location.search,
                    "referrer": window.location.href,
                    "url": window.location.href,
                    "UserEmail": localStorage.getItem("email"),
                    "userid": localStorage.getItem("userid"),
                }
                window.analytics.page(options);
            }
            self.loadBitmovin();
        }
    }
    componentWillReceiveProps(nextProps) {
        let self = this;
        let playerdata = nextProps.playerdata;
        if (playerdata && playerdata !== this.props.playerdata) {
            if (playerdata.assetStatus && playerdata.assetid) {
                assetStatusMessage = playerdata.assetStatus;
                switch (assetStatusMessage.toUpperCase()) {
                    case 'NOT AVAILABLE':
                        assetStatusMessage = "Sorry, this video is not available for viewing in";
                        if (playerdata.availability && playerdata.availability.availableFrom) {
                            self.setState({ showmessage: true, eventStart: playerdata.availability && playerdata.availability.availableFrom })
                            //  browserHistory.push("/assetinfo/" + playerdata.assetid + "/asset");
                            console.log('assetStatusMessage', assetStatusMessage)
                        } else if (history.length > 2) {
                            browserHistory.goBack();
                        } else {
                            browserHistory.push("/onDemand")
                        }

                        break;
                    case 'NOT AVAILABLE IN THIS REGION':
                        assetStatusMessage = "This video is currently unavailable in your location";
                        self.setState({ showmessage: true });
                        break;
                    case 'SUBSCRIPTION REQUIRED..':
                        assetStatusMessage = "Your current membership does not include this asset. Please upgrade your subscription plan.";

                        self.setState({ showmessage: true, assetStatusTitle: 'ACCESS DENIED', assetStatusImage: 'images/states_of_applications/user_lock.png' });
                    default:
                        break;
                }

                self.setState({ assetStatusMessage });
                // return
            }
            if (playerdata.preview && playerdata.preview.enable && !playerdata.buy && !playerdata.rent && !playerdata.gift && playerdata.availability && !playerdata.availability.free) {
                self.stateUpdate("isPreview", true);
                isPreviewEnable = true;
                previewStartTime = playerdata.preview.startAt ? parseInt(playerdata.preview.startAt) * 60 : 10
                previewTimeleft = playerdata.preview.maxMin ? (parseInt(playerdata.preview.maxMin) * 60) : 60;
                if (playerdata.userPreview) {
                    previewTimeleft = playerdata.userPreview && "remainingTime" in playerdata.userPreview && !playerdata.userPreview.isPreviewed ? playerdata.userPreview.remainingTime : previewTimeleft;
                    previewTimeleft = playerdata.userPreview && !playerdata.userPreview.isPreviewed ? previewTimeleft : 0;
                }
                if (playerdata.availability && playerdata.availability.buyplans) {
                    let buyPlans = Object.keys(nextProps.playerdata.availability.buyplans)
                    let subscriptions = buyPlans.filter(obj => obj != "allow" && obj != "buy" && obj != "gift" && obj != "rent" && obj != "free" && obj != "plan" && obj != "download")
                    self.setState({
                        subscriptions
                    });
                }
                player = '';
                if (previewTimeleft != 0) {

                    self.loadBitmovin();
                } else if (previewTimeleft == 0) {
                    self.setState({ 'alertpopup': true });
                }
            }
            // if (this.props.params.type == "channel" && playerdata && playerdata.playlistType) {
            //     self.setState({ playlistType: playerdata.playlistType, isChannel: true })
            // }
            // if (playerdata.voting && playerdata.voting.isLive) {
            //     this.setState({ activeVoting: playerdata.voting.sessions[0] });
            // }
            // if (playerdata.donation && playerdata.donation.isLive) {
            //     this.setState({ activeDonation: playerdata.donation.sessions[0] });
            // }
            // if (playerdata && playerdata.voting) {
            //     this.setState({ selectedSession: playerdata.voting.sessions[0], selectedSessionNo: 0 });
            // }
            this.setState({
                loader: false,
                nextasset: playerdata,
                playerdata: playerdata,
                episodeno: playerdata.episodeno,
                episodename: playerdata.episodename,
                item_id: playerdata.item_id ?
                    playerdata.item_id : null,
            });
            if (this.props.windowWidth < 801) {
                divplayer = "mob_player";
                playerdata.carouselid &&
                    self.props.getAllcarouselsApiLatest(playerdata.carouselid);
                let playerDataDescription = playerdata.description;
                if (playerDataDescription) {
                    if (playerDataDescription.length > 75) {
                        let res = playerDataDescription.substring(0, 75);
                        self.setState({
                            assetDescription: res + "...",
                            fullDescription: true,
                            displayDescription: true
                        })
                    } else {
                        self.setState({
                            assetDescription: playerDataDescription,
                            fullDescription: false
                        })
                    }
                }
            }
            let seektime = 0;

            if (playerdata.watchlist) {
                seektime = playerdata["watchlist"].seektime;
            }

            let playerUrl = (playerdata.m3u8_url || playerdata.safestreamUrl || playerdata.vdms_url || playerdata.iconik_proxies_url || playerdata.mp4url || playerdata.eluvio_url || playerdata.Mp4_proxy_URL || playerdata.ipfs_url);
            if (playerUrl) {
                playerdata.playerurls = {
                    safestreamUrl: playerdata.safestreamUrl,
                    iconik_proxy_url: playerdata.iconik_proxies_url,
                    vdms_url: playerdata.vdms_url,
                    m3u8_url: playerdata.m3u8_url || playerdata.m3u8_Url,
                    source_Url: playerdata.mp4url,
                    eluvio_url: playerdata.eluvio_url,
                    Mp4_proxy_URL: playerdata.Mp4_proxy_URL,
                    ipfs_url: playerdata.ipfs_url
                }
                self.setState({
                    iconik_proxy_url: playerdata.iconik_proxies_url,
                    safestreamUrl: playerdata.safestreamUrl,
                    m3u8_url: playerdata.m3u8_url || playerdata.m3u8_Url,
                    vdms_url: playerdata.vdms_url,
                    eluvio_url: playerdata.eluvio_url,
                    Mp4_proxy_URL: playerdata.Mp4_proxy_URL,
                    source_Url: playerdata.mp4url,
                    assetname: playerdata.assetname,
                    ipfs_url: playerdata.ipfs_url
                })
                // let markerShow = nextProps.markers;
                // playerdata["markers"] = markerShow && self.markerPoints(markerShow) || [];
                self.loadBitmovinInterval();
                if (playerdata && playerdata.m3u8_url) {
                    playerdata.m3u8_url ? self.fetchPlaylist([playerdata], "asset") : self.assetLink(playerdata, "a");
                }
                // switch (videotype) {

                //     case "safestream":
                //         playerdata.safestreamUrl ? self.fetchPlaylist([playerdata], "asset") : self.assetLink(playerdata, "a");
                //         break;
                //     case "iconik":
                //         playerdata.iconik_proxies_url ? self.fetchPlaylist([playerdata], "asset") : self.assetLink(playerdata, "a");
                //         break;
                //     case "vdms":
                //         playerdata.vdms_url ? self.fetchPlaylist([playerdata], "asset") : self.assetLink(playerdata, "a");
                //         break;
                //     case "m3u8":
                //         playerdata.m3u8_url ? self.fetchPlaylist([playerdata], "asset") : self.assetLink(playerdata, "a");
                //         break;
                //     case "eluvio":
                //         playerdata.eluvio_url ? self.fetchPlaylist([playerdata], "asset") : self.assetLink(playerdata, "a");
                //         break;
                //     case "mp4":
                //         playerdata.Mp4_proxy_URL ? self.fetchPlaylist([playerdata], "asset") : self.assetLink(playerdata, "a");
                //         break;
                //     case "ipfs":
                //         playerdata.ipfs_url ? self.fetchPlaylist([playerdata], "asset") : self.assetLink(playerdata, "a");
                //         break;
                //     default:
                //         break;
                // }
                //self.fetchPlaylist([playerdata]);
                // if (!isPreviewEnable || previewTimeLeft != 0) {
                // self.loadBitmovinInterval();
                // }
                if (isPreviewEnable && previewTimeleft != 0) {
                    self.loadBitmovinInterval();
                } else if (!isPreviewEnable) {
                    self.loadBitmovinInterval();
                }
                nextvideoData = true;
                getnextplay = true;
                if (siteConfig && siteConfig.segment && siteConfig.segment.enable) {
                    let options = {
                        "appName": window.app.appName,
                        "path": window.location.pathname,
                        "search": window.location.search,
                        "referer": "",
                        "assetid": playerdata.assetid,
                        "title": playerdata.assetname,
                        "url": window.location.href,
                        "UserEmail": localStorage.getItem("email"),
                        "userid": localStorage.getItem("userid"),
                    }
                    analytics.page(self.constructor.name, options);
                }
            } else if (!playerdata.buy && !playerdata.rent && !playerdata.gift && playerdata.availability && playerdata.availability.buyplans) {
                self.setState({
                    loader: false,
                    // showBuypopup: true,
                    shareClickPopup: true
                })
            } else {
                self.setState({
                    loader: false,
                    // showmessage: true,
                    shareClickPopup: true,
                })
                //self.assetLink(playerdata, "l");

            }
        }
        if (nextProps.contentItems !== this.props.contentItems) {
            let nextAssetInfo = [];
            if (nextProps.contentItems && nextProps.contentItems.Items && nextProps.contentItems.Items.length > 0) {
                let sameAsset = nextProps.contentItems.Items.filter(function (item) {
                    return item.assetid.toLowerCase().search(
                        playerdata.assetid.toLowerCase()) !== -1;
                });
                if (sameAsset && sameAsset[0]) {
                    nextAssetInfo.push(sameAsset[0]);
                }
                let relatedAssets = nextProps.contentItems.Items.filter(function (item) {
                    return item.assetid.toLowerCase().search(
                        playerdata.assetid.toLowerCase()) == -1;
                });
                if (relatedAssets && relatedAssets.length > 0) {
                    relatedAssets.map(function (asset, a) {
                        nextAssetInfo.push(asset);
                    })
                }
                self.setState({
                    nextAssetInfo: nextAssetInfo,
                })
            }
        }
        if (nextProps.assetinfo !== this.props.assetinfo) {
            self.setState({
                assetinfoProps: nextProps.assetinfo
            })
        }



        // if (nextProps.locationData !== this.props.locationData) {
        //     let locationData = nextProps.locationData && nextProps.locationData.headers || {};
        //     self.handleLocation(locationData);
        // }

    }

    componentWillUnmount() {
        let self = this;
        self.mounted = false;
        self.killplayer();
    }
    assetLink(playerdata) {
        console.log('playerdata', playerdata)
        this.setState({ 'defaultpopup': true }, () => {

        });
        // let assetid = playerdata && playerdata.assetid;
        // let channelcode = playerdata && playerdata.channelcode;
        // let url = ""
        // if (channelcode && landingPage != 'learning') {
        //     url = "/channelinfo/" + channelcode;
        // } else if (assetid && landingPage != 'learning') {
        //     url = "/assetinfo/" + assetid + "/asset";
        // }
        // if (url != "") {
        //     browserHistory.push(url)
        // }else if (history.length > 2) {
        //     browserHistory.goBack();
        // }else{
        //     browserHistory.push("/" + defaultRoute)
        // }

    }
    async handleLocation(locationData) {
        let self = this;
        let geoLocation = {}
        if (Object.keys(locationData).length) {
            geoLocation['city'] = locationData['cloudfront-viewer-city'] && locationData['cloudfront-viewer-city'][0] && locationData['cloudfront-viewer-city'][0].value || '';
            geoLocation['region'] = locationData['cloudfront-viewer-country-region'] && locationData['cloudfront-viewer-country-region'][0] && locationData['cloudfront-viewer-country-region'][0].value || '';
            geoLocation['country'] = locationData['cloudfront-viewer-country-name'] && locationData['cloudfront-viewer-country-name'][0] && locationData['cloudfront-viewer-country-name'][0].value || '';
            geoLocation['postalcode'] = locationData['cloudfront-viewer-postal-code'] && locationData['cloudfront-viewer-postal-code'][0] && locationData['cloudfront-viewer-postal-code'][0].value || '';
            geoLocation['latitude'] = locationData['cloudfront-viewer-latitude'] && locationData['cloudfront-viewer-latitude'][0] && locationData['cloudfront-viewer-latitude'][0].value || '';
            geoLocation['longitude'] = locationData['cloudfront-viewer-longitude'] && locationData['cloudfront-viewer-longitude'][0] && locationData['cloudfront-viewer-longitude'][0].value || '';
            self.setState({ geoLocation });
        }
    }
    caseSwitching(videoType, playerData) {
        let self = this;
        const { videoIndex } = self.state;
        if (!playerData) return
        if (playerData.m3u8_url) {
            playerData.m3u8_url ? self.playItem(videoIndex) : self.assetLink(playerData);
        }
        // switch (videoType) {
        //     case "safestream":
        //         playerData.safestreamUrl ? self.playItem(videoIndex) : self.assetLink(playerData);
        //         break;
        //     case "iconik":
        //         playerData.iconik_proxies_url ? self.playItem(videoIndex) : self.assetLink(playerData);
        //         break;
        //     case "vdms":
        //         playerData.vdms_url ? self.playItem(videoIndex) : self.assetLink(playerData);
        //         break;
        //     case "m3u8":
        //         playerData.m3u8_url ? self.playItem(videoIndex) : self.assetLink(playerData);
        //         break;
        //     case "eluvio":
        //         playerData.eluvio_url ? self.playItem(videoIndex) : self.assetLink(playerData);
        //         break;
        //     case "mp4":
        //         playerData.Mp4_proxy_URL ? self.playItem(videoIndex) : self.assetLink(playerData);
        //         break;
        //     case "ipfs":
        //         playerData.ipfs_url ? self.playItem(videoIndex) : self.assetLink(playerData);
        //         break;
        //     default:
        //         break;
        // }
    }
    loadBitmovinInterval() {
        let self = this;
        const { videoIndex } = this.state;
        clearInterval(bitmovininterval);
        bitmovininterval = setInterval(function () {
            if (self.state.bitmovinReady && bitmovin) {
                self.playItem(videoIndex);
            }
            clearInterval(bitmovininterval);
        }, 1000);
    }
    okClick() {
        let self = this;
        self.setState({ showmessage: false });
    }
    loadBitmovin() {
        let self = this;
        if (self.props.windowWidth < 801) {
            divplayer = "mob_player";
        }
        self.setState({ bitmovinReady: true });
        let container = document.getElementById(divplayer);
        if (!player) {
            player = new bitmovin.player.Player(container, config);

            // if (player) {

            //     setTimeout(() => {
            //         myUiManager = new bitmovin.playerui.UIFactory.buildDefaultUI(player);
            //     }, 1000);
            //     window.PlayerKeyboardControlfn(player, true, customKeyMap);
            // }
        }
    }

    stateUpdate(field, value) {
        this.setState({ [field]: value });
    }
    markerPoints(list) {
        let self = this;
        let markersArr = [];
        if (list && list.length > 0) {
            list.forEach(element => {
                let obj = {};
                obj.title = element.title;
                obj.time = Math.round(element.seektime);
                obj.duration = element.duration || 0;
                obj.cssClasses = [element._id];
                markersArr.push(obj)

                $("head").append('<style type="text/css">.bmpui-ui-seekbar .bmpui-seekbar .bmpui-seekbar-markers > .bmpui-' + element._id + ' {border-color:' + element.markerColor + '}</style>');
            });
            self.setState({ instantMarkers: markersArr });
            return markersArr;
        }
    }
    isInputActive() {
        var activeElement = document.activeElement;
        var inputs = ['input', 'textarea'];

        if (activeElement && inputs.indexOf(activeElement.tagName.toLowerCase()) !== -1) {
            return true;
        } else {
            return false;
        }
    }
    fetchPlaylist(assets, type) {
        let self = this;
        console.log('assets', assets)
        // This would be a result from a get play list API call
        // let playlist = [];
        if (assets && assets.length > 0) {
            assets.forEach(function (asset, i) {
                if (!asset) {
                    return;
                }
                let playSource = {};
                if (asset.assetid) {
                    playSource['assetid'] = asset.assetid
                }
                if (asset.assetname) {
                    playSource['title'] = asset.assetname
                }
                if (asset.markers) {
                    playSource['markers'] = asset.markers
                }
                playSource['poster'] = asset.landscape_logo_image || dummy
                if (asset.preview && asset.preview.enable && !asset.buy && !asset.rent) {
                    playSource['options'] = {
                        startTime: previewStartTime
                    }
                }
                if (asset.subtitles) {
                    playSource['subtitles'] = asset.subtitles
                }
                if (asset.viewduration) {
                    playSource['viewduration'] = asset.viewduration
                }
                if (asset.formatedduration) {
                    playSource['formatedduration'] = asset.formatedduration
                }
                if (asset.video && asset.video.vr && asset.video.vr.enable) {
                    playSource['vr'] = {
                        startupMode: asset.video.vr.startupMode,
                        startPosition: asset.video.vr.startPosition,
                    }
                }
                let playerUrls = asset.playerurls || {};
                console.log("playerUrls", playerUrls)
                let playSourceUrl = (playerUrls.m3u8_Url || playerUrls.m3u8_url || playerUrls.safestreamUrl || playerUrls.vdms_url || (playerUrls.iconik_proxy_url || playerUrls.iconik_proxies_url) || playerUrls.Mp4_proxy_URL || playerUrls.eluvio_url || playerUrls.ipfs_url || playerUrls.source_Url || '');
                console.log("playSourceUrl", playSourceUrl)
                if (playerUrls && (playerUrls.m3u8_Url || playerUrls.m3u8_url)) {
                    playSourceUrl = playerUrls.m3u8_url || playerUrls.m3u8_Url;
                }
                // switch (videotype) {
                //     case "safestream":
                //         playSourceUrl = playerUrls.safestreamUrl;
                //         break;
                //     case "iconik":
                //         playSourceUrl = playerUrls.iconik_proxy_url || playerUrls.iconik_proxies_url;
                //         break;
                //     case "vdms":
                //         playSourceUrl = playerUrls.vdms_url;
                //         break;
                //     case "m3u8":
                //         playSourceUrl = playerUrls.m3u8_url || playerUrls.m3u8_Url;
                //         break;
                //     case "eluvio":
                //         playSourceUrl = playerurls.eluvio_url;
                //         break;
                //     case "mp4":
                //         playSourceUrl = playerUrls.Mp4_proxy_URL;
                //         break;
                //     case "ipfs":
                //         playSourceUrl = playerUrls.ipfs_url;
                //         break;
                //     default:
                //         playSourceUrl = playerUrls.Mp4_proxy_URL;
                //         break;
                // }

                if (type == "channel" && (playerUrls.m3u8_url || playerUrls.m3u8_Url)) {
                    playSourceUrl = playerUrls.m3u8_url || playerUrls.m3u8_Url
                }
                if (playSourceUrl && jQuery.type(playSourceUrl) === "string" && playSourceUrl != "") {
                    playerStream = playSourceUrl.includes('.m3u8') ? 'hls' : 'progressive';
                    playSource[playerStream] = playSourceUrl
                }
                console.log("playSource", playSource)
                if (playSource[playerStream])
                    playlist.push(playSource);

                console.log("playlist", playlist)
                // if (i === assets.length - 1 && playlist.length < 1) {
                //  self.assetLink(assets)
                // }
            })
        }
        // return playlist;
    }
    setPlayerEvents(player, config) {
        let self = this;
        config['enableByDefault'] = true;
        let playerevents = ((siteConfig.player || {}).bitmovin || {}).events || {};
        let eventKeys = Object.keys(playerevents);
        if (eventKeys.length > 0) {
            eventKeys.forEach(key => {

                if (playerevents && playerevents[key] && playerevents[key].enabled) {
                    if (config.hasOwnProperty(key) ? config[key].enabled : config.enableByDefault) {
                        player.on(bitmovin.player.PlayerEvent[key], event => self.envoiSendBitmovinPlayerEvent({ event }));
                    }
                }


            });
        }
    }

    envoiSendBitmovinPlayerEvent({ event }) {
        let url = `${envoiActivityBaseUrl}/bitmovin/player/event/${event.type}`
        // Inject metaedata into the event
        let eventOut = { event, metadata: playerSourceMetadata };
        axios.post(url, eventOut)
            .then((response) => {
                //console.log('response player events', response)
                //return response.data.result;
            })
            .catch((err) => {
                console.error.bind("getPurchasedProducts reducer.js error", err);
            })
    }
    getPlayer(hlsPlayer,channelname) {
        let self = this;

        let playSource = {};
        if (hlsPlayer && hlsPlayer != "") {
            playerStream = hlsPlayer.includes('.m3u8') ? 'hls' : 'progressive';
            playSource[playerStream] = hlsPlayer
            playSource["title"] = channelname
                self.bitmovinplayer(playSource)
           
        }


    }
    bitmovinplayer(playSource) {
        //  let markersArr = await this.markerPoints(this.props.markersData)
        let self = this;
        let isPaused;
        // let player;
        let siteSettings = window.site;
        let appSettings = window.app
        let siteConfig = (siteSettings && siteSettings.config) || (appSettings && appSettings.defaultSiteSettings && appSettings.defaultSiteSettings.config);
        let bitmovinSettings = (siteConfig.player || {}).bitmovin || {};
        let bitAnalyticsKey = bitmovinSettings.analyticsKey;
        let playerKey = bitmovinSettings.playerKey;
        var config = {
            "key": playerKey
        };
        console.log('config', config)
        var configWithAnalytics = Object.assign({}, config, {
            analytics: {
                key: bitAnalyticsKey,
            },
            playback: {
                autoplay: true,
                controls: true,
            },
            style: {
                width: "100%",
                aspectratio: "16:9",
                // controls: true,

            }
        });
        console.log('configWithAnalytics', configWithAnalytics)
        var container = document.getElementById("player");

         if (bitmovin) {


            player = new bitmovin.player.Player(container, configWithAnalytics);
            console.log("playSource", playSource)
            // player.load(playSource).then(
            //     function(player) {
            //       console.log('Successfully created Bitmovin Player instance');
            //     },
            //     function(reason) {
            //       console.log('Error while creating Bitmovin Player instance');
            //     },
            //   );
            // if (player) {
            //     let seekTime = player.getCurrentTime() || 0;
            //     player.load(source)
            //         .then(() => {
            //             player.seek(seekTime)
            //         });
            // }

            // let seekTime = player.currentTime() || 0;
            // console.log('seekTime',seekTime)
            player.load(playSource).then(
                function (value) {
                    //myUiManager = new bitmovin.playerui.UIFactory.buildDefaultUI(player);
                    player.on(bitmovin.player.PlayerEvent.Play, function () {
                        isPaused = false;
                    });

                    player.on(bitmovin.player.PlayerEvent.Paused, function () {
                        isPaused = true;
                    });
                    player.on(bitmovin.player.PlayerEvent.Error, function () {
                        self.setState({ notPublished: true })
                    });
                    // player.on(bitmovin.player.PlayerEvent.TimeChanged, (event) => {
                    //     // self.setState(event.time);
                    //     console.log('seekTime', event)
                    //     setCurrentTime(event.time);
                    //     console.log('currentTime', currentTime)
                    //     setTimeout(function () {
                    //         durationTime()
                    //     //console.log('seekTime12345', event)
                    //     }, 5000);

                    // });
                    //player.seek(seekTime)

                }

            );
          }

    }
    playItem(playListIndex) {
        let self = this;
        let siteSettings = window.site;
        let appSettings = window.app
        let siteConfig = (siteSettings && siteSettings.config) || (appSettings && appSettings.defaultSiteSettings && appSettings.defaultSiteSettings.config);
        // let bitmovinSettings = (siteConfig.player || {}).bitmovin || {};
        let bitAnalyticsKey = bitmovinSettings.analyticsKey;


        if (bitAnalyticsKey) {
            config["analytics"] = {
                key: bitAnalyticsKey,
                customData1: window.app.appName
            }
            if (localStorage.getItem("userid")) {
                config["analytics"]["userId"] = localStorage.getItem("userid")
            }
        }
        const { instantMarkers } = self.state;
        if (!player || !playlist || !playlist.length) {
            assetStatusMessage = "Playlist not available";
            self.setState({
                showmessage: true, assetStatusMessage
            })
            return;
        }
        let playlistMaxIndex = playlist.length - 1;
        let shouldPlay = true;
        if (playListIndex != "") {
            playlistCursor = playListIndex;
        }

        if (playlistCursor === -1) {
            // We only want to play if autoplay isn't already set on the player
            let playerConfig = player.getConfig();
            let playerIsSetToAutoPlay = playerConfig.playback.autoplay;
            shouldPlay = playlistShouldAutoPlay && !playerIsSetToAutoPlay;
            // Start over!
            // playlistCursor = 0;
        }
        if (playlistCursor <= playlistMaxIndex) {

            // We aren't at the end yet
            playlistCursor;
        } else if (playlistCursor > playlistMaxIndex) {
            // We've reached the end of the playlist
            // If we don't want to repeat we at least want to load the first element
            if (!playlistShouldRepeat) {
                if (playlistShouldResetOnFinish) {
                    shouldPlay = false;
                } else {
                    return;
                }
            }
            // Start over!
            playlistCursor = 0;
        }
        let source = playlist[0] || {};
        console.log('source', source)
        let startPlay, isPlaystarted, livePlayerStartTime;
        if (source.assetid) {
            //self.fetchAssetInfoandMarkers(source.assetid)
        }
        if (bitAnalyticsKey) {
            source["analytics"] = {
                'customData4': { Geolocation: self.state.geoLocation }
            }
            if (localStorage.getItem("envoiId")) {
                source["analytics"]["customData2"] = 'SessionId :' + (localStorage.getItem("envoiId"));
            }
            if (localStorage.getItem("userid")) {
                source["analytics"]["customData3"] = 'UserID :' + (localStorage.getItem("userid"))
            }
            if (source.assetid) {
                source["analytics"]["videoId"] = source.assetid
            }
            if (source.title) {
                source["analytics"]["title"] = source.title
            }
        }
        if (!this.mounted) {
            return
        }
        player.load(source)
            .then(() => {
                if (shouldPlay) {
                    player.play();

                }
                playerSourceMetadata = source;
                let bitmovinEvents = ((siteConfig.player || {}).bitmovin || {}).enablePlayerEvents;
                if (bitmovinEvents) {
                    self.setPlayerEvents(player, config)
                }

                self.stateUpdate("playStart", true);
                startPlay = true;
                if ((source.subtitles || []).length > 0) {
                    self.addSubTitles(source.subtitles)
                }
                //let seekStart = playlist[playlistCursor].viewduration || 0

                let urlParams = new URLSearchParams(window.location.search);
                if (urlParams.has('t') == true) {
                    let seek = urlParams.get('t');
                    player.seek(seek)
                    self.stateUpdate("assetPlayStart", false);
                }
                //player.seek(seekStart)
            });

        player.on(bitmovin.player.PlayerEvent.SourceLoaded, function () {
            instantMarkers && instantMarkers.map(function (marker) {
                myUiManager.addTimelineMarker(marker);
            });
            self.stateUpdate("playStart", true);
            startPlay = true;
            if (self.state.isPreview) {
                $(".bmpui-controlbar-top").css("display", "none");
            } else {
                $(".bmpui-controlbar-top").css("display", "");
            }
        });

        player.on(bitmovin.player.PlayerEvent.StallStarted, function () {
            isPaused = true;
        });
        player.on(bitmovin.player.PlayerEvent.StallEnded, function () {
            isPaused = false;
        });
        let currentTime = player.getCurrentTime();

        player.on(bitmovin.player.PlayerEvent.TimeChanged, function () {

            if (player.getDuration() && player.getCurrentTime()) {
                if (currentTime != player.getCurrentTime().toString().split(".")[0]) {
                    currentTime = player.getCurrentTime().toString().split(".")[0];
                    if (self.state.playerdata.voting && self.state.playerdata.voting.sessions && self.state.playerdata.voting.sessions.length > 0 && !self.state.playerdata.voting.isLive) {
                        self.updateSessionVoting(currentTime)
                    }
                    if (self.state.playerdata.donation && self.state.playerdata.donation.sessions && self.state.playerdata.donation.sessions.length > 0 && !self.state.playerdata.donation.isLive) {
                        self.updateSessionDonation(currentTime)
                    }
                    if ((player.getDuration() / 2) <= player.getCurrentTime()) {
                        self.setState({ isTestEnabled: true })
                    } else {
                        self.setState({ isTestEnabled: false })
                    }

                }
            }
            if (self.state.isPreview) {

                if (startPlay) {
                    isPlaystarted = true;
                }
                let previewRemaining = previewTimeleft - parseInt(player.getCurrentTime() - previewStartTime)
                //if (parseInt(player.getCurrentTime()) == previewTimeleft + previewStartTime) {
                if (parseInt(player.getCurrentTime()) == previewTimeleft) {
                    setTimeout(() => {
                        player.pause();
                        self.setState({ 'alertpopup': true }, () => {
                            console.log("function called3");
                            self.manageUserActivity();

                        });
                        self.stateUpdate("remainingCountdown", "");
                    }, 500);
                }
                if (isPlaystarted && startPlay) {
                    livePlayerStartTime = Math.round(player.getCurrentTime());
                    isPlaystarted = false;
                    let previewTimeleft2 = previewTimeleft;
                    if (previewProgressInt) {
                        clearInterval(previewProgressInt);
                    }
                    previewProgressInt = setInterval(function () {
                        if (!isPaused) {
                            previewTimeleft2 = previewTimeleft2 - 1;
                            if (previewTimeleft2 <= 0) {
                                self.stateUpdate("remainingCountdown", "");
                                clearInterval(previewProgressInt);
                            } else {
                                let formatedPtimeleft = moment.utc(previewTimeleft2 * 1000).format('HH:mm:ss');
                                self.stateUpdate("remainingCountdown", formatedPtimeleft);
                                self.progress(previewTimeleft2, previewTimeleft, $('#previewProgressBar'));
                            }
                        }
                    }, 1000);
                }
                if (livePlayerStartTime && (livePlayerStartTime + previewTimeleft) == Math.round(player.getCurrentTime())) {
                    player.pause();
                    self.stateUpdate("remainingCountdown", "");
                    clearInterval(previewProgressInt);
                    self.setState({ 'alertpopup': true }, () => {
                        console.log("function called2");
                        self.manageUserActivity();

                    });
                }
                startPlay = false;
                if (previewRemaining <= 0) {
                    // self.stateUpdate("remainingCountdown", "");
                } else {
                    previewRemaining = moment.utc(previewRemaining * 1000).format('HH:mm:ss');
                    //  self.stateUpdate("remainingCountdown", previewRemaining);
                    if (self.state.isPreview) {
                        $(".bmpui-controlbar-top").css("display", "none");
                    } else {
                        $(".bmpui-controlbar-top").css("display", "");
                    }
                }
            }
            isPlaystarted = true;

        });
        player.on(bitmovin.player.PlayerEvent.Ready, function () {

            self.addSelectDropdown();
            self.setState({
                playerVersion: player.version
            })
        });
        player.on(bitmovin.player.PlayerEvent.Paused, function () {

            isPaused = true;
            let presentTime = moment();
            let endedWatching = moment(presentTime).format("hh:mm:ss a");
            let startTime = moment(startWatching, 'hh:mm:ss a');
            let endTime = moment(endedWatching, 'hh:mm:ss a');
            let duration = endTime.diff(startTime, 'hh:mm:ss a');
            totaltimeWatched = totaltimeWatched + duration;
        });
        player.on(bitmovin.player.PlayerEvent.Play, function () {

            isPaused = false;
            let presentTime = moment();
            startWatching = moment(presentTime).format("hh:mm:ss a");
            if (self.state.assetPlayStart && !self.state.isPreview) {
                player.seek(0)
            }
            self.stateUpdate("assetPlayStart", false);
            if (nextvideoData) {
                self.getNextAsset();
            }
            //self.addSelectDropdown();
            setInterval(function () {
                if (!isPaused) {
                    self.markersTime()
                }
            }, sendMarkersCurrenttime);
            if (manageUserActivityTimeinterval) {
                clearInterval(manageUserActivityTimeinterval);
            }
            manageUserActivityTimeinterval = setInterval(function () {
                if (!isPaused) {
                    self.manageUserActivity()
                    console.log("function called 1");
                }
            }, manageUserActivityInterval);
        });
        player.on(bitmovin.player.PlayerEvent.PlaybackFinished, function () {
            let nextassetResult = { ...self.state.nextassetResult }
            if (getnextplay && nextassetResult && nextAssetPlay) {
                nextvideoData = true;
                self.setState({
                    assetid: self.state.nextassetid,
                    iconik_proxy_url: nextassetResult.playerurls.iconik_proxies_url,
                    safestreamUrl: nextassetResult.playerurls.safestreamUrl,
                    m3u8_url: nextassetResult.playerurls.m3u8_Url || nextassetResult.playerurls.m3u8_url,
                    vdms_url: nextassetResult.playerurls.vdms_url,
                    eluvio_url: nextassetResult.playerurls.eluvio_url,
                    Mp4_proxy_URL: nextassetResult.playerurls.Mp4_proxy_URL,
                    ipfs_url: nextassetResult.playerurls.ipfs_url
                })
                // if (playerPanelStatus) {
                //     for (const property in playerPanelStatus) {
                //         self.props.ChangePlayerPanlestatus(property, false)
                //     }
                // }
                if (landingPage != 'learning') {
                    let videoIndexValue = self.state.videoIndex;
                    let nextValue = videoIndexValue + 1;
                    let indexValue = videoIndexValue == playlist.length - 1 ? videoIndexValue : videoIndexValue > playlist.length - 1 ? 0 : nextValue;
                    self.setState({ videoIndex: indexValue }, () => {
                        self.caseSwitching(videotype, nextassetResult.playerurls)
                    });
                }

            } else {
                //self.playItem();
                const { assets } = self.state;
                //console.log('playlistCursor > assets.length -1',playlistCursor,assets.length -1)
                if (playlistCursor < assets.length - 1) {

                    self.nextClick();
                }

            }


        });
        player.on(bitmovin.player.PlayerEvent.Error, function () {
            self.setState({ showmessage: true, assetStatusMessage: "Cannot able to access the video URL" })
        });
        let events = bitmovin.player.PlayerEvent; //Player Events
        if (events) {
            for (var evntCnt = 0, len = Object.keys(events).length; evntCnt < len; evntCnt++) {
                event = Object.keys(events)[evntCnt];
                player.on(bitmovin.player.PlayerEvent[event], function (seekEvent) {
                    if (seekEvent.type != "timechanged") {

                        //console.log('getLatency',player.lowlatency.getLatency());

                        if (self.state.playerBuffered != player.getVideoBufferLength()) {
                            self.setState({
                                playerBuffered: player.getVideoBufferLength().toFixed(2)
                            })
                        }

                        if (seekEvent.type == 'videoplaybackqualitychanged') {
                            self.setState({
                                playerQuality: seekEvent.targetQuality.height
                            })
                        }
                    }

                    if (!isPaused) {
                        self.logEvent(seekEvent)
                    }
                });
            }
        }
    }
    updateSessionVoting(currentTime) {
        let self = this;
        var result = self.state.playerdata.voting.sessions.find(element => {
            return element.startAt <= currentTime && currentTime <= element.endAt
        });
        if (!_.isEqual(result, self.state.activeVoting)) {
            if (!result) {
                self.sideNavClose('none')
            }
            self.setState({ activeVoting: result });
        }
    }
    updateSessionDonation(currentTime) {
        let self = this;
        var result = self.state.playerdata.donation.sessions.find(element => {
            return element.startAt <= currentTime && currentTime <= element.endAt
        });
        if (!_.isEqual(result, self.state.activeDonation)) {
            if (!result) {
                self.sideNavClose('none')
            }
            self.setState({ activeDonation: result });
        }
    }
    fetchAssetInfoandMarkers(assetid) {
        let self = this;
        if (settings && settings.markers && settings.markers.enable) {
            let formData = {
                "mode": "fetch"
            }
            self.props.fetchMarkers(assetid, formData);
        }
        self.props.getassetinfo(assetid, token);
    }
    changeAssetsOrder(item, index) {
        let self = this;
        self.playItem(parseInt(index));
    }
    addSubTitles(subtitles) {
        if (player) {
            for (let i = 0; i < subtitles.length; i++) {
                player.subtitles.add(subtitles[i]);
            }
        }
    }
    logEvent(event) {
        let self = this;
        if (player) {
            let currentSeektime = player.getCurrentTime();
            let videoDuration = parseInt(player.getDuration());
            let assetInfo = {
                assetID: self.state.assetid || "",
                type: event.type,
                timestamp: new Date,
                duration: videoDuration,
                currentSeektime: currentSeektime,
                "appName": window.app.appName,
                "path": window.location.pathname,
                "search": window.location.search,
                "referer": "",
                "title": self.state.assetname || "",
                "url": window.location.href
            }
            analytics.track(event.type, assetInfo);
        }
    }
    markersTime() {
        let self = this;
        if (player && player.getCurrentTime()) {
            let currentSeektime = player.getCurrentTime();
            var videoDuration = parseInt(player.getDuration());
            if (self.refs.VideoAddComments || self.refs.VideoMarkers || self.refs.VideoTranscription) {
                self.setState({ matchedTags: currentSeektime, markerEndTime: videoDuration });
            }
            if (self.refs.VideoMarkers) {
                self.setState({ markerEndTime: videoDuration });
            }
        }
    }
    manageUserActivity() {

        let self = this;
        if (player && player.getCurrentTime()) {
            let currentTime = player.getCurrentTime();
            var presentTime = moment();
            let endedWatching = moment(presentTime).format("hh:mm:ss a");
            let startTime = moment(startWatching, 'hh:mm:ss a');
            let endTime = moment(endedWatching, 'hh:mm:ss a');
            let duration = endTime.diff(startTime, 'hh:mm:ss a');
            totaltimeWatched = totaltimeWatched + duration;
            let formatedTotalTimeWatched = moment.utc(totaltimeWatched).format('HH:mm:ss');
            startWatching = moment(presentTime).format("hh:mm:ss a");
            let durationbody = {
                "assetid": self.state.assetid,
                "activity": "viewduration",
                "duration": currentTime.toString(),
                "useragent": navigator.userAgent,
                "preduration": self.state.userViewDuration.toString(),
                "appName": window.app.appName,
                "userassetType": "asset",
                "preview": self.state.isPreview,
                "iteration": 10,
                "isPreviewed": self.state.alertpopup ? true : false,
                "watchedTime": totaltimeWatched / 1000

            }
            if (currentTime.toString() != self.state.userViewDuration.toString()) {
                self.props.playerDuration(durationbody);
            }

            self.refs.playerReference && self.stateUpdate("userViewDuration", currentTime)
        }
    }
    // getNextAsset() {
    //     nextvideoData = false;
    //     let self = this;
    //     //self.fetchNextassetMarkers();
    //     axios.post(lambdaUrl + '/getnextassetplay?appname=' + window.app.appName + '&env=' + environmentVariable + '&token=' + token + '&assetid=' + self.state.assetid)
    //         .then((response) => {
    //             if (response && response.data && response.data.result) {
    //                 let resultNextAsset = response.data.result;
    //                 if (markersNextasset && markersNextasset.length > 0) {
    //                     resultNextAsset['markers'] = markersNextasset
    //                 }
    //                 self.fetchPlaylist([resultNextAsset], "asset");
    //                 //nextvideoData = true;
    //                 self.setState({
    //                     nextassetid: resultNextAsset.assetid,
    //                     nextassetResult: resultNextAsset,
    //                     assetname: resultNextAsset.assetname
    //                 })
    //             }
    //         }).catch((err) => {
    //         })
    // }
    // fetchNextassetMarkers() {
    //     let self = this;
    //     axios.post(lambdaUrl + '/manageMarkers?appname=' + appname + '&env=' + environmentVariable + '&token=' + token + '&assetid=' + self.state.assetid, {
    //         "mode": "fetch"
    //     }, { type: 'application/json' })
    //         .then((response) => {
    //             if (response && response.data && response.data.result) {
    //                 markersNextasset = self.markerPoints(response.data.result);
    //             } else {
    //                 markersNextasset = []
    //             }
    //         }).catch((err) => {
    //         })
    // }
    closePlayerInfo() {
        let self = this;
        self.setState({
            playerinfo: false
        })
    }
    addSelectDropdown() {
        let self = this;
        if (divinsert == 0) {
            let selectedOption = ""
            switch (videotype) {
                case "safestream":
                    selectedOption = "safestreamUrl";
                    break;
                case "m3u8":
                    selectedOption = "m3u8_url";
                    break;
                case "vdms":
                    selectedOption = "vdms_url";
                    break;
                case "iconik":
                    selectedOption = "iconik_proxy_url" || "iconik_proxies_url";
                    break;
                case "eluvio":
                    selectedOption = "eluvio_url";
                    break;
                case "mp4":
                    selectedOption = "Mp4_proxy_URL";
                    break;
                case "ipfs":
                    selectedOption = "ipfs_url";
                    break;
            }

            let option = "";

            if (siteConfig && (siteConfig.player && siteConfig.player.mediaSource == true) || (siteConfig.mediaSource && siteConfig.mediaSource.length > 0)) {
                siteConfig.mediaSource.forEach(element => {
                    if (element.enable) {
                        let selected = ""
                        if (selectedOption == element.value) {
                            selected = "selected"
                        }
                        option += '<option value="' + element.value + '" ' + selected + ' >' + element.label + '</option>'
                    }
                });

                let innerHtml = '<div id="bmpui-id-1040" class="bmpui-ui-settings-panel-item"><div class="bmpui-container-wrapper"><span class="bmpui-ui-label">Media Source</span><select class="bmpui-ui-selectbox" id="typeurl">' + option;
                innerHtml = innerHtml + '</select></div></div>';


                $(".bmpui-ui-settings-panel>.bmpui-container-wrapper>.bmpui-ui-settings-panel-page >.bmpui-container-wrapper").append(innerHtml);
            }



            let buttonHTML = '<button type="button" id="bmpui-id-1041" class="player_share"></button>'
            //   $(".bmpui-controlbar-bottom>.bmpui-container-wrapper").append(buttonHTML);
            // console.log("buttonHTML ", buttonHTML)
            $(".bmpui-ui-controlbar>.bmpui-container-wrapper").append(buttonHTML);

            let PlayerInfoHTML = '';
            if (siteConfig && siteConfig.player && siteConfig.player.streamInfo === true && self.props.windowWidth > 800) {
                PlayerInfoHTML = '<a href="#" id="playerinfo" class="player_info2"><span class="material-icons"> video_settings</span></a>';
                $(".bmpui-ui-controlbar>.bmpui-container-wrapper").append(PlayerInfoHTML);
            }
            divinsert = 1;
        }
        $(document.body).delegate('#typeurl', 'change', function () {
            let $option = $(this).find('option:selected');
            let value = $option.val();//to get content of "value" attribute
            if (value && self.state[value]) {
                switch (value) {
                    case "Mp4_proxy_URL":
                        playerStream = "progressive";
                        break;
                    case "ipfs_url":
                        playerStream = "progressive";
                        break;
                    case "iconik_proxy_url" || "iconik_proxies_url":
                        playerStream = "progressive";
                        break;
                    default:
                        playerStream = "hls";
                }
                delete playlist[playlistCursor]['hls'];
                delete playlist[playlistCursor]['progressive'];
                playlist[playlistCursor][playerStream] = self.state[value]
                let source = playlist[playlistCursor];
                if (player) {
                    let seekTime = player.getCurrentTime() || 0;
                    player.load(source)
                        .then(() => {
                            player.seek(seekTime)
                        });
                }
                // divinsert = 0;
            } else {
                //  self.setState({ showmessage: true });
            }
        });
    }
    inputEnable(e) {
        this.setState({ inputShow: true });
    }
    getSeekTime(e) {
        const seektime = player.getCurrentTime();
        this.setState({ thumbnailValue: Math.round(seektime), inputShow: false });
    }
    handleChange(e) {
        const time = e.target.value.split(':');
        const hr = time[0];
        const min = time[1];
        const sec = time[2];
        const total_seconds = min * 60 + parseInt(sec) + parseInt(hr * 60 * 60);
        const duration = player.getDuration();
        switch (e.target.name) {
            case "thumbnail":
                total_seconds > duration ?
                    this.setState({ durationErrorThumb: true, thumbnailMessage: 'Invalid Time Input' }) :
                    this.setState({ durationErrorThumb: false });
                setTimeout(() => { this.setState({ thumbnailMessage: '' }); }, 3000);
                this.setState({ thumbnailValue: total_seconds })
                break;

            case 'poster':
                total_seconds > duration ?
                    this.setState({ durationErrorPoster: true, poster_msg: 'Invalid Time Input' }) :
                    this.setState({ durationErrorPoster: false })
                setTimeout(() => { this.setState({ poster_msg: '' }); }, 3000);
                this.setState({ poster_Val: total_seconds })
                break;
        }
    }
    seektime(item_id, time) {
        var err = true;
        if (time) {
            var seektime = (time === 'desktop') ? player.getCurrentTime() : time;
            var seconds = Math.round(seektime);
            err = false;
        }
        if (!item_id) {
            this.setState({ thumbnailMessage: 'Item Id is missing' });
            setTimeout(() => { this.setState({ thumbnailMessage: '' }); }, 3000);
        } else if (this.state.durationErrorThumb) {
            this.setState({ thumbnailMessage: 'Invalid Time Input' });
            setTimeout(() => { this.setState({ thumbnailMessage: '' }); }, 3000);
        }
        let assetid = this.props.params.assetid;
        // if (!err && item_id && !this.state.durationErrorThumb) {
        //     this.setState({ thumbnailLoader: true });
        //     axios.post(lambdaUrl + '/createthumbnail?appname=' + appname + '&env=' + environmentVariable + '&assetid=' + assetid + '&seektime=' + seconds + '&item_id=' + item_id + '&token=' + token + '&type=thumbnail')
        //         .then((response) => {
        //             let thumbnailMessage = response.data.result ? 'Successfully Created Thumbnail' : 'Failed to Create Thumbnail';
        //             this.setState({ thumbnailMessage, thumbnailLoader: false });
        //             setTimeout(() => { this.setState({ thumbnailMessage: '' }); }, 3000);
        //         })
        //         .catch((err) => {
        //             this.setState({ thumbnailMessage: 'Network Error', thumbnailLoader: false });
        //             setTimeout(() => {
        //                 this.setState({ thumbnailMessage: '' });
        //             }, 3000);
        //         })
        // }
    }
    createposter(item_id, time) {
        var err = true;
        if (time) {
            var seektime = (time === 'desktop') ? player.getCurrentTime() : time;
            var seconds = Math.round(seektime);
            err = false;
        }
        if (!item_id) {
            this.setState({ poster_msg: 'Item Id is missing' });
            setTimeout(() => { this.setState({ poster_msg: '' }); }, 3000);
        } else if (this.state.durationErrorPoster) {
            this.setState({ poster_msg: 'Invalid Time Input' });
            setTimeout(() => { this.setState({ poster_msg: '' }); }, 3000);
        }
        let assetid = this.props.params.assetid;
        // if (!err && item_id && !this.state.durationErrorPoster) {
        //     this.setState({ poster_loader: true });
        //     axios.post(lambdaUrl + '/createthumbnail?appname=' + appname + '&env=' + environmentVariable + '&assetid=' + assetid + '&seektime=' + seconds + '&item_id=' + item_id + '&token=' + token + '&type=poster')
        //         .then((response) => {
        //             let postMessage = response.data.result ? 'Successfully Created Poster' : 'Failed to Create Poster';
        //             this.setState({ poster_msg: postMessage, poster_loader: false });
        //             setTimeout(() => { this.setState({ poster_msg: '' }); }, 3000);
        //         })
        //         .catch((err) => {
        //             this.setState({ poster_msg: 'Network Error', poster_loader: false });
        //             setTimeout(() => { this.setState({ poster_msg: '' }); }, 3000);
        //         })
        // }
    }
    subscriptionClick() {
        browserHistory.push("/payment/upgrade")
    }

    handleCancelClick() {
        let self = this;
        self.killplayer();
        // browserHistory.goBack();
        if(channelCode){
            window.location = "/";
        }else{
            browserHistory.goBack();
        }
       
        //playlist = [];
        // if (this.props.params.type == "channel" || this.props.params.type == "aws") {
        //     browserHistory.push("/channel");
        // }
        // else if (localStorage.getItem("isFromBuyPopupSeries")) {
        //     localStorage.removeItem("isFromBuyPopupSeries")
        //     browserHistory.push("/" + defaultRoute);
        //     // console.log("came1");
        // }
        // else if (localStorage.getItem("assetinfopage")) {
        //     localStorage.removeItem("assetinfopage");
        //     if (history.length > 2) {
        //         browserHistory.goBack();
        //         // console.log("came2");
        //     } else {
        //         browserHistory.push("/" + defaultRoute);
        //         // console.log("came3");
        //     }
        // }
        // else if (localStorage.getItem("assetId") && localStorage.getItem("assetId") != "undefined") {
        //     localStorage.removeItem("assetId");
        //     window.localStorage.removeItem("assetId");
        //     localStorage.removeItem("a");
        //     localStorage.removeItem("avaiableAsset");
        //     browserHistory.push("/justadded");
        //     // console.log("came4");
        // }
        // else if (localStorage.getItem("channelcode") && localStorage.getItem("channelcode") != "undefined") {
        //     localStorage.removeItem("channelcode");
        //     localStorage.removeItem("avaiableChannel");
        //     window.localStorage.removeItem("channelcode");
        //     browserHistory.push("/" + defaultRoute);
        //     // console.log("came5");

        // } else if (localStorage.getItem("playerUrl")) {
        //     localStorage.removeItem("playerUrl")
        //     browserHistory.push("/" + defaultRoute);
        //     //  console.log("came6");
        // } else if (history.length > 2) {
        //     browserHistory.goBack();
        //     console.log("came7");
        // } else {
        //     browserHistory.push("/" + defaultRoute);
        //     //   console.log("came8");
        // }
    }
    playNext() {
        window.location.href = '/player/asset/' + nextassetid;
    }
    goBack() {
        if (localStorage.getItem("previousUrl") && localStorage.getItem("previousUrl") != null) {
            // window.location = localStorage.getItem("previousUrl");
        } else if (localStorage.getItem("playerUrl") && localStorage.getItem("playerUrl") != null) {
            // localStorage.removeItem("playerUrl")
            // browserHistory.push("/" + defaultRoute)
        } else {
            // browserHistory.goBack();
        }
    }
    sideNavClose(nav, toggleBtns) {
        this.setState({
            sideNav: nav,
            isCloseCaption: !this.state.isCloseCaption
        });
        $('.bhoechie-tab-content').removeClass('scrollauto active');
        {
            toggleBtns == 'videoinfo_class' ? this.setState({ videoinfo_class: 'bhoechie-tab-content scrollauto active', videoinfo_class_active: 'list-group-item text-center active disabled' }) :
                this.setState({ videoinfo_class: 'bhoechie-tab-content', videoinfo_class_active: 'list-group-item text-center' })
        }
        {
            toggleBtns == 'videoplayerinfo_class' ? this.setState({ videoplayerinfo_class: 'bhoechie-tab-content scrollauto active', videoplayerinfo_class_active: 'list-group-item text-center active disabled' }) :
                this.setState({ videoplayerinfo_class: 'bhoechie-tab-content', videoplayerinfo_class_active: 'list-group-item text-center' })
        }
        {
            toggleBtns == 'videoassets_class' ? this.setState({ videoassets_class: 'bhoechie-tab-content scrollauto active', videoassets_class_active: 'list-group-item text-center active disabled' }) :
                this.setState({ videoassets_class: 'bhoechie-tab-content', videoassets_class_active: 'list-group-item text-center' })
        }
        {
            toggleBtns == 'videotags_class' ? this.setState({ videotags_class: 'bhoechie-tab-content scrollauto active', videotags_class_active: 'button1 list-group-item text-center active disabled' }) :
                this.setState({ videotags_class: 'bhoechie-tab-content', videotags_class_active: 'button1 list-group-item text-center' })
        }
        {
            toggleBtns == 'videovisualintelligence_class' ? this.setState({ videovisualintelligence_class: 'bhoechie-tab-content scrollauto active', videovisualintelligence_class_active: 'button1 list-group-item text-center active disabled' }) :
                this.setState({ videovisualintelligence_class: 'bhoechie-tab-content', videovisualintelligence_class_active: 'button1 list-group-item text-center' })
        }
        {
            toggleBtns == 'videocomment_class' ? this.setState({ videocomment_class: 'bhoechie-tab-content scrollauto active', videocomment_class_active: 'button1 list-group-item active disabled' }) :
                this.setState({ videocomment_class: 'bhoechie-tab-content', videocomment_class_active: 'button1 list-group-item text-center' })
        }
        {
            toggleBtns == 'videomakers_class' ? this.setState({ videomakers_class: 'bhoechie-tab-content scrollauto active', videomakers_class_active: 'button1 list-group-item text-center active disabled' }) :
                this.setState({ videomakers_class: 'bhoechie-tab-content', videomakers_class_active: 'button1 list-group-item text-center' })
        }
        {
            toggleBtns == 'videotransicription_class' ? this.setState({ videotransicription_class: 'bhoechie-tab-content scrollauto active', videotransicription_class_active: 'button1 list-group-item text-center active disabled' }) :
                this.setState({ videotransicription_class: 'bhoechie-tab-content', videotransicription_class_active: 'button1 list-group-item text-center' })
        }
        {
            toggleBtns == 'videoposter_class' ? this.setState({ videoposter_class: 'bhoechie-tab-content scrollauto active', videoposter_class_active: 'button1 list-group-item text-center active disabled' }) :
                this.setState({ videoposter_class: 'bhoechie-tab-content', videoposter_class_active: 'button1 list-group-item text-center' })
        }
        {
            toggleBtns == 'videoshare_class' ? this.setState({ videoshare_class: 'bhoechie-tab-content scrollauto active', videoshare_class_active: 'button1 list-group-item text-center active disabled' }) :
                this.setState({ videoshare_class: 'bhoechie-tab-content', videoshare_class_active: 'button1 list-group-item text-center' })
        }
        {
            toggleBtns == 'videodownload_class' ? this.setState({ videodownload_class: 'bhoechie-tab-content scrollauto active', videodownload_class_active: 'button1 list-group-item text-center active disabled' }) :
                this.setState({ videodownload_class: 'bhoechie-tab-content', videodownload_class_active: 'button1 list-group-item text-center' })
        }
        {
            toggleBtns == 'videoarchive_class' ? this.setState({ videoarchive_class: 'bhoechie-tab-content scrollauto active', videoarchive_class_active: 'button1 list-group-item text-center active disabled' }) :
                this.setState({ videoarchive_class: 'bhoechie-tab-content', videoarchive_class_active: 'button1 list-group-item text-center' })
        }
        {
            toggleBtns == 'videodistribution_class' ? this.setState({ videodistribution_class: 'bhoechie-tab-content scrollauto active', videodistribution_class_active: 'button1 list-group-item text-center active disabled' }) :
                this.setState({ videodistribution_class: 'bhoechie-tab-content', videodistribution_class_active: 'button1 list-group-item text-center' })
        }
        {
            toggleBtns == 'trancoding_class' ? this.setState({ trancoding_class: 'bhoechie-tab-content scrollauto active', trancoding_class_active: 'button1 list-group-item text-center active disabled' }) :
                this.setState({ trancoding_class: 'bhoechie-tab-content', trancoding_class_active: 'button1 list-group-item text-center' })
        }
        {
            toggleBtns == 'closedcaption_class' ? this.setState({ closedcaption_class: 'bhoechie-tab-content scrollauto active', closedcaption_class_active: 'button1 list-group-item text-center active disabled' }) :
                this.setState({ closedcaption_class: 'bhoechie-tab-content', closedcaption_class_active: 'button1 list-group-item text-center' })
        }
        {
            toggleBtns == 'frameIO_class' ? this.setState({ frameIO_class: 'bhoechie-tab-content scrollauto active', frameIO_class_active: 'button1 list-group-item text-center active disabled' }) :
                this.setState({ frameIO_class: 'bhoechie-tab-content', frameIO_class_active: 'button1 list-group-item text-center' })
        }
        {
            toggleBtns == 'videoentity_class' ? this.setState({ videoentity_class: 'bhoechie-tab-content scrollauto active', videoentity_class_active: 'button1 list-group-item text-center active disabled' }) :
                this.setState({ videoentity_class: 'bhoechie-tab-content', videoentity_class_active: 'button1 list-group-item text-center' })
        }
        {
            toggleBtns == 'videofacedetection_class' ? this.setState({ videofacedetection_class: 'bhoechie-tab-content scrollauto active', videofacedetection_class_active: 'button1 list-group-item text-center active disabled' }) :
                this.setState({ videofacedetection_class: 'bhoechie-tab-content', videofacedetection_class_active: 'button1 list-group-item text-center' })
        }
        {
            toggleBtns == 'videofacialattributes_class' ? this.setState({ videofacialattributes_class: 'bhoechie-tab-content scrollauto active', videofacialattributes_class_active: 'button1 list-group-item text-center active disabled' }) :
                this.setState({ videofacialattributes_class: 'bhoechie-tab-content', videofacialattributes_class_active: 'button1 list-group-item text-center' })
        }
        {
            toggleBtns == 'videophrases_class' ? this.setState({ videophrases_class: 'bhoechie-tab-content scrollauto active', videophrases_class_active: 'button1 list-group-item text-center active disabled' }) :
                this.setState({ videophrases_class: 'bhoechie-tab-content', videophrases_class_active: 'button1 list-group-item text-center' })
        }
        {
            toggleBtns == 'videodonation_class' ? this.setState({ videodonation_class: 'bhoechie-tab-content scrollauto active', videodonation_class_active: 'button1 list-group-item text-center active disabled' }) :
                this.setState({ videodonation_class: 'bhoechie-tab-content', videodonation_class_active: 'button1 list-group-item text-center' })
        }
        {
            toggleBtns == 'videoquestion_class' ? this.setState({ videoquestion_class: 'bhoechie-tab-content scrollauto active', videoquestion_class_active: 'button1 list-group-item text-center active disabled' }) :
                this.setState({ videoquestion_class: 'bhoechie-tab-content', videoquestion_class_active: 'button1 list-group-item text-center' })
        }
        {
            toggleBtns == 'videoVoting_class' ? this.setState({ videoVoting_class: 'bhoechie-tab-content scrollauto active', videoVoting_class_active: 'button1 list-group-item text-center active disabled' }) :
                this.setState({ videoVoting_class: 'bhoechie-tab-content', videoVoting_class_active: 'button1 list-group-item text-center' })
        }
        // {
        //     toggleBtns == 'videoassetpurchase_class' ? this.setState({ videoassetpurchase_class: 'bhoechie-tab-content scrollauto active', videoassetpurchase_class_active: 'button1 list-group-item text-center active' }) :
        //         this.setState({ videoassetpurchase_class: 'bhoechie-tab-content', videoassetpurchase_class_active: 'button1 list-group-item text-center' })
        // }
    }
    playerCheck(e, assetid, groupname) {
        browserHistory.push("/player/asset/" + assetid);
    }
    nextVideo(pathlink) {
        window.location = pathlink;
    }
    reorder(arr, index) {
        return arr.slice(parseInt(index)).concat(arr.slice(0, parseInt(index)))
    }
    closeMobileShare(e) {
        this.setState({ mobileShare: e })
    }
    nextassetPlay(item) {
        let self = this;
        window.location = "/player/asset/" + item.assetid;
        // browserHistory.push("/player/" + item.assetid);
    }
    loadtags() {
        let self = this;
        if (self.state.displayDescription) {
            let str = self.state.playerdata && self.state.playerdata.description;
            self.setState({
                assetDescription: str,
                displayDescription: false
            })
        } else {
            let str = self.state.playerdata && self.state.playerdata.description;
            let res = str.substring(0, 75);
            self.setState({
                assetDescription: res + "...",
                displayDescription: true
            })
        }
    }
    openModal() {
        this.setState({ modalIsOpen: true });
    }
    afterOpenModal() {
        // references are now sync'd and can be accessed.
        // this.subtitle.style.color = '#f00';
    }
    closeModal() {
        this.setState({ modalIsOpen: false, shareClickPopup: false, isSubscriptionOpen: false });
        this.handleCancelClick()
        // this.props.popupclose(false);
    }
    onShareClick() {
        let self = this;
        const { playerdata } = this.props;
        if (playerdata.availability && playerdata.availability.type == "Subscription") {
            self.setState({ isSubscriptionOpen: true }, () => {
                self.setState({
                    showBuypopup: true,
                });
            });
        }
        else {
            self.setState({ shareClickPopup: true }, () => {
                self.setState({
                    showBuypopup: true,
                });
            });
        }
    }
    buyChange(e, value, assetInfo) {
        let self = this;
        localStorage.setItem("contentType", value);
        localStorage.setItem("action", "buy");
        self.buyClick(e, assetInfo)
    }
    rentChange(e, value, assetInfo) {
        let self = this;
        localStorage.setItem("contentType", value);
        localStorage.setItem("action", "rent");
        self.rentClick(e, assetInfo)
    }
    giftChange(e, value, assetInfo) {
        let self = this;
        localStorage.setItem("contentType", value);
        self.giftClick(e, assetInfo)
    }
    subscriptionChange(e, value, assetInfo) {
        let self = this;
        localStorage.setItem("contentType", value);
        self.subscriptionClick(e, assetInfo)
    }
    buyClick(e, assetInfo) {
        let self = this;
        let asset = assetInfo.assetid;
        let assetname = assetInfo.assetname;
        let channelcode = assetInfo.channelcode;
        localStorage.setItem("playlistType", this.props.params.param);
        localStorage.setItem("action", "buy");
        //localStorage.removeItem("action");
        let id = assetInfo.channelcode ? assetInfo.channelcode : assetInfo.assetid;
        if (token) {
            if (assetInfo.buy) {
                browserHistory.push("/player/asset/" + id);
            } else {
                if (channelcode) {
                    localStorage.setItem("a", "channelasset");
                    localStorage.setItem("channelcode", id);
                    browserHistory.push("/buyAsset/" + id + '?t=c');
                } else {
                    localStorage.setItem("a", "asset");
                    localStorage.setItem("assetId", asset);
                    localStorage.setItem("assetName", assetname)
                    browserHistory.push("/buyAsset/" + id);
                }
            }
            self.killplayer();
        }
    }
    rentClick(e, assetInfo) {
        let self = this;
        localStorage.setItem("action", "rent");
        localStorage.setItem("playlistType", this.props.params.param);
        if (token) {
            let id;
            if (assetInfo.channelcode) {
                id = assetInfo.channelcode;
                localStorage.setItem("a", "channelasset");
                localStorage.setItem("channelcode", id);
                browserHistory.push("/buyAsset/" + id + '?t=c');
            } else {
                id = assetInfo.assetid;
                localStorage.setItem("a", "asset");
                localStorage.setItem("assetId", id);
                localStorage.setItem("assetName", assetInfo.assetname)
                browserHistory.push("/buyAsset/" + id);
            }
            self.killplayer();
        }
    }
    giftClick(e, assetInfo) {
        let self = this;
        localStorage.setItem("action", "gift");
        localStorage.setItem("playlistType", this.props.params.param);
        if (token) {
            let id;
            if (assetInfo.channelcode) {
                id = assetInfo.channelcode;
                localStorage.setItem("a", "gift");
                localStorage.setItem("channelcode", id);
                browserHistory.push("/giftedpayment/" + id + '?t=g');
            } else {
                id = assetInfo.assetid;
                localStorage.setItem("assetId", id);
                localStorage.setItem("a", "asset");
                localStorage.setItem("assetName", assetInfo.assetname)
                browserHistory.push("/giftedpayment/" + id);
            }
            self.killplayer();
        }
    }
    subscriptionClick(e, assetInfo) {
        let self = this;
        localStorage.setItem("playlistType", this.props.params.param);
        if (token) {
            if (assetInfo.channelcode) {
                localStorage.setItem("a", "subscrption");
                localStorage.setItem("channelcode", assetInfo.channelcode);
                browserHistory.push("/payment/upgrade");
            } else {
                localStorage.setItem("assetId", assetInfo.assetid);
                localStorage.setItem("a", "subscrption");
                localStorage.setItem("assetName", assetInfo.assetname)
                browserHistory.push("/payment/upgrade");
            }
            self.killplayer();
        }
    }
    progress(timeleft, timetotal, $element) {
        let self = this;
        if (prevTimeleft != timeleft) {
            var progressBarWidth = timeleft * $element.width() / timetotal;
            $element.find('div').animate({ width: progressBarWidth }, timeleft == timetotal ? 0 : 1000, "linear")
            prevTimeleft = timeleft;
        }
    };
    killplayer() {
        if (player) {
            var presentTime = moment();
            let endedWatching = moment(presentTime).format("hh:mm:ss a");
            let startTime = moment(startWatching, 'hh:mm:ss a');
            let endTime = moment(endedWatching, 'hh:mm:ss a');
            let duration = endTime.diff(startTime, 'hh:mm:ss a');
            totaltimeWatched = totaltimeWatched + duration;
            let playerDestroy = function () {
                return player.unload().then(function () {
                    //return player.destroy();
                    return 'success';
                });
            };
            // if (playerPanelStatus) {
            //     for (const property in playerPanelStatus) {
            //         this.props.ChangePlayerPanlestatus(property, false)
            //     }
            // }
            playerDestroy();
            divinsert = 0;
            player = null;
        }
        clearInterval(previewProgressInt);
    }
    subscriptionCancelClick() {
        let self = this;
        self.setState({
            subscriptioncancel: false,
        });
    }
    question(e, item) {
        let self = this;
        if (item && item._id) {
            //browserHistory.push("/questions/" + item._id)
            browserHistory.push({
                pathname: "/questions/" + item._id,
                state: { item: item }
            });
        }
        self.killplayer();
    }
    closeplayershareModal() {
        let self = this;
        self.setState({ playershareClickPopup: false });
        player.play();
    }
    previousClick() {
        let self = this;
        const { videoIndex } = this.state;
        let videoIndexValue = videoIndex;
        let previousValue = videoIndexValue - 1;
        this.setState({ videoIndex: videoIndexValue == 0 || videoIndexValue < 0 ? 0 : previousValue });
        self.playItem(parseInt(previousValue));
    }
    nextClick() {
        let self = this;
        //const { assets } = this.props;
        const { videoIndex, assets } = this.state;
        let videoIndexValue = videoIndex;
        let nextValue = videoIndexValue + 1;
        let indexValue = videoIndexValue == assets.length - 1 ? videoIndexValue : videoIndexValue > assets.length - 1 ? 0 : nextValue;
        self.setState({ videoIndex: indexValue });
        self.playItem(parseInt(nextValue));
    }
    render() {
        let self = this;
        if (this.props.playerdata && this.props.playerdata.item_id) {
            var item_id = this.props.playerdata.item_id;
        }
        // let { assetCardOptions } = this.props;
        // if (asset == null) {
        //     asset = { ...this.props.asset };
        // }
        const { priceFormat } = this.props;
        let { sideNav, videoposter_class, videoinfo_class, videoshare_class, videocomment_class, videoarchive_class,
            videocomment_class_active, videoinfo_class_active, videoposter_class_active, videoshare_class_active,
            videotransicription_class, videodownload_class, videodownload_class_active, videoarchive_class_active,
            videotransicription_class_active, videotags_class_active, videotags_class, videomakers_class, videomakers_class_active, isTestEnabled,
            videovisualintelligence_class, videovisualintelligence_class_active, videodistribution_class, videodistribution_class_active, trancoding_class_active, trancoding_class, closedcaption_class_active, closedcaption_class, frameIO_class_active, frameIO_class, matchedTags, mobileShare, videoentity_class, videoentity_class_active, videofacedetection_class, videofacedetection_class_active, videofacialattributes_class,
            videofacialattributes_class_active, videophrases_class, videophrases_class_active, videodonation_class, videodonation_class_active, videoquestion_class, videoquestion_class_active, videoassetpurchase_class, videoassetpurchase_class_active, videoassets_class_active, videoassets_class, markerEndTime, loader, showmessage, asset, assetCardOptions, successmessage, playStart, assetid, alertpopup, isPreview, showBuypopup, playerdata, shareClickPopup, isSubscriptionOpen, remainingCountdown, selectedItem, playershareClickPopup, assets, playlistType, isChannel, subscriptions, assetStatusMessage, videoIndex, activeDonation, videoVoting_class, videoVoting_class_active, activeVoting, assetStatusTitle, assetStatusImage, videoplayerinfo_class, videoplayerinfo_class_active, defaultpopup } = this.state;
        let next;
        if (assets) {
            next = videoIndex === assets.length - 1 ? "fa fa-chevron-right imgSliderRight disabled" : "fa fa-chevron-right imgSliderRight";
        }

        let previous = videoIndex == 0 ? "fa fa-chevron-left imgSliderLeft disabled" : "fa fa-chevron-left imgSliderLeft";
        let playerClassName = "right-panel u-fullblock";
        if (!playerdata) {
            playerdata = selectedItem;
        }

        let buyStatus = playerdata && playerdata.buy && playerdata.buy === true || playerdata && playerdata.buy && playerdata.buy === "true" ? "shopping_cart" : "add_shopping_cart";
        // if (playStart && settings) {
        //     playerClassName = "right-panel";
        // }
        let buyplans = playerdata && playerdata.availability && playerdata.availability.buyplans && Object.keys(playerdata.availability.buyplans).length > 0 ? playerdata.availability.buyplans : {};
        let rentAsset = buyplans && buyplans.rent && buyplans.rent.contentPrice && Object.keys(buyplans.rent.contentPrice).length > 0 || false;
        let giftAsset = buyplans && buyplans.gift && buyplans.gift.allow && buyplans.gift.contentPrice && Object.keys(buyplans.gift.contentPrice).length > 0 || false;
        let buyAsset = buyplans && buyplans.buy && buyplans.buy.contentPrice && Object.keys(buyplans.buy.contentPrice).length > 0 || false;
        let subscriptionAsset;
        if (playerdata && playerdata.availability && playerdata.availability.buyplans && subscriptions) {
            subscriptionAsset = true;
        }
        //let settings;
        let buttonDisabled = "";
        //console.log('buttonDisabled',buttonDisabled)
        let isTestQualified = this.props.location && this.props.location.state && "status" in this.props.location.state && this.props.location.state.status && this.props.location.state.status.toLowerCase() == 'completed' ? true : false;
        if (loader == "true") {
            return (<div>
                <div className="carousel_blk">
                    <Loader />
                </div>
            </div>);
        }
        else {

            let data = moment.utc(Math.ceil(143.81) * 1000).format('HH:mm:ss');
            return (
                <div>
                    {showmessage &&
                        <AlertPopup message={assetStatusMessage ? assetStatusMessage : "Sorry, this video is not available for viewing yet."} closePopup={this.okClick} title={assetStatusTitle ? assetStatusTitle : 'NOT AVAILABLE'} imagePath={assetStatusImage ? assetStatusImage : "images/states_of_applications/warning_gray.png"} showClose={false} eventStart={self.state.eventStart} />
                    }
                    {alertpopup && paymentEnable &&
                        <div className="novideo-alert watching_popup">
                            <div className="alert-u">
                                <p className="u-pop-icon"><img src={imagesAssetResourcesPrefix + themePrefix + "watching-tv.png"} /></p>
                                <p>Please own it to continue watching.</p>
                                <div className="wtg_btns">
                                    <div className="buy-options">
                                        {buyAsset == true ?
                                            <div className="dropdown wtg_buy">
                                                <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                                                    <i className="material-icons mr-2">shopping_cart</i>Buy
                                                </button>
                                                <div className="dropdown-menu" value={this.state.buyPrice} name="buyPrice"  >
                                                    {
                                                        Object.keys(buyplans.buy.contentPrice).map((item, j) => {
                                                            return (buyplans.buy.contentPrice[item] != 0 &&
                                                                <span className="dropdown-item" key={j} value={item} onClick={e => self.buyChange(e, item, playerdata)}>{item.toUpperCase()} {priceFormat(buyplans.buy.contentPrice[item], 'USD', 'en-US')} </span>)
                                                        })
                                                    }
                                                </div>
                                            </div> : null}
                                        {rentAsset == true ?
                                            <div className="dropdown wtg_rent">
                                                <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                                                    <i className="material-icons mr-2">vpn_key</i>Rent
                                                </button>
                                                <div className="dropdown-menu" value={this.state.rentPrice} name="rentPrice"  >
                                                    {
                                                        Object.keys(buyplans.rent.contentPrice).map((item, j) => {
                                                            return (buyplans.rent.contentPrice[item] != 0 &&
                                                                <span className="dropdown-item" key={j} value={item} onClick={e => self.rentChange(e, item, playerdata)}>{item.toUpperCase()} {priceFormat(buyplans.rent.contentPrice[item], 'USD', 'en-US')} </span>)
                                                        })
                                                    }
                                                </div>
                                            </div> : null}
                                        {/* {playerdata && playerdata.gifting && playerdata.gifting.allow && playerdata.gifting.contentPrice && Object.keys(playerdata.gifting.contentPrice).length > 0 ? */}
                                        {giftAsset == true ?
                                            <div className="dropdown wtg_gift">
                                                <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                                                    <i className="material-icons mr-2">card_giftcard</i>Gift
                                                </button>
                                                <div className="dropdown-menu" value={this.state.giftPrice} name="giftPrice"  >
                                                    {
                                                        Object.keys(buyplans.gift.contentPrice).map((item, j) => {
                                                            return (buyplans.gift.contentPrice[item] != 0 &&
                                                                <span className="dropdown-item" key={j} value={item} onClick={e => self.giftChange(e, item, playerdata)}>{item.toUpperCase()} {priceFormat(buyplans.gift.contentPrice[item], 'USD', 'en-US')} </span>)
                                                        })
                                                    }
                                                </div>
                                            </div> : null}
                                        {subscriptionAsset && subscriptions && subscriptions.length > 0 &&
                                            <div className="dropdown subscriptions wtg_gift">
                                                <button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                                                    <i className="material-icons mr-2">subscriptions</i>Subscribe
                                                </button>
                                                <div className="dropdown-menu dropdownsubscriptions" value={this.state.subscriptionPrice} name="subscriptionPrice">
                                                    {
                                                        subscriptions && subscriptions.map((item, j) => {
                                                            return (<span className="dropdown-item" key={j} onClick={e => self.subscriptionChange(e, item, playerdata)}>{item.toUpperCase()} {priceFormat(buyplans && buyplans[item] && buyplans[item].contentPrice && buyplans[item].contentPrice.planPrice, 'USD', 'en-US')} </span>)
                                                        })
                                                    }

                                                </div>
                                            </div>

                                        }
                                    </div>
                                    {/* <button  onClick={e => this.onShareClick()} className="wtg_sub">Buy </button>
                                     <button  onClick={e => this.handleCancelClick()} className="wtg_cancel">Cancel </button> */}
                                </div>
                            </div>
                        </div>
                    }
                    {defaultpopup && paymentEnable &&
                        <div className="alert_block novideo-alert">
                            <div className="alert_wrapper">
                                <div className="alert_box">
                                    <span className="popup-close" onClick={e => self.handleCancelClick()}></span>
                                    <div className="Alert_head">
                                        <img src={imagesAssetResourcesPrefix + themePrefix + "watching-tv.png"} className="error_img na_img" />
                                    </div>
                                    <div className="Alert_body">
                                        <p className="sub">NOT AVAILABLE</p>
                                        <p>Default asset player URL is not available</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {isPreview && remainingCountdown &&
                        <div id="countdown">
                            <p>You are watching a preview of {playerdata && playerdata.channelname ? playerdata.channelname : playerdata.assetname}</p>
                            <div id="previewProgressBar">
                                <div ><span className="video_drsn">Enjoy your preview pass for the next {remainingCountdown}</span></div>
                            </div>
                        </div>
                    }
                    {!isPreview && playerdata && playerdata.subscriptionExpired && playerdata.message && paymentEnable && subscriptionEnable &&
                        <div id="countdown">
                            <p>{playerdata && playerdata.message}</p>
                            <NavLink onClick={e => this.subscriptionClick(e, playerdata)} className="btn btn-sm btn-primary">Subscribe</NavLink>
                        </div>
                    }
                    {playerdata && playerdata.rent && playerdata.rent_remainingTime &&
                        <div id="countdown">
                            <p>Expires in {playerdata.rent && playerdata.rent_remainingTime} {playerdata.rent && playerdata.rent_remainingType}</p>
                        </div>
                    }
                    {/* {self.props.windowWidth > 801 && */}
                        <div id="right-panel" className={playerClassName}>

                            <div className="content">
                                {self.state.subscriptioncancel ?
                                    <div>
                                        {playerdata && !playerdata.subscriptionExpired && playerdata.message && <p className="trial-alert">{playerdata.message}
                                            <span onClick={e => this.subscriptionCancelClick(e)} className="cls"></span></p>
                                        }</div> : null}

                                <div id="player" ref="playerReference" className={this.state.playerclass}>
                                    <span onClick={e => this.handleCancelClick(e)} style={{ "cursor": "pointer" }} className="cls"></span>
                                    {(self.state.playerinfo && $('#bmpui-id-413').hasClass('bmpui-hidden') == false) && <div className="streaming_info2" ><div className="stream" ><p><span>Quality</span>{self.state.playerQuality}</p></div><div className="stream" ><p><span>Buffer</span>{self.state.playerBuffered}</p></div></div>}
                                    {/* {playlistType == "playlistbytag" &&
                                        <span className={previous} style={{ 'fontSize': '30px', "cursor": "pointer", "zIndex": "99999" }} onClick={e => this.previousClick(e)}></span>}
                                    {playlistType == "playlistbytag" &&
                                        <span className={next} style={{ 'fontSize': '30px', "cursor": "pointer", "zIndex": "99999" }} onClick={e => this.nextClick(e)} ></span>} */}
                                </div>
                            </div>
                        </div>
                    {/* } */}
                </div>
            );
        }
    }
}
const mapState = ({ loading, playerdata, channelAssetsProps }) => ({ loading, playerdata, channelAssetsProps });
const mapDispatch = {
    priceFormat,
    getplayerinfo,
    channelAssets
};
export default windowSize(connect(mapState, mapDispatch)(Player));
