// import React from 'react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import EventCountdown from './EventCountdown';
import NavLink from '../NavLink';
let siteSettings = window.site || {};
let siteConfig = siteSettings.config || {};
let commonResourcesUrl = siteConfig.commonResourcesUrl || 'https://envoi-common-resources.imgix.net/'
let imagesAssetResourcesPrefix = commonResourcesUrl + "screening/";

class AlertPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
render() {
    const { message, closePopup, title, imagePath, showClose, eventStart } = this.props;
    let total = 0;
    if(eventStart){
        total = Date.parse(eventStart) - Date.parse(new Date());
    }

    return (
     <div className="alert_block novideo-alert">
            <div className="alert_wrapper">
                <div className="alert_box">
                    {showClose && <span className="popup-close" onClick={closePopup}></span>}
                    <div className="Alert_head">
                        <img src={imagesAssetResourcesPrefix + imagePath} className="error_img" />
                    </div>
                    <div className="Alert_body">
                        <p className="sub">{title}</p>
                        <p>{message}</p>
                        {message == 'Your current membership does not include this asset. Please upgrade your subscription plan.' && <NavLink className="change_plan_btn" to="/payment/upgrade"><i className="material-icons mr-2">credit_card</i>Subscribe</NavLink>}
                        {/* {eventStart && total > 0 && <EventCountdown eventStart={eventStart} />} */}
                    </div>
                </div>
            </div>
        </div>
  )
}
}
export default connect()(AlertPopup);
