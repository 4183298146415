/***
**Module Name:  payment
**File Name :  payment.js
**Project :      CALENDOW
**Copyright(c) : X Platform Consulting.
**Organization : Peafowl Inc
**author :  Hari
**author :  Rajesh
**license :
**version :  1.0.0
**Created on :
**Last modified on:
**Description : payment page functionality and html code
*/
//export default App;
import React, { Component } from 'react';
import { Router, Link, IndexRedirect, browserHistory } from 'react-router';
import { connect } from 'react-redux';
import axios from "axios";
import NavLink from '../NavLink';
import Modal from 'react-modal';
import { getPricing, priceFormat } from '../../redux/reducers/reducer';
import windowSize from 'react-window-size';
import ReactGA from 'react-ga';
import braintree from "braintree-web";
import { v4 as uuidv4 } from 'uuid';
import cryptoJs from 'crypto-js';
import { analyaticsCheck } from '../../utils/index';
import AlertPopup from '../AlertPopup';
var urlParams = new URLSearchParams(window.location.search);
let assetid = new URLSearchParams(window.location.search);
assetid = location("assetid");
var partnercode;
var customer_address = {};
var addressVisible = localStorage.getItem("adv");
import { hotJarUserIdentify, location } from '../../utils/utils';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    }
};
let buyContentType;
let buyActionType;
let actionType;
let contentType;
let userId;
let card = false;
let cashAppPay = false;
let squareApplePay = false;
let squareGooglePay = false;
let payments;
let siteSettings = window.site;
let appSettings = window.app
let siteConfig = (siteSettings && siteSettings.config) || (appSettings && appSettings.defaultSiteSettings && appSettings.defaultSiteSettings.config);
let appStorage = appSettings.storage;
let token = appStorage.getItem('token');
let appId;
let locationId;
appId = (((siteConfig || {}).paymentGateway || {}).squareUp || {}).appId || "";
locationId = (((siteConfig || {}).paymentGateway || {}).squareUp || {}).locationId || "";

if (appId && locationId) {
    let splitAppId = appId.split("-");
    let url = "web.squarecdn.com/v1/square.js"
    if (splitAppId[0] == "sandbox") {
        url = "sandbox." + url
    }
    affixScriptToHead("https://" + url, function (data) {
    }).catch(error => loadError(error));
}

class payment extends Component {
    constructor(props) {
        super(props);

        if (urlParams.has('contentType') == true && urlParams.has('action') == true) {
            buyContentType = urlParams.get('contentType').toLowerCase()
            buyActionType = urlParams.get('action').toLowerCase();
            localStorage.setItem("contentType", buyContentType);
            localStorage.setItem("action", buyActionType);

        }
        if (urlParams.has('t') == true) {
            localStorage.setItem("t", "channel");
        }
        if (urlParams.has('id') == true) {
            userId = urlParams.get('id');
            localStorage.setItem("userId", userId);
        }
        actionType = buyActionType || localStorage.getItem("action") || "buy";
        contentType = buyContentType || localStorage.getItem("contentType");
        var isLoggedIn = false;
        // if (!token) {
        //     window.location = '/';
        // } else {
        //     isLoggedIn = true;
        // }
        var adr = JSON.parse(localStorage.getItem("adr"));
        var firstname = '';
        var lastname = '';
        var email = '';
        var phone = '';
        if (localStorage.getItem("firstname") && localStorage.getItem("firstname") != null && localStorage.getItem("firstname") != undefined) {
            firstname = localStorage.getItem("firstname")
        }
        if (localStorage.getItem("lastname") && localStorage.getItem("lastname") != null && localStorage.getItem("lastname") != undefined) {
            lastname = localStorage.getItem("lastname")
        }
        if (localStorage.getItem("email") && localStorage.getItem("email") != null && localStorage.getItem("email") != undefined) {
            email = localStorage.getItem("email")
        }
        if (localStorage.getItem("phone") && localStorage.getItem("phone") != null && localStorage.getItem("phone") != undefined) {
            phone = localStorage.getItem("phone");
        }

        const unique_id = uuidv4();
        const small_id = unique_id.slice(0, 8)

        this.state = {
            data: [],
            loader: false,
            login: isLoggedIn,
            selected: '',
            navigationtab: '',
            isTop: true,
            partnercode: partnercode,
            // contentType: window.location.pathname.split("/").pop(),
            // tv_show_name: this.props.params.tv_show_name,
            // assetid: this.props.params.assetid,
            // channelcode: this.props.params.channelcode,
            error: '',
            errors: {},
            streetAddress: '',
            locality: '',
            extendedAddress: '',
            countryCodeAlpha2: '',
            region: '',
            showAsset: [],
            postalCode: '',
            checked: false,
            adr: adr,
            customer_address: {},
            countrydefault: "",
            regiondefault: "",
            firstname: firstname,
            lastname: lastname,
            email: email,
            phone: phone,
            //modifiedavatar: imageprefix + 'assets/images/profiles/default/avatar1.jpg' + imagesuffix,
            addressVisible: addressVisible,
            cardholderName: firstname + ' ' + lastname,
            assetPaid: false,
            errMessage: null,
            freeAsset: false,
            showCards: false,
            errType: false,
            notPublishedAsset: false,
            isAppleandGoogleEnabled: false,
            userError: false,
            userinfo: props.userinfo,

            merchantKey: ((siteConfig.paymentGateway || {}).payuIndia || {}).merchantKey || null,
            txnId: small_id,
            merchantSalt: ((siteConfig.paymentGateway || {}).payuIndia || {}).merchantSalt || null,
            hash: null,
            actionURL: ((siteConfig.paymentGateway || {}).payuIndia || {}).mode == "sandbox" ? ((siteConfig.paymentGateway || {}).payuIndia || {}).sandboxURI : ((siteConfig.paymentGateway || {}).payuIndia || {}).productionURI
        };

        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.handletextChange = this.handletextChange.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.handlecheckboxChange = this.handlecheckboxChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
        // this.loadBraintree = this.loadBraintree.bind(this);
    }
    onScroll(isTop) {
        this.setState({ isTop });
    }
    componentDidMount() {
        var self = this;
        actionType = buyActionType || localStorage.getItem("action") || "buy";
        contentType = buyContentType || localStorage.getItem("contentType");
        userId = userId || localStorage.getItem("userId");
        localStorage.setItem("channelcode", self.state.channelcode);
        localStorage.setItem("assetId", assetid);
        let siteSettings = window.site;
        let appSettings = window.app
        let siteConfig = (siteSettings && siteSettings.config) || (appSettings && appSettings.defaultSiteSettings && appSettings.defaultSiteSettings.config);
        // analyaticsCheck(window, this.constructor.name);
        if (token) {
            // this.props.getuser(token);
        }
        // if (self.state.tv_show_name) {
        //     self.props.getContentShowAssets(self.state.tv_show_name);
        // }

        // self.loadBraintree();
        // self.loadBraintreeInterval();

        if (token) {
            self.defaultCard()
        }

        var urlParams = new URLSearchParams(window.location.search);
        //console.log('urlParams', urlParams)
        let typeasset = false;
        if (urlParams.has('t')) {
            typeasset = true;
        }
        // if (!typeasset && self.state.assetid) {
        //     this.props.getassetinfo(self.state.assetid, token);
        //     // localStorage.setItem("assetId", self.state.assetid);
        // } else {
        // localStorage.setItem("channelcode", self.state.assetid);
        let envoiScreeningApiBaseUrl = siteSettings && siteSettings.screeningApiBaseUrl;
        axios.post(envoiScreeningApiBaseUrl + '/assetinfo?appname=' + window.app.appName + '&token=' + token + '&assetid=' + assetid + '&env=' + window.app.appEnvName)
            .then(function (response) {
                let assetinfo = response.data.result;
                console.log('assetinfo1234', assetinfo)
                // self.setState({ arrayAssets, assetprice: assetprice, assetname: assetinfo.assetname, contentType: contentType, assetinfo: assetinfo, envoisku });
                // if (actionType && actionType != "rent" && assetinfo.buy == true) {
                //     localStorage.removeItem("a");
                //     this.setState({ assetPaid: true, assetinfo })
                // } else {
                //     this.setState({ assetinfo });
                // }

                let availability = assetinfo && assetinfo.availability || {}
                // if (availability.type == "Free" || availability.free) {
                //     localStorage.removeItem("a");
                //     self.setState({ freeAsset: true });
                // } 
                let buyplans = availability.buyplans;
                // let contentType = buyContentType || localStorage.getItem("contentType");
                let envoisku = assetinfo && assetinfo.envoisku;

                let assetprice = buyplans && buyplans[actionType] && buyplans[actionType].contentPrice && buyplans[actionType].contentPrice[contentType] || 0;
                var arrayAssets = []

                var obj = {
                    "assetid": assetinfo.assetid,
                    "single_price": assetprice,
                    "assetName": assetinfo.assetname,
                    "assetname": assetinfo.assetname,
                    "description": assetinfo.description,
                    "userassetType": assetinfo.assetType === "Episodic" ? "series" : "asset",
                    "referral": assetinfo.creator
                }
                localStorage.setItem("assetId", assetinfo.assetid);
                arrayAssets.push(obj)
                self.setState({ arrayAssets, assetprice: assetprice, assetname: assetinfo.assetname, contentType: contentType, assetinfo: assetinfo, envoisku });

                if (buyplans && buyplans[actionType] && buyplans[actionType]["contentType"][contentType]) {
                    self.setState({ contentType: contentType, envoisku });
                } else if (assetinfo && assetinfo.message) {
                    self.setState({ notPublishedAsset: true })
                }
                else {
                    self.setState({ errType: true })
                }

                // localStorage.removeItem("buyContentType");
                // localStorage.removeItem("buyActionType");



            })
            .catch(function (error) {
            });
        // }

        document.addEventListener('scroll', () => {
            var isTop;
            if (window.scrollY < 50) {
                isTop = true;
            } else {
                isTop = false;
            }
            if (isTop !== this.state.isTop) {
                if (this.refs.headermenu) {
                    this.onScroll(isTop);
                }
            }
        });

        // this.props.getcountries();
        // this.props.getaddress("billing");

        // if (self.state.adr && self.state.adr.extendedAddress) {

        //     self.props.getregions(self.state.adr.countryCodeAlpha2)
        // } else {
        //     self.props.getregions(self.state.countrydefault)
        // }

        customer_address["countryCodeAlpha2"] = self.state.countrydefault;
        customer_address["region"] = self.state.regiondefault;
        customer_address["addresstype"] = "billing";

        if (self.state.adr) {
            self.state.adr["addresstype"] = "billing";
        }


        this.setState({ customer_address, adr: self.state.adr });


        { this.state.firstname == 'undefined' ? this.setState({ firstname: '' }) : null }
        { this.state.lastname == 'undefined' ? this.setState({ lastname: '' }) : null }
        { this.state.email == 'undefined' ? this.setState({ email: '' }) : null }
        { this.state.phone == 'undefined' ? this.setState({ phone: '' }) : null }


        // document.getElementById("monthly").checked = true;
        if (siteConfig && siteConfig.paymentGateway && siteConfig.paymentGateway.key == "braintree") {
            self.braintreeSetup()
            document.getElementById("credit").checked = true;
            document.getElementById("cardcc").checked = true;
        }

        // let clientToken;
        // let braintreeSettings = siteConfig.paymentGateway || {};
        //       if (braintreeSettings.enable === true) {
        //         clientToken = (braintreeSettings.braintree || {}).gatewayToken; //Braintree Tree Token
        //       }

    }
    componentWillReceiveProps(nextProps) {
        //let actionType = buyActionType || localStorage.getItem("action");
        let self = this;
        let siteSettings = window.site;
        let appSettings = window.app
        let siteConfig = (siteSettings && siteSettings.config) || (appSettings && appSettings.defaultSiteSettings && appSettings.defaultSiteSettings.config);
        if (nextProps.assetinfo && nextProps.assetinfo !== this.props.assetinfo) {
            let assetinfo = nextProps.assetinfo;
            if (actionType && actionType != "rent" && assetinfo.buy == true) {
                localStorage.removeItem("a");
                this.setState({ assetPaid: true, assetinfo })
            } else {
                this.setState({ assetinfo });
            }

            let availability = assetinfo && assetinfo.availability || {}
            if (availability.type == "Free" || availability.free) {
                localStorage.removeItem("a");
                self.setState({ freeAsset: true });
            } else {
                let buyplans = availability.buyplans;
                // let contentType = buyContentType || localStorage.getItem("contentType");
                let envoisku = assetinfo && assetinfo.envoisku;

                let assetprice = buyplans && buyplans[actionType] && buyplans[actionType].contentPrice && buyplans[actionType].contentPrice[contentType] || 0;
                var arrayAssets = []

                var obj = {
                    "assetid": assetinfo.assetid,
                    "single_price": assetprice,
                    "assetName": assetinfo.assetname,
                    "assetname": assetinfo.assetname,
                    "description": assetinfo.description,
                    "userassetType": assetinfo.assetType === "Episodic" ? "series" : "asset",
                    "referral": assetinfo.creator
                }
                localStorage.setItem("assetId", assetinfo.assetid);
                arrayAssets.push(obj)
                this.setState({ arrayAssets, assetprice: assetprice, assetname: assetinfo.assetname, contentType: contentType, assetinfo: assetinfo, envoisku });

                if (buyplans && buyplans[actionType] && buyplans[actionType]["contentType"][contentType]) {
                    this.setState({ contentType: contentType, envoisku });
                } else if (assetinfo && assetinfo.message) {
                    this.setState({ notPublishedAsset: true })
                }
                else {
                    this.setState({ errType: true })
                }

                // localStorage.removeItem("buyContentType");
                // localStorage.removeItem("buyActionType");


            }
        }

        if (nextProps.assetPayment && nextProps.assetPayment !== this.props.assetPayment) {
            if (nextProps.assetPayment.error) {
                $("#overlay").addClass("hidediv");
                self.setState({
                    errMessage: nextProps.assetPayment.error
                })
            } else if (siteConfig && siteConfig.paymentGateway && siteConfig.paymentGateway.key == "braintree") {

                window.location = "/boughtsuccess"
            }
        }
        if (nextProps.userinfo !== this.props.userinfo) {
            let userData = nextProps && nextProps.userinfo;

            this.setState({ userinfo: userData })
            if (userId && userData.userid != userId) {
                this.setState({ userError: true })
            }

        }
    }
    braintreeSetup() {
        let self = this;
        let siteSettings = window.site;
        let appSettings = window.app
        let siteConfig = (siteSettings && siteSettings.config) || (appSettings && appSettings.defaultSiteSettings && appSettings.defaultSiteSettings.config);
        let clientToken;
let braintreeSettings = siteConfig.paymentGateway || {};
      if (braintreeSettings.enable === true) {
        clientToken = (braintreeSettings.braintree || {}).gatewayToken; //Braintree Tree Token
      }
        let stylesConfig = {
            // Style all elements
            'input': {
                'font-size': '30px',
                'color': '#ffffff'
            },
            // Styling element state
            ':focus': {
                'color': '#ffffff'
            },
            '.valid': {
                'color': '#6e9e12'
            },
            '.invalid': {
                'color': 'red'
            },
            '@media screen and (max-width: 700px)': {
                'input': {
                    'font-size': '14px'
                }
            }
        }
        let fieldsConfig = {
            number: {
                selector: "#card-number",
                placeholder: "****************"
            },
            cvv: {
                selector: "#cvv",
                placeholder: "***"
            },
            expirationMonth: {
                selector: "#expiration-month",
                placeholder: "MM"
            },
            expirationYear: {
                selector: "#expiration-year",
                placeholder: "YY"
            },
            postalCode: {
                selector: "#postal-code",
                placeholder: "******"
            }
        }

        let submitBtn = document.getElementById('newSubmit');
        let paypalButton = document.querySelector('#pay-pal-button');
        var googlepayButton = document.querySelector('#google-pay-button');
        var applePayButton = document.querySelector('#apple-pay-button');
        let environment = clientToken && clientToken.substr(0, clientToken.indexOf('_')) == 'production' ? 'PRODUCTION' : 'TEST';
        // var paymentsClient = new google.payments.api.PaymentsClient({
        //     environment: environment // or 'PRODUCTION'
        // });

        braintree.client.create({ authorization: clientToken }).then(function (client) {

            braintree.paypal.create({
                client: client
            }, function (paypalErr, paypalInstance) {
                if (paypalErr) {
                    console.error('Error creating PayPal:', paypalErr);
                    return;
                }


                paypalButton.removeAttribute('disabled');

                // When the button is clicked, attempt to tokenize.
                paypalButton.addEventListener('click', function (event) {
                    // Because tokenization opens a popup, this has to be called as a result of
                    // customer action, like clicking a button. You cannot call this at any time.
                    paypalInstance.tokenize({
                        flow: 'vault'
                        // For more tokenization options, see the full PayPal tokenization documentation
                        // https://braintree.github.io/braintree-web/current/PayPal.html#tokenize
                    }, function (tokenizeErr, payload) {
                        if (tokenizeErr) {
                            if (tokenizeErr.type !== 'CUSTOMER') {
                                console.error('Error tokenizing:', tokenizeErr);
                            }
                            return;
                        }

                        // Tokenization succeeded
                        paypalButton.setAttribute('disabled', true);
                        self.paymentCall(payload.nonce, false)
                    });
                }, false);
            });

            braintree.googlePayment.create({
                client: client,
                googlePayVersion: 2,
                googleMerchantId: siteConfig && siteConfig.paymentGateway && siteConfig.paymentGateway.braintree && siteConfig.paymentGateway.braintree.paymentMethods && siteConfig.paymentGateway.braintree.paymentMethods.googlePayMerchantId && siteConfig.paymentGateway.braintree.paymentMethods.googlePayMerchantId.toString()
            }).then(function (googlePaymentInstance) {
                googlepayButton.addEventListener('click', function (event) {
                    var paymentDataRequest;

                    event.preventDefault();
                    paymentDataRequest = googlePaymentInstance.createPaymentDataRequest({
                        transactionInfo: {
                            currencyCode: 'USD',
                            totalPriceStatus: 'FINAL',
                            totalPrice: self.state.assetprice && self.state.assetprice.toString()
                        }
                    });

                    // paymentsClient.loadPaymentData(paymentDataRequest).then(function (paymentData) {
                    //     return googlePaymentInstance.parseResponse(paymentData);
                    // }).then(function (result) {
                    //     self.paymentCall(result.nonce, false)
                    //     // send result.nonce to your server
                    // }).catch(function (err) {
                    //     // handle err
                    // });
                });
            });
            if (window.ApplePaySession && ApplePaySession.supportsVersion(3) && ApplePaySession.canMakePayments()) {
                // This device supports version 3 of Apple Pay.
                self.setState({ isAppleandGoogleEnabled: true });

            } else {
                self.setState({ isAppleandGoogleEnabled: false });
            }
            braintree.applePay.create({
                client: client
            }, function (applePayErr, applePayInstance) {
                if (applePayErr) {
                    console.error('Error creating applePayInstance:', applePayErr);
                    return;
                }
                applePayButton.addEventListener('click', function (event) {
                    self.applePay(applePayInstance);
                });
            });

            braintree.hostedFields.create({
                client: client,
                styles: stylesConfig,
                fields: fieldsConfig

            }).then(function (hostedFields) {

                submitBtn.addEventListener('click', function (event) {
                    event.preventDefault();

                    if (hostedFields.tokenize()) {
                        hostedFields.tokenize().then(function (payload) {
                            // send payload.nonce to your server
                            const err = self.validate();
                            if (!err) {
                                self.paymentCall(payload.nonce, false)

                            }

                        }).catch(function (err) {
                            self.setState({ errMessage: err.message });

                        });
                    }
                });


            });
        });

    }
    applePay(applePayInstance) {
        // Set up your Apple Pay button here
        let self = this;
        let siteSettings = window.site;
        let appSettings = window.app
        let siteConfig = (siteSettings && siteSettings.config) || (appSettings && appSettings.defaultSiteSettings && appSettings.defaultSiteSettings.config);
        let paymentRequest = applePayInstance.createPaymentRequest({
            total: {
                label: siteConfig.siteTitle,
                amount: self.state.assetprice.toFixed(2).toString()
            },

            // We recommend collecting billing address information, at minimum
            // billing postal code, and passing that billing postal code with
            // all Apple Pay transactions as a best practice.
            requiredBillingContactFields: ["postalAddress"]
        });

        var session = new ApplePaySession(3, paymentRequest);
        session.onvalidatemerchant = function (event) {
            applePayInstance.performValidation({
                validationURL: event.validationURL,
                displayName: siteConfig.siteTitle
            }).then(function (merchantSession) {
                session.completeMerchantValidation(merchantSession);

            }).catch(function (validationErr) {
                // You should show an error to the user, e.g. 'Apple Pay failed to load.'
                console.error('Error validating merchant:', validationErr);
                session.abort();
            });
        };
        session.onpaymentauthorized = function (event) {

            applePayInstance.tokenize({
                token: event.payment.token
            }, function (tokenizeErr, payload) {
                if (tokenizeErr) {
                    console.error('Error tokenizing Apple Pay:', tokenizeErr);
                    session.completePayment(ApplePaySession.STATUS_FAILURE);
                    return;
                }

                // Send payload.nonce to your server.
                self.paymentCall(payload.nonce, false)
                // If requested, address information is accessible in event.payment
                // and may also be sent to your server.

                // After you have transacted with the payload.nonce,
                // call `completePayment` to dismiss the Apple Pay sheet.
                session.completePayment(ApplePaySession.STATUS_SUCCESS);
            });
        };
        session.begin();
    }
    paymentCall(nonce, paymentMode) {
        let self = this;
        //let actionType = localStorage.getItem("action") || "buy";

        const { arrayAssets, assetprice, actionURL } = self.state;


        let itemType = "asset";
        let mediaType = (self.state.assetinfo || {}).mediaType;
        if (mediaType != 'music' && mediaType != 'audio' && mediaType != 'document') {
            localStorage.setItem("a", 'asset');
        }
        let sku = self.state.assetinfo && self.state.assetinfo.quickbooksId;
        if (arrayAssets.length > 0 && arrayAssets[0].userassetType == "channel") {
            itemType = "channel";
            localStorage.setItem("a", 'channelasset');
        } else if (arrayAssets.length > 0 && arrayAssets[0].userassetType == "series") {
            localStorage.setItem("isFromBuyPopupSeries", true);
            localStorage.setItem("seasonName", (self.state.assetinfo.seasons && self.state.assetinfo.seasons.length > 0 && self.state.assetinfo.seasons[0].seasonName || ''));
            itemType = arrayAssets[0].userassetType;
        }
        let envoisku = self.state.envoisku
        self.props.getPricing(token, nonce, assetprice, arrayAssets, self.state.optionalAddress, self.state.optionalCity, self.state.contentType, itemType, actionType, sku, self.state.cardholderName, envoisku);
        $("#overlay").removeClass("hidediv");
        if (paymentMode) {
            setTimeout(function () {
                $('#payUPayment').attr('action', actionURL).submit();

            }, 2000);

        }
    }
    addressChange(e) {
        var self = this;
        const name = e.target.name;
        const value = e.target.value;
        self.setState({ [name]: value });
    }
    payUaddressChange(e) {
        var self = this;
        const name = e.target.name;
        const value = e.target.value;
        if (name == "address1") {
            self.setState({ optionalAddress: value });
        }
        if (name == "city") {
            self.setState({ optionalCity: value });
        }
        self.setState({ [name]: value });
    }
    handletextChange(e) {
        var self = this;
        if (!!this.state.errors[e.target.name]) {
            let errors = Object.assign({}, this.state.errors);
            delete errors[e.target.name];
            customer_address[e.target.name] = e.target.value;
            this.setState({
                [e.target.name]: e.target.value,
                errors,
                customer_address
            });
        } else {
            customer_address[e.target.name] = e.target.value;
            self.setState({
                [e.target.name]: e.target.value,
                customer_address
            });
        }
    }
    handlecheckboxChange() {
        if (this.state.adr && this.state.adr.postalCode) {
            this.setState({ checked: !this.state.checked, postalCode: this.state.adr.postalCode });
        } else {
            this.setState({ checked: !this.state.checked })
        }
    }
    handleOptionChange(e) {
        var self = this;
        self.setState({
            [e.target.name]: e.target.value
        });
    }
    validate() {
        let isError = false;
        let errors = {};

        if (this.state.cardholderName === undefined || this.state.cardholderName === '') {
            isError = true;
            errors.cardholderName = "Enter Cardholder Name";
        }

        if (!this.state.optionalAddress) {
            isError = true;
            errors.optionalAddress = "Address Required";
        }
        if (!this.state.optionalCity) {
            isError = true;
            errors.optionalCity = "City Required";
        }
        if (this.state.checked) {
            this.setState({ errors, streetAddress: this.state.adr.streetAddress, postalCode: this.state.adr.postalCode, locality: this.state.adr.locality, extendedAddress: this.state.adr.extendedAddress, countryCodeAlpha2: this.state.adr.countryCodeAlpha2, region: this.state.adr.region });
        } else {
            this.setState({ errors });
        }

        return isError;
    }
    buildPaymentRequest(payments) {
        let self = this;
        const { assetprice } = self.state;
        const paymentRequest = payments.paymentRequest({
            countryCode: 'US',
            currencyCode: 'USD',
            total: {
                amount: assetprice.toString(),
                label: 'Total',
            },
        });
        return paymentRequest;
    }
    async initializeCard(payments) {
        let cardSource = await payments.card();
        $("#card-container").empty();
        await cardSource.attach('#card-container');
        card = cardSource;
    }
    async initializeApplePay(payments) {
        console.log("intialize apple pay")
        let self = this;
        const { arrayAssets } = self.state;
        const paymentRequest = this.buildPaymentRequest(payments);
        let squarePlanId = arrayAssets[0].assetid
        let subPlanName = arrayAssets[0].assetName
        let useremail = localStorage.getItem("email");
        let referencId = window.app.appName + "_" + squarePlanId + "_" + subPlanName + "_" + useremail
        let applePaySource = await payments.applePay(paymentRequest, {
            redirectURL: window.location.href,
            referenceId: referencId,
        });
        // const buttonOptions = {
        //     shape: 'semiround',
        //     width: 'full',
        // };
        squareApplePay = applePaySource
        //await applePaySource.attach('#square-apple-pay-button');
        console.log("squareApplePay intitalize ", squareApplePay)

        const applePayButton = document.getElementById('square-apple-pay-button');
        applePayButton.addEventListener('click', async function (event) {
            //console.log("squareGooglePay intitalize ", squareGooglePay)
            //console.log("event ", event)
            const tokenResult = await squareApplePay.tokenize();
            if (tokenResult.status === 'OK') {
                self.paymentSquare(tokenResult.token)
            } else {
                let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
                if (tokenResult.errors) {
                    errorMessage += ` and errors: ${JSON.stringify(
                        tokenResult.errors
                    )}`;
                }
            }
        });


        // return applePay;
    }


    async initializeGooglePay(payments) {
        //squareGooglePay = true
        let self = this;
        $("#square-google-pay-button").empty();
        if (squareGooglePay == false) {
            const paymentRequest = this.buildPaymentRequest(payments)

            const googlePay = await payments.googlePay(paymentRequest);
            squareGooglePay = googlePay
            //await googlePay.attach('#google-pay-button');
            await googlePay.attach('#square-google-pay-button', {
                buttonColor: 'white',
                buttonSizeMode: 'static',
                buttonType: 'long'
            });


            const googlePayButton = document.getElementById('square-google-pay-button');
            googlePayButton.addEventListener('click', async function (event) {
                //console.log("squareGooglePay intitalize ", squareGooglePay)
                //console.log("event ", event)
                const tokenResult = await squareGooglePay.tokenize();
                if (tokenResult.status === 'OK') {
                    self.paymentSquare(tokenResult.token)
                } else {
                    let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
                    if (tokenResult.errors) {
                        errorMessage += ` and errors: ${JSON.stringify(
                            tokenResult.errors
                        )}`;
                    }


                }
                //await handlePaymentMethodSubmission(event, googlePay);
            });
        }
        // return squareGooglePay;
    }
    async initializeCashApp(payments) {
        console.log("intialize cash app")
        let self = this;
        const { arrayAssets } = self.state;
        const paymentRequest = this.buildPaymentRequest(payments);
        let squarePlanId = arrayAssets[0].assetid
        let subPlanName = arrayAssets[0].assetName
        let useremail = localStorage.getItem("email");
        let referencId = window.app.appName + "_" + squarePlanId + "_" + subPlanName + "_" + useremail
        let cashAppPaySource = await payments.cashAppPay(paymentRequest, {
            redirectURL: window.location.href,
            referenceId: referencId,
        });
        const buttonOptions = {
            shape: 'semiround',
            width: 'full',
        };
        cashAppPay = cashAppPaySource
        await cashAppPaySource.attach('#cash-app-pay', buttonOptions);
        //  console.log("cashAppPay intitalize ", cashAppPay)

        cashAppPay.addEventListener(
            'ontokenization',
            async function (event) {
                console.log("event ", event)
                const { tokenResult } = event.detail;
                console.log("tokenResult ", tokenResult)
                if (tokenResult.status === 'OK') {
                    self.paymentSquare(tokenResult.token)
                    // const paymentResults = await createPayment(tokenResult.token);
                    // console.debug('Payment Success', paymentResults);
                } else {
                    let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;

                    if (tokenResult.errors) {
                        errorMessage += ` and errors: ${JSON.stringify(
                            tokenResult.errors
                        )}`;
                    }
                    // $("#overlay").hide();
                    // $("#braintreeErrorPopup").show()
                    // $(".braintreeError").html(errorMessage);
                    //throw new Error(errorMessage);
                }
            }
        );
        //return cashAppPay;
    }

    paymentSquare(paymentToken) {
        let self = this
        const { arrayAssets, assetprice, assetinfo, envoisku } = self.state;
        let itemType = "asset";
        let sku = assetinfo && assetinfo.quickbooksId;
        if (arrayAssets.length > 0 && arrayAssets[0].userassetType == "channel") {
            itemType = "channel";
            localStorage.setItem("a", 'channelasset');
        } else if (arrayAssets.length > 0 && arrayAssets[0].userassetType == "series") {
            itemType = arrayAssets[0].userassetType;
        }

        var dataparams = "payment_method_nonce=" + paymentToken + "&token=" + token + '&cardholderName=' + self.state.cardholderName + '&price=' + assetprice + '&transactionType=' + actionType + '&itemType=' + itemType + '&userassetType=' + itemType;

        if (locationId) {
            dataparams = dataparams + "&paymentCurrency=USD&gatewayType=cashapp&sourceId=" + paymentToken + "&locationId=" + locationId;
        }
        if (sku) {
            dataparams = dataparams + "&sku=" + sku;
        }
        if (envoisku) {
            dataparams = dataparams + "&envoisku=" + envoisku;
        }
        console.log('dataparams', dataparams)


        console.log('arrayAssets', arrayAssets)
        if (self.state.sku) {
            dataparams = dataparams + "&sku=" + self.state.sku;
        }
        if (self.state.envoisku) {
            dataparams = dataparams + "&envoisku=" + self.state.envoisku;
        }
        //let siteSettings = window.site;
        //let envoiSubmissionApiBaseUrl = siteSettings && siteSettings.submissionApiBaseUrl;
        let envoiScreeningApiBaseUrl = siteSettings && siteSettings.screeningApiBaseUrl;
        axios.post(envoiScreeningApiBaseUrl + '/paymentAuth?appname=' + window.app.appName + '&env=' + window.app.appEnvName + '&' + dataparams, arrayAssets)
            .then((response) => {
                // $("#overlay").addClass("hidediv");
                if (response && response.data && response.data.statusCode == 200) {
                    window.location = "/boughtsuccess"
                   // browserHistory.push("/boughtsuccess")
                } else {
                    $("#error").html("Credit Card Validation error")
                }
            })
            .catch((err) => {
                console.error.bind(err);
            })
        //   }
    }
    async cardPayment() {
        let self = this
        const tokenResult = await card.tokenize();
        if (tokenResult.status === 'OK') {
            console.log(tokenResult.token);
            self.paymentSquare(tokenResult.token)


        } else {
            let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
            if (tokenResult.errors) {
                errorMessage += ` and errors: ${JSON.stringify(
                    tokenResult.errors
                )}`;
            }
            $("#error").html(errorMessage)
        }
    }
    paymentTypeOption(e) {

        var optionId = e.target.id;
        $(".paymentopt").addClass("hidediv");
        if (optionId == "cashpay") {
            $("#" + optionId + "-container").removeClass("hidediv");
            $("#creditcard-braintree").addClass("hidediv");
        }
        else {
            $("#" + optionId + "card").removeClass("hidediv");
            $("#creditcard-braintree").removeClass("hidediv");
            document.getElementById("cardcc").checked = true;

        }

        console.log("cashapp ", appId, locationId, card, cashAppPay)
        console.log("card ", card)
        console.log("cashAppPay ", cashAppPay)
        if (optionId == "cashpay" && appId && locationId) {


            payments = window.Square.payments(appId, locationId);
            if (!card) {
                this.initializeCard(payments);
            }
            if (!cashAppPay) {
                console.log("payments ", payments)
                this.initializeCashApp(payments);
                this.initializeGooglePay(payments);
                this.initializeApplePay(payments);
            }
        }


    }
    paymentOption(e) {

        var optionId = e.target.id;
        $(".paymentopt").addClass("hidediv");
        if (optionId == "paypal" || optionId == "gpay" || optionId == "applepay") {
            $("#" + optionId + "-container").removeClass("hidediv");
        }
        // else if (optionId == "gpay") {
        //     $("#" + optionId + "-container").removeClass("hidediv");
        // } else if (optionId == "applepay") {
        //     $("#" + optionId + "-container").removeClass("hidediv");
        // } 
        else {
            $("#creditcard").removeClass("hidediv");

        }


    }
    paymentCardOption(e) {
        var optionId = e.target.id;
        $(".paymentcardopt").addClass("hidediv");
        $("#" + optionId + "card").removeClass("hidediv");
        $("#" + optionId + "Submit").removeClass("hidediv");
    }
    handleClose() {
        localStorage.removeItem("action");
        localStorage.removeItem("contentType");
        localStorage.removeItem("assetId");
        localStorage.removeItem("a");
        localStorage.removeItem("channelcode");
        localStorage.removeItem("t");
        // browserHistory.push(defaultRoute);
        window.location = `/asset?assetid=${assetid}`
    }
    defaultCard(paymentToken) {
        let self = this;
        let envoiScreeningApiBaseUrl = siteSettings && siteSettings.screeningApiBaseUrl;
        let url = envoiScreeningApiBaseUrl + '/customerCards?appname=' + window.app.appName + '&token=' + token + '&env=' + window.app.appEnvName
        if (paymentToken) {
            $("#cardslist tr").removeClass("highlight");
            $("#" + paymentToken).addClass("highlight");
            url = url + "&paymentToken=" + paymentToken + "&default=true"
        }
        axios.get(url)
            .then(function (response) {
                if (response && response.data && response.data.result && Array.isArray(response.data.result) && response.data.result.length > 0) {
                    let cardDetails = response.data.result
                    self.setState({ cardDetails: cardDetails, showCards: true });
                    document.getElementById("saved").checked = true;
                    $("#newcard").addClass("hidediv");
                    $("#newSubmit").addClass("hidediv");

                    let defaultCardSelection = cardDetails.filter(card => card.default == true)
                    $("#" + defaultCardSelection[0].token).addClass("highlight");
                }
            })
            .catch(function (error) {
            });

    }
    paymentAPI() {
        let self = this;
        //let actionType = localStorage.getItem("action") || "buy";
        let assetprice = self.state.assetprice;
        let arrayAssets = self.state.arrayAssets;
        let itemType = "asset";
        localStorage.setItem("a", 'asset');
        let sku = self.state.assetinfo && self.state.assetinfo.quickbooksId;
        if (arrayAssets.length > 0 && arrayAssets[0].userassetType == "channel") {
            itemType = "channel";
            localStorage.setItem("a", 'channelasset');
        } else if (arrayAssets.length > 0 && arrayAssets[0].userassetType == "series") {
            localStorage.setItem("isFromBuyPopupSeries", true);
            localStorage.setItem("seasonName", (self.state.assetinfo.seasons && self.state.assetinfo.seasons.length > 0 && self.state.assetinfo.seasons[0].seasonName || ''));
            itemType = arrayAssets[0].userassetType;
        }
        let envoisku = self.state.envoisku
        self.props.getPricing(token, "", assetprice, arrayAssets, self.state.optionalAddress, self.state.optionalCity, self.state.contentType, itemType, actionType, sku, self.state.cardholderName, envoisku);
        $("#overlay").removeClass("hidediv");
        let userBuyIdentify = {
            "title": "getPricing",
            "page": "Buy Asset",
            "created": new Date(),
            "assetid": arrayAssets[0].assetid,
            "assetname": arrayAssets[0].assetname || arrayAssets[0].channelname,
            "assetName": arrayAssets[0].assetName,
            "single_price": assetprice,
            "itemType": itemType,
            "userassetType": arrayAssets[0].userassetType
        }
        hotJarUserIdentify(userBuyIdentify)
    }
    yesClick(e) {
        let self = this;
        self.setState({ userError: false });
    }
    noClick(e) {
        if (defaultRoute && token) {
            browserHistory.push(defaultRoute);
        } else {
            browserHistory.push("/")
        }
    }
    payUsubmit(e) {
        e.preventDefault();

        let self = this
        const { merchantKey, txnId, assetprice, assetname, merchantSalt, userinfo, firstname, email } = self.state;

        let userfirstname = userinfo.firstname || firstname
        let userEmail = userinfo.emailid || email
        let hashSequence = merchantKey + "|" + txnId + "|" + assetprice + "|" + assetname + "|" + userfirstname + "|" + userEmail + "|||||||||||" + merchantSalt
        let hash = cryptoJs.SHA512(hashSequence).toString()

        self.setState({ hash }, () => {
            self.paymentCall(txnId, "PayU");

        });

    }

    render() {
        let self = this;
        let siteSettings = window.site;
        let appSettings = window.app
        let siteConfig = (siteSettings && siteSettings.config) || (appSettings && appSettings.defaultSiteSettings && appSettings.defaultSiteSettings.config);
        let imagePrefix = siteConfig.commonResourcesUrl + siteConfig.appResourcesPath;
        let imageSuffix = siteConfig.imageSuffix || "?auto=compress,format";
        let defaultLandscape = siteConfig.defaultLandscape;
        const { priceFormat } = self.props;
        const { assetinfo, assetPaid, assetprice, contentType, assetname, errMessage, freeAsset, showCards, cardDetails, envoisku, errType, notPublishedAsset, isAppleandGoogleEnabled, userError, merchantKey, txnId, hash, userinfo } = this.state;
        // console.log('assetname',assetname)
        // console.log('assetinfo',assetinfo)
        var color = { color: "#f00" }
        var scolor = { color: "#fff" }
        //let actionType = localStorage.getItem("action");
        let redirectURL = ((siteConfig.paymentGateway || {}).payuIndia || {}).redirectURI
        let successURL = redirectURL + "?location=" + window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + "/boughtsuccess"
        let failedURL = redirectURL + "?location=" + window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + "/boughtsuccess"

        return (

            <div>

                <div id="overlay" className="hidediv">

                    <div className="preLoader">
                        <div className="loftloader-wrapper pl-frame">
                            <div className="loader">
                                <div className="spark"></div>
                                <img src={window.site.preLoader} />
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div ref="headermenu" className={this.state.isTop ? 'header_det pmt_dt' : 'header_det pmt_dt up'}>
                    <div className="wrapper">
                        <div className="logo"><img src={imagePrefix + "screening/images/headerlogo.png"} /></div>
                        {/* <a href="#" onClick={browserHistory.goBack} className="back"></a> */}
                        <span onClick={e => self.handleClose()} className="cls"></span>
                    </div>
                </div>

                <div className="payment_bg new-ubg">
                    {/* <img src={payment_bg} /> */}
                </div>
                <div className=" payment_page">
                    <h2>{actionType && actionType.toUpperCase() || 'BUY'}</h2>
                    <h4 className="sub-text">ENTER PAYMENT INFO</h4>
                    <div className="payment_wrp">
                        <div className="pmt_left">
                            <div className="asset_payment">
                                <h4>{actionType && actionType.toUpperCase() || 'BUY'}</h4>
                                {envoisku && <p><label style={{ 'width': '80px' }}>SKU : </label> {envoisku && envoisku.toUpperCase()}</p>}
                                <p><label style={{ 'width': '80px' }} >Title : </label> {assetname && assetname.substring(0, 25)}{assetname && assetname.length > 25 ? "....." : ""}</p>
                                <p><label style={{ 'width': '80px' }}>Quality : </label> {contentType && contentType.toUpperCase()}</p>
                                <p><label style={{ 'width': '80px' }}>Price : </label> {assetprice && priceFormat(assetprice, 'USD', 'en-US')}</p>
                            </div>
                        </div>
                        <div className="pmt_right">
                            <div className="pmt_blk1 pt-0">
                                <p className="ps3 text-left">ACCOUNT CREATED FOR: {localStorage.getItem("email")}</p>
                            </div>

                            {siteConfig && siteConfig.paymentGateway && siteConfig.paymentGateway.key == "braintree" ?
                                <div className="pmt_blk1 pmt_blk2">
                                    <h3>PAYMENT METHOD</h3>

                                    <div className="row">


                                        <div className="col-md-6 col-xsm-6">
                                            <div className="form-group payment-mode"><input type="radio" id="credit" name="paymentSetType" value="creditcard" onClick={e => this.paymentTypeOption(e)} /><label>BRAINTREE</label></div>

                                        </div>

                                        <div className="col-md-6 col-xsm-6">
                                            <div className="form-group payment-mode"><input type="radio" id="cashpay" name="paymentSetType" value="cashpay" onClick={e => this.paymentTypeOption(e)} /><label>CASH APP</label></div>

                                        </div>

                                    </div>
                                    <div id="creditcard-braintree" className="braintree_payment">

                                        <div>
                                            <div className="form-group payment-mode"><input type="radio" id="cardcc" name="paymenttype" value="cardcc" onClick={e => this.paymentOption(e)} /><label>CARD</label></div>

                                        </div>
                                        {siteConfig && siteConfig.paymentGateway && siteConfig.paymentGateway.braintree && siteConfig.paymentGateway.braintree.paymentMethods && siteConfig.paymentGateway.braintree.paymentMethods.paypal &&
                                            <div>
                                                <div className="form-group payment-mode"><input type="radio" id="paypal" name="paymenttype" value="paypal" onClick={e => this.paymentOption(e)} /><label>PAYPAL</label></div>

                                            </div>
                                        }
                                        {siteConfig && siteConfig.paymentGateway && siteConfig.paymentGateway.braintree && siteConfig.paymentGateway.braintree.paymentMethods && siteConfig.paymentGateway.braintree.paymentMethods.googlePay &&
                                            <div>
                                                <div className="form-group payment-mode"><input type="radio" id="gpay" name="paymenttype" value="gpay" onClick={e => this.paymentOption(e)} /><label>GOOGLE PAY</label></div>

                                            </div>
                                        }
                                        {isAppleandGoogleEnabled && siteConfig && siteConfig.paymentGateway && siteConfig.paymentGateway.braintree && siteConfig.paymentGateway.braintree.paymentMethods && siteConfig.paymentGateway.braintree.paymentMethods.applePay &&
                                            <div>
                                                <div className="form-group payment-mode"><input type="radio" id="applepay" name="paymenttype" value="applepay" onClick={e => this.paymentOption(e)} /><label>APPLE PAY</label></div>

                                            </div>
                                        }
                                    </div>



                                    <div className="cc_det paymentopt" id="creditcard">
                                        <form id="cardForm" method="post">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>CARDHOLDER NAME</label>
                                                        <input type="text" className="hosted-field" value={this.state.cardholderName || ""} name="cardholderName" onChange={e => this.handletextChange(e)} />
                                                        <span className="errormsg" >{this.state.errors.cardholderName}</span>
                                                    </div>
                                                </div>

                                            </div>


                                            {showCards &&
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group payment-mode"><input type="radio" id="saved" name="cardtype" value="saved" onClick={e => this.paymentCardOption(e)} /><label>SAVED CARDS</label></div>

                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group payment-mode"><input type="radio" id="new" name="cardtype" value="new" onClick={e => this.paymentCardOption(e)} /><label>NEW CARD</label></div>

                                                    </div>
                                                </div>
                                            }
                                            {showCards &&
                                                <div id="savedcard" className="paymentcardopt">
                                                    <table id="cardslist" className="table saved-cards">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Card</th>
                                                                <th scope="col">Name</th>
                                                                <th scope="col">Expies on</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {cardDetails && cardDetails.length > 0 && cardDetails.map(function (item, i) {
                                                                return (


                                                                    <tr key={i} onClick={(e) => self.defaultCard(item.token)} id={item.token}>
                                                                        <td >
                                                                            <p><img src={item && item.imageUrl} /> {item && item.maskedNumber}</p>
                                                                        </td>
                                                                        <td>{item && item.cardholderName}</td>
                                                                        <td>{item && item.expirationDate}</td>
                                                                    </tr>
                                                                )
                                                            })}

                                                        </tbody>
                                                    </table>
                                                </div>
                                            }

                                            <div className="row paymentcardopt" id="newcard">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>CARD NUMBER</label>
                                                        <div id="card-number" className="hosted-field"></div>
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="form-group inline-st">
                                                        <label className="pmt_lbl">EXPIRATION</label>
                                                        <div id="expiration-month" className="hosted-field"></div>
                                                        <div id="expiration-year" className="hosted-field"></div>
                                                    </div>
                                                </div>

                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>CVV</label>

                                                        <div id="cvv" className="hosted-field"></div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form-group">
                                                        <label>ZIP CODE</label>

                                                        <div id="postal-code" className="hosted-field"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* } */}


                                            <div className="row">

                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>ADDRESS <span style={{ 'color': '#afafaf' }}></span></label>
                                                        <input type="text" value={this.state.optionalAddress} placeholder="Street address, P.O. box, Company name, C/o" name="optionalAddress" onChange={e => this.addressChange(e)} />
                                                        <span className="errormsg" >{this.state.errors.optionalAddress}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>CITY <span style={{ 'color': '#afafaf' }}></span></label>
                                                        <input type="text" value={this.state.optionalCity} placeholder="City" name="optionalCity" onChange={e => this.addressChange(e)} />
                                                        <span className="errormsg" >{this.state.errors.optionalCity}</span>
                                                    </div>
                                                </div>

                                            </div>
                                            {errMessage != null && <p className="paymentError mb-2 mt-2" style={color}>{errMessage}</p>}

                                            {/* <p className="free_trl">By selecting “Start Envoi Account”, you agree to authorize this charge and to the &nbsp;
                                                 <NavLink to='/terms' target="_blank" className="tos">Terms of Service.</NavLink></p> */}

                                            <div className="form-group">

                                                {showCards ?
                                                    <span onClick={(e) => self.paymentAPI(e)} id="savedSubmit" className="submit paymentcardopt" >PAY</span>
                                                    :
                                                    <input type="button" id="newSubmit" value="PAY" className="submit paymentcardopt" />
                                                }
                                            </div>
                                        </form>
                                    </div>


                                    <div id="paypal-container" className="paymentopt cc_det hidediv">
                                        <div className="text-left"><button id="pay-pal-button" className="submit">Paypal Checkout</button></div>
                                    </div>
                                    <div id="gpay-container" className="paymentopt cc_det hidediv">
                                        <div className="text-left"><button id="google-pay-button" className="paypal-button btn" ><img src={imagePrefix + "screening/images/common-images/gpay.svg"} /></button></div>
                                    </div>
                                    <div id="applepay-container" className="paymentopt cc_det hidediv">
                                        <div className="text-left"><button id="apple-pay-button" className="submit">Apple Pay</button></div>
                                    </div>

                                    <div id="cashpay-container" className="paymentopt cc_det hidediv">

                                        <div id="cashAppPay">
                                            <div id="cash-app-pay"></div>
                                        </div>
                                        <div id="squareAppApplepay">
                                            <div id="square-apple-pay-button" className='applepaySquare'>Pay</div>
                                        </div>

                                        <div id="squareAppGooglepay">
                                            <div id="square-google-pay-button"></div>
                                        </div>
                                        <div id="cardContainer">
                                            <div id="card-container"></div>
                                        </div>

                                        <p className="free_trl">By selecting “Start {siteConfig.siteTitle} Account”, you agree to authorize this charge and to the &nbsp;
                                            <NavLink href="/terms" target="_blank" className="tos">Terms of Service.</NavLink></p>
                                        <div className="form-group">
                                            <button id="card-button" onClick={(e) => self.cardPayment(e)} type="button" className="submit">Submit</button>
                                        </div>

                                    </div>

                                </div>
                                :

                                <div className="pmt_blk1">
                                    <form action="" method='post' id="payUPayment">
                                        <div className="cc_det paymentopt" id="creditcard">

                                            <input type="hidden" name="key" value={merchantKey} />
                                            <input type="hidden" name="txnid" value={txnId} />
                                            <input type="hidden" name="productinfo" value={assetname} />
                                            <input type="hidden" name="furl" value={failedURL} />
                                            <input type="hidden" name="amount" value={assetprice} />
                                            <input type="hidden" name="surl" value={successURL} />
                                            <input type="hidden" name="hash" value={hash} />
                                            <input type="hidden" name="firstname" value={userinfo && userinfo.firstname} />
                                            <input type="hidden" name="lastname" value={userinfo && userinfo.lastname} />
                                            <input type="hidden" name="email" value={userinfo && userinfo.emailid} />
                                            <input type="hidden" name="phone" value={userinfo && userinfo.phone} />

                                            <div className="row">

                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>ADDRESS <span style={{ 'color': '#afafaf' }}></span></label>
                                                        <input type="text" value={this.state.optionalAddress} placeholder="Street address, P.O. box, Company name, C/o" name="address1" onChange={e => this.payUaddressChange(e)} />
                                                        <span className="errormsg" >{this.state.errors.optionalAddress}</span>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>CITY <span style={{ 'color': '#afafaf' }}></span></label>
                                                        <input type="text" value={this.state.optionalCity} placeholder="City" name="city" onChange={e => this.payUaddressChange(e)} />
                                                        <span className="errormsg" >{this.state.errors.optionalCity}</span>
                                                    </div>
                                                </div>

                                            </div>


                                            <p className="free_trl">By selecting “Start {siteConfig.siteTitle} Account”, you agree to authorize this charge and to the &nbsp;
                                                <NavLink to='/terms' target="_blank" className="tos">Terms of Service.</NavLink></p>

                                            <div className="form-group">

                                                <span onClick={(e) => self.payUsubmit(e)} id="savedSubmit" className="submit paymentcardopt" >PAY</span>

                                            </div>


                                        </div>
                                    </form>
                                </div>



                            }



                        </div>
                    </div>
                </div>

                {errType &&
                    <AlertPopup message="Sorry, Availability type is not available." closePopup={this.handleClose} title='Opps' imagePath="images/states_of_applications/warning_gray.png" showClose={false} />
                }
                {notPublishedAsset &&
                    <AlertPopup message="No asset found-asset not published." closePopup={this.handleClose} title='Opps' imagePath="images/states_of_applications/warning_gray.png" showClose={false} />
                }
                {assetPaid &&
                    <AlertPopup message="Sorry, You have already purchased this asset." closePopup={this.handleClose} title='ALREADY PURCHASED' imagePath="images/states_of_applications/warning_gray.png" showClose={false} />
                }

                {freeAsset &&
                    <AlertPopup message="You came to the wrong page." closePopup={this.handleClose} title='Opps!' imagePath="images/states_of_applications/warning_gray.png" showClose={false} />
                }

                {/* {userError &&
                    <AlertPopup message="Your account doesn't match the payment scanned user." closePopup={this.handleClose} title='Opps!' imagePath="images/states_of_applications/warning_gray.png" showClose={false} />
                } */}
                <Modal
                    isOpen={userError}
                    style={customStyles}
                    contentLabel="Example Modal"
                    className="donate_popup rmndr-pop">
                    <div className="modal-body">
                        <p className="don_by"><h4>Your account doesn't match the payment scanned user</h4>
                        </p>
                    </div>
                    <div className="modal-footer nobord nopad_tp">
                        <button className="btn cont-btn" onClick={e => self.yesClick(e)}>Yes</button>
                        <button className="btn cont-btn" onClick={e => self.noClick(e)}>No</button>
                    </div>
                </Modal>
            </div>

        );
    }
}


const mapState = ({ user, address, countries, regions, assetinfo, contentShowAssetsItems, assetPayment, userinfo }) => ({ user, address, countries, regions, assetinfo, contentShowAssetsItems, assetPayment, userinfo });
const mapDispatch = { getPricing, priceFormat };
export default windowSize(connect(mapState, mapDispatch)(payment));
