// import React from 'react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import EventCountdown from './EventCountdown';
import NavLink from '../NavLink';
let siteSettings = window.site || {};
let siteConfig = siteSettings.config || {};
let commonResourcesUrl = siteConfig.commonResourcesUrl || 'https://envoi-common-resources.imgix.net/'
let imagesAssetResourcesPrefix = commonResourcesUrl + "screening/";

let isPaused;
//
 let playerStream = 'hls';
 var my_script = new_script('https://envoi-common-resources.imgix.net/screening/js/bitmovinplayer.js');
 function new_script(src) {
    return new Promise(function(resolve, reject){
      var script = document.createElement('script');
      script.src = src;
      script.addEventListener('load', function () {
        resolve();
      });
      script.addEventListener('error', function (e) {
        reject(e);
      });
      document.body.appendChild(script);
    })
  };
class ChannelPlayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidMount() {
        let self = this;
        const { channelDataResponse } = this.props;
        console.log('channelDataResponse',channelDataResponse)
        if (channelDataResponse && channelDataResponse.mediatailor) {
                        let hlsPlayer = channelDataResponse.mediatailor && channelDataResponse.mediatailor.Outputs && channelDataResponse.mediatailor.Outputs[0] && channelDataResponse.mediatailor.Outputs[0].PlaybackUrl;
                        // setTimeout(function () {
                            self.getPlayer(hlsPlayer);
                        // }, 1000);

                    }
        // let userCode = localStorage.getItem('userCode') || '';
        // let payload = { assetCount, pageNumber, userCode }
        // this.props.getAssets(payload);
    }
    getPlayer(hlsPlayer){
        let self = this;
        
        let playSource = {};
        if (hlsPlayer && hlsPlayer != "") {
            playerStream = hlsPlayer.includes('.m3u8') ? 'hls' : 'progressive';
            playSource[playerStream] = hlsPlayer
            setTimeout(function () {
               self.bitmovinplayer(playSource)
            }, 2000);
        }
       
       
    }
    bitmovinplayer(playSource){
        //  let markersArr = await this.markerPoints(this.props.markersData)
        let self = this;
        let player;
        let siteSettings = window.site;
        let appSettings = window.app
        let siteConfig = (siteSettings && siteSettings.config) || (appSettings && appSettings.defaultSiteSettings && appSettings.defaultSiteSettings.config);
        let bitmovinSettings = (siteConfig.player || {}).bitmovin || {};
        let bitAnalyticsKey = bitmovinSettings.analyticsKey;
        let playerKey = bitmovinSettings.playerKey;
        var config = {
            "key": playerKey
        };
        console.log('config', config)
        var configWithAnalytics = Object.assign({}, config, {
            analytics: {
                key: bitAnalyticsKey,
            },
            playback: {
                autoplay: true,
                 controls: true,
            },
            style: {
                width: "100%",
                aspectratio: "16:9",
               // controls: true,
                
            }
        });
        console.log('configWithAnalytics', configWithAnalytics)
        var container = document.getElementById("playerMovie");
        if(bitmovin){

        
        player = new bitmovin.player.Player(container, configWithAnalytics);
        console.log("playSource",playSource)
        // player.load(playSource).then(
        //     function(player) {
        //       console.log('Successfully created Bitmovin Player instance');
        //     },
        //     function(reason) {
        //       console.log('Error while creating Bitmovin Player instance');
        //     },
        //   );
        player.load(playSource).then(
            function (value) {
                //myUiManager = new bitmovin.playerui.UIFactory.buildDefaultUI(player);
                player.on(bitmovin.player.PlayerEvent.Play, function () {
                    isPaused = false;
                });

                player.on(bitmovin.player.PlayerEvent.Paused, function () {
                    isPaused = true;
                });
                player.on(bitmovin.player.PlayerEvent.Error, function () {
                    self.setState({ notPublished: true })
                });
               
            }

        );
        }

    }
render() {
    const {channelDataResponse  } = this.props;
    

    return (
        <div className='schedule_player'>

        <div className='player_block'>
            {channelDataResponse && channelDataResponse.mediatailor &&
                <div className='player' id="playerMovie" style={{ "cursor": "pointer" }}>
                    {/* <iframe src="https://cdn.bitmovin.com/content/assets/art-of-motion-dash-hls-progressive/MI201109210084_mpeg-4_hd_high_1080p25_10mbits.mp4" title="W3Schools Free Online Web Tutorials"></iframe> */}
                </div>}
            {channelDataResponse && !channelDataResponse.mediatailor &&
                <div className="movie--isloading player_loading">
                    <div className="loading-image"></div>
                    <div className="loading-content">
                        <div className="loading-text-container">
                            <div className="loading-main-text"></div>
                            <div className="loading-sub-text"></div>
                        </div>
                        <div className="loading-btn"></div>
                    </div>
                </div>}

            <div className='player_content'>
                <div className='chnl_title'>
                    <h3>{channelDataResponse && channelDataResponse.channelname}</h3>
                    {/* <p>11ELEVEN PPV</p> */}
                </div>
                <div className='chnl_logo'>
                    <img src={channelDataResponse && channelDataResponse.landscape_logo_image ? channelDataResponse.landscape_logo_image : defaultLandscape} />
                </div>
            </div>
        </div>

        {/* <div className="movie--isloading player_loading">
            <div className="loading-image"></div>
            <div className="loading-content">
                <div className="loading-text-container">
                    <div className="loading-main-text"></div>
                    <div className="loading-sub-text"></div>
                </div>
                <div className="loading-btn"></div>
            </div>
        </div> */}
    </div>
  )
}
}
export default connect()(ChannelPlayer);
