/***
**Module Name:  payment
**File Name :  payment.js
**Project :      CALENDOW
**Copyright(c) : X Platform Consulting.
**Organization : Peafowl Inc
**author :  Hari
**author :  Rajesh
**license :
**version :  1.0.0
**Created on :
**Last modified on:
**Description : payment page functionality and html code
*/
//export default App;
import React, { Component } from 'react';
import { Router, Link, IndexRedirect, browserHistory } from 'react-router';
import { connect } from 'react-redux';
import axios from "axios";
import NavLink from '../NavLink';
//import UserInformationGrid from './UserInformationGrid';
//import Select from 'react-select';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import ReactTags from 'react-tag-autocomplete';
import { getGiftPricing, getGiftCard, priceFormat } from '../../redux/reducers/reducer';
import windowSize from 'react-window-size';
import ReactGA from 'react-ga';
import braintree from "braintree-web";
import { hotJarUserIdentify,location } from '../../utils/utils';

var customer_address = {};
var addressVisible = localStorage.getItem("adv");

function dateFormatChange(cell, row) {
    return <button onClick={e => this.deleteFile(e)}>delte</button>
    //   alert(cell,row)

}
let assetid = new URLSearchParams(window.location.search);
assetid = location("assetid");
let siteSettings = window.site;
let appSettings = window.app
let siteConfig = (siteSettings && siteSettings.config) || (appSettings && appSettings.defaultSiteSettings && appSettings.defaultSiteSettings.config);
let appStorage = appSettings.storage;
let token = appStorage.getItem('token');
let clientToken;
let braintreeSettings = siteConfig.paymentGateway || {};
      if (braintreeSettings.enable === true) {
        clientToken = (braintreeSettings.braintree || {}).gatewayToken; //Braintree Tree Token
      }
//var addressVisible = true;
class giftCardPayment extends Component {
    constructor(props) {
        super(props);


        var isLoggedIn = false;
        if (!token) {
            window.location = '/';
        } else {
            isLoggedIn = true;
        }
        var adr = JSON.parse(localStorage.getItem("adr"));
        var firstname = '';
        var lastname = '';
        var email = '';
        var phone = '';
        if (localStorage.getItem("firstname") && localStorage.getItem("firstname") != null && localStorage.getItem("firstname") != undefined) {
            firstname = localStorage.getItem("firstname")
        }
        if (localStorage.getItem("lastname") && localStorage.getItem("lastname") != null && localStorage.getItem("lastname") != undefined) {
            lastname = localStorage.getItem("lastname")
        }
        if (localStorage.getItem("email") && localStorage.getItem("email") != null && localStorage.getItem("email") != undefined) {
            email = localStorage.getItem("email")
        }
        if (localStorage.getItem("phone") && localStorage.getItem("phone") != null && localStorage.getItem("phone") != undefined) {
            phone = localStorage.getItem("phone");
        }

        this.state = {
            data: [],
            loader: false,
            login: isLoggedIn,
            selected: '',
            navigationtab: '',
            isTop: true,
            // assetid: this.props.params.assetid,
            // tv_show_name: this.props.params.tv_show_name,
            error: '',
            errors: {},
            streetAddress: '',
            locality: '',
            extendedAddress: '',
            countryCodeAlpha2: '',
            region: '',
            postalCode: '',
            showAsset: [],
            checked: false,
            adr: adr,
            customer_address: {},
            countrydefault: "",
            regiondefault: "",
            firstname: firstname,
            lastname: lastname,
            fullName: "",
            completeName: "",
            email: "",
            phone: "",
            // selectedOption: "",
            //modifiedavatar: imageprefix + 'assets/images/profiles/default/avatar1.jpg' + imagesuffix,
            addressVisible: addressVisible,
            cardholderName: firstname + ' ' + lastname,
            users: [],
            tags: [],
            suggestions: [],
            receiverId: null,
            assetimgurl: null,
            selectedEmails: [],
            emailList: [],
            emailsError: false,
            cardholderError: false,
            showmessage: false,
            deleteindex: 0,
        };
     
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.handletextChange = this.handletextChange.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.handlecheckboxChange = this.handlecheckboxChange.bind(this);
        this.okClick = this.okClick.bind(this);
        this.cancelClick = this.cancelClick.bind(this);
    }
    cellButton(cell, row, enumObject, rowIndex) {

        return (
            <button className="close u-close1" href="javascript:void()" data-toggle="modal" data-target="#openModal" onClick={() =>
                this.handleDelete(rowIndex)}  ><span aria-hidden="true">&times;</span></button>

        )
    }


    onScroll(isTop) {
        this.setState({ isTop });
    }


    handleDelete(i) {
        var self = this;
        self.setState({ showmessage: !this.state.showmessage, deleteindex: i });
        // $('#myModal').modal('show');

    }

    okClick(i) {
        var self = this;
        const tags = self.state.tags;
        tags.splice(this.state.deleteindex, 1)
        const selectedEmails = self.state.selectedEmails;
        selectedEmails.splice(this.state.deleteindex, 1)
        this.setState({
            showmessage: false,
            tags,
            selectedEmails
        })


    }

    cancelClick() {
        var self = this;
        this.setState({ showmessage: false })
    }

    ValidateEmail(mail) {
        let errors = {};
        if (/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(mail)) {
            let errors = Object.assign({}, this.state.errors);
            delete errors['email'];
            this.setState({
                errors
            });
            return (true)
        }
        errors.email = "Enter Valid Email";
        this.setState({
            errors
        });
        return (false)
    }
    handleChange(e) {
        if (!!this.state.errors['email']) {
            let errors = Object.assign({}, this.state.errors);
            delete errors['email'];
            this.setState({
                ['email']: e,
                errors
            });
        } else {
            const name = 'email';
            var value = e;

            this.setState({ [name]: value });
        }
        this.setState({ emailsError: false });
    }
    text_truncate(str, length, ending) {
        if (length == null) {
            length = 100;
        }
        if (ending == null) {
            ending = '...';
        }
        if (str && str.length > length) {
            return str.substring(0, length - ending.length) + ending;
        } else {
            return str;
        }
    };
    handleAddition(tag) {
        let self = this;
        const value = tag.name;
        let selectedEmails = self.state.selectedEmails
        let emailList = self.state.emailList
        if (tag.name != "" && this.ValidateEmail(tag.name)) {
            let updatedList = this.props.giftResult;
            let filteredItem = updatedList && updatedList.find(item => item.emailid === value);
            let newObj = {};
            if (filteredItem) {
                newObj.emailid = filteredItem.emailid;
                newObj.firstname = filteredItem.firstname;
                newObj.lastname = filteredItem.lastname;
                newObj.fullName = filteredItem.firstname || filteredItem.lastname ? this.text_truncate(filteredItem.firstname + ' ' + filteredItem.lastname, 10) : '';
                newObj.completeName = filteredItem.firstname || filteredItem.lastname ? filteredItem.firstname + ' ' + filteredItem.lastname : '';
                newObj.price = this.state.assetinfo.price;
                if (filteredItem.receiverid) {
                    newObj.receiverid = filteredItem.receiverid;
                }
                selectedEmails.push(newObj);
                emailList.push(newObj.emailid)
            } else {
                const newMail = {};
                newMail.emailid = value;
                newMail.price = this.state.assetinfo.price;
                // newMail.assetid = self.state.assetid;
                // newMail.assetname = self.state.assetname;
                // newMail.assetimgurl = self.state.assetimgurl;
                // newMail.single_price = self.state.single_price;
                selectedEmails.push(newMail);
                emailList.push(newMail.emailid)
            }
            const tags = [].concat(this.state.tags, tag)

            let uniq = {};
            selectedEmails = selectedEmails.filter(obj => !uniq[obj.emailid] && (uniq[obj.emailid] = true))
            this.setState({ tags, selectedEmails, email: '', emailList });
        } else {
            let errors = {}
            errors.email = "Enter Valid Email";
            this.setState({
                errors
            });
        }


        // console.log("selectedEmails  added", self.state.selectedEmails);

    }
    componentDidMount() {
        var self = this;
        self.braintreeSetup()
        if (siteConfig && siteConfig.segment && siteConfig.segment.enable) {
            let options = {
                "pageName": this.constructor.name,
                "appName": appname,
                "path": window.location.pathname,
                "search": window.location.search,
                "referrer": window.location.href,
                "url": window.location.href,
                "UserEmail": localStorage.getItem("email"),
                "userid": localStorage.getItem("userid"),
            }
            window.analytics.page(options);
        }
        let urlParams = new URLSearchParams(window.location.search);
        let envoiScreeningApiBaseUrl = siteSettings && siteSettings.screeningApiBaseUrl;
        // if (urlParams.has('t')) {
            axios.post(envoiScreeningApiBaseUrl + '/assetinfo?appname=' + window.app.appName + '&token=' + token + '&assetid=' + assetid+ '&env=' + window.app.appEnvName)
                .then(function (response) {
                    if (response && response.data && response.data.result) {
                        self.setState({ assetinfo: response.data.result });
                    }
                })
                .catch(function (error) {
                });

        // } else {
        //    // self.props.getassetinfo(self.state.assetid, token);
        // }


        document.addEventListener('scroll', () => {
            var isTop;
            if (window.scrollY < 50) {
                isTop = true;
            } else {
                isTop = false;
            }
            if (isTop !== this.state.isTop) {
                if (this.refs.headermenu) {
                    this.onScroll(isTop);
                }
            }
        });

        if (self.state.tv_show_name) {
            self.props.getContentShowAssets(self.state.tv_show_name);
            // console.log('showAsset',nextProps.contentShowAssetsItems.Items)
        }

        if (token) {
            this.props.getGiftCard(token);
        }
        //this.props.getcountries();
        //this.props.getaddress("billing");

        if (self.state.adr && self.state.adr.extendedAddress) {

            //self.props.getregions(self.state.adr.countryCodeAlpha2)
        } else {
            //self.props.getregions(self.state.countrydefault)
        }

        customer_address["countryCodeAlpha2"] = self.state.countrydefault;
        customer_address["region"] = self.state.regiondefault;
        customer_address["addresstype"] = "billing";

        if (self.state.adr) {
            self.state.adr["addresstype"] = "billing";
        }
        this.setState({ customer_address, adr: self.state.adr });

        { this.state.firstname == 'undefined' ? this.setState({ firstname: '' }) : null }
        { this.state.lastname == 'undefined' ? this.setState({ lastname: '' }) : null }
        { this.state.fullName == 'undefined' ? this.setState({ fullName: '' }) : null }
        { this.state.completeName == 'undefined' ? this.setState({ completeName: '' }) : null }
        { this.state.email == 'undefined' ? this.setState({ email: '' }) : null }
        { this.state.phone == 'undefined' ? this.setState({ phone: '' }) : null }

        document.getElementById("credit").checked = true;
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.assetinfo !== this.props.assetinfo) {
            this.setState({ assetinfo: nextProps.assetinfo });
        }

        // const options = [];
        if (nextProps.giftResult !== this.props.giftResult) {

            if (!_.isUndefined(nextProps.giftResult)) {
                const users = [];
                _.forEach(nextProps.giftResult, function (item, key) {

                    var userobj = {
                        //   id: 1,
                        name: item.emailid,
                        // key: item.id
                    };
                    users.push(userobj);
                })
                this.setState({
                    suggestions: users
                });
            }
        }
        if (nextProps.contentShowAssetsItems !== this.props.contentShowAssetsItems) {
            this.setState({ showAsset: nextProps.contentShowAssetsItems.Items });
            var arrayAssets = []
            var price = 0;
            _.each(nextProps.contentShowAssetsItems.Items, function (task, index) {
                //  if(task.mylist == "false"){
                var obj = {
                    "assetid": task.assetid,
                    "single_price": task.price,
                    "assetname": task.assetname,
                    "assetimgurl": task.landscape_logo_image,
                    "description": task.description,
                }
                price += parseInt(task.price)
                arrayAssets.push(obj)
                //    }
                // const tags = [].concat(arrayAssets)
            }
            );
            this.setState({ arrayAssets, price });
        }
    }
    braintreeSetup() {
        let self = this;
        let clientToken;
let braintreeSettings = siteConfig.paymentGateway || {};
      if (braintreeSettings.enable === true) {
        clientToken = (braintreeSettings.braintree || {}).gatewayToken; //Braintree Tree Token
      }
        let stylesConfig = {
            // Style all elements
            'input': {
                'font-size': '30px',
                'color': '#ffffff'
            },
            // Styling element state
            ':focus': {
                'color': '#ffffff'
            },
            '.valid': {
                'color': '#6e9e12'
            },
            '.invalid': {
                'color': 'red'
            },
            '@media screen and (max-width: 700px)': {
                'input': {
                    'font-size': '14px'
                }
            }
        }
        let fieldsConfig = {
            number: {
                selector: "#card-number",
                placeholder: "****************"
            },
            cvv: {
                selector: "#cvv",
                placeholder: "***"
            },
            expirationMonth: {
                selector: "#expiration-month",
                placeholder: "MM"
            },
            expirationYear: {
                selector: "#expiration-year",
                placeholder: "YY"
            },
            postalCode: {
                selector: "#postal-code",
                placeholder: "******"
            }
        }

        let submitBtn = document.getElementById('paySubmit');

        braintree.client.create({ authorization: clientToken }).then(function (client) {
            return braintree.hostedFields.create({
                client: client,
                styles: stylesConfig,
                fields: fieldsConfig
            });
        }).then(function (hostedFields) {
            if (hostedFields.tokenize()) {
                submitBtn.addEventListener('click', function (event) {
                    event.preventDefault();
                    hostedFields.tokenize().then(function (payload) {
                        const err = self.validate();
                        if (!err) {
                            let emailList = self.state.emailList;
                            let assetinfo = self.state.assetinfo;
                            let contentType = localStorage.getItem("contentType");
                            let actionType = localStorage.getItem("action") || "gift";
                            let urlParams = new URLSearchParams(window.location.search);
                            let userassetType = "asset";
                            let quickboksID = assetinfo && assetinfo.quickbooksId;
                            let envoisku = assetinfo && assetinfo.envoisku

                            let newArray = [{ "users": emailList, "assetid": self.state.assetid, "assetname": assetinfo.assetname || assetinfo.channelname || envoisku }]
                            let availability = assetinfo && assetinfo.availability || {};
                            let buyplans = availability.buyplans || {};
                            let assetprice = buyplans[actionType] && buyplans[actionType].contentPrice && buyplans[actionType].contentPrice[contentType] || 0;
                            let email = localStorage.getItem("email");
                            if (emailList.length === 0) {
                                self.setState({ emailsError: true })
                            } else {
                                self.setState({ emailsError: false })
                            }

                            if (urlParams.has('t')) {
                                userassetType = "channel"
                            }
                            
                            else if(assetinfo && assetinfo.assetType == "Episodic"){
                                userassetType = 'series';  
                            }
                            if (emailList.length > 0) {
                                let totalPrice = assetprice * emailList.length;
                                self.props.getGiftPricing(token, payload.nonce, totalPrice, email, newArray, self.state.optionalAddress, self.state.optionalCity, userassetType, contentType, quickboksID, envoisku);
                                $("#overlay").removeClass("hidediv");
                                let userGiftIdentify = {
                                    "title":"getGiftPricing",
                                    "page": "Gift Payment",
                                    "created": new Date(),
                                     "assetid": self.state.assetid, 
                                     "assetname": assetinfo.assetname || assetinfo.channelname || envoisku,
                                     "price": totalPrice,
                                     "mailid": email,
                                     "userassetType":userassetType
                                }
                                hotJarUserIdentify(userGiftIdentify)
                            }
                            
                        }

                    }).catch(function (err) {
                        self.setState({ errMessage: err.message });

                    });
                });
            }
        });
    }

    addressChange(e) {
        var self = this;
        const name = e.target.name;
        const value = e.target.value;
        let errors = self.state.errors
        errors[name] = ""
        // if (name === "countryCodeAlpha2") {
        //     // var regioncountry = value.split("_");
        //     self.props.getregions(value)
        // }
        // customer_address[name] = value;
        this.setState({ [name]: value });
    }

    handletextChange(e) {
        var self = this;
        if (self.validate()) {
            if (!!this.state.errors[e.target.name]) {
                let errors = Object.assign({}, this.state.errors);
                delete errors[e.target.name];
                customer_address[e.target.name] = e.target.value;
                this.setState({
                    [e.target.name]: e.target.value,
                    errors,
                    customer_address
                });
            } else {
                customer_address[e.target.name] = e.target.value;
                self.setState({
                    [e.target.name]: e.target.value,
                    customer_address
                });
            }
        }
    }


    handlecheckboxChange() {
        if (this.state.adr && this.state.adr.postalCode) {
            this.setState({ checked: !this.state.checked, postalCode: this.state.adr.postalCode });
        } else {
            this.setState({ checked: !this.state.checked })
        }
    }
    handleOptionChange(e) {
        var self = this;
        self.setState({
            [e.target.name]: e.target.value
        });
    }
    validate() {
        let isError = false;
        let errors = {};

        if (this.state.selectedEmails.length === 0) {
            isError = true;
            errors.email = "Emails Required";
        }

        if (this.state.cardholderName === undefined || this.state.cardholderName === '') {
            isError = true;
            errors.cardholderName = "Enter Cardholder Name";
        }
        if (!this.state.optionalAddress) {
            isError = true;
            errors.optionalAddress = "Address Required";
        }
        if (!this.state.optionalCity) {
            isError = true;
            errors.optionalCity = "City Required";
        }
        if (this.state.checked) {
            this.setState({ errors, cardholderName: this.state.cardholderName, giftto: this.state.giftto, firstname: this.state.firstname, lastname: this.state.lastname, streetAddress: this.state.adr.streetAddress, postalCode: this.state.adr.postalCode, locality: this.state.adr.locality, extendedAddress: this.state.adr.extendedAddress, countryCodeAlpha2: this.state.adr.countryCodeAlpha2, region: this.state.adr.region });
        } else {
            this.setState({ errors });
        }

        return isError;
    }
    paymentOption(e) {

        var optionId = e.target.id;
        $(".paymentopt").addClass("hidediv");

        if (optionId == "paypal") {
            $("#" + optionId + "-container").removeClass("hidediv");
        } else {
            $("#" + optionId + "card").removeClass("hidediv");
        }
    }

    tagFilter(e) {
        const value = e.target.value;
        var updatedList = this.state.assetinfo.tags;
        updatedList = updatedList.filter(function (item) {
            return item.toLowerCase().search(
                value.toLowerCase()) !== -1;
        });
        this.setState({ assetinfoTagsValues: updatedList });
    }

    showCompleteName(cell, row) {
        return <div><a data-toggle="tooltip" title={cell}>{this.text_truncate(cell, 10)}</a></div>
    }

    render() {
        let self = this;
        let siteSettings = window.site;
        let appSettings = window.app
        let siteConfig = (siteSettings && siteSettings.config) || (appSettings && appSettings.defaultSiteSettings && appSettings.defaultSiteSettings.config);
        let imagePrefix = siteConfig.commonResourcesUrl + siteConfig.appResourcesPath;
        let imageSuffix = siteConfig.imageSuffix || "?auto=compress,format";
        let defaultLandscape = siteConfig.defaultLandscape;
        const { priceFormat } = self.props;
        const { selectedOption, options, selectedEmails, assetid, assetname, assetinfo } = this.state;

        var color = { color: "#f00" }
        var scolor = { color: "#fff" }
console.log('assetinfo',assetinfo)

        let contentType = localStorage.getItem("contentType");
        let actionType = localStorage.getItem("action") || "gift";
        let availability = assetinfo && assetinfo.availability || {};
        let buyplans = availability.buyplans || {};
        let assetprice = buyplans[actionType] && buyplans[actionType].contentPrice && buyplans[actionType].contentPrice[contentType] || 0;


        return (

            <div>

                <div id="overlay" className="hidediv">

                    <div className="preLoader">
                        <div className="loftloader-wrapper pl-frame">
                            <div className="loader">
                                <div className="spark"></div>
                                <img src={window.site.preLoader} />
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div ref="headermenu" className={this.state.isTop ? 'header_det pmt_dt' : 'header_det pmt_dt up'}>
                    <div className="wrapper">
                        <div className="logo"><img src={imagePrefix + "screening/images/headerlogo.png"} /></div>
                        {/* <a href="#" onClick={browserHistory.goBack} className="back"></a> */}
                        <span onClick={browserHistory.goBack} className="cls"></span>
                    </div>
                </div>

                <div className="payment_bg new-ubg">
                    {/* <img src={payment_bg} /> */}
                </div>
                <div className="payment_page">
                    <h2>GIFT</h2>
                    <h4 className="sub-text">ENTER PAYMENT INFO</h4>
                    <div className="payment_wrp">

                        <div className="pmt_left">

                            <div className=" ast_ifo1">
                                <h4>Information</h4>
                                <div className="row">
                                    {assetinfo && assetinfo.channelname ?
                                        <div className="col-md-6">{assetinfo && assetinfo.channelname}</div> :
                                        <div className="col-md-6">{assetinfo && assetinfo.assetname}</div>
                                    }
                                    {assetprice && <div className="col-md-6"> {contentType && contentType.toUpperCase()} &nbsp; {priceFormat(assetprice, 'USD', 'en-US')}</div>}
                                </div>
                            </div>
                            <div className=" ast_ifo1">
                                <h4>Recipient Information</h4>
                                {/* {this.state.email} */}
                                <ReactTags
                                    allowNew
                                    addOnBlur
                                    placeholder="Add Email"
                                    //tags={this.state.tags}
                                    //value={this.state.email}
                                    name="email"
                                    suggestions={this.state.suggestions}
                                    handleDelete={this.handleDelete.bind(this)}
                                    handleInputChange={e => this.handleChange(e)}
                                    handleAddition={this.handleAddition.bind(this)} />
                                <button className="giftMail" >Add Email</button>
                                {self.state.emailsError && <span className="errormsg" >Please add valid Email id</span>}
                                {!self.state.emailsError && <span className="errormsg" >{this.state.errors.email}</span>}
                                <BootstrapTable className="payment_table" data={selectedEmails} striped={true} hover={true} >
                                    <TableHeaderColumn dataField="emailid" isKey={true} width='45' dataAlign="center">Email</TableHeaderColumn>

                                    {/* <TableHeaderColumn headerTitle={ false } dataField="completeName" dataFormat={this.showCompleteName.bind(this)} dataAlign="center" width='45'>Name</TableHeaderColumn> */}
                                    <TableHeaderColumn dataFormat={this.cellButton.bind(this)} dataAlign="center" width='10'> <span aria-hidden="true">&times;</span></TableHeaderColumn>
                                </BootstrapTable>

                                {selectedEmails && selectedEmails.length > 0 &&
                                    <div className="rightalign">Total: &nbsp;
                                {contentType && contentType.toUpperCase()} &nbsp;  {assetprice && selectedEmails.length > 0 ? priceFormat(selectedEmails.length * assetprice, 'USD', 'en-US') : priceFormat(assetprice || 0, 'USD', 'en-US')}
                                    </div>}
                                    <span className="errormsg" >{this.state.errors.emails}</span>
                            </div>


                        </div>

                        <div className="modal um-mdl-popup delete_mail" id="openModal">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-body u-bdy">
                                        Are you sure you want to delete permanently ?
                                        </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-default" data-toggle="modal" data-target="#openModal" onClick={e => self.cancelClick(e)}>Cancel</button>
                                        <button type="button" className="btn btn-danger" data-toggle="modal" data-target="#openModal" onClick={e => self.okClick(e)}>Ok</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {/* font-size: 20px;
    color: #000; */}
                        {/* <Modal isOpen={self.state.showmessage} className="modal um-mdl-popup" >

                            <ModalBody>
                                <div>
                                    <h2>Are You Sure Delete Parmanently ?</h2>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <button type="button" className="btn btn-default" onClick={e => self.cancelClick(e)}>Cancel</button>
                                <button type="button" className="btn btn-danger" onClick={e => self.okClick(e)}>Ok</button>
                            </ModalFooter>
                        </Modal> */}
                        <div className="pmt_right">

                            <form id="cardForm" method="post">
                                <div className="pmt_blk1">
                                  
                                    <h3>PAYMENT METHOD</h3>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group payment-mode"><input type="radio" id="credit" name="paymenttype" value="creditcard" onClick={e => this.paymentOption(e)} /><label>CREDIT CARD</label></div>
                                        </div>
                                        <div className="col-md-6 hidediv">
                                            <div className="form-group payment-mode"><input type="radio" id="paypal" name="paymenttype" value="paypal" onClick={e => this.paymentOption(e)} /><label>PAYPAL</label></div>
                                        </div>
                                    </div>
                                    <div className="cc_det">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>CARDHOLDER NAME</label>
                                                    <input type="text" className="hosted-field" value={this.state.cardholderName} name="cardholderName" onChange={e => this.handletextChange(e)} />
                                                    <span className="errormsg" >{this.state.errors.cardholderName}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cc_det paymentopt" id="creditcard">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>CARD NUMBER</label>
                                                    <div id="card-number" className="hosted-field"></div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group inline-st">
                                                    <label className="pmt_lbl">EXPIRATION</label>
                                                    <div id="expiration-month" className="hosted-field"></div>
                                                    <div id="expiration-year" className="hosted-field"></div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>CVV</label>

                                                    <div id="cvv" className="hosted-field"></div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>ZIP CODE</label>

                                                    <div id="postal-code" className="hosted-field"></div>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>ADDRESS <span style={{ 'color': '#afafaf' }}></span></label>
                                                    <input type="text" value={this.state.optionalAddress} placeholder="Street address, P.O. box, Company name, C/o" name="optionalAddress" onChange={e => this.addressChange(e)} />
                                                    <span className="errormsg" >{this.state.errors.optionalAddress}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>CITY <span style={{ 'color': '#afafaf' }}></span></label>
                                                    <input type="text" value={this.state.optionalCity} placeholder="City" name="optionalCity" onChange={e => this.addressChange(e)} />
                                                    <span className="errormsg" >{this.state.errors.optionalCity}</span>
                                                </div>
                                            </div>

                                        </div>
                                        <div id="error" style={color} >{self.state.error}</div>

                                    </div>
                                    <div id="paypal-container" className="paymentopt cc_det hidediv"></div>
                                </div>



                                <p className="free_trl">By selecting “Start {siteConfig.siteTitle} Account”, you agree to authorize this charge and to the &nbsp;
                                <NavLink href="#" to='/terms' target="_blank" className="tos">Terms of Service.</NavLink></p>
                                <div className="form-group width-100">
                                    <input type="button" id="paySubmit" value="PAY" className="submit" />

                                </div>
                            </form>
                        </div>
                    </div>

                </div>
                {/* {this.state.showmessage ?
                    <div class="modal fade" id="confirmDelete" role="dialog" aria-labelledby="confirmDeleteLabel"
                        aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                </div>
                                <div class="modal-body" >
                                    <p>Are You Sure Delete Parmanently ?</p>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-default" data-dismiss="modal" onClick={e => this.cancelClick(e)}>Cancel</button>
                                    <button type="button" class="btn btn-danger" id="confirm-delete-ok" onClick={e => this.okClick(e)}>Ok</button>
                                </div>
                            </div>
                        </div>
                    </div> : null} */}

            </div>

        );
    }
}


const mapState = ({ user, address, countries, regions, assetinfo, giftResult, contentShowAssetsItems }) => ({ user, address, countries, regions, assetinfo, giftResult, contentShowAssetsItems });
const mapDispatch = { getGiftPricing, getGiftCard, priceFormat };
export default windowSize(connect(mapState, mapDispatch)(giftCardPayment));
