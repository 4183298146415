import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getArtists, getUser, customerAddress, updateUserRelation, addProfiles, getCountries, getRegionsAPI, getAddress, generateActivationCode, addCustomerInBrainTree } from '../../redux/reducers/reducer';
import { isValidNumber } from 'libphonenumber-js';
import $ from 'jquery';
import _ from 'lodash';

var userinfo = {};
// var customer_address = {};

var addressVisible = localStorage.getItem("adv");
let token = localStorage.getItem('token');

const streamObj = {
    streamUrl: '',
    password: '',
    // enable: false
}
class Profile extends Component {
    constructor(props) {
        super(props);


        var adr = JSON.parse(localStorage.getItem("adr"));
        var partnerhead = localStorage.getItem("partnerid");
        var partnername = "";
        if (partnerhead) {
            localStorage.setItem("partnerid", partnerhead);
        } else {
            userinfo["partnername"] = "";
        }
        var isLoggedIn = false;

        var firstname = localStorage.getItem("firstname") || ''
        var lastname = localStorage.getItem("lastname") || ''
        var email = localStorage.getItem("email") || ''
        // console.log('props in ', this.props.userInfo && this.props.userInfo[0] && this.props.userInfo[0].phone)
        this.state = {
            data: [],
            userinfo,
            loader: false,
            login: isLoggedIn,
            selected: '',
            navigationtab: '',
            isTop: true,
            partnercode: partnerhead,
            partnerid: partnerhead,
            partnername: partnername,
            partnerinfo: null,
            selectpartner: '',
            errors: {},
            value: '',
            firstname: firstname,
            lastname: lastname,
            email: email,
            // phone: this.props.userInfo && this.props.userInfo[0] && this.props.userInfo[0].phone ?this.props.userInfo[0].phone : '' ,
            modifiedavatar: window.site.config.imagesAssetResourcesPrefix + window.site.themePrefix + 'profiles/default/avatar1.jpg' + window.site.config.imageSuffix,
            countrydefault: "",
            regiondefault: "",
            customer_address: {},
            adr: adr,
            addressVisible: addressVisible,
            countryCode: window.countryCode || "+1 (###) ###-####",
            eventsApp: false,
            isProfile: true,
            isAddress: false,
            isSocialLinks: false,
            destinations: {
                youtube: { ...streamObj, ...{ label: 'YouTube' } },
                facebook: { ...streamObj, ...{ label: 'Facebook' } },
                instagram: { ...streamObj, ...{ label: 'Instagram' } },
                twitch: { ...streamObj, ...{ label: 'Twitch' } },
                linkedin: { ...streamObj, ...{ label: 'Linkedin' } },
            },
            showChannelsNotFound: false,

        };
        this.updateRelation = this.updateRelation.bind(this);
        this.onScroll = this.onScroll.bind(this);
    }
    onScroll(isTop) {
        this.setState({ isTop });
    }
    componentDidMount() {
        let self = this;
        token = localStorage.getItem('token');
        if (!token) {
            self.props.history.push("/");
        }
        this.props.getCountries();

        this.props.getUser(token);
        if (self.state.isAddress) {
            this.props.getAddress("custom");
        }
        if (self.state.adr && self.state.adr.extendedAddress) {
            self.props.getRegionsAPI(self.state.adr.countryCodeAlpha2)
        } else {
            self.props.getRegionsAPI(self.state.countrydefault)
        }
        userinfo["partnername"] = "";
        // customer_address["countryCodeAlpha2"] = self.state.countrydefault;
        // customer_address["region"] = self.state.regiondefault;
        // customer_address["addresstype"] = "custom";
        this.setState({ selectpartner: "", partnerinfo: {} });
        var partinterval = setInterval(function () {
            if (self.props.partners && self.props.partners.length > 0) {
                _.filter(self.props.partners, function (user) {
                    if (self.state.partnerid === user.partnerid) {
                        self.setState({
                            partnername: user.name,
                            partnercode: user.partnerid,
                            selectpartner: user.name + "_" + user.partnerid
                        })
                        clearInterval(partinterval);
                    }
                });
            }
        }, 1000);
        var interval = setInterval(function () {
            if (self.props.userinfo && self.props.userinfo.firstname) {
                self.setState({
                    phone: self.props.userinfo.phone,
                    firstname: self.props.userinfo.firstname,
                    lastname: self.props.userinfo.lastname,
                    email: self.props.userinfo.emailid,
                    idc: self.props.userinfo.idc,
                })
                clearInterval(interval);
            }
        }, 1000);
        setTimeout(function () {
            if (self.props.address && self.props.address[0] && self.props.address[0]._id) {
                self.props.getRegionsAPI(self.props.address[0].countryCodeAlpha2)
                delete self.props.address[0].user_id;
                delete self.props.address[0]._id
                self.setState({
                    adr: self.props.address[0]
                })
            }
        }, 5000);

        let userType = localStorage.getItem("userType");
        if (userType != null) {
            userType = JSON.parse(userType);
            if (userType.param === "event") {
                self.setState({ eventsApp: true })
            }
        }

        let hostName = window.location.hostname;
        hostName = hostName.split(".");
        if (Array.isArray(hostName)) {
            hostName = hostName[0].split("-");
        }
        let hostNameIsArray = Array.isArray(hostName);
        if (hostNameIsArray === true) {
            if (hostName[0] === "events")
                self.setState({ eventsApp: true })
        }
    }
    componentDidUpdate(prevProps) {
        var self = this;
        if (prevProps.userRelationData !== this.props.userRelationData) {

        }

        if (prevProps.userInfo !== this.props.userInfo) {
            let userInfo = this.props.userInfo && this.props.userInfo[0] ? this.props.userInfo[0] : {};
            _.each(Object.keys(userInfo), function (item) {
                self.setState({ [item]: userInfo[item] })
            })
        }
        if (prevProps.countries !== this.props.countries) {
            if (localStorage.getItem("loc")) {
                let locationObj = JSON.parse(localStorage.getItem("loc"));
                let locationData = locationObj && locationObj.headers || {};
                let countryName = locationData['cloudfront-viewer-country-name'] && locationData['cloudfront-viewer-country-name'][0] && locationData['cloudfront-viewer-country-name'][0].value;
                let countryObj = this.props.countries.find(country => country.countryname === countryName);
                if (countryObj) {
                    this.setState({ idc: countryObj.idc })
                }

            }
        }

    }
    formValidation() {
        let errors = {};
        let formIsValid = true;
        const eml = this.validate_onchange_email();
        // if ((this.state.partnerinfo == undefined || this.state.partnerinfo == null)) {
        //     formIsValid = false;
        //     errors.name = "Please select influencer";
        // }
        if (this.state.firstname === undefined || this.state.firstname === "") {
            formIsValid = false;
            errors.firstname = "Firstname Required";
        }
        if (this.state.lastname === undefined || this.state.lastname === "") {
            formIsValid = false;
            errors.lastname = "Lastname Required";
        }
        if (this.state.email === undefined || (this.state.email === "" && !eml)) {
            formIsValid = false;
            errors.email = "Email Required";
        }
        if ((this.state.phone === undefined || this.state.phone === '' || this.state.phone === 'false') && !isValidNumber(this.state.phone)) {
            formIsValid = false;
            errors.phone = "Invalid Phone Number";
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    updateRelation(e) {
        e.stopPropagation();
        e.preventDefault();
        var self = this;
        token = localStorage.getItem('token');
        if (self.formValidation() && token) {
            $("#overlay").removeClass("hidediv");
            var userdata = self.state.userinfo || {};
            userdata["firstname"] = this.state.firstname;
            userdata["lastname"] = this.state.lastname;
            userdata["emailid"] = this.state.email;
            userdata["accountType"] = window.accountType || "customer";
            userdata['customer_address'] = this.state.customer_address || {};
            if (window.paymentEnable) {
                userdata["status"] = "PENDING SUBSCRIPTION";
            }
            else if (window.requiredVerification) {
                userdata["status"] = "PENDING VERFICATION";
            }
            let hostName = window.location.hostname;
            hostName = hostName.split(".");
            if (Array.isArray(hostName)) {
                hostName = hostName[0].split("-");
            }
            let hostNameIsArray = Array.isArray(hostName);
            if (hostNameIsArray) {
                switch (hostName[0]) {
                    case "creators":
                        userdata["usertype"] = "creator";
                        break;
                    case "partners":
                        userdata["usertype"] = "partner";
                        break;
                    default:
                        break;
                }
            }
            userdata["phone"] = this.state.phone || 'false';
            userdata["idc"] = this.state.idc;
            // for skipping verification otp process
            if (!window.requiredVerification) {
                userdata["isVerified"] = true;
            }
            // userdata["partnercode"] = this.state.partnercode;/
            // userdata["partnername"] = this.state.partnername;
            localStorage.setItem("firstname", this.state.firstname);
            localStorage.setItem("lastname", this.state.lastname);
            // self.setState({ type: type })
            self.props.updateUserRelation(userdata);
            // if (requiredSelectDevice == false) {
            //     self.props.generateActivationCode('Android Mobile');
            // }
            // if (paymentEnable == false) {
            //     self.props.addCustomerInBrainTree();
            // }
            // if (self.state.addressVisible === "true") {
            if (self.state.isAddress && this.state.customer_address && this.state.customer_address.extendedAddress) {
                if (self.props.address && self.props.address[0] && self.props.address[0]._id) {
                    self.props.customerAddress(this.state.customer_address, self.props.address[0]._id)
                    localStorage.setItem("adr", JSON.stringify(this.state.customer_address));
                } else {
                    self.props.customerAddress(this.state.customer_address, "new")
                    localStorage.setItem("adr", JSON.stringify(this.state.customer_address));
                }
            } else {
                if (self.state.isAddress && this.state.adr && this.state.adr.extendedAddress) {
                    if (self.props.address && self.props.address[0] && self.props.address[0]._id) {
                        self.props.customerAddress(this.state.adr, self.props.address[0]._id)
                        localStorage.setItem("adr", JSON.stringify(this.state.adr));
                    } else {
                        self.props.customerAddress(this.state.adr, "new")
                        localStorage.setItem("adr", JSON.stringify(this.state.adr));
                    }
                }
            }
            // }
            // if (self.state.firstname) {
            //     self.props.addProfiles(self.state.firstname, self.state.modifiedavatar);
            // }
            localStorage.setItem("profileName", self.state.firstname);
            localStorage.setItem("profileImage", self.state.modifiedavatar);
            localStorage.setItem("phone", this.state.phone);
            localStorage.setItem("transactionalmessages", this.state.phone);
        }
    }
    validate_onchange_email() {
        let formIsValid = false;
        let errors = {};
        var reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
        if (reg.test(this.state.email) === false) {
            formIsValid = true;
            errors.email = "Invalid Email ID";
        }
        this.setState({ errors });
        return formIsValid;
    }
    addressChange(e) {
        var self = this;
        const name = e.target.name;
        const { customer_address } = this.state
        var value = e.target.value;
        if (name === "countryCodeAlpha2") {
            self.props.getRegionsAPI(value)
        }

        this.setState({ [name]: value });
        customer_address[name] = value;
        this.setState({ customer_address })
    }
    onChange(e) {
        let self = this;
        const name = e.target.name;
        var value = e.target.value;
        if (e.target.type == 'checkbox') {
            value = e.target.checked;
        }
        let userinfo = self.state.userinfo;
        // console.log('e.target.name', e.target.name)
        let errors = Object.assign({}, this.state.errors);
        if (e.target.name === "phone") {
            let validNumber = this.validatePhoneNumber('+' + this.state.idc + ' ' + value);
            if (!validNumber) {
                delete errors[name];
                userinfo[name] = value;
                errors[name] = "Invalid Phone Number"
                this.setState({
                    [name]: value,
                    errors,
                    userinfo
                })
            } else {
                delete errors[name];
                userinfo[name] = value;
                this.setState({ userinfo, errors, [name]: value }, () => { console.log('userunausro', this.state) })
            }
        } else if (!!this.state.errors[name]) {
            delete errors[name];
            userinfo[name] = value;
            this.setState({
                [name]: value,
                errors,
                userinfo
            });
        } else {
            userinfo[name] = value;
            this.setState({ userinfo, [name]: value })
        }

    }

    logOut(e) {
        e.stopPropagation();
        e.preventDefault();
        var myAppName = localStorage.getItem('appName');
        localStorage.clear();
        localStorage.setItem('appName', myAppName);
        window.location = '/';
    }

    onSelect2(e) {
        let self = this;
        let userinfo = self.state.userinfo;
        let code = e.target.value;
        userinfo['idc'] = code;
        this.setState({ idc: code, userinfo });
        if (this.state.phone) {
            this.validatePhoneNumber('+' + code + ' ' + this.state.phone);
        }
    }

    validatePhoneNumber(phoneNumber) {
        /*
        Phone number validation using google-libphonenumber
        */
        let errors = Object.assign({}, this.state.errors);
        let valid = false;
        try {
            const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
            valid = phoneUtil.isValidNumber(phoneUtil.parse(phoneNumber));
        } catch (e) {
            // console.log('error here', e);
            valid = false;
        }
        if (valid) {
            errors['phone'] = '';
            this.setState({
                errors
            });
        } else {
            errors['phone'] = 'Phone number ' + phoneNumber + ' is not valid';
            this.setState({
                errors
            });
        }
        return valid;
    }

    onChangeSocial = (e, type) => {
        let self = this;
        const name = e.target.name;
        var value = e.target.value;
        let userinfo = self.state.userinfo;
        if (e.target.type == 'checkbox') {
            value = e.target.checked;
        }
        let errors = Object.assign({}, this.state.errors);
        let destinations = { ...self.state.destinations };
        let obj = { ...destinations[type] };
        obj[name] = value;
        destinations[type] = { ...obj };
        if (!!this.state.errors[name]) {
            delete errors[name];
            userinfo['destinations'] = destinations;
            this.setState({
                [type]: obj,
                destinations,
                errors,
                userinfo
            });
        } else {
            userinfo['destinations'] = destinations;
            this.setState({ userinfo, [type]: obj, destinations })
        }
    }

    clearUserData() {
        // this.setState({
        //     userinfo: {}, firstname: '', lastname: '', email: '', idc: '', phone: '', adr: undefined, streetAddress: undefined, extendedAddress: undefined, countryCodeAlpha2: '', region: '', locality: '', postalCode: '', destinations: {
        //         youtube: { streamObj, label: 'YouTube' },
        //         facebook: { streamObj, label: 'Facebook' },
        //         instagram: { streamObj, label: 'Instagram' },
        //         twitch: { streamObj, label: 'Twitch' },
        //         linkedin: { streamObj, label: 'Linkedin' },
        //     }
        // })
        window.location = '/home';
    }

    backToChannels = () => {
        let self = this;
        self.props.history.push("/home");
    }

    render() {
        let self = this;
        let siteConfig = (window.site || {}).config;
        let title = siteConfig.siteTitle || "";
        const { destinations } = this.state;
        var profileName = localStorage.getItem("profileName", self.state.firstname);
        var profileImage = localStorage.getItem("profileImage", self.state.modifiedavatar);
        var phoneNumber = localStorage.getItem("phone", this.state.phone);
        var transactionalmessages = localStorage.getItem("transactionalmessages", this.state.phone);
        // console.log('this.state-----',this.state.customer_address)
        return (
            <React.Fragment>
                <div className="edit-channel">
                    <header className="fixed-top live-header">
                        <div className="container-fluid">
                            <div className="sec-header"><button className="anchor_btn" onClick={() => self.backToChannels()}><span className="material-symbols-outlined"> arrow_back </span></button>
                                <h3>Profile</h3>
                                <div></div>
                            </div>
                        </div>
                    </header>
                    <div className="envlive_wrapper minbody-ht">
                        <div className="container-fluid pt-4 ">
                            <div className='row edit_profile'>
                                <div className='col-md-4'>
                                    <h4 className='mb-3'>Edit Your Profile</h4>
                                    <div className='inputgroup'>
                                        <p className='label'>First name</p>
                                        <input type="text" value={this.state.firstname} placeholder="First name" label='First name' name="firstname" onChange={e => this.onChange(e)} />
                                        <span className="errormsg" >{this.state.errors.firstname}</span>
                                    </div>
                                    <div className='inputgroup'>
                                        <p className='label'>Last name</p>
                                        <input type="text" value={this.state.lastname} placeholder="Last name" label='Last name' name="lastname" onChange={e => this.onChange(e)} />
                                        <span className="errormsg" >{this.state.errors.lastname}</span>
                                    </div>
                                    <div className='inputgroup'>
                                        <p className='label'>Email Adress</p>
                                        <input type="text" value={this.state.email} name="email" placeholder='Email Adress' label='Email Adress' onBlur={e => this.validate_onchange_email(e)} onChange={e => this.onChange(e)} />
                                        <span className="errormsg" >{this.state.errors.email}</span>
                                    </div>
                                    <div className='inputgroup'>
                                        <p className='label'>Enter Valid Phone Number</p>
                                        <div className="phone">
                                            <select className="phone_Country" value={this.state.idc} placeholder="Country" name="idc" onChange={e => this.onSelect2(e)}>
                                                <option >Country</option>
                                                {this.props.countries && this.props.countries.length > 0 && this.props.countries.map(function (country, i) {
                                                    return (
                                                        <option key={i} value={country.idc}>{country.countryname + ' (' + country.idc + ')'}</option>
                                                    )
                                                })}
                                            </select>
                                            <span className="errormsg">{this.state.errors.country}</span>
                                            <input type="text" disabled={!this.state.idc} placeholder='Enter Valid Phone Number' label='Enter Valid Phone Number' value={this.state.phone} name="phone" onChange={e => this.onChange(e)} />
                                            <span className="errormsg" >{this.state.errors.phone}</span>
                                        </div>
                                    </div>
                                    <h4 className='mb-3 mt-4'>Profile picture</h4>
                                    <div className='uploadPics'>
                                        <div className='upload'>
                                            <label className="custom-file-upload">
                                                <input type="file" />
                                                <span className="material-icons">  add_photo_alternate</span>
                                            </label>
                                            <div className="size">Size  (200 X 200)</div>
                                        </div>
                                        <div className='upload portrait'>

                                        </div>
                                        <div className='upload'>

                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <h4 className='mb-3'>Edit Some Info</h4>
                                    <div className='inputgroup'>
                                        <p className='label'>Street address</p>
                                        <input type="text" value={this.state.customer_address && this.state.customer_address.streetAddress ? this.state.customer_address.streetAddress : this.state.adr ? this.state.adr.streetAddress : ''} placeholder="Street address, P.O. box, Company name, C/o" name="streetAddress" onChange={e => this.addressChange(e)} />
                                    </div>
                                    <div className='inputgroup'>
                                        <p className='label'>Apartment</p>
                                        <input type="text" value={this.state.customer_address && this.state.customer_address.extendedAddress ? this.state.customer_address.extendedAddress : this.state.adr ? this.state.adr.extendedAddress : ''} placeholder="Apartment, suite, unit, building, floor etc" name="extendedAddress" onChange={e => this.addressChange(e)} />
                                    </div>
                                    <div className='inputgroup'>
                                        <p className='label'>Country</p>
                                        <select value={this.state.customer_address && this.state.customer_address.countryCodeAlpha2 ? this.state.customer_address.countryCodeAlpha2 : this.state.adr ? this.state.adr.countryCodeAlpha2 : this.state.countrydefault} name="countryCodeAlpha2" onChange={e => this.addressChange(e)}>
                                            <option value="">Country</option>
                                            {this.props.countries && this.props.countries.map((task, i) => {
                                                return (
                                                    <option key={i} value={task.alpha3}>{task.countryname}</option>
                                                )
                                            }
                                            )}
                                        </select>
                                    </div>
                                    <div className='inputgroup'>
                                        <p className='label'>State / Province / Region</p>
                                        <select value={this.state.customer_address && this.state.customer_address.region ? this.state.customer_address.region : this.state.adr && this.state.adr.region ? this.state.adr.region : this.state.regiondefault} name="region" className="capitalize" onChange={e => this.addressChange(e)}>
                                            <option value="">{this.state.customer_address && this.state.customer_address.region ? this.state.customer_address.region : 'State / Province / Region'}</option>
                                            {!this.props.loading && this.props.regions && this.props.regions.map((task, i) => {
                                                return (
                                                    <option key={i} value={task.regionname}>{task.regionname}</option>
                                                )
                                            }
                                            )}
                                        </select>
                                    </div>
                                    <div className='inputgroup'>
                                        <p className='label'>City</p>
                                        <input type="text" placeholder="City" value={this.state.customer_address && this.state.customer_address.locality ? this.state.customer_address.locality : this.state.adr ? this.state.adr.locality : ''} name="locality" onChange={e => this.addressChange(e)} />
                                    </div>

                                    <div className='inputgroup'>
                                        <p className='label'>Zip Code</p>
                                        <input type="number" placeholder="Zip Code" value={this.state.customer_address && this.state.customer_address.postalCode ? this.state.customer_address.postalCode : this.state.adr ? this.state.adr.postalCode : ''} name="postalCode" onChange={e => this.addressChange(e)} />
                                    </div>

                                </div>
                                <div className='col-md-4 edit_destinations'>
                                    {/* <h4 className='mb-3'> Edit Destinations</h4> */}
                                    {destinations && Object.keys(destinations).length > 0 && Object.keys(destinations).map((key, i) => {
                                        return (
                                            <div className='social-block' key={i}>
                                                <div className="icon">  <i className={"fa fa-" + key}></i>{destinations[key].label || ''}</div>
                                                <div className='social-group'>
                                                    <input type='text' placeholder="Stream URL" name="streamUrl" value={destinations[key].streamUrl || ''} onChange={e => self.onChangeSocial(e, key)} />
                                                </div>
                                                {/* <div className='social-group'>
                                                    <input type='text' placeholder="Password" name="password" value={destinations[key].password || ''} onChange={e => self.onChangeSocial(e, key)} />
                                                </div> */}
                                            </div>)
                                    }
                                    )}


                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="form_footer">
                        <div> <p className="terms">{"By selecting NEXT, you agree to the " + title}<a href="/terms" target="_blank">  Terms of Service.</a></p></div>
                        <div className="form_buttons">
                            <button type="button" onClick={e => this.clearUserData(e)} className="btn btn-link">cancel</button>
                            <button type="button" onClick={e => this.updateRelation(e)} className="btn btn-primary">Save</button>
                        </div>
                    </div>
                    <footer className="footer">
                        <div className="container-fluid"><p className="footer-para">  &copy; 2022 Envoi Live. All rights reserved. </p></div>
                    </footer>
                </div>
                {this.props.loading && <div id="overlay" >
                    <div className="preloader">
                        <div className="loftloader-wrapper pl-frame">
                            <div className="loader">
                                <div className="spark"></div>
                                <img alt="preloader" src={window.site.preLoader} />
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>}
                {/* <div className={this.state.isTop ? 'header_det pmt_dt' : 'header_det pmt_dt up'}>
                    <div className="wrapper">
                        <div className="logo"><img alt="logo" src={window.site.logoImage} /></div>
                        <button onClick={(e) => this.logOut(e)} className="cls" />
                    </div>
                </div> */}
            </React.Fragment>
        );
    }
}
const mapState = ({ loading, userRelationData, partners, userInfo, countries, regions, address }) => ({ loading, userRelationData, partners, userInfo, countries, regions, address });
const mapDispatch = { getArtists, customerAddress, updateUserRelation, getUser, addProfiles, getCountries, getRegionsAPI, getAddress, addCustomerInBrainTree, generateActivationCode };
export default connect(mapState, mapDispatch)(Profile);