import React, { Component } from 'react';
import { connect } from 'react-redux';
import NavLink from '../NavLink';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { generateActivationCodeProps } from '../../redux/reducers/Actions';
import { activationCode, generateActivationCode, updateUserRelation, getUser } from '../../redux/reducers/reducer';
let formData = {};
let token = localStorage.getItem('token');
class ActivationSubscription extends Component {
    constructor() {
        super();
        this.state = {
            formData: {},
            errors: {},
            updatedDevice: {},
            showmessage: false,
            successmessage: false,
            isSubmit: false,
            emailSending: false,
            basicDiv: true,
            emailVerficationDiv: false,
            confirmationDiv: false,
            screeningAccess: false,
            userinfo: {}
        }
        this.handleCancelClick = this.handleCancelClick.bind(this);
    }
    componentDidMount() {
        let self = this;
        if (!window.requiredSelectDevice) {
            self.activationCode()
        }
        if (token) {
            this.props.getUser(token);
        }

    }


    componentDidUpdate(prevProps) {
        let self = this;
        if (prevProps.activationCodeProps != this.props.activationCodeProps) {
            if (this.props.activationCodeProps == "success") {
                self.setState({
                    basicDiv: false,
                    confirmationDiv: true,
                    isSubmit: false,
                    activationCodeProps: []
                })
            } else if (this.props.activationCodeProps.length != 0 && this.props.activationCodeProps != "success") {
                let errors = { ...self.state.errors }
                errors.pairingcode = "Invalid activation code.";
                self.setState({ errors: errors, isSubmit: false, })
            }
        }
        if (prevProps.generateActivationCodeData != this.props.generateActivationCodeData) {
            if (this.props.generateActivationCodeData && this.props.generateActivationCodeData.error) {
                self.setState({
                    generateActivationCode: this.props.generateActivationCodeData,
                    emailSending: false,
                    basicDiv: false,
                    emailVerficationDiv: true,
                    emailMessage: "Unable to send mail",
                    emailSuccessMsg: ""
                })
            } else {
                if (this.props.generateActivationCodeData && !this.props.generateActivationCodeData.length == 0) {
                    self.setState({
                        generateActivationCode: this.props.generateActivationCodeData,
                        emailSending: false,
                        basicDiv: true,
                        emailVerficationDiv: false,
                        emailMessage: this.props.generateActivationCodeData,
                        emailSuccessMsg: "Please check your email"
                    })
                }
                if (this.props.generateActivationCodeData == "unable to sent mail") {
                    self.setState({ emailSuccessMsg: "" })
                }
            }
        }
        if (prevProps.userInfo !== this.props.userInfo) {
            let userInfo = (this.props.userInfo || [])[0] || {};
            self.setState({
                userinfo: userInfo
            });
        }
        if (prevProps.userRelationData !== this.props.userRelationData) {
            self.props.history.push("/create");
        }
    }

    pairingcodeValidation(e) {
        e.preventDefault();
        let formData = this.state.formData;
        let errors = {};
        let formIsValid = true;
        if (formData && !formData.pairingcode) {
            formIsValid = false;
            errors.pairingcode = "Please enter the activation code ";
        } else if (formData && formData.pairingcode.length < 4) {
            formIsValid = false;
            errors.pairingcode = "Invalid activation code.";
        }
        else {
            formIsValid = true;
            errors.term = {};
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    activationcodeValidation(activatinCode) {
        let formData = this.state.formData;
        let errors = {};
        let formIsValid = true;
        formData.pairingcode = activatinCode;
        if (formData && !formData.pairingcode) {
            formIsValid = false;
            errors.pairingcode = "Please enter the activation code displayed on your Mobile Device or Television";
        } else if (formData && formData.pairingcode.length < 4) {
            formIsValid = false;
            errors.pairingcode = "Invalid activation code.";
        }
        else {
            formIsValid = true;
            errors.term = {};
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    addDeviceEvent(e, page) {
        let self = this;
        e.preventDefault();
        const isActivateCodeValid = this.pairingcodeValidation(e);
        if (isActivateCodeValid) {
            self.setState({ isSubmit: true })
            this.props.activationCode(this.state.formData);
        }

    }
    handleCancelClick(e) {
        e.stopPropagation();
        e.preventDefault();
        var myAppName = localStorage.getItem('appName');
        let appEnvName = localStorage.getItem('appEnvName');
        let windowLocation = localStorage.getItem('windowLocation');
        localStorage.clear();
        if (myAppName) localStorage.setItem('appName', myAppName);
        if (appEnvName) localStorage.setItem('appEnvName', appEnvName);
        if (windowLocation) localStorage.setItem('windowLocation', windowLocation);
        window.location = '/?appname=' + myAppName;
    }
    change(e) {
        if (!!this.state.errors[e.target.name]) {
            let errors = Object.assign({}, this.state.errors);
            delete errors[e.target.name];
            formData[e.target.name] = e.target.value.trim();
            this.setState({
                [e.target.name]: e.target.value,
                errors,
                formData
            });
        } else {
            this.setState({ [e.target.name]: e.target.value.trim() });
            let name = [e.target.name];
            let value = e.target.value;
            this.setState({ [name]: value });
            formData[name] = e.target.value.trim();
            this.setState({ formData })
        }
    }
    activationCode() {
        let self = this;
        self.setState({ emailSending: true })
        let deviceName = localStorage.getItem("addDevice") || 'Web';
        self.props.generateActivationCode(deviceName);
        self.props.generateActivationCodeProps([])
    }
    okClick() {
        let self = this;
        self.setState({
            basicDiv: false,
            emailVerficationDiv: false
        });
    }
    screeningAccessFn() {
        localStorage.clear();
        window.location = '/';
    }
    successClick() {
        let self = this;
        let userdata = {
            status: "PENDING PROFILE"
        }
        self.props.updateUserRelation(userdata);
    }
    render() {
        let self = this;
        let siteConfig = (window.site || {}).config;
        let mobileVerification = (siteConfig && siteConfig.mobileVerification || {}).enable;
        let style = {
            color: "red"
        }
        const { basicDiv, emailSending, isSubmit, emailVerficationDiv, emailMessage, emailSuccessMsg, confirmationDiv, successmessage, showmessage, errors, screeningAccess, userinfo } = self.state;
        return (
            <div className="act_page bc_activation">
                <div className="active_wrp">
                    {basicDiv ?
                        <div className="active_logo"><NavLink to="/"><img src={window.site.logoImage} /></NavLink></div> : null}
                    {basicDiv ?
                        <div className="active_block">
                            <h2>Activate Your Profile</h2>
                            <p>Check <span style={{ "color": "#fff" }}>{userinfo && userinfo.emailid}</span> {mobileVerification && <span>(or) <br /><span style={{ "color": "#aaa" }}>{userinfo && userinfo.phone ? userinfo.phone : "mobile"}</span></span>} for a 6 digit confirmation code. </p>
                            <p><input onChange={e => this.change(e)} type="text" name="pairingcode" value={this.state.pairingcode} placeholder="ACTIVATION CODE" className="u-black-input" /></p>
                            <p className="errmsg" style={style}>{errors.pairingcode}</p>
                            <div>
                                <button onClick={e => self.addDeviceEvent(e, "activate")} type="button" form="frmActivation" value="Submit" className="atv_btn submit_code">{isSubmit ? "Sending..." : "Submit"}</button>
                                <div className="resend">
                                    <p className="u-cancel-a">  <a onClick={e => self.activationCode(e)} href="#">{emailSending ? "Sending..." : "Resend activation code"}</a></p>
                                    {/* <a className="atv_btn mobile_code">Send activation code</a> */}
                                    <p className="u-cancel-a"><a href="#" onClick={e => this.handleCancelClick(e)}>Cancel</a></p>
                                </div>
                            </div>
                        </div> : null}
                    {emailVerficationDiv ?
                        <div className="active_wrp">
                            <div className="active_logo"><NavLink to="/"><img src={window.site.logoImage} /></NavLink></div>
                            <div className="active_block">
                                <h2>{emailMessage}</h2>
                                <p>{emailSuccessMsg} </p>
                                <p> <button type="button" form="frmActivation" value="Ok" onClick={e => self.okClick(e)} className="atv_btn submit_code">Ok</button>
                                </p>
                            </div>
                        </div> : null}
                    {confirmationDiv ?
                        <div className="active_wrp">
                            <div className="active_logo"><NavLink to="/"><img src={window.site.logoImage} /></NavLink></div>
                            <div className="active_block">
                                <h2>Your account is Verified! </h2>
                                {/* <p>Thank you for registering with us </p>
                                <p>Your subscription has been confirmed</p> */}
                                <p> <button type="button" form="frmActivation" value="Ok" onClick={e => self.successClick(e)} className="atv_btn submit_code">Ok</button>
                                </p>
                            </div>
                        </div> : null}
                    {screeningAccess ?
                        <div className="active_wrp">
                            <div className="active_logo"><img src={window.site.logoImage} /></div>
                            <div className="active_block">
                                <h2>Sorry!  </h2>
                                <p>Administrator approval is pending. Please contact the administrator.</p>
                                <p> <button type="button" form="frmActivation" value="Ok" onClick={e => self.screeningAccessFn(e)} className="atv_btn submit_code">Back to Home</button>
                                </p>
                            </div>
                        </div> : null}
                    <Modal isOpen={showmessage} className="u-add-new" id="success_modal">
                        <ModalHeader className="success_head"></ModalHeader>
                        <ModalBody>
                            <div className="alert alert-light alert-dismissible fade show">
                                <h2>Email Sent Successfully!</h2>
                                <p> Please check your email </p>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <button type="button" className="close" data-dismiss="alert" onClick={e => self.okClick(e)}> OK </button>
                        </ModalFooter>
                    </Modal>
                    <Modal isOpen={successmessage} className="u-add-new" id="success_modal">
                        <ModalHeader className="success_head"></ModalHeader>
                        <ModalBody>
                            <div className="alert alert-light alert-dismissible fade show">
                                <h2>Your account is Verified!</h2>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <button type="button" className="close" data-dismiss="alert" onClick={e => self.successClick(e)}> OK </button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        )
    }
}
const mapState = ({ activationCodeProps, generateActivationCodeData, userInfo, userRelationData }) => ({ activationCodeProps, generateActivationCodeData, userInfo, userRelationData });
const mapDispatch = { activationCode, generateActivationCode, getUser, updateUserRelation, generateActivationCodeProps };
export default connect(mapState, mapDispatch)(ActivationSubscription);