import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';
import Recaptcha from 'react-recaptcha';
import axios from "axios";
class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Title: window.site.config.siteTitle,
            projectinfo: {},
            errors: {},
            contact: true,
            verifyCaptcha: false,

        };
        this.sendmessage = this.sendmessage.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
        this.expiredCall = this.expiredCall.bind(this);
    }
    componentDidMount() {
        let self = this
    }
    handleChange(e) {
        let self = this;
         let { projectinfo } = self.state
        console.log("projectinfo ", self.state)
        if (!!this.state.errors[e.target.name]) {
            let errors = Object.assign({}, this.state.errors);
            delete errors[e.target.name];
            this.setState({
                [e.target.name]: e.target.value,
                errors
            });
        } else {
            projectinfo[e.target.name] = e.target.value;
            this.setState({ projectinfo, [e.target.name]: e.target.value });
        }
    }
    validate_onchange_email(method) {
        let formIsValid = true;
        let errors = {};
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (reg.test(method) == false) {
            formIsValid = false;
            errors.email = "Invalid Email ID";
        }
        this.setState({ errors });
        return formIsValid;
    }
    formValidation() {
        let errors = {};
        let formIsValid = false;
        const eml = this.validate_onchange_email(this.state.email);
        const ceml = this.validate_onchange_email(this.state.confirmEmail);
        if (this.state.name === undefined || this.state.name === '') {
            formIsValid = true;
            errors.name = "Firstname Required";
        }
        if (this.state.email === undefined || this.state.email === '') {
            formIsValid = true;
            errors.email = "Email Required";
        }
        if (this.state.confirmEmail === undefined || this.state.confirmEmail === '') {
            formIsValid = true;
            errors.confirmEmail = "Confirm Email Required";
        }
        if (this.state.supporttype === undefined || this.state.supporttype === '') {
            formIsValid = true;
            errors.supporttype = "Support Type Required";
        }
        if (this.state.message === undefined || this.state.message === '') {
            formIsValid = true;
            errors.message = "Message Required";
        }
        if (this.state.email != this.state.confirmEmail) {
            formIsValid = true;
            errors.confirmEmail = "Invalid Confirmation Email ID";
        }
        this.setState({
            errors
        });
        return formIsValid;
    }
    verifyCallback(response) {
        let self = this;
        if (response) {
            self.setState({ verifyCaptcha: true });
        }
    };

    expiredCall(response) {
        let self = this;
        self.setState({ verifyCaptcha: false });
    };
    sendmessage(e) {
        var self = this;
        let siteSettings = window.site;
        let envoiScreeningApiBaseUrl = siteSettings && siteSettings.screeningApiBaseUrl;
        let appStorage = window.app.storage;
        let token = appStorage.getItem('token');
        // $("#loading").modal('show');
        if (!token && !self.formValidation()) {
            axios.post(envoiScreeningApiBaseUrl + '/contactus?appname=' + window.app.appName + '&env=' + window.app.appEnvName, this.state.projectinfo, { 'Content-Type': 'application/json' })
                .then(function (response) {
                    // $("#loading").modal('hide');
                    if (response && response.data && response.data.statusCode == 200) {

                        window.location = '/success';
                    }
                })
                .catch(function (error) {
                });

        } else {

            this.state.projectinfo["confirmEmail"] = this.state.projectinfo["email"]
            axios.post(envoiScreeningApiBaseUrl + '/contactus?appname=' + window.app.appName + '&token=' + token + '&env=' + window.app.appEnvName, this.state.projectinfo, { 'Content-Type': 'application/json' })
                .then(function (response) {
                    // $("#loading").modal('hide');
                    if (response && response.data && response.data.statusCode == 200) {

                        window.location = '/success';
                    }
                })
                .catch(function (error) {
                });

        }
    }
    render() {
        let self = this;
        const { Title, projectinfo, contact } = self.state;
        let CaptchaKey = (((window.site.config || {}).captcha || {}).google || {}).siteKey;
        let number;
        if ((window.site.config || {}).contactNumber) {
            number = (window.site.config || {}).contactNumber;
        } else {
            number = "+1 (310) 337-4737"
        }

        let siteConfig = window.site.config;
        let imagePrefix = siteConfig.imagePrefix;
        let imageSuffix = siteConfig.imageSuffix;
        let status = localStorage.getItem("status")
        status = status && status.toLocaleLowerCase();
        return (
            <div className="content_page">
                <Header status isTerms={true} />

              
                    <div className="page_content">
                        <div className="content_wrp">
                            <h2>NEED HELP? HAVE FEEDBACK?</h2>
                            <div className="row column_reverse">
                                <div className="col-md-6">
                                    <h4 className="help_h4">SEND US A MESSAGE</h4>

                                    <div className="form-group">
                                        <label>Name</label>
                                        <input className="form-control" id="name" name="name" onChange={this.handleChange}
                                        />
                                        <span className="errormsg">{this.state.errors.name}</span>
                                    </div>

                                    <div className="form-group">
                                        <label>Email</label>
                                        <input className="form-control" type="email" id="email" name="email"
                                            onChange={this.handleChange} value={self.state.projectinfo.email} />
                                        <span className="errormsg">{this.state.errors.email}</span>
                                    </div>

                                    <div className="form-group">
                                        <label>Confirm Email</label>
                                        <input className="form-control" id="confirmEmail" name="confirmEmail"
                                            onChange={this.handleChange} value={self.state.projectinfo.confirmEmail} />
                                        <span className="errormsg">{this.state.errors.confirmEmail}</span>
                                    </div>
                                    <div className="form-group">
                                        <label>Support</label>
                                        <select name="supporttype" className="form-control" onChange={this.handleChange}
                                            value={self.state.projectinfo.supporttype}>
                                            <option value="">Select</option>
                                            <option value="Technical Support">Technical Support</option>
                                            <option value="Ticket Purchasing">Ticket Purchasing</option>
                                            <option value="Customer Service">Customer Service</option>
                                        </select>
                                        <span className="errormsg">{this.state.errors.supporttype}</span>
                                    </div>
                                    <div className="form-group">
                                        <label>Message</label>
                                        <textarea className="form-control" rows="4" id="message"
                                            placeholder="Please provide as much information for all technical, ticketing or customer service issues."
                                            name="message" onChange={this.handleChange}
                                            value={self.state.projectinfo.message}></textarea>
                                        <span className="errormsg">{this.state.errors.message}</span>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6 captcha">
                                            <Recaptcha sitekey={CaptchaKey} render="explicit" verifyCallback={this.verifyCallback}
                                                expiredCallback={this.expiredCall} />
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary" onClick={(e) => this.sendmessage(e)}
                                            disabled={self.state.verifyCaptcha ? false : true} value="Submit">Send Message</button>
                                    </div>

                                </div>
                                <div className="col-md-6 support">
                                    <h4 className="help_h4">CONTACT LIVE SUPPORT</h4>
                                    <div className="call_blk">
                                        CALL US<br />
                                        {number}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
           
                <Footer />
            </div>
        )
    }
}

export default About;