import {
    ACTIVATION_CODE,
    ADD_PROFILE,
    APPROVED_ASSET,
    ASSET_PAYMENT,
    BRAINTREE_CUSTOMER,
    GENERATE_ACTIVATION_CODE,
    GENERATE_USER_RELATION,
    GET_ADDRESS,
    GET_ALL_PARTNERS,
    GET_ASSET_INFO,
    GET_COUNTRIES,
    GET_EVENT_INFO,
    GET_CHANNELS,
    GET_REGIONS,
    GET_USER,
     GET_WEBSITE_DEFAULT_DATA,
    ITEMS_IS_LOADING,
    GET_CLIPS,
    CREATE_CLIPS,
    GIVE_VOTE,
    GET_LOCATION,
    GET_ASSETS,
    GET_PLAYBACK_URLS,
    MANAGE_CHANNELS,
    CREATE_IVS_CHANNEL,
    MANAGE_PLAYLISTS,
    EDIT_ASSET,
    GET_EVENTS,
    MANAGE_LOOKUPS,
    EMBED_LINKS_DATA,
    MANAGE_EMBED_DATA,
    MANAGE_SCHEDULING,
    MANAGE_SCHEDULES,
    PLAYLIST_ASSETS,
    SCHEDULE_EVENTS,
    GET_GIFTCARDRESULT,
    ASSET_DETAILS,
    GET_PLAYER_INFO,
    CHANNEL_ASSETS
} from './ActionTypes'

/////////////////ACTIONS//////////////
export const activationCodeProps = (activationCodeState) => ({ type: ACTIVATION_CODE, activationCodeState });
export const approvedProps = (approvedState) => ({ type: APPROVED_ASSET, approvedState });
export const assetInfo = (asset) => ({ type: GET_ASSET_INFO, asset });
export const assetPayment = (asset) => ({ type: ASSET_PAYMENT, asset });
export const braintreeCustomer = (braintreeCustomerState) => ({ type: BRAINTREE_CUSTOMER, braintreeCustomerState });
export const dispatch_addprofile = (addProfile) => ({ type: ADD_PROFILE, addProfile });
export const dispatch_address = (address) => ({ type: GET_ADDRESS, address });
export const dispatch_countries = (countries) => ({ type: GET_COUNTRIES, countries });
export const dispatch_channels = (channels) => ({ type: GET_CHANNELS, channels });
export const dispatch_create_ivs_channel = (ivsChannel) => ({ type: CREATE_IVS_CHANNEL, ivsChannel });
export const dispatch_manage_channels = (manageChannels) => ({ type: MANAGE_CHANNELS, manageChannels });
export const dispatch_playbackurls = (playbackUrls) => ({ type: GET_PLAYBACK_URLS, playbackUrls });
export const dispatch_assets = (assets) => ({ type: GET_ASSETS, assets });
export const eventInfo = (event) => ({ type: GET_EVENT_INFO, event });
export const generateActivationCodeProps = (generateActivationCodeState) => ({ type: GENERATE_ACTIVATION_CODE, generateActivationCodeState });
export const generateUserRelationProps = (userRelationData) => ({ type: GENERATE_USER_RELATION, userRelationData });
export const getPartners = (partners) => ({ type: GET_ALL_PARTNERS, partners });
export const itemsIsLoading = (loading) => ({ type: ITEMS_IS_LOADING, loading });
export const regions = (regionsState) => ({ type: GET_REGIONS, regionsState });
export const userInfo = (user) => ({ type: GET_USER, user });
export const websiteDefaults = (websiteDefaultData) => ({ type: GET_WEBSITE_DEFAULT_DATA, websiteDefaultData });
export const allClips = (clips) => ({ type: GET_CLIPS, clips });
export const createdClip = (clips) => ({ type: CREATE_CLIPS, clips });
export const votedProps = (votedState) => ({ type: GIVE_VOTE, votedState });
export const dispatch_location = (location_state) => ({ type: GET_LOCATION, location_state });
export const dispatch_manage_playlists = (managePlayList) => ({ type: MANAGE_PLAYLISTS, managePlayList });
export const dispatch_edit_asset = (editAsset) => ({ type: EDIT_ASSET, editAsset });
export const dispatch_events = (events) => ({ type: GET_EVENTS, events }); 
export const dispatch_manage_lookups = (manageLookUps) => ({ type: MANAGE_LOOKUPS, manageLookUps });
export const dispatch_embed_links_data = (embedLinksData) => ({ type: EMBED_LINKS_DATA, embedLinksData }); 
export const dispatch_manage_embed_data = (manageEmbedData) => ({ type: MANAGE_EMBED_DATA, manageEmbedData });

export const dispatch_manage_scheduling_data = (manageSchedulingData) => ({ type: MANAGE_SCHEDULING, manageSchedulingData });
export const dispatch_manage_schedules_data = (manageSchedulesData) => ({ type: MANAGE_SCHEDULES, manageSchedulesData });
export const dispatch_playlist_assets_data = (playlistAssetsData) => ({ type: PLAYLIST_ASSETS, playlistAssetsData });
export const dispatch_schedule_events_data = (scheduleEventsData) => ({ type: SCHEDULE_EVENTS, scheduleEventsData });
export const giftCardInfo = (giftCardResult) => ({ type: GET_GIFTCARDRESULT, giftCardResult });
export const assetDetailsData = (assetDetailsState) => ({ type: ASSET_DETAILS, assetDetailsState });
export const dispatch_player_data = (playerdata) => ({ type: GET_PLAYER_INFO, playerdata });
export const channelAssetsProps = (channelAssetsState) => ({ type: CHANNEL_ASSETS, channelAssetsState });
