
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { browserHistory } from 'react-router';
import $ from 'jquery';
import '../userContext';
import { UserConsumer } from '../userContext';
import { Auth0Lock } from 'auth0-lock';
import { Auth0LockPasswordless } from 'auth0-lock';
import AlertPopup from './AlertPopup';
import { getChannels, createIVSChannel } from '../../redux/reducers/reducer';
import * as config from '../../config';
import axios from "axios";
import OktaSignIn from '@okta/okta-signin-widget';
import { location } from '../../utils/utils';

let appSettings = window.app || {}
let siteSettings = window.site || {}
let siteConfig = siteSettings.config || {}
let appStorage = appSettings.storage || {}
let localStorage = appStorage;
let clientId, domain, enablePasswordLess, oktaLogin;
let oktaclientId, oktadomain;
let cognitoLogin, cognitoClientId, loginCognitoUrl, cognitoTokenType;

// Authentication
let auth0Logo;
let lambdaUrl = siteSettings.submissionApiBaseUrl;
let locationURL = window.location.protocol + "//" + window.location.host + "/";



const isNotSocialLoginTypes = ['novi-ams',
    'oauth',
    'okta',
    'cognito',
    'auth0',]

class SignIn extends Component {
    state = { showChannelsNotFound: false }
    constructor(props) {
        super(props);
        this.baseHref = config.BASE_HREF;
        let value = this.context;
        let self = this;
        let siteSettings = window.site;
        siteConfig = siteSettings.config || {};
        let auth0ClientId = ((siteConfig.authentication && siteConfig.authentication.auth0) || {}).authClientId || ""; //Auth0 clientId
        let auth0Domain = ((siteConfig.authentication && siteConfig.authentication.auth0) || {}).authClientDomain || "";
        if (siteConfig.cognitoLogin) {
            let url;
            let cognitoToken = location("id_token");
            if (cognitoToken) {
                url = lambdaUrl + '/authentication?appname=' + window.app.appName + '&token=' + cognitoToken + '&env=' + value.env + '&host=' + locationURL;
                this.authenticationMethod(url);
            }
        }
        else if (siteConfig.authentication && siteConfig.authentication.key === "auth0" && auth0ClientId && auth0Domain) {
            auth0Logo = siteConfig.imagePrefix + "screening/images/landingpagelogo.png" + siteConfig.imageSuffix
            let defaultOptions = {
                configurationBaseUrl: 'https://cdn.auth0.com',
                auth: {
                    responseType: 'token id_token',
                }
            };
            window.signinLock = new Auth0Lock(auth0ClientId, auth0Domain, defaultOptions);
            window.signinLock.on("authenticated", function (authResult) {
                $("#overlay").removeClass("hidediv");
                $(".landing_wrap").removeClass("hidediv");
                window.signinLock.hide();
                window.signinLock.getUserInfo(authResult.accessToken, function (error, profile) {
                    if (error) {
                        console.log('authoerror', error)
                        return;
                    }
                    let tokenval = authResult.idToken;
                    let url = lambdaUrl + '/authentication?appname=' + window.app.appName + '&token=' + tokenval + '&env=' + siteSettings.env + '&host=' + locationURL;
                    self.authenticationMethod(url);
                });
            });
        }

    }

    authenticationMethod(url) {
        let self = this;
        let envoiBroadcast = siteConfig.envoiBroadcast || {}
        window.requiredProfile = envoiBroadcast.requiredProfile;
        window.requiredDestination = envoiBroadcast.requiredDestination;
        window.requiredVerification = envoiBroadcast.requiredVerification;
        window.paymentEnable = (envoiBroadcast.subscription || {}).enable;
        self.props.isLoaderUpdated(true);
        axios.post(url)
            .then(function (response) {
                if (response && response.data.statusCode === 200) {
                    let result = response.data.result || {}
                    let token = response.data.result.token;
                    window.token = token;
                    localStorage.setItem("token", result.token);
                    localStorage.setItem("userCode", result.code);
                    localStorage.setItem("fullName", result.firstname + " " + result.lastname);
                    localStorage.setItem("userImage", result.userimage);
                    localStorage.setItem("firstname", result.firstname);
                    localStorage.setItem("lastname", result.lastname);
                    localStorage.setItem("email", result.emailid);
                    localStorage.setItem("user_id", result.userid);
                    localStorage.setItem("status", result.status);
                    self.props.isLoaderUpdated(false);
                    let assetId = localStorage.getItem("assetId") || window.localStorage.getItem("assetId");
                    let assettype = localStorage.getItem("a");
                    if ((assettype == "asset" || assettype == "image" || assettype == "music" || assettype == "document") && assetId) {
                        localStorage.removeItem("a");
                        window.location = "/buyAsset?assetid=" + assetId;
                    } else if ((assettype == "gift" || assettype == "imagegift" || assettype == "musicgift" || assettype == "documentgift") && assetId) {
                        localStorage.removeItem("a");
                        window.location = "/giftedPayment?assetid=" + assetId;
                    } 
                    else if (assettype == "subscrption") {
                        localStorage.removeItem("a");
                        window.location = "/subscriptionpayment";
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    componentDidMount() {
        let self = this;
        if (siteConfig.authentication && siteConfig.authentication.key === 'cognito') {
            let url;
            let cognitotoken = location("id_token");
            if (cognitotoken)
                url = lambdaUrl + '/authentication?appname=' + window.app.appName + '&token=' + cognitotoken + '&env=dev&host=' + locationURL;

            if (cognitotoken) {
                this.authenticationMethod(url);
            }
        } else if (siteConfig.authentication && siteConfig.authentication.key === "auth0") {
            let authToken = location("id_token");
            let accessToken = location("access_token");
            if (authToken) {
                window.signinLock.checkSession({}, function (err, authResult) {
                    let tokenval = authToken;
                    if (tokenval) {
                        let url = lambdaUrl + '/authentication?token=' + tokenval + '&appname=' + window.app.appName + '&env=dev' + '&host=' + locationURL;
                        self.authenticationMethod(url);
                    }
                    window.signinLock.getUserInfo(accessToken, function (error, profile) {
                        if (error) {
                            console.log('authoerror 222', error)
                            return;
                        }
                    });
                });
            }
        } else {
            let urlToken;
            if (siteConfig.authentication && siteConfig.authentication.key === "novi") {
                urlToken = location("id_token");
            } else if (siteConfig.authentication && siteConfig.authentication.key === "oauth") {
                urlToken = location("access_token");
            }
            if (urlToken) {
                let url = lambdaUrl + '/authentication?token=' + urlToken + '&appname=' + window.app.appName + '&env=dev' + '&host=' + locationURL;
                self.authenticationMethod(url);
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.clickAccess) {
            this.loginLock()
        }
        if (prevProps.websiteDefaultData !== this.props.websiteDefaultData) {
            let siteConfig = this.props.websiteDefaultData.config || {};
            let imagePrefix = siteConfig.imagesPrefix;
            let imageSuffix = siteConfig.imageSuffix;
            this.setState({ imagePrefix, imageSuffix })
        }
        if (prevProps.channels !== this.props.channels) {
            let channelsData = ((this.props.channels || {}).data || []);
            this.props.isLoaderUpdated(false);
            this.setState({ channelsData })
            if (channelsData && channelsData.length > 0) {
                window.location = `/broadcast?channelCode=${channelsData[0].channelcode}`
            } else {
                this.props.createIVSChannel();
            }

        }
        if (prevProps.ivsChannel !== this.props.ivsChannel) {
            let ivsChannelData = ((this.props.ivsChannel || [])[0] || {});
            if (ivsChannelData && ivsChannelData.channel && ivsChannelData.channel.length > 0) {
                window.location = `/broadcast?channelCode=${ivsChannelData.channel[0].code}`
            } else {
                this.setState({ showChannelsNotFound: true })
            }
        }
    }

    loginLock(e, configData) {
        e.stopPropagation();
        e.preventDefault();
        switch (configData && configData.siteConfig && configData.siteConfig.authentication && configData.siteConfig.authentication.key) {
            case "auth0":
                clientId = (configData.siteConfig.authentication.auth0 || {}).authClientId || ""; //Auth0 clientId
                domain = (configData.siteConfig.authentication.auth0 || {}).authClientDomain || "";
                enablePasswordLess = (configData.siteConfig.authentication.auth0 || {}).enablePasswordLess || false;
                window.oktaLogin = false;
                break;
            case "okta":
                oktaclientId = (configData.siteConfig.authentication.okta || {}).clientId; // OKTA Client Id
                oktadomain = (configData.siteConfig.authentication.okta || {}).domain;
                if (configData.siteConfig.authentication.key === "okta") {
                    window.oktaLogin = true;
                }
                break;
            case "cognito":
                cognitoLogin = true;
                cognitoClientId = (configData.siteConfig.authentication.cognito || {}).clientId; // Cognito Client Id
                loginCognitoUrl = (configData.siteConfig.authentication.cognito || {}).loginCognitoUrl;
                cognitoTokenType = (configData.siteConfig.authentication.cognito || {}).cognitoTokenType || "token";
                break;
            default:
                break;
        }
        if (configData && configData.siteConfig && configData.siteConfig.authentication && configData.siteConfig.authentication.key === "auth0" && clientId && domain) {
            auth0Logo = configData.imagePrefix + "screening/images/landingpagelogo.png";
            let defaultOptions = {
                container: 'logincontainer',
                configurationBaseUrl: 'https://cdn.auth0.com',
                auth: {
                    responseType: 'token id_token',
                },
                theme: {
                    logo: auth0Logo
                }
            };

            let klass = (enablePasswordLess) ? Auth0LockPasswordless : Auth0Lock
            window.signinLock = new klass(clientId, domain, defaultOptions);
            defaultOptions["container"] = 'signupcontainer';
        }
        else if (window.oktaLogin && oktadomain && oktaclientId) {

            window.oktaWidget = new OktaSignIn({
                el: '#osw-container',
                baseUrl: oktadomain, // https://dev-852395.oktapreview.com  https://sanchaninfo.okta.com
                clientId: oktaclientId,
                redirectUri: window.location.protocol + "//" + window.location.host + '/',
                authParams: {
                    issuer: oktadomain,
                    responseType: ['id_token', 'token']
                },
                scope: 'openid profile email'
            });

        }
        e && e.preventDefault();

        localStorage.setItem("showDiv", this.props.hiddenDiv)
        localStorage.setItem("removeHidden", this.props.removeHidden)

        if (configData && configData.siteConfig && configData.siteConfig.authentication && configData.siteConfig.authentication.key === "auth0") {
            $("." + this.props.hiddenDiv).hide();
            $("#" + this.props.removeHidden).removeClass("hidediv");
            let signInLockOptions = {
                languageDictionary: {
                    emailInputPlaceholder: "Enter Email",
                    passwordInputPlaceholder: "Enter Password",
                    loginTitle: 'Login',
                    loginAtLabel: 'Login',
                    loginSubmitLabel: 'Log in',
                    title: "Login with"
                },
                allowSignUp: false
            };
            let signInLockArgs = (enablePasswordLess) ? { passwordlessMethod: 'link' } : {};
            if (enablePasswordLess == true) {
                window.signinLock.show(signInLockArgs, signInLockOptions);
            } else {
                window.signinLock.show(signInLockOptions);
            }
        } else if (cognitoLogin) {
            window.location = `${loginCognitoUrl}?client_id=${cognitoClientId}&response_type=${cognitoTokenType}&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=${window.location.origin}`
        } else if (window.oktaLogin) {
            $("." + this.props.hiddenDiv).hide();
            $("#" + this.props.removeHidden).removeClass("hidediv");
            window.oktaWidget.show();
            this.oktashowLogin();
        }
        else if (configData.siteConfig.authentication && configData.siteConfig.authentication.key == "novi") {
            let novibaseURL = configData.siteConfig.authentication.novi.baseUrl
            let noviClientId = configData.siteConfig.authentication.novi.clientId

            window.location = novibaseURL + "/oauth/authorize?client_id=" + noviClientId + "&scope=openid&response_type=code%20token%20id_token&redirect_uri=" + window.location.origin

        } else if (configData.siteConfig.authentication && configData.siteConfig.authentication.key == "oauth") {
            let oauthbaseURL = configData.siteConfig.authentication.oauth.baseUrl
            let oauthClientId = configData.siteConfig.authentication.oauth.clientId
            window.location = oauthbaseURL + "/oauth/index/authorize?client_id=" + oauthClientId + "&state=openid,email&response_type=token&redirect_uri=" + window.location.origin

        }
    }
    oktashowLogin() {
        let self = this;
        let locationURL = window.location.protocol + "//" + window.location.host + "/";
        window.oktaWidget && window.oktaWidget.remove();
        window.oktaWidget && window.oktaWidget.renderEl({ el: "#osw-container" },
            (oktaResponse) => {
                if (oktaResponse && oktaResponse.tokens && oktaResponse.tokens.idToken) {
                    $("#overlay").removeClass("hidediv");
                    let url = lambdaUrl + '/authentication?appname=' + window.app.appName + '&token=' + oktaResponse.tokens.idToken.idToken + '&env=dev' + '&host=' + locationURL

                    self.authenticationMethod(url)
                }
            },
            (err) => {

            }
        );
    }

    okClick = () => {
        let self = this;
        self.setState({ showChannelsNotFound: false });
        self.props.history.push("/scheduler");
    }
    render() {
        const { buttonHide, cssClass, labelText } = this.props
        return (
            <React.Fragment>
                {!buttonHide ?
                    <UserConsumer>
                        {
                            (data) => {
                                return <button style={{ "cursor": "pointer" }} onClick={e => this.loginLock(e, data)} className={cssClass}>{labelText}</button>
                            }
                        }
                    </UserConsumer>

                    : null}

                {this.state.showChannelsNotFound &&
                    <AlertPopup message="Sorry, you don’t have any Live Channels assigned." closePopup={this.okClick} title='NOT AVAILABLE' imagePath="images/states_of_applications/warning_gray.png" showClose={true} />
                }
            </React.Fragment>

        )
    }
}

const mapState = ({ websiteDefaultData, channels, ivsChannel }) => ({ websiteDefaultData, channels, ivsChannel });
const mapDispatch = { createIVSChannel, getChannels };
export default withRouter(connect(mapState, mapDispatch)(SignIn));
