import axios from "axios";
import { webAppName } from '../../utils/utils';

var locationObj = JSON.parse(localStorage.getItem("loc"));
if (locationObj) {
    let locationData = locationObj.headers || {}
    let geoLocation = {}
    if (Object.keys(locationData).length) {
        geoLocation['city'] = locationData['cloudfront-viewer-city'] && locationData['cloudfront-viewer-city'][0] && locationData['cloudfront-viewer-city'][0].value || '';
        geoLocation['region'] = locationData['cloudfront-viewer-country-region'] && locationData['cloudfront-viewer-country-region'][0] && locationData['cloudfront-viewer-country-region'][0].value || '';
        geoLocation['country'] = locationData['cloudfront-viewer-country-name'] && locationData['cloudfront-viewer-country-name'][0] && locationData['cloudfront-viewer-country-name'][0].value || '';
        geoLocation['timezone'] = locationData['cloudfront-viewer-time-zone'] && locationData['cloudfront-viewer-time-zone'][0] && locationData['cloudfront-viewer-time-zone'][0].value || '';
    }
    locationUrl = '&country=' + geoLocation.country || ""
    locationUrl += '&region=' + geoLocation.region || ""
    locationUrl += '&city=' + geoLocation.city || ""
    locationUrl += '&timezone=' + geoLocation.timezone || ""
}
import {
    ADD_PROFILE,
    APPROVED_ASSET,
    ASSET_PAYMENT,
    BRAINTREE_CUSTOMER,
    ACTIVATION_CODE,
    GENERATE_ACTIVATION_CODE,
    GENERATE_USER_RELATION,
    GET_ADDRESS,
    GET_ALL_PARTNERS,
    GET_ASSET_INFO,
    GET_COUNTRIES,
    GET_EVENT_INFO,
    GET_CHANNELS,
    GET_REGIONS,
    GET_USER,
    GET_WEBSITE_DEFAULT_DATA,
    ITEMS_IS_LOADING,
    GET_CLIPS,
    CREATE_CLIPS,
    GIVE_VOTE,
    GET_ASSETS,
    GET_PLAYBACK_URLS,
    MANAGE_CHANNELS,
    CREATE_IVS_CHANNEL,
    MANAGE_PLAYLISTS,
    EDIT_ASSET,
    MANAGE_LOOKUPS,
    EMBED_LINKS_DATA,
    MANAGE_EMBED_DATA,
    GET_EVENTS,
    MANAGE_SCHEDULING,
    MANAGE_SCHEDULES,
    PLAYLIST_ASSETS,
    SCHEDULE_EVENTS,
    GET_GIFTCARDRESULT,
    ASSET_DETAILS,
    GET_PLAYER_INFO,
    CHANNEL_ASSETS,
} from './ActionTypes'

import {
    activationCodeProps,
    approvedProps,
    assetInfo,
    assetPayment,
    braintreeCustomer,
    dispatch_addprofile,
    dispatch_address,
    dispatch_countries,
    dispatch_channels,
    dispatch_playbackurls,
    dispatch_manage_channels,
    dispatch_edit_asset,
    dispatch_manage_playlists,
    dispatch_assets,
    eventInfo,
    generateActivationCodeProps,
    generateUserRelationProps,
    getPartners,
    itemsIsLoading,
    regions,
    userInfo,
    websiteDefaults,
    allClips,
    createdClip,
    votedProps,
    dispatch_location,
    dispatch_create_ivs_channel,
    dispatch_events,
    dispatch_manage_lookups,
    dispatch_embed_links_data,
    dispatch_manage_embed_data,
    dispatch_manage_scheduling_data,
    dispatch_manage_schedules_data,
    dispatch_playlist_assets_data,
    dispatch_schedule_events_data,
    dispatch_player_data,
    giftCardInfo,
    assetDetailsData,
    channelAssetsProps
} from './Actions'
import $ from 'jquery';
import moment from 'moment';
//initiate your starting state
let initial = {
    approvedProps: [],
    assetInfo: [],
    assetPayment: [],
    braintreeCustomer: [],
    eventInfo: {},
    channels: [],
    assets: [],
    generateActivationCodeData: [],
    getPartners: [],
    loading: false,
    regions: [],
    userinfo: [],
    userRelationData: {},
    websiteDefaultData: [],
    allClips: [],
    createdClip: [],
    votedProps: [],
    playbackUrls: [],
    manageChannelsData: [],
    ivsChannel: [],
    activationCodeProps: [],
    manageplaylistsData: [],
    events: [],
    manageLookUps: [],
    embedLinksData: [],
    manageEmbedData: [],
    manageSchedulingData: [],
    manageSchedulesData: [],
    playlistAssetsData: [],
    scheduleEventsData: [],
    giftResult: [],
    assetDetailsData: [],
    playerdata: [],
    channelAssetsProps: []
};



let appSettings = window.app
let siteSettings = window.site;

let siteConfig = (siteSettings && siteSettings.config) || (appSettings && appSettings.defaultSiteSettings && appSettings.defaultSiteSettings.config);
let envoiScreeningApiBaseUrl = siteSettings && siteSettings.screeningApiBaseUrl;
let envoiSubmissionApiBaseUrl = siteSettings && siteSettings.submissionApiBaseUrl;
let commonApi = siteConfig.commonApi;
let appStorage = appSettings.storage;

let token = appStorage.getItem('token');
let envoiBroadcast = siteConfig.envoiBroadcast || {}
window.requiredProfile = envoiBroadcast.requiredProfile;
window.requiredDestination = envoiBroadcast.requiredDestination;
window.requiredVerification = envoiBroadcast.requiredVerification;
window.paymentEnable = (envoiBroadcast.subscription || {}).enable
let commonLambdaUrl = siteConfig.commonApi;
let assetsCount = 12;
let pageNumber = 1;
let scrubbing = true;

/////////////////REDUCER/////////////////////
const reducer = (state = initial, action) => {
    switch (action.type) {
        case ADD_PROFILE:
            return Object.assign({}, state, { addingProfile: action.addProfile });
        case APPROVED_ASSET:
            return Object.assign({}, state, { approvedProps: action.approvedState })
        case ASSET_PAYMENT:
            return Object.assign({}, state, { assetPayment: action.asset });
        case BRAINTREE_CUSTOMER:
            return Object.assign({}, state, { braintreeCustomer: action.braintreeCustomerState });
        case ACTIVATION_CODE:
            return Object.assign({}, state, { activationCodeProps: action.activationCodeState });
        case GENERATE_ACTIVATION_CODE:
            return Object.assign({}, state, { generateActivationCodeData: action.generateActivationCodeState });
        case GENERATE_USER_RELATION:
            return Object.assign({}, state, { userRelationData: action.userRelationData });
        case GET_ADDRESS:
            return Object.assign({}, state, { address: action.address });
        case GET_ALL_PARTNERS:
            return Object.assign({}, state, { getPartners: action.partners });
        case GET_ASSET_INFO:
            return Object.assign({}, state, { assetInfo: action.asset });
        case GET_COUNTRIES:
            return Object.assign({}, state, { countries: action.countries });
        case GET_EVENT_INFO:
            return Object.assign({}, state, { eventInfo: action.event });
        case GET_CHANNELS:
            return Object.assign({}, state, { channels: action.channels });
        case GET_ASSETS:
            return Object.assign({}, state, { assets: action.assets });
        case GET_PLAYBACK_URLS:
            return Object.assign({}, state, { playbackUrls: action.playbackUrls });
        case MANAGE_CHANNELS:
            return Object.assign({}, state, { manageChannelsData: action.manageChannels });
        case EDIT_ASSET:
            return Object.assign({}, state, { editAssetsData: action.editAsset });
        case MANAGE_PLAYLISTS:
            return Object.assign({}, state, { manageplaylistsData: action.managePlayList });
        case CREATE_IVS_CHANNEL:
            return Object.assign({}, state, { ivsChannel: action.ivsChannel });
        case GET_REGIONS:
            return Object.assign({}, state, { regions: action.regionsState });
        case GET_USER:
            return Object.assign({}, state, { userInfo: action.user });
        case GET_WEBSITE_DEFAULT_DATA:
            return Object.assign({}, state, { websiteDefaultData: action.websiteDefaultData });
        case ITEMS_IS_LOADING:
            return Object.assign({}, state, { loading: action.loading });
        case GET_CLIPS:
            return Object.assign({}, state, { allClips: action.clips });
        case CREATE_CLIPS:
            return Object.assign({}, state, { createdClip: action.clips });
        case MANAGE_LOOKUPS:
            return Object.assign({}, state, { manageLookUps: action.manageLookUps, });
        case EMBED_LINKS_DATA:
            return Object.assign({}, state, { embedLinksData: action.embedLinksData, });
        case MANAGE_EMBED_DATA:
            return Object.assign({}, state, { manageEmbedData: action.manageEmbedData, });
        case GIVE_VOTE:
            return Object.assign({}, state, { votedProps: action.votedState });
        case GET_EVENTS:
            return Object.assign({}, state, { events: action.events });
        case MANAGE_SCHEDULING:
            return Object.assign({}, state, { manageSchedulingData: action.manageSchedulingData });
        case MANAGE_SCHEDULES:
            return Object.assign({}, state, { manageSchedulesData: action.manageSchedulesData });
        case PLAYLIST_ASSETS:
            return Object.assign({}, state, { playlistAssetsData: action.playlistAssetsData });
        case SCHEDULE_EVENTS:
            return Object.assign({}, state, { scheduleEventsData: action.scheduleEventsData });
            case GET_GIFTCARDRESULT:
                return Object.assign({}, state, { giftResult: action.giftCardResult });
                case ASSET_DETAILS:
            return Object.assign({}, state, { assetDetailsData: action.assetDetailsState });
            case GET_PLAYER_INFO:
            return Object.assign({}, state, { playerdata: action.playerdata });
            case CHANNEL_ASSETS:
            return Object.assign({}, state, { channelAssetsProps: action.channelAssetsState });

        default:
            return state;
    }
};

export default reducer;

// Price Format
export const priceFormat = (value, currency, countryCode) => dispatch => {
    const formatter = new Intl.NumberFormat(countryCode, {
        style: 'currency',
        currency: currency,
    });

    return formatter.format(value);
}

/////////////// ACTION GET WEBSITE_DEFAULT_DATA///////////////////
export const getWebsiteDefault = () => dispatch => {
    dispatch(itemsIsLoading(true));
    window.getAndProcessSiteSettings()
        .then((siteSettings) => {
            dispatch(itemsIsLoading(false));
            dispatch(websiteDefaults(siteSettings));
            return siteSettings;
        })
        .catch((err) => {
            dispatch(websiteDefaults([]))
            console.error.bind("reducer.js getWebsiteDefault", { err });
        })
};

export function activationCode(bodydata) {
    token = appStorage.getItem('token');
    return (dispatch) => {
        let url = envoiScreeningApiBaseUrl + '/activationCode?appname=' + window.app.appName + '&env=' + window.app.appEnvName + '&token=' + token + '&pairingcode=' + bodydata.pairingcode;
        if (bodydata.userid)
            url += "&uid=" + bodydata.userid

        axios.post(url, { type: 'application/json' })
            .then((response) => {
                if (response.data.statusCode === 401) {
                    return response.data;
                } else {
                    return response.data.result;
                }
            })
            .then((activationCodeState) => {
                dispatch(activationCodeProps(activationCodeState))
            })
            .catch((err) => {
                dispatch(activationCodeProps(err.response.data));
                console.error.bind("reducer.js activationCode", { err });
            })
    };
}


export const getArtists = (menu, creatorType) => dispatch => {
    dispatch(itemsIsLoading(true));
    token = appStorage.getItem('token');
    let url = envoiScreeningApiBaseUrl + '/getArtists?appname=' + window.app.appName + '&env=' + window.app.appEnvName + "&token=" + token + "&menu=" + menu + '&assetcount=' + assetsCount + '&pageNumber=' + pageNumber + '&scrubbing=' + scrubbing;

    if (creatorType) {
        url += "&creatorType=" + creatorType;
    }
    axios.get(url)
        .then((response) => {
            dispatch(itemsIsLoading(false));
            return response.data.result;
        })
        .then((partners) => {
            dispatch(getPartners(partners))
        })
        .catch((err) => {
            console.error.bind("reducer.js getArtists", { err });
        })
};

export const getUser = (token) => dispatch => {
    dispatch(itemsIsLoading(true));
    axios.post(envoiSubmissionApiBaseUrl + '/getuser?appname=' + window.app.appName + '&env=' + window.app.appEnvName + '&token=' + token)
        .then((response) => {
            dispatch(itemsIsLoading(false));
            return response.data.result;
        })
        .then((user) => {
            dispatch(userInfo(user))
        })
        .catch((err) => {
            console.error.bind("reducer.js getUser", { err });
        })
};

export const customerAddress = (formData, id) => dispatch => {
    dispatch(itemsIsLoading(true));
    token = appStorage.getItem('token');
    axios.post(envoiScreeningApiBaseUrl + '/address?token=' + token + '&env=' + window.app.appEnvName + '&appname=' + window.app.appName + '&id=' + id, formData, { type: 'application/json' })
        .then((response) => {

            dispatch(itemsIsLoading(false));
            return response.data.result;
        })
        .catch((err) => {
            console.error.bind("reducer.js customerAddress", { err });
        })
};

export const updateUserRelation = (formData) => dispatch => {

    dispatch(itemsIsLoading(true));
    token = appStorage.getItem('token');
    axios.post(envoiSubmissionApiBaseUrl + '/partnerDetails?token=' + token + '&env=' + window.app.appEnvName + '&appname=' + window.app.appName, formData, { type: 'application/json' })
        .then((response) => {
            dispatch(itemsIsLoading(false));
            return response.data.result;
        })
        .then((UserRelationData) => {
            dispatch(generateUserRelationProps(UserRelationData))
        })
        .catch((err) => {
            console.error.bind("reducer.js updateUserRelation", { err });
        })
};

export function addProfiles(name, profileImage) {
    return (dispatch) => {

        let createProfile = { "profileName": name, "profileImage": profileImage }

        axios.post(envoiScreeningApiBaseUrl + '/createProfile?appname=' + window.app.appName + '&env=' + window.app.appEnvName + '&token=' + token, createProfile, { type: 'application/json' })
            .then((response) => {
                if (response.data.statusCode === 200) {
                    $(document).ready(function () {
                        $('#SuccessAddPopup').css("display", "block");
                        setTimeout(function () { $('#SuccessAddPopup').css('display', 'none'); }, 2000);
                    });
                }
                else if (response.data.statusCode === 401) {
                    $(document).ready(function () {
                        if (response.data.error && response.data.error === 'profileName exists') {
                            $('#ErrorPopup').css("display", "block");
                            setTimeout(function () { $('#ErrorPopup').css('display', 'none'); }, 2000);
                        }

                    });
                }

                return response.data.result;
            })
            .then((addProfile) => {
                dispatch(dispatch_addprofile(addProfile))
            })
            .catch((err) => {
                console.error.bind("reducer.js addProfiles", { err });
            })
    };
}

export function getCountries(token) {
    return (dispatch) => {
        dispatch(itemsIsLoading(true));
        commonApi = (window.site.config || window.app.defaultSiteSettings.config).commonApi;
        axios.get(commonApi + '/countries')
            .then((response) => {
                dispatch(itemsIsLoading(false));
                return response.data.result;
            })
            .then((countries_result) => {
                dispatch(dispatch_countries(countries_result))
            })
            .catch((err) => {
                console.error.bind("reducer.js getCountries", { err });
            })
    };
}

export function getRegionsAPI(alpha3) {
    return (dispatch) => {
        dispatch(itemsIsLoading(true));
        commonApi = (window.site.config || window.app.defaultSiteSettings.config).commonApi;
        axios.get(commonApi + '/regions/' + alpha3)
            .then((response) => {
                dispatch(itemsIsLoading(false));
                return response.data.result;
            })
            .then((regionsState) => {
                dispatch(regions(regionsState))
            })
            .catch((err) => {
                console.error.bind("reducer.js getRegionsAPI", { err });
            })
    };
}

export function getAddress(addresstype) {
    return (dispatch) => {
        dispatch(itemsIsLoading(true));
        token = appStorage.getItem('token');
        axios.post(envoiScreeningApiBaseUrl + '/getaddress?&appname=' + window.app.appName + '&env=' + window.app.appEnvName + '&token=' + token + '&addresstype=' + addresstype)
            .then((response) => {
                dispatch(itemsIsLoading(false));
                return response.data.result;
            })
            .then((address_result) => {
                dispatch(dispatch_address(address_result))
            })
            .catch((err) => {
                console.error.bind("reducer.js getAddress", { err });
            })
    };
}

export const addCustomerInBrainTree = () => dispatch => {
    token = appStorage.getItem('token');
    axios.post(envoiScreeningApiBaseUrl + '/addcard?token=' + token + '&appname=' + window.app.appName + '&env=' + window.app.appEnvName + "&createCustomer=true")
        .then((response) => {
            return response.data.result;
        })
        .then((braintreeCustomerState) => {
            dispatch(braintreeCustomer(braintreeCustomerState))
        })
        .catch((err) => {
            console.error.bind("reducer.js addCustomerInBrainTree", { err });
        })
};

export function generateActivationCode(device) {
    return (dispatch) => {
        dispatch(itemsIsLoading(true));
        token = appStorage.getItem('token');
        let appUrl = window.location.origin;
        axios.post(envoiSubmissionApiBaseUrl + '/genarateActivationCode?appname=' + window.app.appName + '&env=' + window.app.appEnvName + '&token=' + token + '&device=' + device + '&appUrl=' + appUrl)
            .then((response) => {
                dispatch(itemsIsLoading(false));
                if (response.data.statusCode === 401)
                    return response.data;
                else
                    return response.data.result;
            })
            .then((generateActivationCodeState) => {
                dispatch(generateActivationCodeProps(generateActivationCodeState))
            })
            .catch((err) => {
                dispatch(generateActivationCodeProps(err.response.data));
                console.error.bind("reducer.js generateActivationCode", { err });
            })
    };
}

export function getChannels(payload) {
    return (dispatch) => {
        dispatch(itemsIsLoading(true));
        let token = appStorage.getItem('token');
        let assetCount = payload.assetCount;
        let pageNumber = payload.pageNumber;
        let userCode = payload.userCode;
        let type = payload.type;
        let channelCode = payload.channelCode;
        let url = envoiSubmissionApiBaseUrl + '/channel?env=' + window.app.appEnvName + '&token=' + token;
        url += '&appname=' + window.app.appName;
        if (assetCount) url += '&assetCount=' + assetCount;
        if (pageNumber) url += '&pageNumber=' + pageNumber;
        if (userCode) url += '&code=' + userCode;
        if (channelCode) url += '&channelcode=' + channelCode;
        if (type) url += '&serviceProvider=' + type;


        axios.post(url)
            .then((response) => {
                dispatch(itemsIsLoading(false));
                if (response.data.error && response.data.error.message === "Invalid Token") {
                    dispatch(logOut())
                } else {
                    return response.data.result;
                }
            })
            .then((channels) => {
                dispatch(dispatch_channels(channels))
            })
            .catch((err) => {
                console.error.bind("reducer.js getChannels", { err });
            })
    };
}



export function getAssets(payload) {
    return (dispatch) => {
        dispatch(itemsIsLoading(true));
        let token = appStorage.getItem('token');

        
        webAppName()

        let assetCount = payload.assetCount;
        let pageNumber = payload.pageNumber;
        let userCode = payload.userCode;
        let playlistid = payload.playlistid;
        let search = payload.search;
        let assetId = payload.assetId;
        let carousel = payload.carousel;
        let isMarkerDuration = true
        let url = envoiScreeningApiBaseUrl + '/assets?env=' + window.app.appEnvName;
        url += '&appname=' + window.app.appName
        if (assetCount) url += '&assetcount=' + assetCount;
        if (pageNumber) url += '&pageNumber=' + pageNumber;
        if (userCode) url += '&code=' + userCode;
        if (playlistid) url += '&playlistid=' + playlistid;
        if (search) url += '&search=' + search;
        if (assetId) url += '&assetid=' + assetId;
        if (carousel) url += '&carousel=' + carousel;
        if (isMarkerDuration) url += '&isMarkerDuration=' + isMarkerDuration;

        axios.post(url, { assetType: payload.assetType })
            .then((response) => {
                dispatch(itemsIsLoading(false));
                if (response.data.error && response.data.error.message === "Invalid Token") {
                    dispatch(logOut())
                } else {
                    return response.data.result;
                }
            })
            .then((assets) => {
                dispatch(dispatch_assets(assets))
            })
            .catch((err) => {
                console.error.bind("reducer.js getAssets", { err });
            })
    };
}


export function getPlaybackUrls(payload) {
    return (dispatch) => {
        dispatch(itemsIsLoading(true));
        let token = appStorage.getItem('token');
        let assetId = payload.assetId;
        let url = envoiSubmissionApiBaseUrl + '/proxyUrls?env=' + window.app.appEnvName + '&token=' + token;
        url += '&appname=' + window.app.appName;
        if (assetId) url += '&assetid=' + assetId;
        axios.post(url)
            .then((response) => {
                dispatch(itemsIsLoading(false));
                if (response.data.error && response.data.error.message === "Invalid Token") {
                    dispatch(logOut())
                } else {
                    return response.data.result;
                }
            })
            .then((channels) => {
                dispatch(dispatch_playbackurls(channels))
            })
            .catch((err) => {
                console.error.bind("reducer.js getPlaybackUrls", { err });
            })
    };
}


export function manageChannels(payload, id) {
    return (dispatch) => {
        dispatch(itemsIsLoading(true));
        let token = appStorage.getItem('token');
        let url = envoiSubmissionApiBaseUrl + '/manageChannels?env=' + window.app.appEnvName + '&token=' + token;
        url += '&appname=' + window.app.appName, payload;
        if (id) url = url + "&id=" + id;
        axios.post(url, payload)
            .then((response) => {
                dispatch(itemsIsLoading(false));
                if (response.data.error && response.data.error.message === "Invalid Token") {
                    dispatch(logOut())
                } else {
                    return response.data.result;
                }
            })
            .then((manageChannelsData) => {
                dispatch(dispatch_manage_channels(manageChannelsData))
            })
            .catch((err) => {
                console.error.bind("reducer.js manageChannels", { err });
            })
    };
}


export function editAsset(payload) {
    console.log('payload', payload)
    return (dispatch) => {
        dispatch(itemsIsLoading(true));
        let token = appStorage.getItem('token');
        let url = envoiSubmissionApiBaseUrl + '/getFormById?env=' + window.app.appEnvName + '&token=' + token;
        url += '&appname=' + window.app.appName;
        if (payload && payload.id) url = url + "&id=" + payload.id;
        if (payload && payload.assetid) url = url + "&assetid=" + payload.assetid;
        axios.post(url)
            .then((response) => {
                dispatch(itemsIsLoading(false));
                if (response.data.error && response.data.error.message === "Invalid Token") {
                    dispatch(logOut())
                } else {
                    return response.data.result;
                }
            })
            .then((editAssetsData) => {
                dispatch(dispatch_edit_asset(editAssetsData))
            })
            .catch((err) => {
                console.error.bind("reducer.js editAssetsData", { err });
            })
    };
}

export const managePlayList = (playlistbody, id) => dispatch => {
    dispatch(itemsIsLoading(true));
    let token = appStorage.getItem('token');
    let url = envoiScreeningApiBaseUrl + '/manageUserPlaylist?token=' + token + '&env=' + window.app.appEnvName + '&appname=' + window.app.appName + '&assetcount=' + assetsCount + '&pageNumber=' + pageNumber + '&scrubbing=' + scrubbing;
    if (id)
        url = envoiScreeningApiBaseUrl + '/manageUserPlaylist?token=' + token + '&env=' + window.app.appEnvName + '&appname=' + window.app.appName + "&id=" + id + '&scrubbing=' + scrubbing;
    axios.post(url + locationUrl, playlistbody)

        .then((response) => {
            dispatch(itemsIsLoading(false));
            return response.data.result;
        })
        .then((managePlaylistData) => {
            dispatch(dispatch_manage_playlists(managePlaylistData))
        })
        .catch((err) => {
            console.error.bind("managePlayList reducer.js error", err);
        })
};
export function createIVSChannel() {
    return (dispatch) => {
        dispatch(itemsIsLoading(true));
        let token = appStorage.getItem('token');
        let userType = 'creator';
        let embed = true;
        let url = envoiSubmissionApiBaseUrl + '/ivschannel?env=' + window.app.appEnvName + '&token=' + token;
        url += '&appname=' + window.app.appName
        url += '&userType=' + userType
        url += '&embed=' + embed
        axios.post(url)
            .then((response) => {
                dispatch(itemsIsLoading(false));
                if (response.data.error && response.data.error.message === "Invalid Token") {
                    dispatch(logOut())
                } else {
                    return response.data.result;
                }
            })
            .then((ivsChannel) => {
                dispatch(dispatch_create_ivs_channel(ivsChannel))
            })
            .catch((err) => {
                console.error.bind("reducer.js createIVSChannel", { err });
            })
    };
}




export const approvedAsset = (actionBody) => dispatch => {
    // dispatch(itemsIsLoading(true));

    axios.post(envoiScreeningApiBaseUrl + '/manageUserAssetActivity?appname=' + window.app.appName + '&env=' + window.app.appEnvName + '&token=' + token, actionBody, { type: 'application/json' })
        .then((response) => {
            dispatch(itemsIsLoading(false));
            return response.data.result;
        })
        .then((approvedState) => {

            dispatch(approvedProps(approvedState))
        })
        .catch((err) => {
            console.error.bind("reducer.js approvedAsset", { err });
        })
};

export const giveVote = (actionBody) => dispatch => {
    // dispatch(itemsIsLoading(true));
    axios.post(envoiScreeningApiBaseUrl + '/manageUserAssetActivity?appname=' + window.app.appName + '&env=' + window.app.appEnvName + '&token=' + token, actionBody, { type: 'application/json' })
        .then((response) => {
            dispatch(itemsIsLoading(false));
            return response.data.result;
        })
        .then((votedState) => {

            dispatch(votedProps(votedState))
        })
        .catch((err) => {
            console.error.bind("reducer.js votedProps", { err });
        })
};


export const getPricing = (token, payment_method_nonce, price, arrayAssets, optionalAddress, optionalCity, quality, itemType, transactionType, sku, cardholderName, envoiSku, isSingleEventPurchase) => dispatch => {
    dispatch(itemsIsLoading(true));
    token = appStorage.getItem('token');
    let url = envoiScreeningApiBaseUrl + '/assetPayment?appname=' + window.app.appName + '&env=' + window.app.appEnvName + '&token=' + token + '&payment_method_nonce=' + payment_method_nonce + '&transactionType=' + transactionType + '&itemType=' + itemType + '&price=' + price + '&address=' + optionalAddress + '&city=' + optionalCity + '&quality=' + quality + '&userassetType=' + itemType;
    if (sku) {
        url += "&sku=" + sku;
    }
    if (isSingleEventPurchase) {
        url += "&isSingleEventPurchase=" + isSingleEventPurchase;
    }
    if (cardholderName) {
        url += "&cardholderName=" + cardholderName;
    }
    if (envoiSku) {
        url += "&envoisku=" + envoiSku;
    }
    axios.post(url, arrayAssets)
        .then((response) => {
            dispatch(itemsIsLoading(false));
            if (response.data.statusCode === 401) {
                let error = {
                    error: response.data.error ? response.data.error : "Call Failed"
                }
                dispatch(assetPayment(error))
            } else {
                return response.data.result;
            }
        })
        .then((asset) => {
            dispatch(assetPayment(asset))
        })
        .catch((err) => {
            let error = {
                error: "Call Failed"
            }
            dispatch(assetPayment(error))
            console.error.bind("reducer.js getPricing", { err });
        })
};

export const getAssetInfo = (assetId, token) => dispatch => {
    token = appStorage.getItem('token');

    let idFieldName = (assetId && token) ? 'event_id' : 'assetid';
    let requestUrl = `${envoiScreeningApiBaseUrl}/assetinfo?appname=${window.app.appName}&env=${window.app.appEnvName}&${idFieldName}=${assetId}&scrubbing=${scrubbing}`;
    if (assetId && token) {
        requestUrl += '&token=' + token;
    }

    dispatch(itemsIsLoading(true));
    axios.post(requestUrl)
        .then((response) => {
            if (response.data.statusCode === 401) {
                $(document).ready(function () {
                    //  window.location = "/notfound";
                });
            }
            dispatch(itemsIsLoading(false));
            return response.data.result;
        })
        .then((asset) => {

            dispatch(assetInfo(asset))
        })
        .catch((err) => {
            console.error.bind("reducer.js getAssetInfo", { err });
        })
};

export const getEventInfo = (assetid) => dispatch => {
    token = appStorage.getItem('token');
    let requestUrl = envoiScreeningApiBaseUrl + '/eventInfo?eventId=' + assetid + '&token=' + token + '&appname=' + window.app.appName + '&env=' + window.app.appEnvName;
    if (window.app.windowLocation) {
        requestUrl += '&' + window.app.windowLocation.href || ""
    }

    dispatch(itemsIsLoading(true));
    axios.post(requestUrl)
        .then((response) => {
            if (response.data.statusCode === 401) {
                $(document).ready(function () {
                    //  window.location = "/notfound";
                });
            }
            dispatch(itemsIsLoading(false));
            return response.data.result;
        })
        .then((event) => {

            dispatch(eventInfo(event))
        })
        .catch((err) => {
            console.error.bind("reducer.js getEventInfo", { err });
        })
};

export const msToTime = (duration) => dispatch => {
    duration = duration * 1000;
    let seconds = parseInt((duration / 1000) % 60)
        , minutes = parseInt((duration / (1000 * 60)) % 60)
        , hours = parseInt((duration / (1000 * 60 * 60)) % 24);

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
};

// export const logOut = () => dispatch => {
//     console.log("logout......")
//     var myAppName = appStorage.getItem('appName');
//     appStorage.clear();
//     appStorage.setItem('appName', myAppName);
//     window.location = '/?appname=' + myAppName;
// };

export const getClips = (eventId) => dispatch => {
    dispatch(itemsIsLoading(true));
    token = appStorage.getItem('token');
    let url = 'https://content-screening-api.dev2.envoi.cloud/clips?token=' + token + '&env=' + window.app.appEnvName + '&eventId=' + eventId + '&appname=' + window.app.appName;


    axios.get(url)
        .then((response) => {
            dispatch(itemsIsLoading(false));
            if (response.data.statusCode === 401) {
                return response.data;
            } else {
                return response.data.result;
            }
        })
        .then((clips) => {
            dispatch(allClips(clips))
        })
        .catch((err) => {
            console.error.bind("reducer.js getClips", { err });
        })
};

export const createClip = (params, bodyparams, serviceProvider) => dispatch => {
    dispatch(itemsIsLoading(true));
    token = appStorage.getItem('token');
    let url = envoiScreeningApiBaseUrl + '/createClip?env=' + window.app.appEnvName + '&token=' + token + params + '&appName=' + window.app.appName;
    let body = '';
    if (serviceProvider && serviceProvider == 'AWS Media Package') {
        url = envoiScreeningApiBaseUrl + '/harvestJob?env=' + window.app.appEnvName + '&token=' + token + '&appName=' + window.app.appName;
        console.log('start tomhere', moment().format());
        body = {
            "startTime": bodyparams.startTime,
            "endTime": bodyparams.endTime,
            "originEndpointId": "2C582335_71EF_4BD9_934C_BF7A27877FDF_Envoi_Peafowl_Meadia_Package_Test-vimond"
        }
    }

    // console.log('url rajesh', url, body);
    axios.post(url, body, { type: 'application/json' })
        .then((response) => {
            dispatch(itemsIsLoading(false));
            if (response.data.statusCode === 401) {
                return response.data;
            } else {
                return response.data.result;
            }
        })
        .then((clips) => {
            dispatch(createdClip(clips))
        })
        .catch((err) => {
            console.error.bind("reducer.js getClips", { err });
        })
};

export const getLocation = () => dispatch => {
    siteSettings = window.site;
    siteConfig = (siteSettings && siteSettings.config) || (appSettings && appSettings.defaultSiteSettings && appSettings.defaultSiteSettings.config);
    axios.get(siteConfig.geoLocatoinCloudfrontUrl)
        .then((response) => {
            localStorage.setItem('loc', JSON.stringify(response.data))
            return response.data;
        })
        .then((loactionState) => {
            dispatch(dispatch_location(loactionState))
        })
        .catch((err) => {
            console.error.bind("dispatch_location reducer.js error", err);
        })
};

export function getEvents(eventid, eventType) {
    return (dispatch) => {
        dispatch(itemsIsLoading(true));
        let token = appStorage.getItem('token');
        let url = envoiScreeningApiBaseUrl + '/getEvents?env=' + window.app.appEnvName + '&token=' + token;
        if (eventid) url += '&id=' + eventid;
        if (eventType) url += '&eventType=' + eventType;
        url += '&appname=' + window.app.appName
        axios.get(url)
            .then((response) => {
                dispatch(itemsIsLoading(false));
                if (response.data.error && response.data.error.message === "Invalid Token") {
                    dispatch(logOut())
                } else {
                    return response.data.result;
                }
            })
            .then((events) => {
                dispatch(dispatch_events(events))
            })
            .catch((err) => {
                console.error.bind("reducer.js getEvents", { err });
            })
    };
}

export function manageLookUp(lookupsType) {
    return (dispatch) => {
        dispatch(itemsIsLoading(true));
        let type = lookupsType.type || [];
        let token = appStorage.getItem('token');
        let url = 'https://w5gkasqpv3.execute-api.us-east-1.amazonaws.com/latest/lookups/';
        _.each(type && type.length > 0 && type, function (item, i) {
            if (item || item != "") {
                item ? (url += `${item},`) : null;
            }
            if (i == type.length - 1) {
                url = url.slice(0, -1);
            }
        });
        window.app.appName ? (url += `/${window.app.appName}`) : null;
        // url += '&appname=' + window.app.appName
        axios.get(url)
            .then((response) => {
                dispatch(itemsIsLoading(false));
                if (response.data.error && response.data.error.message === "Invalid Token") {
                    dispatch(logOut())
                } else {
                    return response.data.result;
                }
            })
            .then((manageLookUps) => {
                dispatch(dispatch_manage_lookups(manageLookUps))
            })
            .catch((err) => {
                console.error.bind("reducer.js getEvents", { err });
            })
    };
}

export function embedLinks(payload, data) {
    return (dispatch) => {
        dispatch(itemsIsLoading(true));
        let embedId = payload.embedId || "";
        let contentId = payload.contentId || "";
        token = appStorage.getItem('token');
        let url = envoiSubmissionApiBaseUrl + "/embedLinks?appname=" + window.app.appName + '&env=' + window.app.appEnvName + "&token=" + token;
        embedId ? (url += `&embedId=${embedId}`) : null;
        contentId ? (url += `&contentId=${contentId}`) : null;
        axios.post(url)
            .then((response) => {
                dispatch(itemsIsLoading(false));
                if (response.data.error && response.data.error.message === "Invalid Token") {
                    dispatch(logOut())
                } else {
                    return response.data.result;
                }
            })
            .then((embedLinksData) => {
                dispatch(dispatch_embed_links_data(embedLinksData))
            })
            .catch((err) => {
                console.error.bind("reducer.js embedLinksData", { err });
            })
    };
}

export function mangeEmbedLink(payload, activity, embedId, share) {
    return (dispatch) => {
        dispatch(itemsIsLoading(true));
        let type = payload.contentType || "";
        let assetid = payload.contentId || "";
        token = appStorage.getItem('token');
        let url = envoiSubmissionApiBaseUrl + "/manageEmbedLinks?appname=" + window.app.appName + '&env=' + window.app.appEnvName + "&token=" + token + "&activity=" + activity;
        embedId ? (url += `&embedId=${embedId}`) : null;
        share ? (url += `&shareWith=${share}`) : null;
        type ? (url += `&type=${type}`) : null;
        assetid ? (url += `&assetid=${assetid}`) : null;
        axios.post(url, payload)
            .then((response) => {
                dispatch(itemsIsLoading(false));
                if (response.data.error && response.data.error.message === "Invalid Token") {
                    dispatch(logOut())
                } else {
                    return response.data.result;
                }
            })
            .then((manageEmbedData) => {
                dispatch(dispatch_manage_embed_data(manageEmbedData))
            })
            .catch((err) => {
                console.error.bind("reducer.js embedLinksData", { err });
            })
    };
}

export function manageScheduling(payload) {
    return (dispatch) => {
        dispatch(itemsIsLoading(true));
        token = appStorage.getItem('token');
        let calederId = payload.calederId || "";
        let url = envoiScreeningApiBaseUrl + "/schedule?appname=" + window.app.appName + '&env=' + window.app.appEnvName + "&token=" + token
        calederId ? (url += `&calederId=${calederId}`) : null;
        axios.post(url, payload)
            .then((response) => {
                dispatch(itemsIsLoading(false));
                if (response.data.error && response.data.error.message === "Invalid Token") {
                    dispatch(logOut())
                } else {
                    return response.data.result;
                }
            })
            .then((manageSchedulingData) => {
                dispatch(dispatch_manage_scheduling_data(manageSchedulingData))
            })
            .catch((err) => {
                console.error.bind("reducer.js embedLinksData", { err });
            })
    };
}

export function manageSchedules(payload) {
    return (dispatch) => {
        dispatch(itemsIsLoading(true));
        token = appStorage.getItem('token');
        let url = envoiScreeningApiBaseUrl + "/scheduleslist?appname=" + window.app.appName + '&env=' + window.app.appEnvName
        axios.post(url, payload)
            .then((response) => {
                dispatch(itemsIsLoading(false));
                if (response.data.error && response.data.error.message === "Invalid Token") {
                    dispatch(logOut())
                } else {
                    return response.data.result;
                }
            })
            .then((manageSchedulesData) => {
                dispatch(dispatch_manage_schedules_data(manageSchedulesData))
            })
            .catch((err) => {
                console.error.bind("reducer.js embedLinksData", { err });
            })
    };
}


export function playlistAssets(payload) {
    return (dispatch) => {
        dispatch(itemsIsLoading(true));;
        token = appStorage.getItem('token');
        let type = payload.contentId || "";
        let search = payload.search || "";
        let url = envoiSubmissionApiBaseUrl + "/PlayLists?appname=" + window.app.appName + '&env=' + window.app.appEnvName + "&token=" + token
        type ? (url += `&type=${type}`) : null;
        search ? (url += `&search=${search}`) : null;
        axios.post(url, payload)
            .then((response) => {
                dispatch(itemsIsLoading(false));
                if (response.data.error && response.data.error.message === "Invalid Token") {
                    dispatch(logOut())
                } else {
                    return response.data.result;
                }
            })
            .then((playlistAssetsData) => {
                dispatch(dispatch_playlist_assets_data(playlistAssetsData))
            })
            .catch((err) => {
                console.error.bind("reducer.js embedLinksData", { err });
            })
    };
}

export function scheduleEventsToS3(id, payload) {
    return (dispatch) => {
        dispatch(itemsIsLoading(true));;
        token = appStorage.getItem('token');
        // let id = id || "";
        let url = envoiSubmissionApiBaseUrl + "/scheduleCache?appname=" + window.app.appName + '&env=' + window.app.appEnvName + "&token=" + token
        id ? (url += `&id=${id}`) : null;
        axios.post(url, payload)
            .then((response) => {
                dispatch(itemsIsLoading(false));
                if (response.data.error && response.data.error.message === "Invalid Token") {
                    dispatch(logOut())
                } else {
                    return response.data.result;
                }
            })
            .then((scheduleEventsData) => {
                dispatch(dispatch_schedule_events_data(scheduleEventsData));
            })
            .catch((err) => {
                console.error.bind("reducer.js embedLinksData", { err });
            })
    };
}


export const getGiftPricing = (token, payload_nonce, single_price, sender, selectedEmails, optionalAddress, optionalCity, userassetType, contentType, quickboksID, envoisku) => dispatch => {
    dispatch(itemsIsLoading(true));
    token = appStorage.getItem('token');
    let url = envoiScreeningApiBaseUrl + '/paymentAuth?appname=' + window.app.appName + '&env=' + window.app.appEnvName + '&token=' + token + '&payment_method_nonce=' + payload_nonce + '&transactionType=gift&itemType=asset&price=' + single_price + '&mailid=' + sender + '&address=' + optionalAddress + '&city=' + optionalCity + '&userassetType=' + userassetType + '&quality=' + contentType
    if (quickboksID) {
        url = url + "&sku=" + quickboksID;
    }
    if (envoisku) {
        url = url + "&envoisku=" + envoisku;
    }

    axios.post(url, selectedEmails)
        .then((response) => {
            if (response.data.statusCode == 200) {
                window.location = "/paymentsuccess"
                //browserHistory.push("/paymentsuccess");
            }
            else if (response.data.statusCode == 401) {
                window.location = "/emailpopup" + response.data.error
               // browserHistory.push("/emailpopup/" + response.data.error);
            }
            // dispatch(itemsIsLoading(false));
            //  browserHistory.push("/paymentsuccess");
            // return response.data.result;
        })
        .then((giftCardPayment) => {
            dispatch(giftCardPayment(giftCardPayment))
        })
        .catch((err) => {
            console.log("err", err)
            if (err && err.response.data && err.response.data.statusCode == 401) {
                browserHistory.push("/emailpopup/" + err.response.data.error);
            }
            console.error.bind("getGiftPricing reducer.js error", err);
        })
};

export const getGiftCard = (token) => dispatch => {
    dispatch(itemsIsLoading(true));
    var email = localStorage.getItem('email');
    token = appStorage.getItem('token');
    axios.post(envoiScreeningApiBaseUrl + '/giftcard?appname=' +  window.app.appName + '&env=' + window.app.appEnvName + '&token=' + token + '&emailid=' + email)
        .then((response) => {
            dispatch(itemsIsLoading(false));
            return response.data.result;
        })
        .then((giftCardResult) => {
            dispatch(giftCardInfo(giftCardResult))
        })
        .catch((err) => {
            console.error.bind("getGiftPricing reducer.js error", err);
        })
};

export const assetDetails = (payload) => dispatch => {
    dispatch(itemsIsLoading(true));
    token = appStorage.getItem('token');
    let assetid = payload.assetid || "";
    let groupName = payload.groupName || "";
    let appType = "screening"
    let url = `${envoiScreeningApiBaseUrl}/assetDetails?appname=${window.app.appName}&token=${token}&env=${window.app.appEnvName}&appType=${appType}`;
    assetid ? url += `&assetid=${assetid}` : null;
    //groupName ? url += `&groupName=${groupName}` : null;
    if (locationUrl) {
        url += locationUrl || ""
    }
    axios.post(url)
        .then((response) => {
            dispatch(itemsIsLoading(false));
            if (response.data.error == "Invalid Token") {
                dispatch(logout())
            } else {
                return response.data.result;
            }

        })
        .then((justaddedLoadMore) => {
            dispatch(assetDetailsData(justaddedLoadMore))
        })
        .catch((err) => {
            console.log("catch ", err)
            console.error.bind("getassetdetails reducer.js error", err);
        })
};

export const getplayerinfo = (assetid,channelCode, token) => dispatch => {
    let locationURL = window.location.protocol + "//" + window.location.host + "/";
    // if (assetid && token) {
    //     var isToken = '/playlistinfo?appname=' + appname + '&env=' + environmentVariable + '&assetid=' + assetid + '&token=' + token;
    // } else {
    //     var isToken = '/playlistinfo?appname=' + appname + '&env=' + environmentVariable + '&assetid=' + assetid;
    // }
    let urlendpoint;
    if(assetid){
        urlendpoint = envoiScreeningApiBaseUrl + '/playlistinfo?appname=' + window.app.appName + '&env=' + window.app.appEnvName + '&assetid=' + assetid + '&host=' + locationURL;
    }else{
        urlendpoint = envoiScreeningApiBaseUrl + '/playlistinfo?appname=' + window.app.appName + '&env=' + window.app.appEnvName + '&channelcode=' + channelCode + '&host=' + locationURL;
    }
    
    // if (assetid && token) {
    //     urlendpoint += '&token=' + token
    // }
    if (locationUrl) {
        urlendpoint += locationUrl || ""
    }

    dispatch(itemsIsLoading(true));
    axios.post(urlendpoint)
        .then((response) => {
            if (response.data.statusCode == 401) {
                $(document).ready(function () {
                    window.location = '/onDemand';
                    // window.location = "/notfound";
                });
            }
            dispatch(itemsIsLoading(false));
            return response.data.result;
        })
        .then((playerdata) => {

            dispatch(dispatch_player_data(playerdata))
        })
        .catch((err) => {
            console.error.bind("getassetinfo reducer.js error", err);
        })
};

export function channelAssets(channelCode, playbackUrls, playlistType) {
    return (dispatch) => {
        dispatch(itemsIsLoading(true));
        let URL;
        // if (playlistType) {
        //     URL = envoiScreeningApiBaseUrl + '/channelAssets?appname=' + window.app.appName + '&env=' + window.app.appEnvName + '&playbackUrls=' + playbackUrls + '&channelcode=' + channelCode + '&cameraType=' + playlistType + '&scrubbing=' + scrubbing;
        // } else {
            URL = envoiScreeningApiBaseUrl + '/channelAssets?appname=' + window.app.appName + '&env=' + window.app.appEnvName + '&playbackUrls=' + playbackUrls + '&channelcode=' + channelCode;
        // }
        // if (token != null) {
        //     URL +=  '&scrubbing=' + scrubbing;
        // }
        if (locationUrl) {
            URL += locationUrl || ""
        }

        axios.post(URL)
            .then((response) => {
                dispatch(itemsIsLoading(false));
                return response.data.result;
            })
            .then((channelAssetsState) => {
                dispatch(channelAssetsProps(channelAssetsState))
            })
            .catch((err) => {
                dispatch(channelAssetsProps(err));
                console.log("channelAssets reducer.js error", err);
            })
    };
}