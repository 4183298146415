/** get location  */
export const location = (name) => {
  let results = new RegExp(name + '=([^&#]*)').exec(window.location.href);
  if (results == null) {
    results = new RegExp('[?&]' + name + '=([^&#]*)').exec(window.location.href);
  }
  if (results == null) {
    return null;
  }
  return decodeURI(results[1]) || 0;
}

export const removeSpecialCharecters = (filename) => {
  let timeStamp = new Date().getTime();
  let tmpFile = filename
    .substring(0, filename.lastIndexOf("."))
    .replace(/[^a-zA-Z 0-9]/g, "");
  tmpFile = tmpFile.replaceAll(" ", "");
  let tmpExtension = filename.substring(filename.lastIndexOf("."));
  let tmpNewFileName = tmpFile + timeStamp + tmpExtension;
  // console.log("tmpNewFileName", tmpNewFileName)
  // return encodeURIComponent(tmpNewFileName);
  return tmpNewFileName;
};

export const copyToClipboard = (promourl) => {
  const el = document.createElement("input");
  el.value = promourl;
  el.id = "url-input";
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  el.remove();
};

export const hotJarUserIdentify = (props) => {
  var userId = localStorage.getItem("userid") || null;
  props["firstname"] = localStorage.getItem("firstname") || null;
  props["lastname"] = localStorage.getItem("lastname") || null;
  props["email"] = localStorage.getItem("email") || null;
  props["role"] = localStorage.getItem("userRole") || null;
  props["host"] = window.location.host
  let hotjarSettings = analyticsData.hotjar || {};
  if (hotjarSettings.enable === true) {
      window.hj('identify', userId, props);
  }
}

let eventGuid = 0
let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today
export const INITIAL_EVENTS = [
  {
    id: createEventId(),
    title: 'All-day event',
    start: todayStr
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: todayStr + 'T12:00:00'
  }
]

export function createEventId() {
  return String(eventGuid++)
}
export function webAppName(){
  let storage = window.app.storage = localStorage || sessionStorage;
        const queryStringIn = window.location.href;
        const queryParamsIn = new URLSearchParams(queryStringIn);
        let windowLocationHref = queryParamsIn.get('windowLocationHref') || queryParamsIn.get('windowlocationhref');

        let windowLocation, relativeQueryParamsIn;
        if (windowLocationHref) {
            let windowLocationAnchorElement = document.createElement('a');
            windowLocationAnchorElement.href = windowLocationHref;
            windowLocation = windowLocationAnchorElement;
            relativeQueryParamsIn = new URLSearchParams(windowLocation.pathname);
        } else {
            windowLocation = window.location;
            relativeQueryParamsIn = queryParamsIn;
        }

        var urlquery = new URL(queryStringIn);
        let appName = urlquery.searchParams.get("appName") || urlquery.searchParams.get("appname") || queryParamsIn.get('appName') || queryParamsIn.get('appname')
            || relativeQueryParamsIn.get('appName') || relativeQueryParamsIn.get('appname') || storage.getItem('appName') || '';

        let appEnvName = urlquery.searchParams.get("appEnvName") || urlquery.searchParams.get("appEnvName") || queryParamsIn.get('appEnvName') || queryParamsIn.get('appenvname') || queryParamsIn.get('env')
            || relativeQueryParamsIn.get('appEnvName') || relativeQueryParamsIn.get('appenvname') || relativeQueryParamsIn.get('env') || storage.getItem('appEnvName') || '';

        window.app.appName = appName;
        window.app.appEnvName = appEnvName
}