import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { browserHistory } from "react-router";
import axios from "axios";
import { connect } from 'react-redux';
import { getAssets, getPlaybackUrls } from '../../redux/reducers/reducer';
import asyncLoop from "node-async-loop";
 import Loader from '../Loader';
import Header from '../authentication/Header';
import Footer from '../authentication/Footer';
// import VideoPlayer from '../videoPlayer/VideoPlayer';
// import AlertPopup from '../payment/AlertPopup';
// import PlayListPopup from './PlayListPopup';
let siteSettings = window.site;
let appSettings = window.app
let siteConfig = (siteSettings && siteSettings.config) || (appSettings && appSettings.defaultSiteSettings && appSettings.defaultSiteSettings.config);
let imagePrefix = siteConfig.commonResourcesUrl + siteConfig.appResourcesPath;
let defaultLandscape = siteConfig && siteConfig.defaultLandscape;
class Movies extends Component {
    constructor(props) {
        super(props);
        const { innerWidth: width, innerHeight: height } = window;
        let assetcountDefault = 12
        let pageCountDefault = 12
        if(height > 1200){
            assetcountDefault = 36
            pageCountDefault = 36
        }else if(height > 1080 && height < 1199){
            assetcountDefault = 20
            pageCountDefault = 20
        }else if(height > 940 && height < 1079){
            assetcountDefault = 16
            pageCountDefault = 16
        }
        this.state = {
            assetCount: assetcountDefault,
            pageCount: pageCountDefault,
            pageNumber: 1,
            playVideo: false,
            showMessage: false,
            playbackUrlsData: undefined,
            loadbtn: "Load More",
            assetTotal: 0,
            noOfAssets: 0,
            playListPopup: false,
            isLoading: true,
            isRecordingPlay: false,
            dummyDivLoader: false,
            
        }
        
        this.loadmoreassets = this.loadmoreassets.bind(this);
       
    }

    componentDidMount() {
        const { assetCount, pageNumber } = this.state;
        let userCode = localStorage.getItem('userCode') || '';
      //  let carousel = "Films"
        let payload = { assetCount, pageNumber, userCode }
        this.props.getAssets(payload);
    }

    componentWillMount() {
        let self = this;
        window.addEventListener('scroll', self.loadmoreassets);
    }

    componentWillUnmount() {
        let self = this;
        window.removeEventListener('scroll', self.loadmoreassets);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.assets != this.props.assets) {
            let assetsData = ((this.props.assets || {}).Items || []);
            let assetTotal = (this.props.assets || {}).assetTotal || '';
            let noOfAssets = (this.state.noOfAssets + assetsData.length);
            // console.log('noOfAssets ,', noOfAssets, assetTotal, assetsData.length);
            this.setState({ assetsData, assetTotal, noOfAssets, isLoading: false }, () => {
                
            })

        }
        if (prevProps.playbackUrls != this.props.playbackUrls) {
            let playbackUrlsData = (this.props.playbackUrls || {});
            this.setState({ playbackUrlsData })
            let playerUrl = '';
            if (playbackUrlsData) {
                let playerUrls = playbackUrlsData;
                if (playerUrls && playerUrls.playback) {
                    let playbackUrls = playerUrls.playback;
                    if (playbackUrls) {
                        playerUrl = playbackUrls.hlsUrl || playbackUrls.progressiveUrl;
                    }
                }
                // console.log("playerUrls", playerUrls)
                //Added this to play for previous object 
                if (!playerUrl) {
                    playerUrl = (playerUrls.hls) || (playerUrls.m3u8_Url) || (playerUrls.progressive) || (playerUrls.vdms_url) || (playerUrls.iconik_proxies_url) || (playerUrls.mp4url) || (playerUrls.eluvio_url || playerUrls.Mp4_proxy_URL);
                    // console.log("playerUrls22", playerUrl)
                }
            }
            if (!playerUrl) {
                this.setState({
                    showMessage: true
                })
            } else {
                this.setState({ playbackURL: playerUrl })
            }
        }
    }
    loadmoreassets() {
        let self = this
        const { noOfAssets, assetTotal } = self.state;
        //console.log("scrolled ", scrolled)
       // window.innerHeight + document.documentElement.scrollTop === document.scrollingElement.scrollHeight && 
        if (noOfAssets < assetTotal && scrolled) {
            // Do load more content here!
           // console.log("scrolled121212 ", scrolled)
            if (window.location.pathname == '/onDemand') {
                scrolled = false
                self.setState({ dummyDivLoader: true });
                setTimeout(function () {

                    self.loadMore(undefined, false)
                }, 3000,)
                
            }
        }

    }
    playAssets(item) {
        let self = this;
        let payload = { assetId: item.assetid }
        // console.log('payload===>', item)
        self.setState({ isRecordingPlay: true }, () => console.log('self.state.isRecordingPlay', self.state.isRecordingPlay))
        this.props.getPlaybackUrls(payload);
    }

    okClick = () => {
        let self = this;
        self.setState({ showMessage: false, playbackUrlsData: undefined });
    }



    closePopup = () => {
        let self = this;
        self.setState({ playbackUrlsData: undefined });
    }
   
    perPage(e) {
        this.setState({
            pageCount: e.target.value,
            pageNumber: 1
        }, () => {
            this.loadMore(this.state.pageNumber, false);
        });
    }


    loadMore(number, loader) {
        let self = this;
        let pageNumber = number ? number : (self.state.pageNumber + 1);
        let assetCount = self.state.pageCount
        self.setState({
            loadbtn: loader ? isLoading : this.state.loadbtn,
            dummyDivLoader: false,
            //isLoading : true,
            loading: loader,
            pageNumber: pageNumber
        });
        let appSettings = window.app || {}
        let appStorage = appSettings.storage || {};
        let token = appStorage.getItem('token');
        let siteSettings = window.site || {};
        let envoiScreeningApiBaseUrl = siteSettings.screeningApiBaseUrl;
        let url = envoiScreeningApiBaseUrl + '/assets?appname=' + window.app.appName + '&assetcount=' + assetCount + '&pageNumber=' + pageNumber + '&carousel=Films' + '&env=' + window.app.appEnvName + '&isMarkerDuration='+ true;
        let userCode = localStorage.getItem('userCode') || '';
        if (userCode) url += '&code=' + userCode;
        axios.post(url)
            .then((response) => {
                self.setState({ assetsData: number ? [] : self.state.assetsData, isLoading: false })
                if (response.data && response.data.result && response.data.result.Items && response.data.result.Items.length > 0) {

                   
                    asyncLoop(response.data.result && response.data.result.Items, function (item, next) {
                        self.setState({
                            assetsData: self.state.assetsData.concat(item),
                            loading: false,
                            loadbtn: !loader ? "Load More" : this.state.loadbtn,
                            isLoading: false,
                            dummyDivLoader: false
                        }, function () {
                           // setTimeout(function () {
                                next();
                           // }, 100);
                        });
                    },);
                    let newNoOfAssets = response.data.result.Items.length;
                    // console.log("self.state.noOfAssets ", self.state.noOfAssets, newNoOfAssets, response.data.result.assetTotal)
                    scrolled = true
                    self.setState({
                        noOfAssets: (self.state.noOfAssets + newNoOfAssets),
                        loading: false,
                        dummyDivLoader: false,
                        assetTotal: response.data.result.assetTotal,
                        
                    });
                } else {
                    self.setState({
                        assetTotal: response.data.result.assetTotal,
                        loading: false,
                        dummyDivLoader: false
                    });
                }
            })
            .catch((err) => {
            })
    }

    createPopup(e, asset) {
        let self = this;
        self.setState({ playListPopup: true, asset: asset });
    }
    closeModal(e) {
        let self = this;
        self.setState({ playListPopup: false });
    }
    toggleChange() {
        this.setState({
            isChecked: !this.state.isChecked,
        });
    }
    editAsset(asset) {
        let self = this;
        //window.location ="/asset?assetid=" + asset.assetid
        window.location = `/asset?assetid=${asset && asset.assetid}`
    }
    assetClick(item){
        let self = this;
        let playerUrl
        if (item && item.availability && item.availability.free) {
           // console.log('item12345', item)
        playerUrl = `/player?appname=${window.app.appName}&asset=${item.assetid}`
            window.location = playerUrl
            //window.location = "/player?appname=${window.app.appName} &asset=" + item.assetid;
        }
        else if (item && item.availability && item.availability.buyplans || item.buy == false  || item.gift == false  || item.rent == false) {
            //console.log('item12345', item)
            window.location = `/asset?assetid=${item && item.assetid}&appname=${window.app.appName}`
        }
        else if (item && item.availability && item.availability.buyplans || item.buy == true  || item.gift == true  || item.rent == true) {
            //console.log('item12345', item)
            playerUrl = `/player?appname=${window.app.appName}&asset=${item.assetid}`
            window.location = playerUrl
        }
        else if (item && item.preview && item.preview.enable && item.mediaType != "audio" && item.mediaType != "image" && item.mediaType != "document") {
            localStorage.setItem("assetinfopage", item.assetid);
            // browserHistory.push("/player/" + routeRedirect + "?=" + item.assetid)
            playerUrl = `/player?appname=${window.app.appName}&asset=${item.assetid}`
            window.location = playerUrl
        } else if (item && !item.availability) {
            self.assetTypeBasedNavigation(item, routeRedirect);
            // localStorage.setItem("assetinfopage", item.assetid);
            // browserHistory.push("/player/" + routeRedirect + "/" + item.assetid)
        } else if (item && item.availability && item.availability.availableFrom && item.availability.availableTo) {
            let availabilityStart = moment(item.availability.availableFrom);
            let availabilityEnd = moment(item.availability.availableTo);
            if (moment().isAfter(availabilityStart) && moment().isBefore(availabilityEnd)) {
                self.assetTypeBasedNavigation(item, routeRedirect);
            } else {
                self.setState({ showmessage: true })
            }
        } else {
            self.assetTypeBasedNavigation(item, routeRedirect);
        }
    }
    assetTypeBasedNavigation(item, routeRedirect) {
        if (item && (item.assetType && item.assetType.toLowerCase() == "image" || item.mediaType == "image")) {
            browserHistory.push("/gallery/Image/" + item.assetid)
        } else if (item && item.mediaType == "audio" && item.audio) {
            browserHistory.push("/musicplayer/asset/" + (item.assetid || ''))
        } else if (item && item.mediaType == "document" && item.document) {
            browserHistory.push("/file/" + (item.assetid || ''));
        } else {
            localStorage.setItem("assetinfopage", item.assetid);
            console.log('item.assetid', item.assetid)
            // browserHistory.push("/player/" + routeRedirect + "?=" + item.assetid)
            playerUrl = `/player?appname=${window.app.appName}&asset=${item.assetid}`
            window.location = playerUrl
        }
    }
    render() {
        let self = this;
        const { assetsData, isRecordingPlay, playVideo, isLoading, playbackUrlsData, noOfAssets, assetTotal, loadbtn, playListPopup, asset, loader, dummyDivLoader } = self.state;
        let siteSettings = window.site;
        let siteConfig = siteSettings.config || {};
        return (
            <React.Fragment>
                <div className="recording_page">
                    <Header />
                    <React.Fragment>
                        {!playVideo && <div className="enchannel_wrapper minbody-ht">

                            {!playbackUrlsData && assetsData && Array.isArray(assetsData) && assetsData.length > 0 && <div className="page-heading">

                                <div className="perpage">
                                    <div className="pull-left">{'Results: 1 to ' + noOfAssets + ' of ' + assetTotal}</div>
                                    {/* <div className="pull-right">Per Page<select name="pagination" id="pagination" onChange={e => self.perPage(e)} value={self.state.pageCount} defaultValue={self.state.assetsCount}>
                                            <option value="12">12</option>
                                            <option value="36">36</option>
                                            <option value="72">72</option>
                                            <option value="108">108</option>
                                            <option value="144">144</option>
                                        </select>
                                        </div> */}
                                </div>
                            </div>}
                            {this.props.loading ? <Loader /> :
                                !playbackUrlsData && !this.props.loading && assetsData && Array.isArray(assetsData) && assetsData.length > 0 ?
                                    <div className="row">
                                        {assetsData.map((asset, i) => {
                                            return (
                                                <div className="col-md-3" key={i}>
                                                    <div className="channel-card">
                                                        <div className="channel-thumb" onClick={() => self.assetClick(asset)} style={{ "cursor": "pointer" }}>
                                                        
                                                            {asset && asset.formatedduration && <span className="asset_duration">{asset && asset.formatedduration ? asset.formatedduration : ""}</span>}
                                                            <img alt="channel" src={asset && asset.landscape_logo_image ? asset.landscape_logo_image : siteConfig.defaultLandscape} />
                                                            
                                                            {/* <div className="asset_play" title="Play" onClick={() => self.playAssets(asset)}><span className="material-icons">play_arrow</span></div> */}
                                                            <a className="publish-btn selected rd_edit" onClick={() => self.editAsset(asset)} style={{ "cursor": "pointer" }} title="info"><span className="material-symbols-outlined"> info </span></a>
                                                        </div>

                                                        {/* <div className="thumb-footer"> */}
                                                        {/* <div>
                                                            <div className="dropdown actions-dd">
                                                                <a className="roundbtns" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"><span className="material-symbols-outlined">
                                                                    more_horiz
                                                                </span></a>
                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                    <li><div className="dropdown-item" onClick={() => self.editAsset(asset)}><span className="material-symbols-outlined"> edit </span>Edit</div></li>
                                                                    <li><div className="dropdown-item" ><span className="material-symbols-outlined"> share </span>Share</div></li>
                                                                    <li><div className="dropdown-item" onClick={(e) => self.createPopup(e, asset)}> <span className="material-icons">playlist_add</span>Add to playlist</div></li>
                                                                </ul>
                                                            </div>
                                                        </div> */}
                                                        {/* <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                    <li><div className="dropdown-item" onClick={() => self.editAsset(asset)}><span className="material-symbols-outlined"> edit </span>Edit</div></li>
                                                                    <li><div className="dropdown-item" ><span className="material-symbols-outlined"> share </span>Share</div></li>
                                                                    <li><div className="dropdown-item" onClick={(e) => self.createPopup(e, asset)}> <span className="material-icons">playlist_add</span>Add to playlist</div></li>
                                                                </ul> */}

                                                        {/* {channel && channel.status == "Active" ?
                                                            <a className="publish-btn selected" onClick={e => self.unPublishClick(e, channel)}>
                                                                <span className="material-symbols-outlined"> cloud_upload </span><span className="unpubtxt">Unpublished</span>
                                                            </a> :
                                                            <a className="publish-btn selected" onClick={e => self.publishClick(e, channel)}>
                                                                <span className="material-symbols-outlined"> cloud_upload </span><span className="pubtxt"></span>
                                                            </a>
                                                        } */}
                                                        {/* </div> */}
                                                        <div className="channel-footer">
                                                            <h5>{asset.assetname}</h5>

                                                        </div>
                                                    </div>
                                                </div>)
                                        })}



                                    </div> :

                                    (isRecordingPlay === false) ? (
                                        <div className="warning-wrapper empty-result">
                                            <img src="https://envoi-common-resources.imgix.net/screening/images/common-images/no-data-found.jpg" className='no-data-found' />
                                            <div className="error-text">
                                                <h2 className='text-center'>Sorry !</h2>
                                                <p>There are no assets available</p>
                                            </div>

                                        </div>
                                    ) : null
                            }
                            {dummyDivLoader && assetsData != "" && <div className='enchannel_wrapper'>
                                <div className="row">
                                    <div className="col-sm-6 col-md-3">
                                        <div className="movie--isloading">
                                            <div className="loading-image"></div>
                                            <div className="loading-content">
                                                <div className="loading-text-container">
                                                    <div className="loading-main-text"></div>
                                                </div>
                                                <div className="loading-btn"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="movie--isloading">
                                            <div className="loading-image"></div>
                                            <div className="loading-content">
                                                <div className="loading-text-container">
                                                    <div className="loading-main-text"></div>
                                                </div>
                                                <div className="loading-btn"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="movie--isloading">
                                            <div className="loading-image"></div>
                                            <div className="loading-content">
                                                <div className="loading-text-container">
                                                    <div className="loading-main-text"></div>
                                                </div>
                                                <div className="loading-btn"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="movie--isloading">
                                            <div className="loading-image"></div>
                                            <div className="loading-content">
                                                <div className="loading-text-container">
                                                    <div className="loading-main-text"></div>
                                                </div>
                                                <div className="loading-btn"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="movie--isloading">
                                            <div className="loading-image"></div>
                                            <div className="loading-content">
                                                <div className="loading-text-container">
                                                    <div className="loading-main-text"></div>
                                                </div>
                                                <div className="loading-btn"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="movie--isloading">
                                            <div className="loading-image"></div>
                                            <div className="loading-content">
                                                <div className="loading-text-container">
                                                    <div className="loading-main-text"></div>
                                                </div>
                                                <div className="loading-btn"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="movie--isloading">
                                            <div className="loading-image"></div>
                                            <div className="loading-content">
                                                <div className="loading-text-container">
                                                    <div className="loading-main-text"></div>
                                                </div>
                                                <div className="loading-btn"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="movie--isloading">
                                            <div className="loading-image"></div>
                                            <div className="loading-content">
                                                <div className="loading-text-container">
                                                    <div className="loading-main-text"></div>
                                                </div>
                                                <div className="loading-btn"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="movie--isloading">
                                            <div className="loading-image"></div>
                                            <div className="loading-content">
                                                <div className="loading-text-container">
                                                    <div className="loading-main-text"></div>
                                                </div>
                                                <div className="loading-btn"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="movie--isloading">
                                            <div className="loading-image"></div>
                                            <div className="loading-content">
                                                <div className="loading-text-container">
                                                    <div className="loading-main-text"></div>
                                                </div>
                                                <div className="loading-btn"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="movie--isloading">
                                            <div className="loading-image"></div>
                                            <div className="loading-content">
                                                <div className="loading-text-container">
                                                    <div className="loading-main-text"></div>
                                                </div>
                                                <div className="loading-btn"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-3">
                                        <div className="movie--isloading">
                                            <div className="loading-image"></div>
                                            <div className="loading-content">
                                                <div className="loading-text-container">
                                                    <div className="loading-main-text"></div>
                                                </div>
                                                <div className="loading-btn"></div>
                                            </div>
                                        </div>
                                    </div>

                                </div></div>}
                            {this.state.playbackUrlsData && <VideoPlayer
                                videoStream={this.state.playbackURL || ''}
                                eventData={playbackUrlsData}
                                playerType="bitmovin"
                                item={this.item || {}}
                                title={this.title || ''}
                                closePopup={this.closePopup}
                            // playerTimeChanged={this.playerTimeChanged}
                            />}

                        </div>}

                        {noOfAssets < assetTotal && self.state.loadbtn == 'Loading...' && <div className="text-center clearfix"><div onClick={() => this.loadMore(undefined, false)} className="btn_load" type="button" id="myButton" value=" ">{loadbtn}</div></div>}
                        {/* {this.state.showMessage &&
                            <AlertPopup message="Sorry, this video is not available for viewing yet." closePopup={this.okClick} title='NOT AVAILABLE' imagePath="images/states_of_applications/warning_gray.png" showClose={true} />}
                        {playListPopup ?
                            <PlayListPopup playListPopup={playListPopup} asset={asset} closeModal={e => this.closeModal(e)} toggleChange={e => this.toggleChange(e)} />
                            : null} */}
                    </React.Fragment>
                    {/* <Footer /> */}
                    {/* <footer className="footer "><div className="container-fluid">
                    <ul className='footer_nav'>
                        <li><a href="#" className='active'>Live Channels</a></li>
                        <li><a href="#">On Demand</a></li>
                    </ul>
                    <p className="footer-para">  © 2022 Envoi Live. All rights reserved. </p></div></footer> */}
                    <Footer/>
                </div>
            </React.Fragment>
        )

    }
}
const mapState = ({ assets, playbackUrls, loading }) => ({ assets, playbackUrls, loading });
const mapDispatch = { getAssets, getPlaybackUrls };
export default withRouter(connect(mapState, mapDispatch)(Movies));