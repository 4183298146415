import React, { Component } from 'react';
import Header from './Header';
import Footer from './Footer';

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Title: window.site.config.siteTitle,
        };
    }
    componentDidMount() {
        let self = this
    }
    render() {
        let self = this;
        const { Title } = this.state;
        let siteConfig = window.site.config;
        let imagePrefix = siteConfig.imagePrefix;
        let imageSuffix = siteConfig.imageSuffix;
        let status = localStorage.getItem("status")
        status = status && status.toLocaleLowerCase();
        return (
            <div className="content_page">
                <Header status isTerms={true} />
                <div class="page_content"><div class="content_wrp"><div class="row"><div class="col-md-12 col-sm-12 col-xs-12"><h1 class="h1">About</h1><h3>About {Title}</h3><p>{Title} is a one-stop video-on-demand and live-stream content distribution company based in Inglewood, California. In addition to streaming original content,{Title} offers full service publicity, marketing, promotions, and eCommerce services to enable content creators and influencers to reach a global audience. Available across a myriad of digital platforms and devices via the {Title} app and website.{Title} is home to high quality, entertaining, exclusive pay-per-view titles that can only be found on the {Title} app or website.</p><h3>What is {Title}?</h3><p>{Title} is the one-stop distribution company for content creators. Support your favorite creators by purchasing directly from the content owners via pay-per-view. Watch at any time, right from your phone or mobile device, desktop browser, smart TV, gaming console, or streaming device.</p></div></div></div></div>
                <Footer />
            </div>
        )
    }
}

export default About;