import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import NavLink from '../NavLink';
import SignIn from '../authentication/SignIn';
import SignUp from '../authentication/SignUp';
import Header from '../authentication/Header';
import Footer from '../authentication/Footer';
import { useHistory } from "react-router-dom";
import { manageSchedules, manageScheduling } from '../../redux/reducers/reducer';
import _ from 'lodash';
import "./player.css";
import ChannelPlayer from './channelPlayer';
import { Timeline, ProgramItem } from "../scheduler";
import moment from 'moment';
import Modal from 'react-modal';
//import { ProgramItem } from "../scheduler";
import { theme } from "../scheduler/theme";
import {
    Epg, Layout, useEpg, ChannelBox, ChannelLogo, Channel, ProgramBox,
    ProgramContent,
    ProgramFlex,
    ProgramStack,
    ProgramTitle,
    ProgramText,
    ProgramImage,
    useProgram
} from "planby";
//import { useApp } from "./useApp";
import axios from "axios";

let siteSettings = window.site;
let appSettings = window.app
let siteConfig = (siteSettings && siteSettings.config) || (appSettings && appSettings.defaultSiteSettings && appSettings.defaultSiteSettings.config);
let imagePrefix = siteConfig.commonResourcesUrl + siteConfig.appResourcesPath;
let defaultLandscape = siteConfig && siteConfig.defaultLandscape;


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    }
};
let isPaused;
let player;
let playerStream = 'hls';
var my_script = new_script('https://envoi-common-resources.imgix.net/screening/js/bitmovinplayer.js');
function new_script(src) {
    return new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        script.src = src;
        script.addEventListener('load', function () {
            resolve();
        });
        script.addEventListener('error', function (e) {
            reject(e);
        });
        document.body.appendChild(script);
    })
};

const LiveChannels = (props) => {

    let currentDateMMDDYYY = new Date()
    var tempoMonth = (currentDateMMDDYYY.getMonth() + 1);
    var tempoDate = (currentDateMMDDYYY.getDate());
    if (tempoMonth < 10) tempoMonth = '0' + tempoMonth;
    if (tempoDate < 10) tempoDate = '0' + tempoDate;

    let mmddyyyyFormat =  tempoMonth + "/" + tempoDate + "/" + currentDateMMDDYYY.getFullYear() 
    const [datevalue, setDateValue] = React.useState(moment().format('YYYY-MM-DD'));
    const [channelIndex, setChannelIndex] = React.useState(1);
    const [channelCount, setChannelResult] = React.useState(0);
    const [channels, setChannels] = React.useState([]);
    const [epg, setEpg] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [scheduleResponse, setScheduleResponse] = React.useState([]);
    const [channelDataResponse, setChannelDataResponse] = React.useState([]);
    const [nextDayResponse, setNextDayResponse] = React.useState([]);
    const [channelAllDataResponse, setChannelAllDataResponse] = React.useState([]);
    const [selectedDateResponse, setSelectedDateResponse] = React.useState(mmddyyyyFormat);
    const [selectedEPGDate, setSelectedEPGDate] = React.useState(null);
    const [bitmovinloaded, setbitmovinloaded] = React.useState(false);
    const history = useHistory();
    const [channelPlayer, setChannelPlayer] = useState(false);
    const [timelinePopup, setTimelinePopup] = useState(false);
    const [programData, setProgramData] = React.useState([]);
    const [currentTime, setCurrentTime] = React.useState([]);
    const [VerticalMode, setVerticalMode] = React.useState(false);
    const [state, setState] = useState({
        userInfo: '',
        isDisableLoginButtons: false,
        loading: false,
        selectedDate: null,
        //channelPlayer:false
    });




    // useEffect(() => {
    //     console.log("channels ", channels)
    //     //channelsData = React.useMemo(() => channels, [channels]);
    // }, [channels]);

    //   console.log("chanenl", channelsData)

    // console.log("epg", selectedEPGDate)
    const now = new Date();


    useEffect(() => {
        getContent(null, false);
    }, []);

    // useEffect(() => {
    //     console.log("setchannels ", channels.length, channelIndex)
        
    // }, [channels]);

    // useEffect(() => {
    //     console.log("channelIndex ", channelCount, channels.length, channelIndex)
        
    //     // console.log("channelCount ", channelCount, channels.length, channelIndex)
    //     if (channels.length > 0 && channelIndex > 0 && channels.length == channelIndex) {
    //         setChannelIndex(0)
    //          console.log("channels ", channels.length, channelCount + 1)
    //         setTimeout(function () {
    //             scheduleData()
    //         }, 1000);
    //     }
    // }, [channelCount]);

    
    const setCResult = () => {
         console.log("setCResult ", schResponse)
        // console.log("setCResult ch ", chResponse.length, chResponse)
        // console.log("chCount ch ", chCount)
        if (chResponse.length == chCount) {

            setTimeout(function () {
                scheduleData(schResponse)
            }, 1000);
        }
    }

    const scheduleData = (schResponse) => {
           console.log("schResponseschedule ", schResponse)
        let epgRes = [...schResponse]
        setEpg(epgRes)
        chCount = 0
        // setChannelIndex(0)
    }

    useEffect(() => {
        setDate();
    }, []);

    const getContent = (scheduleDate, dateFormat) => {
        let siteSettings = window.site;
        let appSettings = window.app
        let siteConfig = (siteSettings && siteSettings.config) || (appSettings && appSettings.defaultSiteSettings && appSettings.defaultSiteSettings.config);
        let defaultLandscape = siteConfig && siteConfig.defaultLandscape;
        let envoiScreeningApiBaseUrl = siteSettings && siteSettings.screeningApiBaseUrl;
        let envoiSubmissionApiBaseUrl = siteSettings && siteSettings.submissionApiBaseUrl;
        let serviceProvider = "envoiPlaylistByUsers"
        let url = envoiSubmissionApiBaseUrl + "/channel?appname=" + window.app.appName + '&env=' + window.app.appEnvName + '&serviceProvider=' + serviceProvider + '&status=Active'
        //  setChannelIndex(0)
        axios.post(url, {})
            .then(function (response) {
                if (response.data.result && response.data.result.data && response.data.result.data.length > 0) {
                    let resultChannels = response.data.result.data

                    let channelResponse = []
                    resultChannels.forEach(function (item, index) {

                        if (item.channelcode) {

                            setTimeout(function () {

                                getScheduleContent(item, index+1, scheduleDate, dateFormat)
                            }, 4000);
                        }
                        let obj = {
                            "uuid": item.channelcode,
                            "title": item.channelname,
                            "logo": item && item.landscape_logo_image ? item.landscape_logo_image : defaultLandscape,
                            "item": item
                        }
                        channelResponse.push(obj);
                       
                    });


                    //console.log("cha", channelResponse)
                    chResponse = channelResponse;
                    setChannels(channelResponse)
                    //getScheduleContent(resultChannels)
                    // setChannelAllDataResponse(resultChannels)
                    //setChannelDataResponse(resultChannels[0])
                    //getScheduleContent(resultChannels[0])
                    // if (channelPlayer === false) {
                    //     setChannelPlayer(true);
                    // }

                    if (resultChannels && resultChannels[0] && resultChannels[0].mediatailor) {
                        let hlsPlayer = resultChannels[0].mediatailor && resultChannels[0].mediatailor.Outputs && resultChannels[0].mediatailor.Outputs[0] && resultChannels[0].mediatailor.Outputs[0].PlaybackUrl;
                        setTimeout(function () {
                            getPlayer(hlsPlayer);
                        }, 1000);

                    }

                }
            });
    }

    const getScheduleContent = (item, index, scheduleDate, dateFormat) => {
        let splitDate = selectedDateResponse.split("/")
        if(scheduleDate && dateFormat == false){
            splitDate = scheduleDate.split("/")
        }
        splitDate = splitDate[2] + "-" + splitDate[0] + "-" + splitDate[1];
        if(scheduleDate && dateFormat == true){
            splitDate = scheduleDate
        }
        // splitDate = selectedDateResponse
        let siteSettings = window.site;

        let appSettings = window.app
        //  console.log('reducer.js', { appSettings, siteSettings });

        let siteConfig = (siteSettings && siteSettings.config) || (appSettings && appSettings.defaultSiteSettings && appSettings.defaultSiteSettings.config);
        let envoiScreeningApiBaseUrl = siteSettings && siteSettings.screeningApiBaseUrl;
        let envoiSubmissionApiBaseUrl = siteSettings && siteSettings.submissionApiBaseUrl;
        let defaultLandscape = siteConfig && siteConfig.defaultLandscape;
        let scheduleId = item.channelcode || "";
        let activity = "fetch";

        //now.toISOString().split('T')
        //  console.log('index', index)

        let epgnextDate = splitDate.split("-")
        
        
        let epgDate = epgnextDate[0] + "/" + epgnextDate[1] + "/" + epgnextDate[2];
        // console.log('channels', channels)
        // console.log('epgDate', epgDate)
        setSelectedEPGDate(epgDate)


        //setSelectedDateResponse(splitDate[0])
        let payload = { activity: activity, calenderId: scheduleId, startDate: splitDate }
        let url = envoiSubmissionApiBaseUrl + "/schedule?appname=" + window.app.appName + '&env=' + window.app.appEnvName
        // scheduleId ? (url += `&calenderId=${scheduleId}`) : null;
        axios.post(url, payload)
            .then(function (response) {

                if (response.data.result && response.data.result.length > 0) {
                    let resultEpg = response.data.result
                    let scheduleResponse = []
                    let i = 0
                    // console.log("scheduleResponse1234", scheduleResponse.length , resultEpg.length)
                    resultEpg.forEach(function (item) {
                        if (item.start && item.title) {
                              console.log("item ", item.start,)
                            //  console.log("moment('2019-11-03T05:00:00.000Z').utc().format('MM/DD/YYYY')",console.log(moment(item.start).utc().format('MM/DD/YYYY')))
                            let obj = {
                                "channelUuid": item.extendedProps.calendarId,
                                "id": item._id + "_" + item.extendedProps.calendarId + "_"+i,
                                "title": item.title,
                                "since": item.start,
                                "till": item.end,
                                "description": item.title,
                                "image": item && item.landscape_logo_image ? item.landscape_logo_image : item && item.extendedProps && item.extendedProps.imageurl ? item.extendedProps.imageurl : defaultLandscape,
                            }
                           // console.log(" obj.start ", obj.since,)
                            //setEpg(obj)
                            scheduleResponse.push(obj)
                        }
                        i = i + 1

                    });
                    
                    if (resultEpg.length == i) {
                        // console.log("resultEpg.length", resultEpg.length, i, index)
                        //setChannelResult(channelCount => channelCount + index)
                         console.log("scheduleResponse ",scheduleResponse)
                        schResponse = scheduleResponse
                        chCount = chCount + 1
                        setCResult()
                        
                        //setChannelIndex(channelIndex => channelIndex + 1)
                         //setChannelIndex(channelIndex + 1)

                        // setChannelIndex(channelIndex => channelIndex + 1, () => { // The callback function
                        //     // All actions here will be run exactly AFTER the update of the count state
                        //     setCResult()
                        //   })

                        // setTimeout(function () {
                        //     setCResult()
                        // }, 4000);
                    }

                } else {
                    chCount = chCount + 1
                    // schResponse = []
                    setCResult()

                    // setChannelResult(channelCount => channelCount + index)
                    
                    //setChannelIndex(channelIndex => channelIndex + 1)
                    // setTimeout(function () {
                    //     setCResult()
                    // }, 4000);
                    //console.log("channelCounddd", channelCount)
                    //let countresult = channelCount+1
                    // console.log("countresultddd", countresult)
                    // setChannelResult(channelCount+1)
                    // setScheduleResponse(response.data.result)
                    // setScheduleResponse(response.data.result)
                }

            });
    }
    //  
    const getPlayer = (hlsPlayer) => {
        let self = this;

        let playSource = {};
        if (hlsPlayer && hlsPlayer != "") {
            playerStream = hlsPlayer.includes('.m3u8') ? 'hls' : 'progressive';
            playSource[playerStream] = hlsPlayer
            setTimeout(function () {
                bitmovinplayer(playSource)
            }, 2000);
        }


    }
    const bitmovinplayer = (playSource) => {
        //  let markersArr = await this.markerPoints(this.props.markersData)
        let self = this;
        let isPaused;
        let siteSettings = window.site;
        let appSettings = window.app
        let siteConfig = (siteSettings && siteSettings.config) || (appSettings && appSettings.defaultSiteSettings && appSettings.defaultSiteSettings.config);
        let bitmovinSettings = (siteConfig.player || {}).bitmovin || {};
        let bitAnalyticsKey = bitmovinSettings.analyticsKey;
        let playerKey = bitmovinSettings.playerKey;
        var config = {
            "key": playerKey
        };
        var configWithAnalytics = Object.assign({}, config, {
            analytics: {
                key: bitAnalyticsKey,
            },
            playback: {
                autoplay: true,
                controls: true,
            },
            style: {
                width: "100%",
                aspectratio: "16:9",
                // controls: true,

            }
        });
        var container = document.getElementById("playerMovie");

        if (bitmovin) {


            player = new bitmovin.player.Player(container, configWithAnalytics);
            // player.load(playSource).then(
            //     function(player) {
            //       console.log('Successfully created Bitmovin Player instance');
            //     },
            //     function(reason) {
            //       console.log('Error while creating Bitmovin Player instance');
            //     },
            //   );
            // if (player) {
            //     let seekTime = player.getCurrentTime() || 0;
            //     player.load(source)
            //         .then(() => {
            //             player.seek(seekTime)
            //         });
            // }

            // let seekTime = player.currentTime() || 0;
            // console.log('seekTime',seekTime)
            player.load(playSource).then(
                function (value) {
                    //myUiManager = new bitmovin.playerui.UIFactory.buildDefaultUI(player);
                    player.on(bitmovin.player.PlayerEvent.Play, function () {
                        isPaused = false;
                    });

                    player.on(bitmovin.player.PlayerEvent.Paused, function () {
                        isPaused = true;
                    });
                    player.on(bitmovin.player.PlayerEvent.Error, function () {
                        self.setState({ notPublished: true })
                    });
                    // player.on(bitmovin.player.PlayerEvent.TimeChanged, (event) => {
                    //     // self.setState(event.time);
                    //     console.log('seekTime', event)
                    //     setCurrentTime(event.time);
                    //     console.log('currentTime', currentTime)
                    //     setTimeout(function () {
                    //         durationTime()
                    //     //console.log('seekTime12345', event)
                    //     }, 5000);

                    // });
                    //player.seek(seekTime)

                }

            );
        }

    }
    // const durationTime = () => {
    //     let startTime = currentTime ? currentTime.format('HH:mm:ss') : null;
    //     console.log('startTime', startTime)
    //     let durationStart = moment.duration(startTime)
    //     durationStart = durationStart._milliseconds / 1000;
    //     console.log('durationStart', durationStart)
    // };
    const setDate = () => {
        const date = new Date();
        let selectedDate = date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate()
        setSelectedEPGDate(selectedDate)
    };
    const setRes = (scheduleResponse) => {
        let data = epg
        //console.log("epg", data)
        // console.log("scheduleResponse", scheduleResponse)
        let epgRes = [...data, ...scheduleResponse]
        setEpg(epgRes)
    }
    // console.log("epg12334556 ", epg)
    const handleClick = (e) => {
        //history.push("/onDemand")
        e.preventDefault();
        history.push('/onDemand');
    }
    const isLoaderUpdated = (status) => {
        //this.setState({ loading: status })
        setState({ ...state, loading: status });
    }

    const channelClick = (channel) => {
        //this.setState({ loading: status })
        //setState({ ...state, loading: status });
        // console.log('channel', channel.item)
        // console.log('channel123456', channel)

        setChannelDataResponse(channel && channel.item)
        //localStorage.setItem('channelData', channel && channel.item);
        //  console.log('ChannelDataResponse123',channelDataResponse)

        if (channel && channel.item && channel.item.mediatailor) {
            let resultChannels = channel.item.mediatailor
            let hlsPlayer = resultChannels && resultChannels.Outputs && resultChannels.Outputs[0] && resultChannels.Outputs[0].PlaybackUrl
            localStorage.setItem('channelData', hlsPlayer);
            localStorage.setItem('channelname', channel.item.channelname);
            
            let channelUrl = `/player?appname=${window.app.appName}&channelCode=${channel.item && channel.item.channelcode}`
            window.location = channelUrl
            // if (player) {

            //     let playSource = {};
            //     if (hlsPlayer && hlsPlayer != "") {
            //         let playerStream = hlsPlayer.includes('.m3u8') ? 'hls' : 'progressive';
            //         playSource[playerStream] = hlsPlayer
            //     }

            //     player.load(playSource).then(
            //         function (value) {
            //         }
            //     )


            // }
            // getPlayer(hlsPlayer);
        }
        // if (channel && channel.item && channel.item.channelcode) {
        //     // setTimeout(function () {
        //     //     getScheduleContent(channel)
        //     // }, 3000);
        //     getScheduleContent(channel.item)
        // }


    }
    const previousClick = () => {
        let MyDateString = "";
        let currentdate = new Date()


        const currentDayInMilli = new Date(selectedDateResponse).getTime()
        const oneDay = 1000 * 60 * 60 * 24
        const previousDayInMilli = currentDayInMilli - oneDay
        const previousDate = new Date(previousDayInMilli)
        //previousDate.setDate(previousDate.getDate());
        if (new Date(previousDate).toDateString() <= currentdate.toDateString()) {
            var tempoMonth = (previousDate.getMonth() + 1);
            var tempoDate = (previousDate.getDate());
            if (tempoMonth < 10) tempoMonth = '0' + tempoMonth;
            if (tempoDate < 10) tempoDate = '0' + tempoDate;
            MyDateString = previousDate.getFullYear() + "-" + tempoMonth + "-" + tempoDate;
            let epgDate = previousDate.getFullYear() + "/" + tempoMonth + "/" + tempoDate;
            // let previ>ousDayDate = previousDate.getFullYear() + "-" + (previousDate.getMonth() + 1) + "-" + previousDate.getDate()
           
            let mmddyyyyFormat =  tempoMonth + "/" + tempoDate + "/" + previousDate.getFullYear() 
            setSelectedDateResponse(mmddyyyyFormat)
            setSelectedEPGDate(epgDate)
            // nextAndPrevApiClick(MyDateString)
            getContent(mmddyyyyFormat, false)
        }


    }

    const nextClick = () => {
        var MyDateString = '';
        const currentDayInMilli = new Date(selectedDateResponse).getTime()
        //console.log('ScheduleResponse', channelAllDataResponse)
        const oneDay = 1000 * 60 * 60 * 24
        const nextDayInMilli = currentDayInMilli + oneDay
        //console.log('nextDayInMilli', nextDayInMilli)
        const nextDate = new Date(nextDayInMilli)
        //console.log('nextDate', nextDate)
        nextDate.setDate(nextDate.getDate());
        var tempoMonth = (nextDate.getMonth() + 1);
        var tempoDate = (nextDate.getDate());
        if (tempoMonth < 10) tempoMonth = '0' + tempoMonth;
        if (tempoDate < 10) tempoDate = '0' + tempoDate;
        MyDateString = nextDate.getFullYear() + "-" + tempoMonth + "-" + tempoDate;
        let epgnextDate = nextDate.getFullYear() + "/" + tempoMonth + "/" + tempoDate;
        //console.log('MyDateString', MyDateString)
        let mmddyyyyFormat =  tempoMonth + "/" + tempoDate + "/" + nextDate.getFullYear() 
        setSelectedDateResponse(mmddyyyyFormat)
        setSelectedEPGDate(epgnextDate)
        // nextAndPrevApiClick(MyDateString)
        getContent(mmddyyyyFormat, false)
    }


    const programClick = (data) => {
        setTimelinePopup(true);
        setProgramData(data)
    }
    const closeClick = () => {
        setTimelinePopup(false);
    }

    const ChannelItem = ({ channel }) => {
        const { position, logo } = channel;
        return (
            <ChannelBox {...position}>
                <ChannelLogo
                    onClick={() => channelClick(channel)}
                    src={logo}
                    alt="Logo"
                    style={{ "cursor": "pointer" }}
                />
            </ChannelBox>
        );
    };
    const ProgramItem = ({ program, ...rest }) => {
        const {
            styles,
            formatTime,
            set12HoursTimeFormat,
            isLive,
            isMinWidth
        } = useProgram({
            program,
            ...rest
        });

        const { data } = program;
        const { image, title, since, till } = data;
        // let startDate = moment(since).format('MM-DD-YYYY HH:mm:ss');
        const sinceTime = formatTime(since, set12HoursTimeFormat()).toLowerCase();
        const tillTime = formatTime(till, set12HoursTimeFormat()).toLowerCase();

        return (
            <ProgramBox width={styles.width} style={styles.position} onClick={() => programClick(data)}>
                <ProgramContent width={styles.width} isLive={isLive}>
                    <ProgramFlex>
                        {isLive && isMinWidth && <ProgramImage src={image} alt="Preview" />}
                        <ProgramStack>
                            <ProgramTitle>{title}</ProgramTitle>
                            <ProgramText>
                                {sinceTime} - {tillTime}
                            </ProgramText>
                        </ProgramStack>
                    </ProgramFlex>
                </ProgramContent>
            </ProgramBox>
        );
    };
    const channelsData = React.useMemo(() => channels, [channels]);
    const epgData = React.useMemo(() => epg, [epg]);
    // console.log("selectedDateResponse ", selectedDateResponse && new Date(selectedDateResponse).toISOString())
    // console.log("selectedDateResponse end ", selectedDateResponse && new Date(selectedDateResponse.setDate(selectedDateResponse.getDate() + 1)).toISOString())
    // console.log("now.toISOString() ", selectedEPGDate, now.toISOString())
    const {
        getEpgProps,
        getLayoutProps,
    } = useEpg({
        epg: epgData,
        channels: channelsData,
        startDate: selectedEPGDate,
        //  endDate: selectedDateResponse ? new Date(selectedDateResponse.setDate(selectedDateResponse.getDate() + 1)).toISOString() : now.toISOString(),
        // startDate: '2023/06/13',
        //  endDate: '2023-06-13T00:00:00.000Z',
        //  startDate: now.toISOString(),
        dayWidth: 7200,
        sidebarWidth: 100,
        itemHeight: 80,
        isSidebar: true,
        isTimeline: true,
        isLine: true,
        isBaseTimeFormat: true,
        isVerticalMode: VerticalMode,
        theme
    });
    const onVerticalClick = (e) => {
        var self = this;
        //let formData = self.state.formData
        let checked = e.target.checked;
        setVerticalMode(checked)

    }

    const onChangeDate = e => {
        e.preventDefault()
        const newDate = moment(new Date(e.target.value)).format('YYYY-MM-DD');
        setDateValue(newDate);
        getContent(newDate, true)
       // console.log(newDate); //value picked from date picker
        setScheduleResponse([])
        // setChannelIndex(0)
      };

   //console.log('schResponse', schResponse)
   // console.log('chResponse', chResponse.length)
     //   console.log("epgData", epgData)
    // console.log('getEpgProps',getEpgProps)
   //   console.log("selectedDateResponse render ", selectedDateResponse )
    return (

        <>

            <div>
                <Header />
                <div className='schedule_block'>

                    <div className='schedule_section'>
                        <div className='schedule_wrp'>
                            {/* <div className='schedule_player'>

                                <div className='player_block'>
                                    {channelDataResponse && channelDataResponse.mediatailor &&
                                        <div className='player' id="playerMovie" style={{ "cursor": "pointer" }}>
                                        </div>}
                                    {channelDataResponse && !channelDataResponse.mediatailor &&
                                        <div className="movie--isloading player_loading">
                                            <div className="loading-image"></div>
                                            <div className="loading-content">
                                                <div className="loading-text-container">
                                                    <div className="loading-main-text"></div>
                                                    <div className="loading-sub-text"></div>
                                                </div>
                                                <div className="loading-btn"></div>
                                            </div>
                                        </div>}

                                    <div className='player_content'>
                                        <div className='chnl_title'>
                                            <h3>{channelDataResponse && channelDataResponse.channelname}</h3>
                                        </div>
                                        <div className='chnl_logo'>
                                            <img src={channelDataResponse && channelDataResponse.landscape_logo_image ? channelDataResponse.landscape_logo_image : defaultLandscape} />
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            <div className='schedule_content'>
                                <div className='sdl_buttons'>
                                    <input type="date" className='lc_input' style={{"width": "120px"}} onChange={(e)=> onChangeDate(e)} value={datevalue} />
                                    {/* <h2>July 2023</h2> */}

                                    <div className='right_sn' style={{"display":"none"}}>
                                        {/* <div className='switch_btn'>
                                            <span>Vertical Mode</span>
                                            <label className="switch"> <input type="checkbox" onClick={e => onVerticalClick(e)} /> <span className="slider"></span> </label>
                                        </div> */}
                                        <div className='arrows'>
                                            <button href='#' className='icon' onClick={e => previousClick(e)} style={{ "cursor": "pointer" }} title="previous"><span className="material-icons"> chevron_left </span></button>
                                            {/* } */}
                                            <button href='#' className='icon' onClick={e => nextClick(e)} style={{ "cursor": "pointer" }} title="next"><span className="material-icons"> chevron_right </span></button>
                                        </div>
                                    </div>
                                </div>
                                {/* {scheduleDataResponse && scheduleDataResponse.length > 0 ? */}
                                <Epg {...getEpgProps()}>

                                    <Layout
                                        {...getLayoutProps()}
                                        renderTimeline={(props) => <Timeline {...props} />}
                                        renderChannel={({ channel }) => (
                                            <ChannelItem key={channel.uuid} channel={channel} />
                                        )}

                                        renderProgram={({ program, ...rest }) => (
                                            <ProgramItem key={program.data.id} program={program} {...rest} />
                                        )}


                                    />
                                </Epg>

                            </div>
                        </div>


                    </div>
                    {!timelinePopup ?
                        <div></div>
                        :
                        <Modal
                            isOpen={timelinePopup}
                            style={customStyles}
                            ariaHideApp={false}

                            shouldCloseOnOverlayClick={true}
                            contentLabel="Example Modal"
                            className="custom_modal u-conf-popup pd_popup pgm_detail">
                            <div className='popup add-channel'>
                                <div className="popup_hed"><h2>Program Details</h2> <a href="#" onClick={e => closeClick(e)} className='close material-symbols-outlined'>close </a></div>
                                <div className='popup_content'>

                                    <div className='pgm_img'> <img src={programData && programData.image ? programData.image : programData && programData.imageurl ? programData.imageurl : defaultLandscape} className='img' /></div>


                                    <div className="column">
                                        <div className='leftd'> <label className="dsp_flx">Title :</label></div>
                                        <div className='rightd'> <p>{programData && programData.title || 'N/A'}</p></div>
                                    </div>
                                    <div className="column">
                                        <div className='leftd'> <label className="dsp_flx">Description : </label></div>
                                        <div className='rightd'> <p>{programData && programData.description || 'N/A'}</p></div>
                                    </div>
                                    {/* <div className="column">
                                        <div className='leftd'> <label className="dsp_flx">Start Date :</label></div>
                                        <div className='rightd'> <p>{programData && programData.since ? moment(programData.since).format('MM-DD-YYYY HH:mma') : 'N/A'}</p></div>
                                    </div>
                                    <div className="column">
                                        <div className='leftd'> <label className="dsp_flx">End Date :</label></div>
                                        <div className='rightd'> <p>{programData && programData.till ? moment(programData.till).format('MM-DD-YYYY HH:mma') : 'N/A'}</p></div>
                                    </div>
                                    <div className="column">
                                        <div className='leftd'> <label className="dsp_flx">ChannelId :</label></div>
                                        <div className='rightd'> <p>{programData && programData.channelUuid || 'N/A'}</p></div>
                                    </div> */}

                                </div>

                            </div>
                        </Modal>}
                </div>
                <Footer />
                <div className="signup_btns" style={{ display: "none" }}>
                    <SignUp isLoaderUpdated={isLoaderUpdated} labelText="SIGN UP" cssClass="green_filled" hiddenDiv="jp_signinWrapper" removeHidden="authlogindiv" buttonHide={false} />
                    <SignIn isLoaderUpdated={isLoaderUpdated} labelText="SIGN IN" cssClass="stroke" hiddenDiv="jp_signinWrapper" removeHidden="authlogindiv" buttonHide={false} />
                </div>


            </div>
        </>
    );

};

export default (LiveChannels);