/***
**Module Name:  subscriptionPayment
**File Name :  subscriptionPayment.js
**Project :      CALENDOW
**Copyright(c) : X Platform Consulting.
**Organization : Peafowl Inc
**author :  Hari
**author :  Rajesh
**license :
**version :  1.0.0
**Created on :
**Last modified on:
**Description : subscriptionPayment page functionality and html code
*/
//export default App;
import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import axios from "axios";
import NavLink from '../NavLink';
import ReactGA from 'react-ga';
import { v4 as uuidv4 } from 'uuid';
import cryptoJs from 'crypto-js';
import { assetDetails, priceFormat, msToTime, getPricing, } from '../../redux/reducers/reducer';
import windowSize from 'react-window-size';
import braintree from "braintree-web";
var urlParams = new URLSearchParams(window.location.search);
var partnercode;
var customer_address = {};
var addressVisible = localStorage.getItem("adv");
let card = false;
let cashAppPay = false;
let squareApplePay = false;
let squareGooglePay = false;
let payments;
let siteSettings = window.site;
let appSettings = window.app
let siteConfig = (siteSettings && siteSettings.config) || (appSettings && appSettings.defaultSiteSettings && appSettings.defaultSiteSettings.config);
let appStorage = appSettings.storage;
let token = appStorage.getItem('token');
let appId;
let locationId;
appId = (((siteConfig || {}).paymentGateway || {}).squareUp || {}).appId || "";
locationId = (((siteConfig || {}).paymentGateway || {}).squareUp || {}).locationId || "";

if (appId && locationId) {
    let splitAppId = appId.split("-");
    let url = "web.squarecdn.com/v1/square.js"
    if (splitAppId[0] == "sandbox") {
        url = "sandbox." + url
    }
    affixScriptToHead("https://" + url, function (data) {
    }).catch(error => loadError(error));
}
// let clientToken;
// let braintreeSettings = siteConfig.paymentGateway || {};
// if (braintreeSettings.enable === true) {
//     clientToken = (braintreeSettings.braintree || {}).gatewayToken; //Braintree Tree Token
// }
class subscriptionPayment extends Component {
    constructor(props) {
        super(props);

        if (urlParams.has('partnercode') == true) {
            partnercode = urlParams.get('partnercode');
        }

        if (siteConfig && siteConfig.google && siteConfig.google.enable) {
            ReactGA.initialize(siteConfig.google.key);
            ReactGA.pageview(window.location.pathname + window.location.search);
        }

        // if (siteConfig && siteConfig.segment && siteConfig.segment.enable) {
        //     var options = {
        //         pageName: this.constructor.displayName,
        //         "appName": appname
        //     }
        //     analytics.page(this.constructor.displayName, options);
        // }

        var isLoggedIn = false;
        if (!token) {
            window.location = '/';
        } else {
            isLoggedIn = true;
        }
        var plans = siteConfig.subscription.subscriptionPlans;
        var adr = JSON.parse(localStorage.getItem("adr"));
        var planpartner = localStorage.getItem("partnerid");
        var firstname = '';
        var lastname = '';
        var email = '';
        var phone = '';
        if (localStorage.getItem("firstname") && localStorage.getItem("firstname") != null && localStorage.getItem("firstname") != undefined) {
            firstname = localStorage.getItem("firstname")
        }
        if (localStorage.getItem("lastname") && localStorage.getItem("lastname") != null && localStorage.getItem("lastname") != undefined) {
            lastname = localStorage.getItem("lastname")
        }
        if (localStorage.getItem("email") && localStorage.getItem("email") != null && localStorage.getItem("email") != undefined) {
            email = localStorage.getItem("email")
        }
        if (localStorage.getItem("phone") && localStorage.getItem("phone") != null && localStorage.getItem("phone") != undefined) {
            phone = localStorage.getItem("phone");
        }
        const unique_id = uuidv4();
        const small_id = unique_id.slice(0, 8)
        let contentType = localStorage.getItem("contentType");
        let subPlans = plans.plans;
        let selectedAsset = subPlans && subPlans.filter(item => item.planId == contentType)
        let priceSub = selectedAsset && selectedAsset[0] && selectedAsset[0].price || 0;
        let subName = selectedAsset && selectedAsset[0] && selectedAsset[0].planId || contentType

        this.state = {
            data: [],
            loader: false,
            login: isLoggedIn,
            selected: '',
            navigationtab: '',
            isTop: true,
            partnercode: partnercode,
            planid: plans && plans.plans && plans.plans[0] && plans.plans[0].planId,
            planName: plans && plans.plans && plans.plans[0] && plans.plans[0].planName,
            squarePlanId: plans && plans[0] && plans[0].squarePlanId,
            planpartner: planpartner,
            plans: plans && plans.plans || [],
            error: '',
            errors: {},
            streetAddress: '',
            locality: '',
            extendedAddress: '',
            countryCodeAlpha2: '',
            region: '',
            postalCode: '',
            checked: false,
            adr: adr,
            customer_address: {},
            countrydefault: "",
            regiondefault: "",
            firstname: firstname,
            lastname: lastname,
            email: email,
            phone: phone,
            // modifiedavatar: imagesAssetResourcesPrefix + 'images/profiles/default/avatar1.jpg' + imageSuffix,
            addressVisible: addressVisible,
            cardholderName: firstname + ' ' + lastname,
            price: plans && plans.plans && plans.plans[0] && plans.plans[0].price || 0,
            assetSubscription: false,
            creditCard: true,
            paypal: false,
            googlePay: false,
            applePay: false,
            errMessage: null,
            isAppleandGoogleEnabled: false,
            merchantKey: ((siteConfig.paymentGateway || {}).payuIndia || {}).merchantKey || null,
            txnId: small_id,
            merchantSalt: ((siteConfig.paymentGateway || {}).payuIndia || {}).merchantSalt || null,
            hash: null,
            actionURL: ((siteConfig.paymentGateway || {}).payuIndia || {}).mode == "sandbox" ? ((siteConfig.paymentGateway || {}).payuIndia || {}).sandboxURI : ((siteConfig.paymentGateway || {}).payuIndia || {}).productionURI,
            assetprice: priceSub,
            assetname: subName,
            subPlanId: contentType

        };

        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.handletextChange = this.handletextChange.bind(this);
        this.onScroll = this.onScroll.bind(this);
        this.handlecheckboxChange = this.handlecheckboxChange.bind(this);
    }
    onScroll(isTop) {
        this.setState({ isTop });
    }
    componentDidMount() {
        let self = this;
        console.log('price', self.state.price)
        self.loadSiteConfig();
        let payload;
        let channelCode;
        let assetId = localStorage.getItem("assetId")
        if (assetId) {
            payload = { 'assetid': assetId };
        } else {
            channelCode = localStorage.getItem("channelcode");
        }
        if (assetId) {
            this.props.assetDetails(payload);
        } else {
            //self.props.channelAssets(channelCode, true)
        }
        if (token) {
            // this.props.getuser(token);
        }


        if (siteConfig && siteConfig.segment && siteConfig.segment.enable) {
            let options = {
                "pageName": this.constructor.name,
                "appName": appname,
                "path": window.location.pathname,
                "search": window.location.search,
                "referrer": window.location.href,
                "url": window.location.href,
                "UserEmail": localStorage.getItem("email"),
                "userid": localStorage.getItem("userid"),
            }
            window.analytics.page(options);
        }

        document.addEventListener('scroll', () => {
            var isTop;
            if (window.scrollY < 50) {
                isTop = true;
            } else {
                isTop = false;
            }
            if (isTop !== this.state.isTop) {
                if (this.refs.headermenu) {
                    this.onScroll(isTop);
                }
            }
        });

        document.getElementById("credit").checked = true;
    }
    componentWillReceiveProps(nextProps) {
        let self = this;
        let contentType = localStorage.getItem("contentType");
        if (nextProps.Getpartners !== self.props.Getpartners) {
            self.setState({
                artistData: nextProps.Getpartners
            })
        }
        if (nextProps.assetDetailsData !== this.props.assetDetailsData) {
            self.setState({ assetDetailsData: nextProps.assetDetailsData });
            let item = nextProps.assetDetailsData;
            let subPlanPrice = item && item.availability && item.availability.buyplans && item.availability.buyplans[contentType] && item.availability.buyplans[contentType].contentPrice && item.availability.buyplans[contentType].contentPrice.planPrice
            let availabilityBuy = item && item.availability || {};
            if (availabilityBuy && availabilityBuy.buyplans && Object.keys(availabilityBuy.buyplans).length > 0) {
                let buyPlans = Object.keys(availabilityBuy.buyplans)
                let subscriptions = buyPlans.filter(obj => obj != "allow" && obj != "buy" && obj != "gift" && obj != "rent" && obj != "plan" && obj != "download")
                self.setState({
                    subscriptions
                });
            }
            self.setState({
                availabilityBuy: availabilityBuy, subPlanPrice
            });
        }
        if (nextProps.channelAssetsProps && nextProps.channelAssetsProps !== this.props.channelAssetsProps) {
            self.setState({ assetDetailsData: nextProps.channelAssetsProps && nextProps.channelAssetsProps[0] });
            let item = nextProps.channelAssetsProps && nextProps.channelAssetsProps[0];
            let subPlanPrice = item && item.availability && item.availability.buyplans && item.availability.buyplans[contentType] && item.availability.buyplans[contentType].contentPrice && item.availability.buyplans[contentType].contentPrice.planPrice
            let availabilityBuy = item && item.availability || {};
            if (availabilityBuy && availabilityBuy.buyplans && Object.keys(availabilityBuy.buyplans).length > 0) {
                let buyPlans = Object.keys(availabilityBuy.buyplans)
                let subscriptions = buyPlans.filter(obj => obj != "allow" && obj != "buy" && obj != "gift" && obj != "rent" && obj != "plan" && obj != "download")
                self.setState({
                    subscriptions
                });
            }
            self.setState({
                availabilityBuy: availabilityBuy, subPlanPrice
            });
            if (nextProps.channelAssetsProps && nextProps.channelAssetsProps[0] && nextProps.channelAssetsProps[0].channelassets) {
                let channelArrayAssets = nextProps.channelAssetsProps[0];
                let channelAssetsArray = channelArrayAssets.channelassets;
                self.setState({
                    channelData: channelAssetsArray
                });

            }
        }

        if (nextProps.assetPayment && nextProps.assetPayment !== this.props.assetPayment) {
            if (nextProps.assetPayment.error) {
                $("#overlay").addClass("hidediv");
                self.setState({
                    errMessage: nextProps.assetPayment.error
                })
            } else if (siteConfig && siteConfig.paymentGateway && siteConfig.paymentGateway.key == "braintree") {

                browserHistory.push("/boughtsuccess")
            }
        }

        if (nextProps.userinfo !== this.props.userinfo) {
            let userData = nextProps && nextProps.userinfo;

            this.setState({ userinfo: userData })

        }
    }
    loadSiteConfig() {
        let self = this;
        let logoImage;
        let preLoader;
        let siteSettings = window.site;
        let appSettings = window.app
        let siteConfig = (siteSettings && siteSettings.config) || (appSettings && appSettings.defaultSiteSettings && appSettings.defaultSiteSettings.config);
        let imagePrefix = siteConfig.commonResourcesUrl + siteConfig.appResourcesPath;
        let imageSuffix = siteConfig.imageSuffix || "?auto=compress,format";
        let configinterval = setInterval(function () {
            if (siteConfig && siteConfig.appResourcesUrl) {
                logoImage = imagePrefix + "screening/images/headerlogo.png" + imageSuffix;
                preLoader = imagePrefix + 'screening/images/preloader.png';
                self.setState({ logoImage: logoImage, preLoader: preLoader });

                // Braintree
                let braintreeSettings = siteConfig.paymentGateway || {};
                if (braintreeSettings.enable === true) {
                    // clientToken = (braintreeSettings.braintree || {}).gatewayToken; //Braintree Tree Token
                    self.braintreeSetup()
                    document.getElementById("credit").checked = true;
                    document.getElementById("cardcc").checked = true;
                    // self.payPalMethod()
                    // self.googlePay()
                }

                // if (siteConfig && siteConfig.analytics && siteConfig.analytics.google && siteConfig.analytics.google.enable) {
                //     let googleKey = siteConfig.analytics.google.analyticsKey;
                //     let path = window.location.pathname;
                //     let search = window.location.search;
                //     self.props.googleAnalytics(googleKey, search, path)
                // }

                clearInterval(configinterval);
            }

        }, 1000);
    }
    buildPaymentRequest(payments) {
        let subPlanPrice = this.state.subPlanPrice
        if (subPlanPrice > 0) {
            const paymentRequest = payments.paymentRequest({
                countryCode: 'US',
                currencyCode: 'USD',
                total: {
                    amount: subPlanPrice.toString(),
                    label: 'Total',
                },
            });
            return paymentRequest;
        }

    }
    applePay(applePayInstance) {
        // Set up your Apple Pay button here
        let self = this;
        let siteSettings = window.site;
        let appSettings = window.app
        let siteConfig = (siteSettings && siteSettings.config) || (appSettings && appSettings.defaultSiteSettings && appSettings.defaultSiteSettings.config);
        let paymentRequest = applePayInstance.createPaymentRequest({
            total: {
                label: siteConfig.siteTitle,
                amount: self.state.assetprice.toFixed(2).toString()
            },

            // We recommend collecting billing address information, at minimum
            // billing postal code, and passing that billing postal code with
            // all Apple Pay transactions as a best practice.
            requiredBillingContactFields: ["postalAddress"]
        });

        var session = new ApplePaySession(3, paymentRequest);
        session.onvalidatemerchant = function (event) {
            applePayInstance.performValidation({
                validationURL: event.validationURL,
                displayName: siteConfig.siteTitle
            }).then(function (merchantSession) {
                session.completeMerchantValidation(merchantSession);

            }).catch(function (validationErr) {
                // You should show an error to the user, e.g. 'Apple Pay failed to load.'
                console.error('Error validating merchant:', validationErr);
                session.abort();
            });
        };
        session.onpaymentauthorized = function (event) {

            applePayInstance.tokenize({
                token: event.payment.token
            }, function (tokenizeErr, payload) {
                if (tokenizeErr) {
                    console.error('Error tokenizing Apple Pay:', tokenizeErr);
                    session.completePayment(ApplePaySession.STATUS_FAILURE);
                    return;
                }

                // Send payload.nonce to your server.
                self.paymentCall(payload.nonce, false)
                // If requested, address information is accessible in event.payment
                // and may also be sent to your server.

                // After you have transacted with the payload.nonce,
                // call `completePayment` to dismiss the Apple Pay sheet.
                session.completePayment(ApplePaySession.STATUS_SUCCESS);
            });
        };
        session.begin();
    }
    async initializeCard(payments) {
        let cardSource = await payments.card();
        $("#card-container").empty();
        await cardSource.attach('#card-container');
        card = cardSource;
    }
    async initializeApplePay(payments) {
        console.log("intialize apple pay")
        let self = this;
        // let envoiScreeningApiBaseUrl = siteSettings && siteSettings.screeningApiBaseUrl;
        const paymentRequest = this.buildPaymentRequest(payments);
        let useremail = localStorage.getItem("email");
        let squarePlanId = this.state.squarePlanId
        let subPlanName = this.state.planName
        let referencId = window.app.appName + "_" + squarePlanId + "_" + subPlanName + "_" + useremail
        let applePaySource = await payments.applePay(paymentRequest, {
            redirectURL: window.location.href,
            referenceId: referencId,
        });
        // const buttonOptions = {
        //     shape: 'semiround',
        //     width: 'full',
        // };
        squareApplePay = applePaySource
        //await applePaySource.attach('#square-apple-pay-button');
        console.log("squareApplePay intitalize ", squareApplePay)

        const applePayButton = document.getElementById('square-apple-pay-button');
        applePayButton.addEventListener('click', async function (event) {
            //console.log("squareGooglePay intitalize ", squareGooglePay)
            //console.log("event ", event)
            const tokenResult = await squareApplePay.tokenize();
            if (tokenResult.status === 'OK') {
                self.paymentSquare(tokenResult.token)
            } else {
                let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
                if (tokenResult.errors) {
                    errorMessage += ` and errors: ${JSON.stringify(
                        tokenResult.errors
                    )}`;
                }
            }
        });


        // return applePay;
    }


    async initializeGooglePay(payments) {
        //squareGooglePay = true
        let self = this;
        $("#square-google-pay-button").empty();
        if (squareGooglePay == false) {
            const paymentRequest = this.buildPaymentRequest(payments)

            const googlePay = await payments.googlePay(paymentRequest);
            squareGooglePay = googlePay
            //await googlePay.attach('#google-pay-button');
            await googlePay.attach('#square-google-pay-button', {
                buttonColor: 'white',
                buttonSizeMode: 'static',
                buttonType: 'long'
            });


            const googlePayButton = document.getElementById('square-google-pay-button');
            googlePayButton.addEventListener('click', async function (event) {
                //console.log("squareGooglePay intitalize ", squareGooglePay)
                //console.log("event ", event)
                const tokenResult = await squareGooglePay.tokenize();
                if (tokenResult.status === 'OK') {
                    self.paymentSquare(tokenResult.token)
                } else {
                    let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
                    if (tokenResult.errors) {
                        errorMessage += ` and errors: ${JSON.stringify(
                            tokenResult.errors
                        )}`;
                    }


                }
                //await handlePaymentMethodSubmission(event, googlePay);
            });
        }
        // return squareGooglePay;
    }
    async initializeCashApp(payments) {
        console.log("intialize cash app")
        let self = this;
        const paymentRequest = this.buildPaymentRequest(payments);
        let squarePlanId = this.state.squarePlanId
        let subPlanName = this.state.planName
        let useremail = localStorage.getItem("email");
        let referencId = window.app.appName + "_" + squarePlanId + "_" + subPlanName + "_" + useremail
        let cashAppPaySource = await payments.cashAppPay(paymentRequest, {
            redirectURL: window.location.href,
            referenceId: referencId,
        });
        const buttonOptions = {
            shape: 'semiround',
            width: 'full',
        };
        cashAppPay = cashAppPaySource
        await cashAppPaySource.attach('#cash-app-pay', buttonOptions);
        console.log("cashAppPay intitalize ", cashAppPay)

        cashAppPay.addEventListener(
            'ontokenization',
            async function (event) {
                console.log("event ", event)
                const { tokenResult } = event.detail;
                console.log("tokenResult ", tokenResult)
                if (tokenResult.status === 'OK') {
                    self.paymentSquare(tokenResult.token)
                    // const paymentResults = await createPayment(tokenResult.token);
                    // console.debug('Payment Success', paymentResults);
                } else {
                    let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;

                    if (tokenResult.errors) {
                        errorMessage += ` and errors: ${JSON.stringify(
                            tokenResult.errors
                        )}`;
                    }
                    // $("#overlay").hide();
                    // $("#braintreeErrorPopup").show()
                    // $(".braintreeError").html(errorMessage);
                    //throw new Error(errorMessage);
                }
            }
        );
        //return cashAppPay;
    }

    paymentSquare(paymentToken) {
        let self = this
        var dateend = new Date();
        var dataparams = "planId=" + self.state.squarePlanId + "&subscription_end=" + dateend + "&payment_method_nonce=" + paymentToken + "&token=" + token + '&cardholderName=' + self.state.cardholderName + '&price=' + self.state.price + '&transactionType=subscription' + '&itemType=subscription' + '&userassetType=subscription';

        if (locationId) {
            dataparams = dataparams + "&paymentCurrency=USD&gatewayType=cashapp&sourceId=" + paymentToken + "&locationId=" + locationId;
        }
        console.log('dataparams', dataparams)
        //   if (subscriptionType == 'subscription') {
        let emailBody = [{
            "assetid": self.state.squarePlanId,
            "single_price": self.state.price,
            "userassetType": "subscription",
            "assetname": self.state.planName,
            // "sku": self.state.sku,
            // "envoisku": self.state.envoisku,
        }]
        console.log('emailBody', emailBody)
        if (self.state.sku) {
            dataparams = dataparams + "&sku=" + self.state.sku;
        }
        if (self.state.envoisku) {
            dataparams = dataparams + "&envoisku=" + self.state.envoisku;
        }
        //let siteSettings = window.site;
        //let envoiSubmissionApiBaseUrl = siteSettings && siteSettings.submissionApiBaseUrl;
        let envoiScreeningApiBaseUrl = siteSettings && siteSettings.screeningApiBaseUrl;
        axios.post(envoiScreeningApiBaseUrl + '/paymentAuth?appname=' + window.app.appName + '&env=' + window.app.appEnvName + '&' + dataparams, emailBody)
            .then((response) => {
                // $("#overlay").addClass("hidediv");
                if (response && response.data && response.data.statusCode == 200) {
                    // if (token) {
                    //     self.setState({ isSubscrirptionSuccess: true })
                    // }
                    // self.props.createIVSChannel();
                    // localStorage.setItem("status", 'active');
                    // let userinfo = { status: "active" }
                    // self.props.updateUserRelation(userinfo)
                    browserHistory.push("/myaccount")
                } else {
                    $("#error").html("Credit Card Validation error")
                }
            })
            .catch((err) => {
                console.error.bind(err);
            })
        //   }
    }
    async cardPayment() {
        let self = this
        const tokenResult = await card.tokenize();
        if (tokenResult.status === 'OK') {
            console.log(tokenResult.token);
            self.paymentSquare(tokenResult.token)


        } else {
            let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
            if (tokenResult.errors) {
                errorMessage += ` and errors: ${JSON.stringify(
                    tokenResult.errors
                )}`;
            }
            $("#error").html(errorMessage)
        }
    }
    braintreeSetup() {
        let self = this;
        let siteSettings = window.site;
        let siteConfig = (siteSettings && siteSettings.config);
        let clientToken;
        let braintreeSettings = siteConfig.paymentGateway || {};
        if (braintreeSettings.enable === true) {
            clientToken = (braintreeSettings.braintree || {}).gatewayToken; //Braintree Tree Token
        }
        let stylesConfig = {
            // Style all elements
            'input': {
                'font-size': '30px',
                'color': '#ffffff'
            },
            // Styling element state
            ':focus': {
                'color': '#ffffff'
            },
            '.valid': {
                'color': '#6e9e12'
            },
            '.invalid': {
                'color': 'red'
            },
            '@media screen and (max-width: 700px)': {
                'input': {
                    'font-size': '14px'
                }
            }
        }
        let fieldsConfig = {
            number: {
                selector: "#card-number",
                placeholder: "****************"
            },
            cvv: {
                selector: "#cvv",
                placeholder: "***"
            },
            expirationMonth: {
                selector: "#expiration-month",
                placeholder: "MM"
            },
            expirationYear: {
                selector: "#expiration-year",
                placeholder: "YY"
            },
            postalCode: {
                selector: "#postal-code",
                placeholder: "******"
            }
        }

        let submitBtn = document.getElementById('paySubmit');
        let paypalButton = document.querySelector('#pay-pal-button');
        var googlepayButton = document.querySelector('#google-pay-button');
        var applePayButton = document.querySelector('#apple-pay-button');
        let environment = clientToken && clientToken.substr(0, clientToken.indexOf('_')) == 'production' ? 'PRODUCTION' : 'TEST';
        // var paymentsClient = new google.payments.api.PaymentsClient({
        //     environment: environment // or 'PRODUCTION'
        // });
        braintree.client.create({ authorization: clientToken }).then(function (client) {
            console.log('submitBtn', submitBtn)


            braintree.googlePayment.create({
                client: client,
                googlePayVersion: 2,
                googleMerchantId: siteConfig && siteConfig.paymentGateway && siteConfig.paymentGateway.braintree && siteConfig.paymentGateway.braintree.paymentMethods && siteConfig.paymentGateway.braintree.paymentMethods.googlePayMerchantId && siteConfig.paymentGateway.braintree.paymentMethods.googlePayMerchantId.toString()
            }).then(function (googlePaymentInstance) {
                googlepayButton.addEventListener('click', function (event) {
                    var paymentDataRequest;

                    event.preventDefault();
                    paymentDataRequest = googlePaymentInstance.createPaymentDataRequest({
                        transactionInfo: {
                            currencyCode: 'USD',
                            totalPriceStatus: 'FINAL',
                            totalPrice: self.state.assetprice && self.state.assetprice.toString()
                        }
                    });

                    // paymentsClient.loadPaymentData(paymentDataRequest).then(function (paymentData) {
                    //     return googlePaymentInstance.parseResponse(paymentData);
                    // }).then(function (result) {
                    //     self.paymentCall(result.nonce, false)
                    //     // send result.nonce to your server
                    // }).catch(function (err) {
                    //     // handle err
                    // });
                });
            });
            if (window.ApplePaySession && ApplePaySession.supportsVersion(3) && ApplePaySession.canMakePayments()) {
                // This device supports version 3 of Apple Pay.
                self.setState({ isAppleandGoogleEnabled: true });

            } else {
                self.setState({ isAppleandGoogleEnabled: false });
            }
            braintree.paypal.create({
                client: client
            }, function (paypalErr, paypalInstance) {
                if (paypalErr) {
                    console.error('Error creating PayPal:', paypalErr);
                    return;
                }


                paypalButton.removeAttribute('disabled');

                // When the button is clicked, attempt to tokenize.
                paypalButton.addEventListener('click', function (event) {
                    // Because tokenization opens a popup, this has to be called as a result of
                    // customer action, like clicking a button. You cannot call this at any time.
                    paypalInstance.tokenize({
                        flow: 'vault'
                        // For more tokenization options, see the full PayPal tokenization documentation
                        // https://braintree.github.io/braintree-web/current/PayPal.html#tokenize
                    }, function (tokenizeErr, payload) {
                        if (tokenizeErr) {
                            if (tokenizeErr.type !== 'CUSTOMER') {
                                console.error('Error tokenizing:', tokenizeErr);
                            }
                            return;
                        }

                        // Tokenization succeeded
                        paypalButton.setAttribute('disabled', true);
                        self.paymentCall(payload.nonce, false)
                    });
                }, false);
            }); braintree.applePay.create({
                client: client
            }, function (applePayErr, applePayInstance) {
                if (applePayErr) {
                    console.error('Error creating applePayInstance:', applePayErr);
                    return;
                }
                applePayButton.addEventListener('click', function (event) {
                    self.applePay(applePayInstance);
                });
            });
            return braintree.hostedFields.create({
                client: client,
                styles: stylesConfig,
                fields: fieldsConfig
            });
            console.log('clientToken', clientToken)
        }).then(function (hostedFields) {
            console.log('clientToken123', clientToken)
            submitBtn.addEventListener('click', function (event) {
                event.preventDefault();
                // if (hostedFields.tokenize()) {
                console.log('clientToken5ttttthththththty', clientToken)



                hostedFields.tokenize().then(function (payload) {
                    // send payload.nonce to your server

                    // const err = self.validate();

                    let contentType = localStorage.getItem("contentType");
                    const { assetDetailsData } = self.state;
                    console.log('assetDetailsData', assetDetailsData)
                    let subscriptionPrice = contentType && assetDetailsData && assetDetailsData.availability && assetDetailsData.availability.buyplans && assetDetailsData.availability.buyplans[contentType] && assetDetailsData.availability.buyplans[contentType].contentPrice && assetDetailsData.availability.buyplans[contentType].contentPrice.planPrice;
                    // console.log('subscriptionPrice', subscriptionPrice)
                    let assetid;
                    let assetname;
                    let sku;
                    if (assetDetailsData && assetDetailsData.assetid) {
                        assetid = assetDetailsData && assetDetailsData.assetid;
                        assetname = assetDetailsData && assetDetailsData.assetname;
                    } else {
                        assetid = assetDetailsData && assetDetailsData.channelcode;
                        assetname = assetDetailsData && assetDetailsData.channelname;
                    }
                    sku = assetDetailsData && assetDetailsData.quickbooksId;
                    let envoisku = assetDetailsData && assetDetailsData.envoisku;


                    var dateend = new Date();
                    var dataparams = "planId=" + assetid + "&subscription_end=" + dateend + "&payment_method_nonce=" + payload.nonce + "&token=" + token + '&cardholderName=' + self.state.cardholderName + '&price=' + subscriptionPrice + '&type=' + self.state.planid;
                  //  if (subscriptionType == 'product') {
                        let emailBody = [{
                            "assetid": assetid,
                            "single_price": subscriptionPrice,
                            "userassetType": "product",
                            "assetname": assetname,
                            "sku": sku,
                            "envoisku": envoisku,
                        }]
                        if (sku) {
                            dataparams = dataparams + "&sku=" + sku;
                        }
                        if (envoisku) {
                            dataparams = dataparams + "&envoisku=" + envoisku;
                        }
                        let envoiScreeningApiBaseUrl = siteSettings && siteSettings.screeningApiBaseUrl;
                        axios.post(envoiScreeningApiBaseUrl + '/paymentAuth?appname=' + window.app.appName + '&env=' + window.app.appEnvName + '&' + dataparams, emailBody)
                            .then((response) => {
                                $("#overlay").addClass("hidediv");
                                if (response && response.data && response.data.statusCode == 200) {
                                    // self.props.getuser(token);
                                    // let assetId = localStorage.getItem("assetId");
                                    // window.location = "/buyAsset?assetid=" + assetId;
                                    let assetId = localStorage.getItem("assetId");
                                    window.location = "/player?asset=" + assetId;
                                    // if (myaccountRoute) {
                                    //     browserHistory.push("/myaccount");
                                    // } else if (response.data.result && response.data.result[0] && response.data.result[0].isVerified) {
                                    //     localStorage.setItem("usub", response.data.result[0].subscriptionplanid || self.state.planid);
                                    //     browserHistory.push(defaultRoute);
                                    // } else if (verifyOtp == false) {
                                    //     browserHistory.push(defaultRoute);
                                    // } else if (!requiredSelectDevice && requiredVerification) {
                                    //     browserHistory.push("/activation");
                                    // } else if (requiredSelectDevice) {
                                    //     browserHistory.push("/adddevice");
                                    // } else {
                                    //    // self.props.navigateLanding()
                                    // }
                                } else {
                                    $("#error").html("Credit Card Validation error")
                                }
                            })
                            .catch((err) => {
                                console.error.bind(err);
                            })
                    
                }).catch(function (err) {
                    $("#error").html(err.message)
                });
                // }
            });
        });

    }

    addressChange(e) {
        var self = this;
        const name = e.target.name;
        var value = e.target.value;
        if (name === "countryCodeAlpha2") {
            self.props.getregions(value)
        }
        customer_address[name] = value;
        this.setState({ [name]: value, customer_address });
    }
    handletextChange(e) {
        var self = this;
        if (!!this.state.errors[e.target.name]) {
            let errors = Object.assign({}, this.state.errors);
            delete errors[e.target.name];
            customer_address[e.target.name] = e.target.value;
            this.setState({
                [e.target.name]: e.target.value,
                errors,
                customer_address
            });
        } else {
            customer_address[e.target.name] = e.target.value;
            self.setState({
                [e.target.name]: e.target.value,
                customer_address
            });
        }
    }
    handlecheckboxChange() {
        if (this.state.adr && this.state.adr.postalCode) {
            this.setState({ checked: !this.state.checked, postalCode: this.state.adr.postalCode });
        } else {
            this.setState({ checked: !this.state.checked })
        }
    }
    handleOptionChange(e, price, planid, plan) {
        var self = this;

        // self.setState({
        //     price: price,
        //     [e.target.name]: e.target.value
        // });
        self.setState({
            price: price,
            [e.target.name]: planid,
            planName: plan.planName,
            squarePlanId: plan.squarePlanId,
            subPlanPrice: price
        });
        if (price == "")
            self.setState({ price: 0 })

        if (plan && plan.quickbooksId) {
            self.setState({ sku: plan.quickbooksId })
        }
    }
    validate() {
        let isError = false;
        let errors = {};

        if (this.state.cardholderName === undefined || this.state.cardholderName === '') {
            isError = true;
            errors.cardholderName = "Enter Cardholder Name";
        }
        this.setState({ errors });
        return isError;
    }

    paymentTypeOption(e) {

        var optionId = e.target.id;
        $(".paymentopt").addClass("hidediv");
        if (optionId == "cashpay") {
            $("#" + optionId + "-container").removeClass("hidediv");
            $("#creditcard-braintree").addClass("hidediv");
        }
        else {
            $("#" + optionId + "card").removeClass("hidediv");
            $("#creditcard-braintree").removeClass("hidediv");
            document.getElementById("cardcc").checked = true;

        }

        console.log("cashapp ", appId, locationId, card, cashAppPay)
        console.log("card ", card)
        console.log("cashAppPay ", cashAppPay)
        if (optionId == "cashpay" && appId && locationId) {


            payments = window.Square.payments(appId, locationId);
            if (!card) {
                this.initializeCard(payments);
            }
            if (!cashAppPay) {
                console.log("payments ", payments)
                this.initializeCashApp(payments);
                this.initializeGooglePay(payments);
                this.initializeApplePay(payments);
            }
        }


    }
    paymentOption(e) {
        var optionId = e.target.id;
        $(".paymentopt").addClass("hidediv");
        if (optionId == "paypal" || optionId == "gpay" || optionId == "applepay") {
            $("#" + optionId + "-container").removeClass("hidediv");
        }
        // else if (optionId == "gpay") {
        //     $("#" + optionId + "-container").removeClass("hidediv");
        // } else if (optionId == "applepay") {
        //     $("#" + optionId + "-container").removeClass("hidediv");
        // } 
        else {
            $("#creditcard").removeClass("hidediv");

        }
    }
    logout() {
        if (signinLock == undefined) {
            signinLock = new Auth0Lock(clientId, domain, {
                auth: {
                    responseType: 'token id_token',
                }
            });
            signinLock.logout({
                returnTo: '/'
            });
        }
        localStorage.clear();
        window.location = '/';
    }

    handleChange(e, item) {
        let self = this;
        let name = e.target.name;
        let value = e.target.value;
        self.setState({ [name]: value });
    }
    payUaddressChange(e) {
        var self = this;
        const name = e.target.name;
        const value = e.target.value;
        self.setState({ [name]: value });
    }
    paymentCall(nonce, paymentType, quickboksID, envoiSku) {
        let self = this;

        const { subPlanId, assetprice, assetname, actionURL } = self.state;
        let contentType = localStorage.getItem("contentType");
        let itemType = "subscription";
        let arrayAssets = [{
            assetid: subPlanId,
            assetName: assetname
        }]

        self.props.getPricing(token, nonce, assetprice, arrayAssets, self.state.address1, self.state.city, contentType, itemType, "subscription", null, quickboksID, envoiSku);
        $("#overlay").removeClass("hidediv");
        if (paymentType) {
            setTimeout(function () {
                $('#payUPayment').attr('action', actionURL).submit();

            }, 2000);
        }
    }
    payUsubmit(e) {
        e.preventDefault();

        let self = this
        const { merchantKey, txnId, assetprice, assetname, merchantSalt, userinfo } = self.state;

        let userfirstname = userinfo.firstname
        let userEmail = userinfo.emailid
        let hashSequence = merchantKey + "|" + txnId + "|" + assetprice + "|" + assetname + "|" + userfirstname + "|" + userEmail + "|||||||||||" + merchantSalt

        let hash = cryptoJs.SHA512(hashSequence).toString()
        self.setState({ hash }, () => {
            self.paymentCall(txnId, "PayU", null, null);

        });

    }
    render() {
        let self = this;
        let siteSettings = window.site;
        let appSettings = window.app
        let siteConfig = (siteSettings && siteSettings.config) || (appSettings && appSettings.defaultSiteSettings && appSettings.defaultSiteSettings.config);
        let imagePrefix = siteConfig.commonResourcesUrl + siteConfig.appResourcesPath;
        let imageSuffix = siteConfig.imageSuffix || "?auto=compress,format";
        let defaultLandscape = siteConfig.defaultLandscape;
        let payment_bg = imagePrefix + "images/landBg_landscape8.jpg";
        if (this.props.windowWidth < 801) {
            payment_bg = imagePrefix + "images/landBg_portrait8.jpg";
        }
        let color = { color: "#f00" }
        let scolor = { color: "#fff" }
        const { assetSubscription, planid, assetprice, assetname, assetDetailsData, merchantKey, txnId, hash, userinfo, isAppleandGoogleEnabled } = this.state;
        const { priceFormat } = this.props;
        // console.log('trailerAssetinfo', assetDetailsData)
        let paymentType = localStorage.getItem("a");
        let contentType = localStorage.getItem("contentType");
        let redirectURL = ((siteConfig.paymentGateway || {}).payuIndia || {}).redirectURI

        let successURL = redirectURL + "?location=" + window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + "/boughtsuccess"
        let failedURL = redirectURL + "?location=" + window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + "/boughtsuccess"

        return (
            <div>
                <div id="overlay" className="hidediv">
                    <div className="preLoader">
                        <div className="loftloader-wrapper pl-frame">
                            <div className="loader">
                                <div className="spark"></div>
                                <img src={window.site.preLoader} />
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref="headermenu" className={this.state.isTop ? 'header_det pmt_dt' : 'header_det pmt_dt up'}>
                    <div className="wrapper">
                        <div className="logo"><img src={imagePrefix + "screening/images/headerlogo.png"} /></div>
                        <span style={{ "right": "10px" }} title="Go back" alt="Go back" onClick={browserHistory.goBack} className="back"></span>
                    </div>
                </div>

                <div className="payment_page subscription_page">
                    <h2>ENTER PAYMENT INFO</h2>
                    <div className="payment_wrp">
                        <div className="pmt_lft">
                            <h3><span className="bold">STEP 3</span> OF 3</h3>
                            <div className="lock_blk">Secure Server<br />
                                Tell me more</div>
                        </div>
                        <div className="pmt_rgt">

                            <div className="pmt_blk">
                                <p className="ps3">ACCOUNT CREATED FOR: {localStorage.getItem("email")}</p>
                            </div>

                            <div className="pmt_blk">
                                <h3>YOUR SUBSCRIPTION</h3>
                                <div className="row">
                                    <div className="col-md-6 col">
                                        <div className="form-group">
                                            <input type="radio" name="planid" value={contentType} checked={true} />
                                            <label>{contentType && contentType.toUpperCase()}</label>

                                        </div>
                                    </div>
                                    <div className="col-md-6 col">
                                        <h4>{contentType && assetDetailsData && assetDetailsData.availability && assetDetailsData.availability.buyplans && priceFormat(assetDetailsData.availability.buyplans[contentType].contentPrice.planPrice, 'USD', 'en-US')}/<span className="h4s">mo</span></h4>
                                    </div>
                                </div>
                            </div>

                            {siteConfig && siteConfig.paymentGateway && siteConfig.paymentGateway.key == "braintree" ?
                                <>

                                    <div className="pmt_blk border-none">


                                        <h3>PAYMENT METHOD</h3>

                                        <div className="row">
                                            <div className="col-md-6 col">
                                                <div className="form-group payment-mode"><input type="radio" id="credit" name="paymentSetType" value="creditcard" onClick={e => this.paymentTypeOption(e)} /><label>BRAINTREE</label></div>

                                            </div>
                                            <div className="col-md-6 col">
                                                <div className="form-group payment-mode"><input type="radio" id="cashpay" name="paymentSetType" value="cashpay" onClick={e => this.paymentTypeOption(e)} /><label>CASH APP</label></div>

                                            </div>
                                            {/* <div className="col-md-6 hidediv">
                                                <div className="form-group"><input type="radio" id="paypal" name="paymenttype" value="paypal" onClick={e => this.paymentOption(e)} /><label>PAYPAL</label></div>

                                            </div> */}
                                        </div>
                                        <div id="creditcard-braintree" className="braintree_payment">

                                            <div>
                                                <div className="form-group payment-mode"><input type="radio" id="cardcc" name="paymenttype" value="cardcc" onClick={e => this.paymentOption(e)} /><label>CARD</label></div>

                                            </div>
                                            {siteConfig && siteConfig.paymentGateway && siteConfig.paymentGateway.braintree && siteConfig.paymentGateway.braintree.paymentMethods && siteConfig.paymentGateway.braintree.paymentMethods.paypal &&
                                                <div>
                                                    <div className="form-group payment-mode"><input type="radio" id="paypal" name="paymenttype" value="paypal" onClick={e => this.paymentOption(e)} /><label>PAYPAL</label></div>

                                                </div>
                                            }
                                            {siteConfig && siteConfig.paymentGateway && siteConfig.paymentGateway.braintree && siteConfig.paymentGateway.braintree.paymentMethods && siteConfig.paymentGateway.braintree.paymentMethods.googlePay &&
                                                <div>
                                                    <div className="form-group payment-mode"><input type="radio" id="gpay" name="paymenttype" value="gpay" onClick={e => this.paymentOption(e)} /><label>GOOGLE PAY</label></div>

                                                </div>
                                            }
                                            {isAppleandGoogleEnabled && siteConfig && siteConfig.paymentGateway && siteConfig.paymentGateway.braintree && siteConfig.paymentGateway.braintree.paymentMethods && siteConfig.paymentGateway.braintree.paymentMethods.applePay &&
                                                <div>
                                                    <div className="form-group payment-mode"><input type="radio" id="applepay" name="paymenttype" value="applepay" onClick={e => this.paymentOption(e)} /><label>APPLE PAY</label></div>

                                                </div>
                                            }
                                        </div>
                                        <div className="cc_det">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label>CARDHOLDER NAME</label>
                                                        <input type="text" className="hosted-field" value={this.state.cardholderName} name="cardholderName" onChange={e => this.handletextChange(e)} />
                                                        <span className="errormsg" >{this.state.errors.cardholderName}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cc_det paymentopt" id="creditcard">
                                            <form id="cardForm" method="post">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>CARD NUMBER</label>
                                                            <div id="card-number" className="hosted-field"></div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="form-group inline-st">
                                                            <label className="pmt_lbl">EXPIRATION</label>
                                                            <div id="expiration-month" className="hosted-field"></div>
                                                            <div id="expiration-year" className="hosted-field"></div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>CVV</label>
                                                            <div id="cvv" className="hosted-field"></div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>ZIP CODE</label>

                                                            <div id="postal-code" className="hosted-field"></div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div id="error" style={color} >{self.state.errMessage}</div>
                                                <p className="free_trl">By selecting “Start {siteConfig.siteTitle} Account”, you agree to authorize this charge and to the &nbsp;
                                                    <NavLink href="#" to='/terms' target="_blank" className="tos">Terms of Service.</NavLink></p>
                                                <div className="form-group">
                                                    <input type="button" id="paySubmit" value="Submit" className="submit" />

                                                </div>
                                            </form>
                                        </div>
                                        <div id="paypal-container" className="paymentopt cc_det hidediv">
                                            <div className="text-left"><button id="pay-pal-button" className="submit">Paypal Checkout</button></div>
                                        </div>
                                        <div id="gpay-container" className="paymentopt cc_det hidediv">
                                            <div className="text-left"><button id="google-pay-button" className="paypal-button btn" ><img src={imagePrefix + "screening/images/common-images/gpay.svg"} /></button></div>
                                        </div>
                                        <div id="applepay-container" className="paymentopt cc_det hidediv">
                                            <div className="text-left"><button id="apple-pay-button" className="submit">Apple Pay</button></div>
                                        </div>
                                        <div id="cashpay-container" className="paymentopt cc_det hidediv">

                                            <div id="cashAppPay">
                                                <div id="cash-app-pay"></div>
                                            </div>
                                            {/* <label className="text-center">(or)</label> */}
                                            <div id="squareAppApplepay">
                                                <div id="square-apple-pay-button" className='applepaySquare'>Pay</div>
                                            </div>

                                            <div id="squareAppGooglepay">
                                                <div id="square-google-pay-button"></div>
                                            </div>
                                            <div id="cardContainer">
                                                <div id="card-container"></div>
                                            </div>

                                            <p className="free_trl">By selecting “Start {siteConfig.siteTitle} Account”, you agree to authorize this charge and to the &nbsp;
                                                <NavLink href="/terms" target="_blank" className="tos">Terms of Service.</NavLink></p>
                                            <div className="form-group">
                                                <button id="card-button" onClick={(e) => self.cardPayment(e)} type="button" className="submit">Submit</button>
                                            </div>

                                        </div>
                                    </div>
                                    {
                                        self.state.addressVisible && self.state.addressVisible == true ?
                                            <div className="cc_det ">
                                                <h3>BILLING ADDRESS</h3>

                                                {self.state.addressVisible == "true" ?
                                                    <div>
                                                        <div className="bill_check">
                                                            <input type="checkbox" onClick={this.handlecheckboxChange} name="billcheck" /><label>Billing address is same as shipping address</label>

                                                        </div>
                                                        <br />
                                                    </div>
                                                    : null}

                                                <div className="row col_pad0">

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>CARDHOLDER ADDRESS</label>
                                                            <input type="text" value={this.state.streetAddress ? this.state.streetAddress : this.state.checked && this.state.adr ? this.state.adr.streetAddress : ''} placeholder="Street address, P.O. box, Company name, C/o" name="streetAddress" onChange={e => this.addressChange(e)} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>ADDRESS 2</label>
                                                            <input type="text" value={this.state.extendedAddress ? this.state.extendedAddress : this.state.checked && this.state.adr ? this.state.adr.extendedAddress : ''} placeholder="Apartment, suite, unit, building, floor etc" name="extendedAddress" onChange={e => this.addressChange(e)} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>COUNTRY</label>
                                                            <select value={this.state.countryCodeAlpha2 ? this.state.countryCodeAlpha2 : this.state.checked && this.state.adr ? this.state.adr.countryCodeAlpha2 : this.state.countrydefault} name="countryCodeAlpha2" className="colorselect capitalize" onChange={e => this.addressChange(e)}>
                                                                <option value="">Select Country</option>
                                                                {this.props.countries && this.props.countries.map((task, i) => {
                                                                    return (
                                                                        <option key={i} value={task.alpha2}>{task.countryname}</option>
                                                                    )
                                                                }
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label>STATE / PROVINCE / REGION</label>
                                                            <select value={this.state.region ? this.state.region : this.state.checked && this.state.adr ? this.state.adr.region : this.state.regiondefault} name="region" className="colorselect capitalize" onChange={e => this.addressChange(e)}>
                                                                <option value="">Select State / Province / Region</option>
                                                                {!this.props.loading && this.props.regions && this.props.regions.map((task, i) => {
                                                                    return (
                                                                        <option key={i} value={task.regionname}>{task.regionname}</option>
                                                                    )
                                                                }
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>CITY</label>
                                                            <input type="text" className="capitalize" value={this.state.locality ? this.state.locality : this.state.checked && this.state.adr ? this.state.adr.locality : ''} name="locality" onChange={e => this.addressChange(e)} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>ZIP / POSTAL CODE</label>

                                                            <input type="number" id="postalCode" value={this.state.postalCode ? this.state.postalCode : this.state.checked && this.state.adr ? this.state.adr.postalCode : ''} onChange={e => this.handletextChange(e)} name="postalCode" />
                                                            <p className="errorhelp2" style={color}>{this.state.errors.postalCode}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr /></div>
                                            : null
                                    }

                                    {/* < p className="free_trl">By selecting “Start {Title} Account”, you agree to authorize this charge and to the &nbsp;
                                        <NavLink href="#" to='/terms' target="_blank" className="tos">Terms of Service.</NavLink></p>
                                    <div className="form-group">
                                        <input type="button" id="paySubmit" value="Submit" className="submit" />

                                    </div> */}

                                </>
                                :


                                <form action="" method='post' id="payUPayment">
                                    <div className="cc_det paymentopt" id="creditcard">

                                        <input type="hidden" name="key" value={merchantKey} />
                                        <input type="hidden" name="txnid" value={txnId} />
                                        <input type="hidden" name="productinfo" value={assetname} />
                                        <input type="hidden" name="furl" value={failedURL} />
                                        <input type="hidden" name="amount" value={assetprice} />
                                        <input type="hidden" name="surl" value={successURL} />
                                        <input type="hidden" name="hash" value={hash} />
                                        <input type="hidden" name="firstname" value={userinfo && userinfo.firstname} />
                                        <input type="hidden" name="lastname" value={userinfo && userinfo.lastname} />
                                        <input type="hidden" name="email" value={userinfo && userinfo.emailid} />
                                        <input type="hidden" name="phone" value={userinfo && userinfo.phone} />

                                        <div className="row">

                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>ADDRESS <span style={{ 'color': '#afafaf' }}></span></label>
                                                    <input type="text" value={this.state.optionalAddress} placeholder="Street address, P.O. box, Company name, C/o" name="address1" onChange={e => this.payUaddressChange(e)} />
                                                    <span className="errormsg" >{this.state.errors.optionalAddress}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label>CITY <span style={{ 'color': '#afafaf' }}></span></label>
                                                    <input type="text" value={this.state.optionalCity} placeholder="City" name="city" onChange={e => this.payUaddressChange(e)} />
                                                    <span className="errormsg" >{this.state.errors.optionalCity}</span>
                                                </div>
                                            </div>

                                        </div>


                                        <p className="free_trl">By selecting “Start {siteConfig.siteTitle} Account”, you agree to authorize this charge and to the &nbsp;
                                            <NavLink to='/terms' target="_blank" className="tos">Terms of Service.</NavLink></p>

                                        <div className="form-group">

                                            <span onClick={(e) => self.payUsubmit(e)} id="savedSubmit" className="submit paymentcardopt" >PAY</span>

                                        </div>


                                    </div>
                                </form>
                            }

                        </div>
                    </div>

                </div>

            </div>

        );
    }
}


const mapState = ({ user, address, countries, regions, Getpartners, assetDetailsData, channelAssetsProps, userinfo, assetPayment }) => ({ user, address, countries, regions, Getpartners, assetDetailsData, channelAssetsProps, userinfo, assetPayment });
const mapDispatch = { assetDetails, priceFormat, msToTime, getPricing, };
export default windowSize(connect(mapState, mapDispatch)(subscriptionPayment));
